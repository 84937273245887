import { useCallback, useState } from "react";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import LateCheckInModal from "@hotel-engine/app/modals/LateCheckInModal";
import TripExtensions from "@hotel-engine/app/TripExtensions";
import { ModalSteps } from "@hotel-engine/app/TripExtensions/constants";
import { useSearchParams } from "@hotel-engine/hooks";
import { useLocation } from "@hotel-engine/lib/react-router-dom";
import type { ILocationState as ITripExtensionLocationState } from "@hotel-engine/app/TripExtensions";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";

interface IItineraryActionParams {
  lateCheckin?: string;
}

interface IWithModalsProps {
  itinerary: IReservationBase;
  handleLateCheckInModal?: () => void;
  handleTripExtensionModal?: () => void;
}

export const withModificationsModals = (
  WrappedComponent: React.ComponentType<IWithModalsProps>
) => {
  return function WithModalsComponent(props: IWithModalsProps) {
    const params = useSearchParams<IItineraryActionParams>();
    const { refetchReservation } = useModificationsContext();
    const { state: locationState } = useLocation<ITripExtensionLocationState>();
    const [showLateCheckInModal, setShowLateCheckInModal] = useState(
      params?.lateCheckin === "true"
    );
    const [showTripExtensionModal, setShowTripExtensionModal] = useState(
      !!locationState?.startTripExtensionFlow
    );
    const [modalStep, setModalStep] = useState(ModalSteps.Invisible);

    const handleLateCheckInModal = useCallback(() => {
      setShowLateCheckInModal(!showLateCheckInModal);
    }, [showLateCheckInModal]);

    const handleTripExtensionModal = useCallback(() => {
      setModalStep(ModalSteps.Calendar);
      setShowTripExtensionModal(!showTripExtensionModal);
    }, [showTripExtensionModal]);

    return (
      <>
        <WrappedComponent
          {...props}
          handleLateCheckInModal={handleLateCheckInModal}
          handleTripExtensionModal={handleTripExtensionModal}
        />
        {!!showLateCheckInModal && (
          <LateCheckInModal
            contract={props.itinerary}
            onClose={handleLateCheckInModal}
            visible={showLateCheckInModal}
            refetchReservation={() => refetchReservation()}
            data-testid="late-check-in-modal"
          />
        )}
        {!!showTripExtensionModal && (
          <TripExtensions
            requestedModalStep={modalStep}
            setIsSearching={setShowTripExtensionModal}
          />
        )}
      </>
    );
  };
};
