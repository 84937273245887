import { ampli } from "ampli";
import { truncate } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { FixedSizeList } from "react-window";

import FilterPill from "@hotel-engine/app/FilterPill";
import { useBreakpoint } from "@hotel-engine/hooks";
import { useDepartmentsQuery } from "@hotel-engine/react-query/departments/useDepartmentsQuery";
import type { IDepartmentRecord } from "@hotel-engine/types/department";
import { Box, Icon, TextInput, Typography } from "@hotelengine/atlas-web";

import useTripsAdminAccess from "pages/Trips/hooks/useTripsAdminAccess";
import useTripsControl from "pages/Trips/hooks/useTripsControl";
import useTripsResult from "pages/Trips/hooks/useTripsResult";

import * as Styled from "./styles";
import { useTranslation } from "react-i18next";
const DEPARTMENT_ITEM_HEIGHT = 40;
const DEPARTMENT_CONTAINER_HEIGHT = DEPARTMENT_ITEM_HEIGHT * 5 + DEPARTMENT_ITEM_HEIGHT * 0.4;

function DepartmentFilters() {
  const { t } = useTranslation("trips");
  const { isLoading } = useTripsResult();
  const hasAdminClearance = useTripsAdminAccess();
  const isMobile = useBreakpoint("md", "max");
  const [params, tripsControl] = useTripsControl();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDepartments, setFilteredDepartments] = useState<IDepartmentRecord[]>([]);
  const departmentNames = useMemo(() => params?.["department_names"] ?? [], [params]);
  const [selectedDepartmentNames, setSelectedDepartmentNames] = useState(departmentNames);
  const isFilterApplied = !isLoading && !!departmentNames.length;
  const isFilterSelected = (!isLoading && !!selectedDepartmentNames.length) || !!searchQuery;

  const {
    data: allDepartmentData,
    isLoading: isDepartmentsLoading,
    isError: isDepartmentsError,
  } = useDepartmentsQuery({
    options: { enabled: hasAdminClearance },
  });

  const filterLabels = useMemo(() => {
    if (!departmentNames.length || isLoading) {
      return [t("departmentFilters.departments")];
    }

    if (departmentNames.length === 1) {
      return [truncate(departmentNames[0], { length: 20 })];
    }

    return [truncate(departmentNames[0], { length: 20 }), `+ ${departmentNames.length - 1} more`];
  }, [departmentNames, isLoading, t]);

  const departmentListContainerHeight =
    filteredDepartments.length < 6
      ? filteredDepartments.length * DEPARTMENT_ITEM_HEIGHT
      : DEPARTMENT_CONTAINER_HEIGHT;

  const resetDepartmentNames = () => {
    tripsControl.unsetParams("department_names");
  };

  const handleResetFilter = () => {
    setSelectedDepartmentNames([]);
    setSearchQuery("");

    if (departmentNames.length) {
      resetDepartmentNames();
    }
  };

  const handleSubmitFilter = () => {
    if (!isFilterSelected) {
      if (isFilterApplied) {
        resetDepartmentNames();
      }

      if (searchQuery) {
        setSearchQuery("");
      }

      return;
    }

    tripsControl.setParams({
      department_names: selectedDepartmentNames,
    });
  };

  useEffect(() => {
    if (!hasAdminClearance || isDepartmentsLoading || isDepartmentsError) return;

    if (allDepartmentData && Array.isArray(allDepartmentData)) {
      const searchExpression = new RegExp(searchQuery, "i");
      const matchingDepartments = allDepartmentData.filter(({ name }) =>
        name?.match(searchExpression)
      );

      setFilteredDepartments(matchingDepartments);
    }
  }, [allDepartmentData, hasAdminClearance, isDepartmentsError, isDepartmentsLoading, searchQuery]);

  useEffect(() => {
    setSelectedDepartmentNames(departmentNames);
  }, [departmentNames]);

  if (!hasAdminClearance) return null;

  return (
    <FilterPill
      isMobile={isMobile}
      labels={filterLabels}
      data-testid="trips-department-filters"
      isApplied={isFilterApplied}
      isDisabled={isLoading}
      hideResetIcon={!isFilterApplied}
      isTemporaryApplied={isFilterSelected}
      onReset={handleResetFilter}
      onSubmit={handleSubmitFilter}
    >
      <Box display="flex" flexDirection="column" gap={16} paddingLeft={2} paddingRight={2}>
        <Typography
          variant="body/md-strong"
          color="foregroundPrimary"
          id="department-filters-heading"
        >
          {t("departmentFilters.departments")}
        </Typography>

        <TextInput
          placeholder={t("departmentFilters.searchPlaceholder")}
          leadingAdornment={<Icon name="magnifying-glass" />}
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
          data-testid="departments-search-input"
        />

        <Box role="group" aria-labelledby="department-filters-heading">
          <FixedSizeList
            height={departmentListContainerHeight}
            itemCount={filteredDepartments.length}
            itemData={filteredDepartments}
            itemKey={(index, data) => `${data[index].id}-${index}`}
            itemSize={DEPARTMENT_ITEM_HEIGHT}
            overscanCount={30}
            width="100%"
          >
            {({ data, index, style }) => {
              const department = data[index];
              const isDepartmentSelected = selectedDepartmentNames.includes(department.name);

              const handleCheckedChange = () => {
                setSelectedDepartmentNames((prev) =>
                  isDepartmentSelected
                    ? prev.filter((item) => item !== department.name)
                    : [...prev, department.name]
                );

                ampli.clickCheckboxInBookingsDepartmentsFilter({
                  isChecked: !isDepartmentSelected,
                  department: department.name,
                  searchValue: searchQuery,
                });
              };

              return (
                <Box style={{ ...style, overflow: "hidden" }} key={department.id}>
                  <Styled.DepartmentCheckbox
                    aria-labelledby={department.id}
                    aria-label={department.name}
                    data-state={isDepartmentSelected ? "checked" : "unchecked"}
                    data-testid={`department-checkbox-${department.name}`}
                    isChecked={isDepartmentSelected}
                    onCheckedChange={handleCheckedChange}
                  >
                    <Typography variant="body/md" title={department.name}>
                      {department.name}
                    </Typography>
                  </Styled.DepartmentCheckbox>
                </Box>
              );
            }}
          </FixedSizeList>
        </Box>
      </Box>
    </FilterPill>
  );
}

export default DepartmentFilters;
