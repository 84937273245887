import { Link } from "@hotel-engine/lib/react-router-dom";

import { routes } from "@hotel-engine/constants";
import type {
  TNotificationCategory,
  TNotificationType,
} from "@hotel-engine/types/courierNotifications";

import PropertyReviewLink from "./PropertyReviewLink";
import { useAppDispatch } from "store/hooks";
import { SupportCaseDrawerActions } from "store/components/SupportCaseDrawer/SupportCaseDrawerRedux";

export interface INotificationLink {
  active_property_review_request_id?: string;
  category?: TNotificationCategory;
  contract_number?: string;
  handleNotificationAction: () => void;
  notification_type?: TNotificationType;
  support_case_id?: number;
}

export const NotificationLink = ({
  active_property_review_request_id,
  contract_number,
  handleNotificationAction,
  notification_type,
  support_case_id,
}: INotificationLink) => {
  const dispatch = useAppDispatch();
  const handleSupportCaseNotification = () => {
    dispatch(
      SupportCaseDrawerActions.setSupportCaseDrawerState({
        showDrawer: true,
        caseId: support_case_id,
      })
    );
    handleNotificationAction();
  };
  switch (notification_type) {
    case "abandoned_cart_reminder":
    case "expiring_he_rewards":
    case "expiring_travel_credits":
    case "travel_credit_received":
      return (
        <Link onClick={handleNotificationAction} to={routes.dashboard}>
          Book Now.
        </Link>
      );
    case "expired_credit_card":
      return (
        <Link onClick={handleNotificationAction} to={routes.paymentMethods}>
          Change card info.
        </Link>
      );
    case "invite_friends":
      return (
        <Link onClick={handleNotificationAction} to={routes.referFriends.base}>
          Invite more friends.
        </Link>
      );
    case "expired_referral":
      return (
        <Link onClick={handleNotificationAction} to={routes.referFriends.base}>
          View my invites.
        </Link>
      );
    case "loyalty_rewards":
    case "rebrand_loyalty_rewards":
      return (
        <Link onClick={handleNotificationAction} to={routes.settings.loyalty}>
          Link my account.
        </Link>
      );
    case "new_support_case":
      return (
        <Link to={{}} onClick={handleSupportCaseNotification}>
          View now.
        </Link>
      );
    case "property_review":
      return (
        <PropertyReviewLink
          onClick={handleNotificationAction}
          reviewID={active_property_review_request_id}
          contractNumber={contract_number}
        />
      );
    case "upcoming_trip_reminder":
    case "check_in_reminder":
    case "check_out_reminder":
      return (
        <Link
          onClick={handleNotificationAction}
          to={`${routes.itinerary}?contract_number=${contract_number}`}
        >
          Check your itinerary.
        </Link>
      );
    case "first_stay":
    case "five_referral_bonus":
      return (
        <Link onClick={handleNotificationAction} to={routes.travelCredits}>
          Check out your travel credit.
        </Link>
      );
    default:
      return null;
  }
};
