import config from "config";
import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import type { HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { addCustomFormatters } from "./formatters";
import get from "lodash/get";
/*
This is a virtual module, so we need to import it. It's being generated by the build process
check vite-local-plugins.ts, and https://vite.dev/guide/api-plugin.html#virtual-modules-convention
for more information
*/
import { hashes } from "virtual:i18n-cache-buster-hashes";

let i18n;

const LOCAL_NAMESPACES = [
  "common",
  "cars",
  "lodging",
  "cars_trips",
  "groups",
  "dashboard",
  "trips",
  "itinerary",
  "flights_flex",
  "company_settings",
];

export const initI18n = () => {
  if (i18n) {
    return i18n;
  }

  i18n = i18next
    .use(initReactI18next)
    .use(HttpApi)
    .init<HttpBackendOptions>({
      ns: [
        ...LOCAL_NAMESPACES,
        "iata_aircraft",
        "iata_airline",
        "iata_airport",
        "iata_city",
        "iata_loyalty",
        "iata_airline_shortname",
        "iata_airline_website",
        "iata_airline_checkin_url",
        "iata_airline_support_url",
        "car_rental_vendor_url",
        "car_rental_vendor_company_name",
        "car_rental_vendor_loyalty_program",
      ],
      lng: "en",
      fallbackLng: "en",
      supportedLngs: ["en", "pt-BR"],
      defaultNS: "common",
      backend: {
        loadPath: (languages, namespaces) => {
          const namespace = namespaces[0];
          if (LOCAL_NAMESPACES.includes(namespace)) {
            const pathLang = languages.find((lang) => lang.includes("en")) || languages[0];
            const version = get(hashes, `${pathLang}.${namespace}`);
            return `/assets/i18n/{{lng}}/{{ns}}.json${!!version ? `?v=${version}` : ""}`;
          } else {
            return `${config.engineContentCdn}/engine-content-static-resources/locales/{{lng}}/{{ns}}.json`;
          }
        },
      },
      interpolation: {
        escapeValue: false, // react already safe from xss
      },
    });

  addCustomFormatters(i18next.services?.formatter);

  return i18n;
};
