import type { BoxProps, ButtonProps, IconProps } from "@hotelengine/atlas-web";
import { Box, Button, Divider, Icon } from "@hotelengine/atlas-web";
import styled from "styled-components";

export const CollapsibleContainer = styled<React.FC<BoxProps>>(Box)`
  ${({ theme, $hasBottomMargin }) => `
    background-color: ${theme.colors.backgroundPrimary} !important;

    border: solid ${theme.borderWidth.normal} ${theme.colors.borderMinimal};
    border-radius: ${theme.borderRadius.lg};

    overflow: hidden;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    margin-bottom: ${$hasBottomMargin ? theme.spacing[24] : 0};
  `}
`;

export const CollapsibleToggleBtn = styled<React.FC<ButtonProps & { $isOpen: boolean }>>(
  Button
).attrs(() => ({
  color: "everlight",
}))`
  ${({ theme, $isOpen }) => `
    display: inline-block;
    margin-bottom: ${$isOpen ? theme.spacing[8] : 0};

    width: 100%;

    padding: ${theme.spacing[24]};
    padding-bottom: ${$isOpen ? theme.spacing[16] : theme.spacing[24]};

    border-radius: 0 !important;

    > span {
      width: 100%;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
          background-color: ${theme.colors.backgroundPrimaryHover} !important;
      }
    }

    &:active {
      background-color: ${theme.colors.backgroundPrimaryHover} !important;
    }
  `}
`;

export const CollapsibleToggleIcon = styled<React.FC<IconProps & { $isOpen: boolean }>>(Icon).attrs(
  () => ({
    name: "chevron-down",
  })
)`
  transform: ${(props) => (props.$isOpen ? "rotate(180deg);" : "rotate(0deg);")};
`;

export const CollapsibleDivider = styled(Divider)`
  border-color: ${({ theme }) => theme.colors.borderMinimal};
`;
