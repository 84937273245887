import { z } from "zod";

import type { TripsQueryParams } from "../querySchema";
import { querySchema } from "../querySchema";

/**
 * Checks if a string is a valid ISO date.
 *
 * @param dateString - Date string to validate.
 * @returns Whether the string is a valid date.
 */
function isValidDate(dateString: string): boolean {
  const date = new Date(dateString);
  return !Number.isNaN(date.getTime());
}

/**
 * Checks if the start date occurs strictly before the end date.
 *
 * @param start - Start date string.
 * @param end - End date string.
 * @returns Whether start date is strictly before the end date.
 */
function isChronologicalOrder(start: string, end: string): boolean {
  return new Date(start) < new Date(end);
}

/**
 * Removes dependent facet filters if their primary filter is missing.
 *
 * @param params - The strongly typed query parameters.
 * @returns The updated query parameters.
 */
function removeInvalidFacet(params: TripsQueryParams): TripsQueryParams {
  const search = params["filter[search]"];
  const searchFacet = params["filter[search_facet]"];

  if (searchFacet && !search) {
    delete params["filter[search_facet]"];
  }

  return params;
}

/**
 * Removes invalid or illogical date filters.
 *
 * @param params - The strongly typed query parameters.
 * @returns The updated query parameters.
 */
function removeInvalidDates(params: TripsQueryParams): TripsQueryParams {
  const startTimeGt = params["filter[start_time_gt]"];
  const startTimeLt = params["filter[start_time_lt]"];

  // Check if either date is missing
  if (!startTimeGt || !startTimeLt) {
    delete params["filter[start_time_gt]"];
    delete params["filter[start_time_lt]"];

    return params;
  }

  // Check if either date is invalid
  if (!isValidDate(startTimeGt) || !isValidDate(startTimeLt)) {
    delete params["filter[start_time_gt]"];
    delete params["filter[start_time_lt]"];

    return params;
  }

  // Check if date order is incorrect
  if (!isChronologicalOrder(startTimeGt, startTimeLt)) {
    delete params["filter[start_time_gt]"];
    delete params["filter[start_time_lt]"];

    return params;
  }

  return params;
}

/**
 * Processes and validates URL query parameters.
 * - Converts URLSearchParams into plain objects.
 * - Ensures dependent search facets only exist with a primary search term.
 * - Validates date formats and logical order.
 */
export const resolveParams = z
  .preprocess(
    (url: unknown) => (url instanceof URL ? Object.fromEntries(url.searchParams) : url),
    querySchema
  )
  .transform(removeInvalidFacet)
  .transform(removeInvalidDates).safeParse;
