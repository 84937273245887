import { Button, Typography } from "@hotelengine/atlas-web";
import { COMPANY_ROUTES } from "@hotel-engine/constants/companyNames";
import { Link } from "@hotel-engine/lib/react-router-dom";
import { openOneTrustCookieModal } from "@hotel-engine/scripts/ThirdPartyScripts/addOneTrustScripts";
import * as Styled from "./styles";
import FocusableLink from "../FocusableLink/FocusableLink";
import { useGlobalTheme } from "../../contexts/GlobalThemeContext";

interface IBasicFooter {
  /** optional classname */
  className?: string;
}

export const BasicFooter = ({ className = "" }: IBasicFooter) => {
  const { tokens } = useGlobalTheme();
  return (
    <Styled.FooterBox forwardedAs="footer" display="flex" alignItems="center" className={className}>
      <Button className="cookieButton" variant="link" onClick={openOneTrustCookieModal}>
        <Typography variant="body/sm" color="linkPrimary">
          Manage cookies
        </Typography>
      </Button>
      <FocusableLink
        as={Typography}
        forwardedAs={Link}
        to={COMPANY_ROUTES.terms}
        target="_blank"
        rel="noopener noreferrer"
        className="footerLink"
        variant="body/sm"
        color="linkPrimary"
        style={{ color: tokens.colors.linkPrimary }} // overrides AntD's hover styling
      >
        Terms of service
      </FocusableLink>
      <FocusableLink
        as={Typography}
        forwardedAs={Link}
        to={COMPANY_ROUTES.privacy}
        target="_blank"
        rel="noopener noreferrer"
        className="footerLink"
        variant="body/sm"
        color="linkPrimary"
        style={{ color: tokens.colors.linkPrimary }} // overrides AntD's hover styling
      >
        Privacy policy
      </FocusableLink>
    </Styled.FooterBox>
  );
};
