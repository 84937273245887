import {
  ModificationTypes,
  useCarModificationsContext,
} from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useBreakpoint } from "@hotel-engine/hooks";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import type { ICarItinerary, TCarInsurancePolicy } from "@hotel-engine/types/itinerary";
import { sanitizeHtml } from "@hotel-engine/utilities";
import { openNewTab } from "@hotel-engine/utilities/helpers/navigationHelpers";
import { Box, Button, Icon, Typography } from "@hotelengine/atlas-web";
import { ampli } from "ampli";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { isPastBooking } from "../../../../helpers";
import * as Styled from "../../styles";

const RENTAL_COVER_FULL_POLICY_URL = "https://rentalcover.com/en/pds";
const RENTAL_COVER_FILE_A_CLAIM_URL = "https://www.rentalcover.com/en/claim";

interface IManagePolicyModal {
  reservation: ICarItinerary;
  insurancePolicy: TCarInsurancePolicy;
}

export function ManagePolicyModal({ reservation, insurancePolicy }: IManagePolicyModal) {
  const { t } = useTranslation("cars_trips");
  const isPast = isPastBooking(reservation);
  const isMobile = useBreakpoint("md", "max");
  const user = useAppSelector((state) => state.Auth.user);

  const rawPolicies = insurancePolicy.coverageInformation.disclaimer || "";

  const { dispatch: modificationsDispatch } = useCarModificationsContext();
  const navigate = useNavigate();

  return (
    <Box
      marginTop={24}
      gap={16}
      display="flex"
      flexDirection="column"
      marginBottom={isMobile ? 12 : 0}
      data-testid="insurance-bottom-sheet"
    >
      <Box>
        <Typography variant="heading/sm">Coverage Details</Typography>
      </Box>

      <Box>
        <Typography variant="body/sm-strong" color="foregroundPrimary">
          {t("helpAndPolicies.insurance.content", {
            policyId: insurancePolicy?.policyId,
          })}
        </Typography>
      </Box>

      <Typography variant="body/sm" color="foregroundPrimary">
        <Styled.PolicyDescriptionContainer
          marginTop={false}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(rawPolicies) }}
        />
      </Typography>

      <Button
        variant="filled"
        size="lg"
        data-testid="insurance-view-full-policy"
        onClick={() => {
          ampli.clickViewFullPolicy({
            policyReferenceNumber: insurancePolicy?.policyId,
            contractNumber: reservation.bookingNumber,
            userId: user?.id,
          });

          openNewTab(
            `${RENTAL_COVER_FULL_POLICY_URL}/${insurancePolicy?.policyId}`,
            "_blank",
            navigate
          );
        }}
      >
        <Box display="flex" alignItems="center" gap={8}>
          {t("helpAndPolicies.insurance.modal.viewFullPolicy")}
          <Icon name="arrow-up-right-from-square" style={{ flexShrink: 0, marginTop: "2px" }} />
        </Box>
      </Button>

      <Button
        variant="outlined"
        size="lg"
        data-testid="insurance-file-a-claim"
        onClick={() => {
          ampli.clickFileAClaim({
            policyReferenceNumber: insurancePolicy?.policyId,
            contractNumber: reservation.bookingNumber,
            userId: user?.id,
          });

          openNewTab(RENTAL_COVER_FILE_A_CLAIM_URL, "_blank", navigate);
        }}
      >
        <Box display="flex" alignItems="center" gap={8}>
          {t("helpAndPolicies.insurance.modal.fileAClaim")}
          <Icon name="arrow-up-right-from-square" style={{ flexShrink: 0, marginTop: "2px" }} />
        </Box>
      </Button>

      {!isPast && (
        <Button
          variant="outlined"
          size="lg"
          data-testid="insurance-cancel-trigger"
          onClick={() => {
            ampli.clickCancelCoverageFromManageTrip({
              policyReferenceNumber: insurancePolicy?.policyId,
              contractNumber: reservation.bookingNumber,
              userId: user?.id,
            });

            modificationsDispatch({
              type: "SET_MODIFICATION_VIEW",
              payload: ModificationTypes.CancelInsurance,
            });
          }}
        >
          {t("helpAndPolicies.insurance.modal.cancelCoverage")}
        </Button>
      )}
    </Box>
  );
}
