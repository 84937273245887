import type { IReservationBase } from "@hotel-engine/types/reservation";
import type { IUser } from "@hotel-engine/types/user";

/**
 * Checks if the user is the primary occupant of any of the rooms on the booking
 * @param itinerary - The booking to check
 * @param user - The user to check
 * @returns true if the user is the primary occupant on any of the rooms, false otherwise
 */
export const isPrimaryOccupant = (itinerary: IReservationBase, user: IUser | null) => {
  if (!user) return false;

  const occupantsByRoom = itinerary.occupants;
  return occupantsByRoom.some((occupantsInRoom) => occupantsInRoom[0].occupantId === user.id);
};
