import { z } from "zod";
import { datadogLogs } from "@datadog/browser-logs";
import type { TripsResult } from "../data/schemas/trips";
import { bookingSchema, tripsSchema, tripSchema } from "../data/schemas/trips";

export const DEFAULT_TRIPS_RESULT: TripsResult = {
  limit: 25,
  offset: 0,
  trips: [],
  mostExpensiveTrip: 0,
  leastExpensiveTrip: 0,
  totalToday: 0,
  totalCancelled: 0,
  totalUpcoming: 0,
};

/**
 * Logs an error to Datadog Logs.
 *
 * @param data - The data that caused the error.
 * @param error - The error object.
 */
function logDatadogError(data: unknown, error: unknown) {
  try {
    datadogLogs.logger.error("Malformed trip data received", { data, error });
  } catch (exception) {
    console.error("Failed to send error to Datadog Logs:", exception);
  }
}

/**
 * Validates and sanitizes a raw booking object.
 * Logs errors to Datadog if validation fails.
 *
 * @param booking - A raw booking object from the API.
 * @returns A Booking object if validation succeeds, otherwise null.
 */
function parseBooking(booking: unknown) {
  const parsedBooking = bookingSchema.safeParse(booking);

  if (!parsedBooking.success) {
    logDatadogError(booking, parsedBooking.error);

    return [];
  }

  return [parsedBooking.data];
}

/**
 * Validates and sanitizes a raw trip object.
 * It logs errors to Datadog if validation fails.
 *
 * @param trip - A raw trip object from the API.
 * @returns A TripData object if validation succeeds, otherwise null.
 */
function parseTrip(trip: unknown) {
  const parsedTrip = tripSchema.extend({ bookings: z.array(z.any()) }).safeParse(trip);

  if (!parsedTrip.success) {
    logDatadogError(trip, parsedTrip.error);

    return [];
  }

  return [parsedTrip.data];
}

/**
 * Validates, sanitizes, and returns a TripsResult object.
 * 1. It uses a loose tripsSchema to parse the entire result.
 * 2. For each trip, sanitizes the trip object and its bookings.
 * 3. Filters out trips with invalid data or no valid bookings.
 *
 * @param rawResult - Raw trips result data from the API.
 * @returns A sanitized TripsResult, or DEFAULT_TRIPS_RESULT if top-level validation fails.
 */
export function sanitizeTrips(rawResult: unknown): TripsResult {
  const parsedTrips = tripsSchema.extend({ trips: z.array(z.any()) }).safeParse(rawResult);

  if (!parsedTrips.success) {
    return DEFAULT_TRIPS_RESULT;
  }

  const sanitizedTrips = parsedTrips.data.trips
    .flatMap(parseTrip)
    .map((trip) => ({
      ...trip,
      bookings: trip.bookings.flatMap(parseBooking),
    }))
    .filter((trip) => trip.bookings.length > 0);

  return {
    ...parsedTrips.data,
    trips: sanitizedTrips,
  };
}
