import { Alert, Typography } from "@hotelengine/atlas-web";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";

import * as Styled from "./styles";

import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { formatCurrency, formatDate } from "@hotel-engine/utilities";

export const FlexClawbackList = () => {
  const { typographyVariants } = useTypographyScaling();
  const { reservation } = useModificationsContext();
  const { isPreview } = useTypographyScaling();
  const userBusiness = useAppSelector((state) => state.Auth.user?.business);
  const { t } = useTranslation("lodging");

  const { rooms } = reservation;

  const showFlexClawback = rooms.some((room) => room.clawback);

  if (!showFlexClawback) return null;

  return (
    <>
      <Styled.Divider dotted />
      {rooms.map(
        (room, index) =>
          room.clawback && (
            <Styled.FlexAdjustmentFieldLine key={room.clawback.id}>
              <Typography variant={typographyVariants.body} color="foregroundSecondary">
                {t("trip.flexClawback.roomRefundAdjustment", { roomNumber: index + 1 })}
                <br /> (
                {formatDate(
                  room.clawback.clawedBackDate,
                  "MMM D YYYY",
                  userBusiness?.preferredDateFormat || "mdy"
                )}
                )
              </Typography>
              <Typography variant={typographyVariants.body} color="foregroundPrimary">
                -
                {formatCurrency(
                  room.clawback.clawedBackAmount || "",
                  true,
                  userBusiness?.currencyCode
                )}
              </Typography>
            </Styled.FlexAdjustmentFieldLine>
          )
      )}
      <Alert
        title={t("trip.flexClawback.alertTitle")}
        sentiment="critical"
        variant="outlined"
        size={isPreview ? "sm" : "md"}
      >
        {t("trip.flexClawback.alertDescription")}
      </Alert>
    </>
  );
};
