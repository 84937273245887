import { pick } from "lodash";
import { useSearchParams } from "./useSearchParams";

export type UTMParams = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  utm_adgroup?: string;
  hsa_grp?: string;
  msclkid?: string;
  hsa_mt?: string;
  gclid?: string;
};

export function useUTMParams(): UTMParams {
  return pick(
    useSearchParams(),
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
    "utm_adgroup",
    "hsa_grp",
    "msclkid",
    "hsa_mt",
    "gclid"
  );
}
