import { ampli } from "ampli";
import { type ReactElement, useMemo, useCallback } from "react";
import { Link } from "@hotel-engine/lib/react-router-dom";

import { PARTNER_OFFERS } from "@hotel-engine/constants";
import {
  TopNavMenuTrigger,
  TopNavMenuContent,
  TopNavCardList,
  TopNavCard,
} from "@hotelengine/atlas-web";
import type { IconName } from "@hotelengine/atlas-core";
import type { ILinks } from "@hotel-engine/app/Header/constants";

import { StyledTopNavMenu } from "./styles";

export type SubMenuCard = {
  id: string;
  title: string;
  icon: IconName;
  href?: string;
  onClick?: () => void;
  content?: string | ReactElement;
  showOnMobile?: boolean;
};

export type SubMenuCards = SubMenuCard[];

interface ITopNavMenu {
  triggerLabel: string;
  cards: SubMenuCards;
  activePathId: ILinks["id"] | null;
  isMobile: boolean;
  // per design some use cases (such as a "More" dropdown) contain unrelated submenu items and should not be highlighted
  // for default behavior leave undefined
  triggerIsActiveDisabled?: true;
  overFlowLinks?: ILinks[];
}

export const TopNavMenu = ({
  triggerLabel,
  cards,
  overFlowLinks,
  activePathId,
  isMobile,
  triggerIsActiveDisabled,
}: ITopNavMenu) => {
  const triggerCanBeActive = useMemo(() => {
    if (typeof triggerIsActiveDisabled === "boolean") {
      return !triggerIsActiveDisabled;
    }
    return triggerIsActiveDisabled;
  }, [triggerIsActiveDisabled]);

  const handlePartnerOffersClick = useCallback(() => {
    ampli.clickPartnerOffers();
  }, []);

  const onClickMap = useMemo(
    () => ({
      [PARTNER_OFFERS]: handlePartnerOffersClick,
    }),
    [handlePartnerOffersClick]
  );

  const subMenuItems = useMemo(() => {
    return cards.filter((card) => !isMobile || (isMobile && card.showOnMobile));
  }, [cards, isMobile]);

  if (!subMenuItems.length) return null;

  return (
    <StyledTopNavMenu>
      <TopNavMenuTrigger label={triggerLabel} isActive={triggerCanBeActive} />
      <TopNavMenuContent>
        <TopNavCardList>
          <>
            {overFlowLinks?.map(({ name, route, id, content, icon }) => (
              <TopNavCard
                data-testid={`top-nav-card-${id}`}
                isActive={activePathId?.includes(id)}
                as={Link}
                to={route ?? "#"}
                key={id}
                title={name}
                onClick={onClickMap[id]}
                icon={icon}
              >
                {!content ? null : content}
              </TopNavCard>
            ))}
            {subMenuItems.map(({ title, icon, href, content, id }) => (
              <TopNavCard
                data-testid={`top-nav-card-${id}`}
                isActive={activePathId?.includes(id)}
                as={Link}
                to={href ?? "#"}
                key={title}
                title={title}
                icon={icon}
                onClick={onClickMap[id]}
              >
                {content}
              </TopNavCard>
            ))}
          </>
        </TopNavCardList>
      </TopNavMenuContent>
    </StyledTopNavMenu>
  );
};
