import { useEffect } from "react";

import { useBlocker } from "@hotel-engine/lib/react-router-dom";
import { useBaseModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";

/** This can be used within the ModificationsContext to intercept route changes while in an active modification */
export const useNavigationBlocker = () => {
  const {
    dispatch,
    state: { modificationView, navigationRoute },
  } = useBaseModificationsContext();

  /**
   * This is here to intercept any route changes that happen while
   * an active modification flow is open and show the NavigationModal
   */
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const isDifferentRoute = currentLocation.pathname !== nextLocation.pathname;
    const isDifferentBooking = currentLocation.search !== nextLocation.search;
    const shouldBlockNavigation = !!modificationView && (isDifferentRoute || isDifferentBooking);

    if (shouldBlockNavigation) {
      dispatch({
        type: "SET_SHOW_NAVIGATION_MODAL",
        payload: true,
      });
      dispatch({
        type: "SET_NAVIGATION_ROUTE",
        payload: `${nextLocation.pathname}${nextLocation.search}`,
      });
    }

    // Return true to block navigation
    return shouldBlockNavigation;
  });

  /** If the user was mid-modification and clicked a new route, then confirmed they want to abandon the modification
   * this useEffect will then navigate them to the route they clicked after the NavigationModal is closed
   */
  useEffect(() => {
    if (!modificationView && navigationRoute) {
      if (blocker && blocker.proceed) {
        blocker.proceed();
      }

      dispatch({
        type: "SET_NAVIGATION_ROUTE",
        payload: undefined,
      });
    }
  }, [modificationView, navigationRoute, dispatch, blocker]);
};
