import styled from "styled-components";

export const ButtonsContainer = styled.div<{ padTop?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing[8]};
  margin-bottom: ${({ theme }) => theme.spacing[24]};
  margin-top: ${({ theme, padTop }) => theme.spacing[padTop ? 24 : 0]};

  > * {
    flex-grow: 1;
  }
`;

export const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
`;

export const TimeTable = styled.div`
  flex-direction: row;
  display: flex;
  gap: ${({ theme }) => theme.spacing[4]};

  span {
    line-height: 1.5;
  }

  &:last-of-type {
    margin-bottom: ${({ theme }) => theme.spacing[4]};
  }
`;
