import type { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useEffect, useRef, useState } from "react";

import moment from "moment";

import { CustomFieldsForm } from "@hotel-engine/app/CustomFieldsForm";
import { useWindowSize } from "@hotel-engine/hooks";
import { toggleSalesForceChat } from "@hotel-engine/scripts/hooks";
import { Reservation } from "@hotel-engine/services";
import { isIErrorResponse } from "@hotel-engine/types/errors";
import type { ICustomField } from "@hotel-engine/types/customField";
import type { IReservationBase, IReservationCustomField } from "@hotel-engine/types/reservation";
import { Dimensions } from "@hotel-engine/utilities";
import { endpoints } from "@hotel-engine/react-query/constants";
import { queryClient } from "@hotel-engine/contexts";

import * as Styled from "./styles";
import { Form, Formik } from "formik";
import { customFieldsSchema } from "@hotel-engine/app/CustomFieldsForm/helpers";
import {
  Button,
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  toast,
} from "@hotelengine/atlas-web";

const EditTripCustomFields: FunctionComponent<IEditTripCustomFieldsProps> = ({
  allCustomFields,
  forceOpen,
  reservation,
  setReservation,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(forceOpen || false);
  const [editedFields, setEditedFields] = useState<ICustomField[]>(
    getInitialFormData(reservation.customFields, allCustomFields)
  );
  const [formHeight, setFormHeight] = useState(0);
  const { height } = useWindowSize();

  useEffect(() => {
    setEditedFields(getInitialFormData(reservation.customFields, allCustomFields));
  }, [reservation, allCustomFields]);

  const formRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (drawerVisible) {
      toggleSalesForceChat(false);
    } else {
      toggleSalesForceChat(!Dimensions.mobile);
    }
  }, [drawerVisible]);

  useEffect(() => {
    if (formRef.current) {
      setFormHeight(formRef.current.clientHeight);
    }
  }, []);

  const editable =
    reservation.status !== "cancelled" &&
    reservation &&
    moment(new Date()).isBefore(moment(new Date(reservation.checkOut)));

  if (!editable) {
    return null;
  }

  const _handleOpen = () => {
    setDrawerVisible(true);
  };

  const _handleClose = () => {
    setDrawerVisible(false);
    setEditedFields(getInitialFormData(reservation.customFields, allCustomFields));
  };

  let isFooterAbsolute = false;

  if (height && height * 0.5 <= formHeight) {
    isFooterAbsolute = true;
  }

  const handleSubmit = async (values) => {
    const editedCustomFields = values.customFields;
    const payload = editedCustomFields.map((f) => ({
      custom_field_id: f.id,
      value: f.value || "",
    }));

    try {
      const res = await Reservation.updateCustomFields(reservation.id, {
        contract_custom_fields: payload,
      });

      toast({
        title: "Edits to custom fields saved",
        icon: "circle-check",
        sentiment: "positive",
      });

      const customFields: IReservationCustomField[] = res.map((item) => {
        return {
          id: item.id,
          contractId: reservation.id,
          customFieldId: item.customFieldId,
          name: item.customFieldName,
          value: item.value,
        };
      });

      setReservation({ ...reservation, customFields });
      await queryClient.invalidateQueries(endpoints.reservations);
      setDrawerVisible(false);
      setEditedFields(getInitialFormData(customFields, allCustomFields));
    } catch (e: unknown) {
      if (isIErrorResponse(e)) {
        toast({
          title: "Error saving custom fields",
          description: e.errors[0],
          icon: "circle-exclamation",
          sentiment: "critical",
        });
      }
    }
  };

  const initialValues = { customFields: [...editedFields] };

  return (
    <>
      <Button variant="outlined" size="lg" onClick={_handleOpen}>
        Edit company details
      </Button>

      <Sheet isOpen={drawerVisible} onOpenChange={(open) => !open && _handleClose()}>
        <SheetContent align="right">
          <SheetHeader>
            <SheetTitle>Edit custom fields</SheetTitle>
            <SheetClose onClick={_handleClose} />
          </SheetHeader>
          <Styled.SheetContentContainer>
            <div ref={formRef}>
              <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={customFieldsSchema}
                validateOnMount
              >
                {({ isSubmitting }) => {
                  return (
                    <Form>
                      <CustomFieldsForm />
                      <Styled.FooterContainer isFooterAbsolute={isFooterAbsolute}>
                        <Button
                          type="button"
                          variant="plain"
                          isDisabled={isSubmitting}
                          onClick={_handleClose}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" isDisabled={isSubmitting} isLoading={isSubmitting}>
                          Save
                        </Button>
                      </Styled.FooterContainer>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Styled.SheetContentContainer>
        </SheetContent>
      </Sheet>
    </>
  );
};

const getInitialFormData = (customFields, allCustomFields) => {
  const data: {
    value?: string;
    withValues: ICustomField[];
    missingRequiredValues: ICustomField[];
    noValues: ICustomField[];
  } = { withValues: [], missingRequiredValues: [], noValues: [] };

  for (const field of allCustomFields) {
    const populated = customFields?.find((f) => field.id === f.customFieldId);
    if (populated) {
      data.withValues.push({ ...field, value: populated.value || undefined });
    } else if (field.required) {
      data.missingRequiredValues.push(field);
    } else {
      data.noValues.push(field);
    }
  }
  return [...data.withValues, ...data.missingRequiredValues, ...data.noValues];
};

export default EditTripCustomFields;

export interface IEditTripCustomFieldsProps {
  allCustomFields: ICustomField[];
  reservation: IReservationBase;
  forceOpen: boolean;
  setReservation: Dispatch<SetStateAction<IReservationBase | null>>;
}
