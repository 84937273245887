import * as Yup from "yup";

export const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Please enter your current password"),
  newPassword: Yup.string()
    .matches(
      new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"),
      `Please enter a password at least 8 characters long
          containing one uppercase letter, one lowercase letter, and one number.`
    )
    .required("Please enter a password"),
  passwordConfirmation: Yup.string()
    .matches(
      new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"),
      `Please enter a password at least 8 characters long
          containing one uppercase letter, one lowercase letter, and one number.`
    )
    .required("Please re-enter password"),
});

export const ChangeNameSchema = Yup.object().shape({
  firstName: Yup.string().required("First name required"),
  lastName: Yup.string().required("Last name required"),
});

/**
 *
 * @param phoneNumber string that is being evaluated and formatted
 * @returns if no dial code exists append the US dial code +1
 *
 */
export const returnLegacyNumber = (phoneNumber: string | undefined) => {
  /** Return empty string if no number */
  if (!phoneNumber) {
    return "";
  }
  /** Checks phone number for the dial code */
  const isLegacyNumber = phoneNumber.slice(0, 1) !== "+";

  /** Remove formatting characters */
  const number = phoneNumber.replace(/[^0-9+]/g, "");

  return isLegacyNumber ? `+1${number}` : number;
};
