import { z } from "zod";

export type Charges = z.infer<typeof chargesSchema>;
export type CarDetails = z.infer<typeof carDetailsSchema>;
export type RentalCompany = z.infer<typeof rentalCompanySchema>;
export type LocationDetails = z.infer<typeof locationDetailsSchema>;
export type Location = z.infer<typeof locationSchema>;
export type BookingStatuses = z.infer<typeof bookingStatusesSchema>;
export type CarTraveler = z.infer<typeof travelerSchema>;
export type CarBookingDetails = z.infer<typeof carBookingDetailsSchema>;
export type CarBooking = z.infer<typeof carBookingSchema>;

export const chargesSchema = z.object({
  billingName: z.string().nullable(),
  paymentMethod: z.string().nullable(),
  paidOn: z.string(),
  baseRental: z.string(),
  taxesFees: z.string(),
  totalCharges: z.string(),
  insuranceAmount: z.string().nullable(),
});

export const carDetailsSchema = z.object({
  image: z.string(),
  type: z.string(),
  category: z.string(),
  variant: z.string(),
  transmission: z.string(),
  unlimitedKm: z.boolean(),
  luggageCapacity: z.number(),
  passengerCapacity: z.number(),
});

export const rentalCompanySchema = z
  .object({
    name: z.string(),
    companyCode: z.string(),
    logo: z.string(),
  })
  .passthrough();

export const locationDetailsSchema = z.object({
  name: z.string(),
  city: z.string(),
  country: z.string(),
  id: z.string(),
  state: z.string(),
  street: z.array(z.string()),
  pickUpType: z.string().nullable(),
});

export const locationSchema = z.object({
  pickUp: locationDetailsSchema,
  dropOff: locationDetailsSchema,
});

export const bookingStatusesSchema = z.object({
  pendingModifications: z.boolean(),
  refundable: z.boolean(),
});

export const travelerSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    email: z.string(),
    loyaltyNumber: z.array(z.any()),
  })
  .passthrough();

export const carBookingDetailsSchema = z.object({
  carConfirmation: z.string(),
  startTime: z.string(),
  endTime: z.string(),
  charges: chargesSchema,
  car: carDetailsSchema,
  rentalCompany: rentalCompanySchema,
  location: locationSchema,
  paymentType: z.string(),
  bookingStatuses: bookingStatusesSchema,
  cancelType: z.string(),
});

export const carBookingSchema = z.object({
  bookingNumber: z.string(),
  bookingType: z.literal("car"),
  details: carBookingDetailsSchema,
  travelers: z.array(travelerSchema),
});
