import { z } from "zod";

import { resolveTimezone } from "./resolvers/resolveTimezone";

export type TripsQueryParams = z.infer<typeof querySchema>;

export type TripsStatus = z.infer<typeof querySchema>["filter[group]"];

export type BaseQueryParams = {
  [K in keyof TripsQueryParams as K extends `filter[${infer _}]` ? never : K]: TripsQueryParams[K];
};

export type TripsFilter = {
  [K in keyof TripsQueryParams as K extends `filter[${infer Inside}]`
    ? Inside
    : never]: TripsQueryParams[K];
};

export type BookingType = NonNullable<TripsFilter["booking_type"]>[number];

export type SearchFacet = NonNullable<TripsFilter["search_facet"]>;

export const FILTER_SHAPE_REGEX = /^filter\[(.+)\]$/;
export const TRIPS_DEFAULT_LIMIT = 25;
export const TRIPS_DEFAULT_OFFSET = 0;
export const TRIPS_DEFAULT_SORT = "start_time";
export const TRIPS_DEFAULT_GROUP = "upcoming";
export const TRIPS_DEFAULT_VIEW = "list";

export const querySchema = z.object({
  limit: z.coerce.number().int().nonnegative().default(TRIPS_DEFAULT_LIMIT),
  offset: z.coerce.number().int().nonnegative().default(TRIPS_DEFAULT_OFFSET),
  sort: z
    .enum(["start_time", "-start_time", "end_time", "-end_time", "price", "-price"])
    .default(TRIPS_DEFAULT_SORT),
  "filter[timezone]": resolveTimezone,
  "filter[group]": z.enum(["all", "today", "upcoming", "past"]).default(TRIPS_DEFAULT_GROUP),
  "filter[only_my_trips]": z.coerce.boolean().optional(),
  "filter[only_cancelled_trips]": z.coerce.boolean().optional(),
  "filter[booking_type]": z.enum(["lodging", "flight", "car"]).array().optional(),
  "filter[department_names]": z.string().array().optional(),
  "filter[pending_modification_only]": z.coerce.boolean().optional(),
  "filter[refundable_only]": z.coerce.boolean().optional(),
  "filter[search_facet]": z
    .enum(["booker", "traveler", "location", "confirmation_number", "all"])
    .optional(),
  "filter[search]": z.coerce.string().optional(),
  "filter[total_gt]": z.coerce.number().optional(),
  "filter[total_lt]": z.coerce.number().optional(),
  "filter[start_time_gt]": z.string().optional(),
  "filter[start_time_lt]": z.string().optional(),
  "filter[unverified]": z.coerce.boolean().optional(),
  "filter[manual_bookings]": z.coerce.boolean().optional(),
  "filter[contractType]": z.coerce.boolean().optional(),
});

/**
 * List of allowed parameter names that can be used in the `filter[name]=value` URL format.
 * Only these keys will be processed when they appear in the format `filter[name]=value`.
 * Other parameters using this bracket notation will be ignored during parsing.
 */
export const filterSafeKeys = querySchema
  .keyof()
  .options.filter((name) => FILTER_SHAPE_REGEX.test(name))
  .map((name) => {
    const match = name.match(FILTER_SHAPE_REGEX);

    if (!match) return "";

    return match[1];
  }) as readonly (keyof TripsFilter)[];
