import { useRef } from "react";
import {
  useAllExperiments,
  useExperiment,
} from "@hotel-engine/react-query/amplitude/useExperimentQuery";
import type { IVariant } from "@hotel-engine/types/amplitude";
import type { StandardFlagKey } from "@hotel-engine/types/featureFlag";
import type { IReduxStore } from "store";
import { useAppSelector } from "store/hooks";

/** Standard feature flags */
type StandardFlagVariant = IVariant<"on" | "control">;

/**
 * A hook for standard feature flags in Amplitude Experiments.
 * @param key The feature flag key.
 * @returns {Object}:
 *  - data.value {('on'|'control')} - The feature flag value
 *  - trackExposure {Function} - Function to track exposure
 */
export const useFeatureFlag = (key: StandardFlagKey) => {
  const featureFlagsOverriden = useAppSelector(
    (store: IReduxStore) => store.Debug.featureFlagsOverriden
  );
  const experiment = useExperiment<StandardFlagVariant>(key, {
    value: "control",
  });

  return {
    data: {
      value: featureFlagsOverriden?.[key] || experiment.data.value,
    },
    trackExposure: experiment.trackExposure,
  };
};

/**
 * A hook to retrieve all standard feature flags in Amplitude Experiments.
 * @returns all feature flags
 */
export const useAllFeatureFlags = () => {
  return useAllExperiments();
};

/**
 * A hook for directly checking if a feature flag is `on`.
 * @param key The feature flag key.
 * @returns `true`,`false`
 */
export const useIsFeatureFlagOn = (key: StandardFlagKey) => {
  const { data, trackExposure } = useFeatureFlag(key);
  const isInitialRender = useRef(true);

  if (!isInitialRender.current) {
    trackExposure();
  } else {
    isInitialRender.current = false;
  }

  return data.value === "on";
};
