import { useBreakpoints } from "@hotelengine/atlas-web";

import {
  GROUPS,
  PARTNER_OFFERS,
  SUPPORT_CENTER,
  BILLING,
  COMPANY_SETTINGS,
  TRENDS,
  MEMBERS,
  DASHBOARD,
  TRIPS,
} from "@hotel-engine/constants";

/*
  This is hand tuned and hopefully a temporary solution.
  If you're adding or removing links to the header you'll need to update these breakpoints.
*/

export const useHeaderBreakpoints = (isDemo: boolean, isSelectedProperties: boolean) => {
  const allAccessoriesBreakpoints = [775, 875, 975, 1075, 1175, 1275, 1375, 1475, 1550];
  const onlyDemoBreakpoints = allAccessoriesBreakpoints.map((breakpoint) => breakpoint - 100);
  const onlySelectedPropertiesBreakpoints = allAccessoriesBreakpoints.map(
    (breakpoint) => breakpoint - 100
  );
  const baseBreakpoints = allAccessoriesBreakpoints.map((breakpoint) => breakpoint - 200);

  let itemBreakpoints;
  if (isDemo && isSelectedProperties) {
    itemBreakpoints = allAccessoriesBreakpoints;
  } else if (isDemo) {
    itemBreakpoints = onlyDemoBreakpoints;
  } else if (isSelectedProperties) {
    itemBreakpoints = onlySelectedPropertiesBreakpoints;
  } else {
    itemBreakpoints = baseBreakpoints;
  }

  const numberOfItemsBreakpoints = [
    useBreakpoints({ minBreakPoint: `${itemBreakpoints[0]}px` }),
    useBreakpoints({ minBreakPoint: `${itemBreakpoints[1]}px` }),
    useBreakpoints({ minBreakPoint: `${itemBreakpoints[2]}px` }),
    useBreakpoints({ minBreakPoint: `${itemBreakpoints[3]}px` }),
    useBreakpoints({ minBreakPoint: `${itemBreakpoints[4]}px` }),
    useBreakpoints({ minBreakPoint: `${itemBreakpoints[5]}px` }),
    useBreakpoints({ minBreakPoint: `${itemBreakpoints[6]}px` }),
    useBreakpoints({ minBreakPoint: `${itemBreakpoints[7]}px` }),
    useBreakpoints({ minBreakPoint: `${itemBreakpoints[8]}px` }),
  ];
  let numberOfItems = 0;

  const preferredItemOrderByKey = [
    DASHBOARD,
    TRIPS,
    GROUPS,
    BILLING,
    TRENDS,
    COMPANY_SETTINGS,
    MEMBERS,
    SUPPORT_CENTER,
    PARTNER_OFFERS,
  ];

  for (let i = numberOfItemsBreakpoints.length - 1; i >= 0; i--) {
    if (numberOfItemsBreakpoints[i]) {
      numberOfItems += i + 1;
      break;
    }
  }

  return {
    numberOfItems,
    preferredItemOrderByKey,
  };
};
