import { createSelector } from "@reduxjs/toolkit";
import type { IReduxStore } from "../..";
import { ViewType } from "./constants";

export const selectViewType = createSelector(
  (state: IReduxStore) => state.SearchPage.viewType,
  (viewType) => ({
    isCombinedView: viewType === ViewType.Combined,
    isListView: viewType === ViewType.List,
    isMapView: viewType === ViewType.Map,
    viewType,
  })
);

export const selectSpecificSearchDismissed = (state: IReduxStore) =>
  state.SearchPage.specificSearchDismissed;

// Done
export const selectShowLoadingBar = (state: IReduxStore) => state.SearchPage.showLoadingBar;
