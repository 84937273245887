import type { IUser } from "./user";

export type IRole = "admin" | "coordinator" | "department_manager" | "user" | "view_only_traveler";

/** All possible roles a user may have, with an associated value.
 * Higher values indicate more restricted access.
 */
export enum roleMap {
  view_only_traveler = 0,
  user = 1,
  department_manager = 2,
  coordinator = 3,
  admin = 4,
}

/** front end display for each role */
export type RoleDisplay =
  | "Administrator"
  | "Coordinator"
  | "Department Manager"
  | "Traveler"
  | "View Only Traveler";

export interface IRoleConfig {
  /** role type for this select */
  name: IRole;
  /** content for this role type */
  content: string;
  /** display string for this role type */
  display: RoleDisplay;
}

export const Role = {
  admin: "admin",
  departmentManager: "department_manager",
  coordinator: "coordinator",
  view_only_traveler: "view_only_traveler",
  user: "user",
} as const;

export function hasRole(role: IRole, user: IUser) {
  return roleMap[user.role] >= roleMap[role];
}

export const roles: IRoleConfig[] = [
  {
    name: "admin",
    display: "Administrator",
    content:
      "Full site permissions, including company settings, member management, billing, and the ability to book on behalf of others.",
  },
  {
    name: "coordinator",
    display: "Coordinator",
    content:
      "Limited site permissions, including visibility into trips and the ability to book on behalf of others.",
  },
  {
    name: "user",
    display: "Traveler",
    content:
      "Limited site permissions, including visibility into their own trips, and the ability to book trips for themselves and their guests.",
  },
];
