import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import { useState } from "react";
import { ampli } from "ampli";

import { useAppSelector } from "store/hooks";

import { routes } from "@hotel-engine/constants";
import HidePropertyModal from "@hotel-engine/app/modals/HidePropertyModal";
import UnhidePropertyModal from "@hotel-engine/app/modals/UnhidePropertyModal";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { Box, Typography } from "@hotelengine/atlas-web";

import type { IButtonListItem } from "../ActionButtonLayout";
import ActionButtonLayout from "../ActionButtonLayout";
import { usePastCancelledActions } from "./usePastCancelledActions";

import { ManageTripTrigger } from "../ManageTripTrigger";

export type IItineraryActionsProps = {
  itinerary: IReservationBase;
};

const PastCancelledActions = ({ itinerary }: IItineraryActionsProps) => {
  const navigate = useNavigate();
  const [showHidePropertyModal, setShowHidePropertyModal] = useState(false);
  const [showUnhidePropertyModal, setShowUnhidePropertyModal] = useState(false);
  const property = useAppSelector((state) => state.Property.property);
  const isPropertyHidden = property?.hiddenPropertyId || false;

  const { showHidePropertyButton, isEligibleToReportCheckoutDate, hideForNonPrimary } =
    usePastCancelledActions(itinerary);

  const handleHidePropertyClick = () => {
    if (isPropertyHidden) {
      setShowUnhidePropertyModal(true);
    } else {
      setShowHidePropertyModal(true);
    }
  };

  const handleModifyReservation = () => {
    ampli.clickModifyTripAtCheckout({
      propertyId: Number(itinerary.propertyId),
    });

    navigate(`${routes.modifyReservation}/${itinerary.id}`, {
      state: {
        itinerary,
      },
    });
    ampli.clickModifyThisReservation({
      bookingId: itinerary.id,
    });
  };

  const buttonList: IButtonListItem[] = [
    {
      action: handleHidePropertyClick,
      dataTestId: "hide-property",
      displayCriteria: showHidePropertyButton,
      text: `${isPropertyHidden ? "Unhide" : "Hide"} property`,
    },
    {
      action: handleModifyReservation,
      dataTestId: "modify-or-cancel",
      displayCriteria: isEligibleToReportCheckoutDate,
      text: "Report early checkout",
    },
  ];

  const noButtons = buttonList.every((button) => !button.displayCriteria);

  if (!property) {
    return null;
  }

  const Actions = () => (
    <>
      {!hideForNonPrimary && (
        <Typography as="p" variant="body/md" marginTop={12}>
          Life happens, plans change! Explore your options below and easily modify your stay.
        </Typography>
      )}
      <Box paddingTop={hideForNonPrimary ? 12 : 24} gap={16}>
        <ActionButtonLayout showFlexMessage={false} renderList={buttonList} />
        {!!showHidePropertyModal && (
          <HidePropertyModal
            propertyId={property.id}
            onClose={() => setShowHidePropertyModal(false)}
            onHideSuccessful={() => setShowHidePropertyModal(false)}
            scope="user"
          />
        )}
        {!!showUnhidePropertyModal && (
          <UnhidePropertyModal
            hiddenPropertyId={property.hiddenPropertyId}
            onClose={() => setShowUnhidePropertyModal(false)}
            onUnhideSuccessful={() => setShowUnhidePropertyModal(false)}
            propertyId={property.id}
            scope="user"
          />
        )}
      </Box>
    </>
  );

  return noButtons ? null : (
    <ManageTripTrigger itinerary={itinerary}>
      <Actions />
    </ManageTripTrigger>
  );
};

export default PastCancelledActions;
