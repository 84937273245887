import { Button, Icon, useTheme } from "@hotelengine/atlas-web";
import { useCurrencyCode } from "@hotel-engine/hooks";
import { formatCurrency } from "@hotel-engine/utilities";

import { useTranslation } from "react-i18next";
import * as Styled from "../styles";

export interface IRoomPriceIncreaseModalProps {
  /** handles routing to the Checkout screen */
  goToCheckout: () => void;
  /** handles routing to the Property screen */
  goToProperty: () => void;
  /** roomRates object declaring both original and newly selected room rates */
  roomRates: { chosenRoomRate: number; originalRoomRate: number };
}

const RoomPriceIncreaseModal = ({
  goToCheckout,
  goToProperty,
  roomRates,
}: IRoomPriceIncreaseModalProps) => {
  const currencyCode = useCurrencyCode();
  const roomRateDifference =
    Math.round(roomRates.chosenRoomRate) - Math.round(roomRates.originalRoomRate);
  const { tokens } = useTheme();
  const { t } = useTranslation("lodging");

  const rateDifferenceCurrencyText = formatCurrency(roomRateDifference, false, currencyCode);

  const originalRateCurrencyText = formatCurrency(
    Math.round(roomRates.originalRoomRate),
    false,
    currencyCode
  );

  const updatedRateCurrencyText = formatCurrency(
    Math.round(roomRates.chosenRoomRate),
    false,
    currencyCode
  );

  return (
    <Styled.RoomPriceIncreaseModalWrapper>
      <Styled.CircleIconContainer>
        <Icon
          name="square-dollar"
          color="sentimentCautionaryStrong"
          style={{ width: tokens.spacing[32], height: tokens.spacing[32] }}
        />
      </Styled.CircleIconContainer>
      <Styled.RoomPriceIncreaseModalHeader data-testid="rate-increased-modal-header">
        {t("checkout.tripExtensions.modalCopy.rateIncrease.single.title", {
          rateDifference: rateDifferenceCurrencyText || "",
        })}
      </Styled.RoomPriceIncreaseModalHeader>
      <Styled.RoomPriceIncreaseModalMessage data-testid="rate-increased-modal-message">
        {t("checkout.tripExtensions.modalCopy.rateIncrease.single.note", {
          originalRate: originalRateCurrencyText,
          updatedRate: updatedRateCurrencyText,
        })}
      </Styled.RoomPriceIncreaseModalMessage>
      <Styled.RoomPriceIncreaseButtonGroup>
        <Button onClick={goToCheckout} data-testid="rate-increased-book-now-button">
          Book now
        </Button>
        <Button
          variant="outlined"
          onClick={goToProperty}
          data-testid="rate-increased-go-back-button"
        >
          Go back to search results
        </Button>
      </Styled.RoomPriceIncreaseButtonGroup>
    </Styled.RoomPriceIncreaseModalWrapper>
  );
};

export default RoomPriceIncreaseModal;
