import type * as sdk from "@amplitude/experiment-js-client";
import { Experiment } from "@amplitude/experiment-js-client";

import { Unsafe } from "@hotel-engine/data";
import storage from "@hotel-engine/storage";
import type { IAmplitudeSdk, IExperimentSDK } from "@hotel-engine/types/amplitude";
import type { IUser } from "@hotel-engine/types/user";

import { useCallback, useEffect, useMemo, useState } from "react";

import { detect } from "detect-browser";

import { useAppSelector } from "store/hooks";

import { ampli } from "ampli";

import config from "config";

import {
  useAnonymousAmplitudeUserId,
  useExtractInvitationParams,
} from "@hotel-engine/utilities/helpers";
import { BigNumber } from "bignumber.js";
import { createEnrichmentPageUrlPlugin } from "./enrichPageUrlPlugin";
import type { IAmpliLoadOptions } from "./types";

/**
 * A hook for initializing the Amplitude browser sdk.
 */
export const useInitAmplitude = (): IAmplitudeSdk => {
  const [initialized, setInitialized] = useState(false);
  const [tracking, setTracking] = useState(() => !trackingDisabled());

  const toggleTracking = useCallback(() => {
    setTracking(true);
  }, []);

  useEffect(() => {
    ampli
      .load({
        client: {
          apiKey: config.amplitudeApiKey,
          configuration: {
            /**
             * We're overriding the endpoint url that the Amplitude Analytics
             * SDK uses to submit user events data. Because ad blockers will block,
             * these requests we instead point these requests to our own domain, which
             * is just a CNAME to the actual amplitude analytics endpoint.
             * This is the base url that's being replaced:
             *  https://api2.amplitude.com/2/httpapi
             * Note: We do need to append the path suffix to this url. If we ever want
             * to enabled `useBatch: true`, which would use `/batch`, then we'll have
             * to use a more robust transportProvider config instead.
             * Note: only override it if there is a specified url from env.
             */
            ...(!!config.ampliAnalyticsUrl ? { serverUrl: config.ampliAnalyticsUrl } : {}),
            appVersion: config.release,
            disableCookies: !tracking,
            attribution: {
              trackNewCampaigns: true,
              trackPageViews: true,
            },
            autocapture: { sessions: true },
          },
        },
        disabled: !tracking,
      } as IAmpliLoadOptions)
      .promise.then(() => {
        setInitialized(true);
        ampli.client?.add(createEnrichmentPageUrlPlugin());
      }, Unsafe.IGNORE_ERROR);
  }, [tracking]);

  return {
    initialized,
    tracking,
    toggleTracking,
  };
};

/**
 * Extract sign-up parameters from the URL or if we are logged in, use the userId from state.
 * Failing either of those, we may fall back to the anonymous amplitude user ID if the current
 * route supports it.
 * This is needed because Amplitude only works when we have a userId,
 * and in our case, we only used to have the userId on the state,
 * but now we can get it from useExtractInvitationParams as well,
 * and the main objective is to have feature flags on /sign-up flow
 */
const useGetUserId = (id: number | undefined) => {
  const signUpFlowUserId = useExtractInvitationParams()?.id;
  const anonymousAmplitudeUserId = useAnonymousAmplitudeUserId(signUpFlowUserId);
  // Logged-in user first, then the user ID from signup params, then the anonymous
  // user.
  const userId = id ?? signUpFlowUserId ?? anonymousAmplitudeUserId;
  return typeof userId === "string" ? BigNumber(userId).toNumber() : userId;
};

/**
 * A hook for generating the experiment sdk context.
 */
export const useExperimentSdk = (): IExperimentSDK => {
  const [client, setClient] = useState<sdk.ExperimentClient>();

  /** Create a user profile for interacting in the experiment client. */
  const user = useAppSelector((store) => store.Auth.user);
  const profile = useMemo(
    () => (user ? getUserProfile(user, user.accountType === "business") : getVisitorProfile()),
    [user]
  );

  const userId = useGetUserId(user?.id);

  /** Callback to initialize the experiment client. */
  const init = useCallback(() => {
    const { ampliExperimentKey: apiKey, ampliExperimentUrl } = config;
    const next = Experiment.initializeWithAmplitudeAnalytics(apiKey, {
      automaticExposureTracking: true,
      /**
       * We're overriding the endpoint url that the Amplitude Experiment
       * SDK uses to fetch feature flags. Because ad blockers will block this,
       * we instead tunnel these requests through our own backend service,
       * pretty much like a reverse-proxy.
       * This is the base url that's being replaced:
       *  https://api.lab.amplitude.com/sdk/vardata
       * Note: there is no need to include the path suffix here. The SDK will
       * append it automatically (ex: /sdk/vardata)
       * Note: only override it if there is a specified url from env.
       */
      ...(!!ampliExperimentUrl ? { serverUrl: ampliExperimentUrl } : {}),
    });
    setClient(next);
    return next;
  }, []);

  /**
   * Any time the user object in the redux store is updated, we want to
   * update the internal state of the amplitude experiment sdk.
   * When we invoke ampExperimentClient.fetch(), it will read from that
   * internal state (which can be accessed via ampExperimentClient.getUser())
   */
  useEffect(() => {
    if (!!client) {
      const sdkInternalStateUserId = client.getUser()?.user_id ?? "";
      // One may be a string and the other a number. Using double-equals
      if (profile?.user_id != sdkInternalStateUserId) {
        client.setUser(profile);
      }
    }
  }, [client, profile]);

  return {
    init,
    userId,
    client,
    profile,
  };
};

const getDeviceProfile = (loggedIn: boolean) => ({
  platform: "web",
  baseUrl: globalThis.location.origin,
  browser_name: String(detect()?.name),
  is_logged_in: loggedIn,
});

const getUserProfile = (user: IUser, businessUser: boolean) => ({
  user_id: String(businessUser ? user.id : user.businessTravelUserId),
  user_properties: {
    ...getDeviceProfile(true),
    business_id: businessUser ? user.businessId : user.businessTravelUserBusinessId,
    organization_id: user.business?.organizationId,
    iso_code: user.business?.currencyCode ?? "",
    isDemoAccount: Boolean(user.isDemo),
    isInternalBusiness: Boolean(user.business?.isInternal),
  },
});

const getVisitorProfile = () => ({
  user_id: "",
  user_properties: getDeviceProfile(false),
});

/**
 * Util to check cookies in legacy cookie environments.
 */
export const trackingDisabled = () => {
  const cookieSettings = JSON.parse(String(storage.getSecureItem("cookiePerms")));

  return (
    config.useLegacyScriptLoader &&
    !Boolean(cookieSettings?.Statistics || storage.getSecureItem("amplitude"))
  );
};
