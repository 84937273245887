import type { ReactNode } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import type { CSSProperties } from "styled-components";

import { Box } from "@hotelengine/atlas-web";

import * as Styled from "./styles";

export interface ICollapsibleProps {
  toggleBtnLabel: ReactNode;
  children: ReactNode;
  variant?: "sm" | "lg";
  defaultOpen?: boolean;
  testID?: string;
  toggleTestID?: string;
  hideDropdown?: boolean;
  suppressBottomMargin?: boolean;
  onChangeState?: (isOpen: boolean) => void;
  styles?: CSSProperties;
  footerContent?: ReactNode;
}

export const CollapsibleContent = ({
  toggleBtnLabel,
  children,
  defaultOpen = false,
  testID,
  toggleTestID,
  hideDropdown,
  suppressBottomMargin = false,
  onChangeState = () => {
    /* noop */
  },
  styles,
  footerContent,
}: ICollapsibleProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const delayedOpeningRef = useRef(false);

  const handleToggleSection = useCallback(() => {
    if (!hideDropdown) {
      const state = !isOpen;

      setIsOpen(state);
      onChangeState(state);
    }
  }, [hideDropdown, isOpen, onChangeState]);

  /** When manually passing down a defaultOpen based on an async condition, it's possible that value will update after the initial render,
   * so we need to watch for that change to open correctly. The ref is used here to ensure we can maintain the typical toggle behavior
   * after this effect is run once.
   */
  useEffect(() => {
    if (!!defaultOpen && !delayedOpeningRef.current) {
      if (!isOpen) {
        handleToggleSection();
      }

      delayedOpeningRef.current = true;
    }
  }, [defaultOpen, handleToggleSection, isOpen]);

  return (
    <Styled.CollapsibleContainer
      $hasBottomMargin={!suppressBottomMargin}
      className="flights-collapsible"
      data-testid={testID}
    >
      <Styled.CollapsibleToggleBtn
        onClick={handleToggleSection}
        data-testid={toggleTestID}
        $isOpen={isOpen}
        style={{ cursor: hideDropdown ? "default" : "pointer", ...styles }}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Box>{toggleBtnLabel}</Box>
          {!hideDropdown && <Styled.CollapsibleToggleIcon $isOpen={isOpen} />}
        </Box>
      </Styled.CollapsibleToggleBtn>
      {!!isOpen && <Box>{children}</Box>}
      {footerContent ? (
        <Box>
          <Styled.CollapsibleDivider />
          <Box padding={16}>{footerContent}</Box>
        </Box>
      ) : null}
    </Styled.CollapsibleContainer>
  );
};
