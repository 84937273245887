import type { FieldProps } from "formik";
import { FormControl, TextArea, type TextAreaProps } from "@hotelengine/atlas-web";

import { object } from "../helpers";

export interface ITextAreaFieldProps extends FieldProps {
  isMultiStep?: boolean;
  label: string;
  required?: boolean;
  size?: TextAreaProps["size"];
}

/**
 * The `TextAreaField` component is a wrapper around Atlas' FormControl and TextArea components.
 * It requires the use of formik and their `<Field />` component.
 *
 * @remarks Props are spread onto Atlas' TextArea
 * @example <Field component={TextAreaField} label="Notes" name="notes" />
 * @see {@link https://atlas.he-dev.com/latest/components/text-area-91qWEB5x Atlas TextArea Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-text-area--docs Storybook of TextArea}
 * @see {@link https://formik.org/docs/api/field Formik Field Documentation}
 */
const TextAreaField = ({
  field,
  form: { errors, submitCount, touched },
  isMultiStep,
  label,
  ...props
}: ITextAreaFieldProps) => {
  const fieldTouched = object.get<boolean>(touched, field.name);
  const fieldError = object.get<string>(errors, field.name);
  const showValidation = isMultiStep ? fieldTouched : submitCount || fieldTouched;

  const status = fieldError && showValidation ? "error" : "default";

  return (
    <FormControl label={label} isRequired={props.required} status={status} errorText={fieldError}>
      <TextArea {...field} {...props} id={field.name} />
    </FormControl>
  );
};

export default TextAreaField;
