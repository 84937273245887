import type { IEmailPillValue } from "@hotel-engine/app/BatchEmailInput";
import * as Styled from "./styles";
import { EnumPillClickState } from "@hotel-engine/app/BatchEmailInput/helpers/EffectRulesEngine";
import { AutoComplete } from "@hotel-engine/common/AutoComplete";
import { useMembersQuery } from "@hotel-engine/react-query/members/useMembersQuery";
import { capitalize } from "@hotel-engine/utilities/formatters/formatStrings";
import { useEffect, useState } from "react";
import type { IAutoCompleteMembers } from "@hotel-engine/types/batchEmailAutoComplete";
import {
  enrichRecentTargetsWithMemberInfo,
  extractValidMembers,
  getRecentEmailsFromLocalStorage,
  getNextRecentEmailTargets,
} from "./helpers";
import { BATCH_EMAIL_LOCAL_STORAGE_KEY } from "./constants";
import { useAppSelector } from "store/hooks";
import { BatchEmailInput } from "../BatchEmailInput";
import { SelectItem } from "@hotelengine/atlas-web";

interface IProps<T> {
  values: T;
  maxEmails: number;
  name: string;
  label?: string;
  placeholder: string;
  setShowAlreadyInvitedError?: React.Dispatch<React.SetStateAction<boolean>>;
  setFieldValue: (
    field: string,
    value: IEmailPillValue[],
    shouldValidate?: boolean | undefined
  ) => void;
  isRefresh?: boolean;
  existingEmails?: IEmailPillValue[];
  required?: boolean;
}

const BatchEmailAutoComplete = <T extends { emails: IEmailPillValue[] }>({
  values,
  name,
  label,
  placeholder,
  maxEmails,
  setFieldValue,
  setShowAlreadyInvitedError,
  isRefresh,
  existingEmails,
  required = true,
}: IProps<T>) => {
  const { data } = useMembersQuery({
    offset: 0,
    sort: "nameOrEmail",
    order: "asc",
    limit: 10000,
  });

  const user = useAppSelector((state) => state.Auth.user);

  const [filteredData, setFilteredData] = useState<IAutoCompleteMembers[]>([]);
  const [rawRecentMembers, setRawRecentMembers] = useState<IAutoCompleteMembers[]>([]);

  const recentMembers = enrichRecentTargetsWithMemberInfo(rawRecentMembers, data);

  const validData = extractValidMembers(data, recentMembers);

  useEffect(() => {
    if (!!user) {
      const storedEmails = getRecentEmailsFromLocalStorage(
        `${BATCH_EMAIL_LOCAL_STORAGE_KEY}-${user.id}`
      )?.split(",");
      const storedMembers: IAutoCompleteMembers[] | undefined = storedEmails?.map((email) => ({
        email,
      }));
      setRawRecentMembers(storedMembers ?? []);
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  const handleEmailSearch = (value: string) => {
    if (value === "") {
      const nextRecentEmails = getNextRecentEmailTargets(values.emails, recentMembers, 3);
      setFilteredData(nextRecentEmails ?? []);
      return;
    }
    const filteredD = validData?.filter((item) => {
      const sanitizedInput = value.toLowerCase();
      const sanitizedFirstName = item?.firstName?.toLowerCase();
      const sanitizedLastName = item?.lastName?.toLowerCase();
      const sanitizedEmail = item.email.toLowerCase();

      return (
        sanitizedFirstName?.startsWith(sanitizedInput) ||
        sanitizedLastName?.startsWith(sanitizedInput) ||
        sanitizedEmail.startsWith(sanitizedInput)
      );
    });

    setFilteredData(filteredD ?? []);
  };

  const buildAutoCompleteOptions = () => {
    const filteredDataWithSelectedMembersRemoved = filteredData.filter(
      (item) => !values.emails.some((valueEmail) => valueEmail.value === item.email)
    );
    return filteredDataWithSelectedMembersRemoved.map((item) => {
      const { firstName, lastName, email, role } = item;
      const displayedRole = role === "admin" ? "Administrator" : role;
      return (
        <SelectItem value={email} key={email}>
          <Styled.AutoCompleteNameAndRole>
            {!!firstName && !!lastName ? `${firstName} ${lastName} ` : `${firstName}`}
          </Styled.AutoCompleteNameAndRole>
          {!!displayedRole && (
            <>
              <Styled.Separator>{"|"}</Styled.Separator>
              <Styled.AutoCompleteNameAndRole>{` ${capitalize(
                displayedRole
              )}`}</Styled.AutoCompleteNameAndRole>{" "}
            </>
          )}
          <br />
          <Styled.AutoCompleteEmail>{email}</Styled.AutoCompleteEmail>
        </SelectItem>
      );
    });
  };

  const handleEmailSelect = (value: string) => {
    const emailExists = values.emails.some((item) => item.value === value);
    const email: IEmailPillValue = {
      value: value,
      key: `email-pill-${Math.random()}`,
      valid: !emailExists,
      clickState: EnumPillClickState.EMAIL_TEXT_CLICKABLE,
      lastErrorMessage: "",
    };
    setFieldValue("emails", [...values.emails, email]);
    handleEmailFocus();
  };

  const handleEmailFocus = () => {
    const nextRecentEmails = getNextRecentEmailTargets(values.emails, recentMembers, 3);
    setFilteredData(nextRecentEmails);
  };

  return (
    <AutoComplete
      data-testid="emails"
      aria-label="Search for your hotel's location"
      dataSource={buildAutoCompleteOptions()}
      onSelect={(value) => handleEmailSelect(value as string)}
      style={{ width: "100%" }}
      onChange={(value) => handleEmailSearch(value as string)}
      onFocus={() => handleEmailFocus()}
      getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
    >
      <Styled.BatchEmailField
        component={BatchEmailInput}
        setShowAlreadyInvitedError={setShowAlreadyInvitedError}
        existingEmails={existingEmails}
        name={name}
        value={values.emails}
        label={label}
        placeholder={placeholder}
        maxEmails={maxEmails}
        isWithAutoComplete
        $isRefresh={isRefresh}
        required={required}
      />
    </AutoComplete>
  );
};

export default BatchEmailAutoComplete;
