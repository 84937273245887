import type { IReservationBase } from "@hotel-engine/types/reservation";
import type { IUser } from "@hotel-engine/types/user";

/**
 * Returns a boolean after determining if the modification actions should be hidden based on user type, company settings, and whether a user is the user who booked a given reservation. Will only return false if view_single_rooms_in_gc FF is on.
 */
export const shouldShowModificationActions = (
  reservation: IReservationBase,
  user: IUser | null
): boolean => {
  const didUserBookTrip = reservation.bookedBy === `${user?.firstName} ${user?.lastName}`;

  // If all the following evaluate to true, we should hide the actions, so showModificationActions is false
  const showModificationActions = !(!didUserBookTrip && reservation.isChildContract);

  switch (user?.role) {
    // Admins should never have mod actions hidden
    case "admin":
    case "department_manager":
      return true;
    // If coordinator dashboard is enabled, they should always see mod actions, otherwise they follow the same logic as a user
    case "coordinator":
      return user?.business.showCoordinatorDashboard ? true : showModificationActions;
    // If a user did not book the trip and FF is on, mod actions should be hidden
    case "user":
      return showModificationActions;
    default:
      return true;
  }
};
