import { ampli } from "ampli";
import { useCallback, useMemo } from "react";

import type { SortingState } from "@hotelengine/atlas-web";
import { Box, Button, DataTable, Typography } from "@hotelengine/atlas-web";
import { useBreakpoint } from "@hotel-engine/hooks";
import { useNavigate, useSearchParams } from "@hotel-engine/lib/react-router-dom";
import type { ITrip } from "@hotel-engine/types/trips";
import {
  PREVIEW_BOOKING_NUMBER,
  PREVIEW_BOOKING_TYPE,
} from "@hotel-engine/app/ItineraryPreviewPanel/constants";

import { useTableColumns } from "./Columns/useTableColumns";
import { NoTripsMessage, TripsListItem, TripsError } from "../../components";
import * as Styled from "./styles";
import SectionList from "../../components/MobileWebComponents/SectionList";
import { groupTripsByDay } from "./helpers/groupTripsByDay";
import TripsListPagination from "./Pagination";
import DataTableContent from "./DataTableContent";
import { extractId } from "./helpers/extractId";
import { usePaginationControls } from "./Pagination/usePaginationControls";
import useTripsCache from "../../hooks/useTripsCache";
import useTripsControl from "../../hooks/useTripsControl";
import useTripsResult from "../../hooks/useTripsResult";
import type { BaseQueryParams } from "../../data/querySchema";
import ErrorWatchdog from "@hotel-engine/app/ErrorWatchdog";
import type { TripData } from "pages/Trips/data/schemas/trips";

const TripsListView = () => {
  const result = useTripsResult();
  const [params, tripsControl] = useTripsControl();
  const { accumulateTrips } = useTripsCache();
  const isMobileScreen = useBreakpoint("xxl", "max");
  const navigate = useNavigate();
  const { columns } = useTableColumns();
  const [, setSearchParams] = useSearchParams();
  const { cachedTrips, isNextDisabled } = usePaginationControls();
  const bookingNumber = params.booking_number;
  const isDesc = !!params?.sort?.startsWith("-");

  const rowSelectionState = useMemo(() => {
    if (!bookingNumber || !result.data?.trips.length) return {};

    return result.data.trips.reduce((acc, trip, index) => {
      const match = trip.bookings.some((booking) => {
        if (booking.bookingType === "lodging") {
          return booking.details.internalConfirmationNumber === bookingNumber;
        }

        return trip.id === bookingNumber || booking.bookingNumber === bookingNumber;
      });

      if (match) {
        acc[index] = true;
      }

      return acc;
    }, {});
  }, [bookingNumber, result.data?.trips]);

  const handleItemPreviewClick = useCallback(
    (record: ITrip) => {
      const id = extractId(record);

      setSearchParams((prev) => {
        const currentBookingNumber = params.booking_number;
        const currentBookingType = params.type;
        const nextBookingNumber = id;
        const nextBookingType = record.bookings[0].bookingType;

        if (currentBookingNumber === nextBookingNumber && currentBookingType === nextBookingType) {
          prev.delete(PREVIEW_BOOKING_NUMBER);
          prev.delete(PREVIEW_BOOKING_TYPE);
        } else {
          prev.set(PREVIEW_BOOKING_NUMBER, String(nextBookingNumber));
          prev.set(PREVIEW_BOOKING_TYPE, nextBookingType);
        }

        return prev;
      });
    },
    [params, setSearchParams]
  );

  const handleSort = (sorting: SortingState[number]) => {
    if (sorting == undefined) {
      tripsControl.unsetParams("sort");

      return;
    }

    const direction = sorting.desc ? "-" : "";
    const column = (direction + sorting.id) as BaseQueryParams["sort"];

    ampli.clickTripsTableSort({ sortBy: `${column}` });

    tripsControl.setParams({
      sort: column,
    });
  };

  const handleItemSelection = (item: TripData) => {
    const booking = item.bookings[0];
    const isLodging = booking.bookingType === "lodging";

    const id = isLodging ? booking.details.internalConfirmationNumber : item.id;

    navigate({
      pathname: "/itinerary",
      // Itinerary uses contract_number instead of booking_number
      search: `?contract_number=${id}&${PREVIEW_BOOKING_TYPE}=${booking.bookingType}`,
    });
  };

  const mobileRenderItem = (item: TripData) => (
    <ErrorWatchdog view="trips-list" scope="mobile-section-list-item" key={item.id}>
      <TripsListItem
        key={item.id}
        loading={result.isLoading}
        item={item}
        onSelection={handleItemSelection}
      />
    </ErrorWatchdog>
  );

  const mobileRenderSectionHeader = (title: string) => (
    <Typography variant="body/sm" color="foregroundTertiary">
      {title}
    </Typography>
  );

  const loadMore = () => {
    tripsControl.setParams((prev) => ({
      offset: Number(prev.offset) + Number(prev.limit),
    }));
  };

  return (
    <>
      {isMobileScreen ? (
        <>
          {!!result.data?.trips.length && !result.error && (
            <>
              <ErrorWatchdog view="trips-list" scope="mobile-section-list">
                <SectionList<TripData>
                  sections={groupTripsByDay(accumulateTrips(result.data.trips))}
                  renderItem={mobileRenderItem}
                  renderSectionHeader={mobileRenderSectionHeader}
                  keyExtractor={(item) => `${item.id}`}
                />
              </ErrorWatchdog>
              {!isNextDisabled && (
                <ErrorWatchdog view="trips-list" scope="mobile-load-more">
                  <Styled.LoadMore>
                    <Button onClick={loadMore}>Load More</Button>
                  </Styled.LoadMore>
                </ErrorWatchdog>
              )}
            </>
          )}
          {result.data?.trips.length === 0 && !result.isLoading && <NoTripsMessage />}
          {!!result.error && !result.isLoading && <TripsError />}
        </>
      ) : (
        <>
          <Styled.DesktopContainer>
            <Box style={{ flexBasis: bookingNumber ? "70%" : "100%" }}>
              {!!(result.data?.trips.length || result.isLoading) && !result.error && (
                <ErrorWatchdog view="trips-list" scope="desktop-data-table">
                  <DataTable
                    columns={columns}
                    data={
                      result.isLoading && !result.data?.trips.length
                        ? Array(5)
                        : result.data?.trips || []
                    }
                    rowClickOptions={{
                      onRowClicked(_, row) {
                        void handleItemPreviewClick(row.original);
                      },
                    }}
                    defaultSorting={[
                      { id: params?.sort?.slice(isDesc ? 1 : 0) || "", desc: isDesc },
                    ]}
                    onSortingChange={(sorting) => handleSort(sorting[0])}
                    isManualSortingEnabled
                  >
                    <DataTableContent
                      tripIdentifier={bookingNumber as string}
                      rowSelectionState={rowSelectionState}
                    />
                  </DataTable>
                </ErrorWatchdog>
              )}
              {result.data?.trips.length === 0 && !result.error && !result.isLoading && (
                <NoTripsMessage endOfList={!!cachedTrips?.length} />
              )}
              {!!result.error && !result.isLoading && <TripsError />}
            </Box>
          </Styled.DesktopContainer>
          <ErrorWatchdog view="trips-list" scope="desktop-pagination">
            <TripsListPagination />
          </ErrorWatchdog>
        </>
      )}
    </>
  );
};

export default TripsListView;
