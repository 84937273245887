import { routes } from "@hotel-engine/constants/routes";
import type { RouteObject } from "@hotel-engine/lib/react-router-dom";
import { loader } from "./data/loader";
import { shouldRevalidate } from "./data/shouldRevalidate";
import { TripsController } from "./TripsController";

export default {
  id: routes.trips.base,
  path: `${routes.trips.base}/:status?/*`,
  Component: TripsController,
  shouldRevalidate,
  loader,
} as const satisfies RouteObject;
