export const COMPANY_NAME = "Engine";
export const COMPANY_NAME_POSSESSIVE = "Engine's";
export const COMPANY_LOGO = "engine--lockup";
export const COMPANY_URL = "engine.com";
export const COMPANY_BRANDMARK = "engine";
export const COMPANY_ROUTES = {
  aboutUs: `https://www.${COMPANY_URL}/about-us`,
  base: `https://www.${COMPANY_URL}`,
  careers: `https://www.${COMPANY_URL}/careers`,
  contactUs: `https://www.${COMPANY_URL}/contact-us`,
  faqs: `https://www.${COMPANY_URL}/faqs`,
  library: `https://www.${COMPANY_URL}/library`,
  privacy: `https://www.${COMPANY_URL}/privacy-policy`,
  signup: `https://www.${COMPANY_URL}/sign-up`,
  socialResponsibility: `https://www.${COMPANY_URL}/social-responsibility`,
  texasFaqs: `https://www.${COMPANY_URL}/texas-faqs`,
  terms: `https://www.${COMPANY_URL}/terms-of-service`,
  termsGroups: `https://www.${COMPANY_URL}/terms-of-service-groups`,
  tsunamiRejected: `https://www.${COMPANY_URL}/thank-you`,
};
export const COMPANY_CREDIT_EMAIL = `credit@${COMPANY_URL}`;
export const COMPANY_SUPPORT_EMAIL = `support@${COMPANY_URL}`;
export const GROUPS_SUPPORT_EMAIL = `groups@${COMPANY_URL}`;
export const GROUPS_SUPPORT_PHONE_NUMBER = "(720) 566-7224";
export const COMPANY_AR_EMAIL = `ar@${COMPANY_URL}`;
export const COMPANY_SOCIALS = {
  twitter: "https://x.com/gowithengine",
  linkedin: "https://www.linkedin.com/company/engine",
  facebook: "https://www.facebook.com/gowithengine",
  instagram: "https://www.instagram.com/gowithengine",
};
