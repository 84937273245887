import type { IReservationBase } from "@hotel-engine/types/reservation";
import moment from "moment";
import { useAppSelector } from "store/hooks";
import { useFlexReservationShorten } from "@hotel-engine/hooks/useFlexReservationShorten";
import { userRoleCanModifyBooking } from "../../helpers/userRoleCanModifyBooking";
import { isPrimaryOccupant } from "../../helpers/isPrimaryOccupant";

export const usePastCancelledActions = (itinerary: IReservationBase) => {
  const user = useAppSelector((state) => state.Auth.user);

  const hasAdminAccess = userRoleCanModifyBooking(itinerary, user);
  const userIsPrimaryOccupant = isPrimaryOccupant(itinerary, user);
  const hideForNonPrimary = !hasAdminAccess && !userIsPrimaryOccupant;

  const { flexShortenCutoffDate } = useFlexReservationShorten(itinerary);
  const isViewOnlyTraveler = user?.role === "view_only_traveler";
  const { checkOut, propertyTimezone } = itinerary;

  const showReviewPropertyButton =
    !isViewOnlyTraveler &&
    itinerary.status === "completed" &&
    !!itinerary.activePropertyReviewRequestId;

  const showHidePropertyButton =
    !isViewOnlyTraveler &&
    ["completed", "cancelled"].includes(itinerary.status) &&
    !hideForNonPrimary;

  const isEligibleToReportCheckoutDate =
    itinerary.flexEnabled &&
    itinerary.nightCount > 1 &&
    itinerary.status === "completed" &&
    moment
      .tz(propertyTimezone)
      .isBetween(moment.tz(checkOut, propertyTimezone), flexShortenCutoffDate) &&
    !hideForNonPrimary;

  return {
    showHidePropertyButton,
    showReviewPropertyButton,
    isEligibleToReportCheckoutDate,
    hideForNonPrimary,
  };
};
