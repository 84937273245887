import { useAppSelector } from "store/hooks";

/**
 * Hook to determine if the current user has trips management access.
 *
 * @returns {boolean} Returns true if:
 *  - User is an admin, OR
 *  - User is a department manager, OR
 *  - User is a coordinator and has coordinator dashboard access
 */
function useTripsAdminAccess(): boolean {
  const user = useAppSelector((state) => state.Auth?.user);
  const isAdmin = /admin|department_manager/.test(user?.role || "");
  const isCoordinator = user?.role === "coordinator";
  const hasCoordinatorDashboardAccess = !!user?.business?.showCoordinatorDashboard;
  const hasClearance = !!user && ((isCoordinator && hasCoordinatorDashboardAccess) || isAdmin);

  return hasClearance;
}

export default useTripsAdminAccess;
