import styled from "styled-components";

export const NeedHelpContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing[16]};
  padding-right: ${({ theme }) => theme.spacing[24]};
  padding-left: ${({ theme }) => theme.spacing[24]};
  padding-bottom: ${({ theme }) => theme.spacing[24]};
`;

export const HotelFeeDescriptionContainer = styled.div`
  ul {
    padding: ${({ theme }) => theme.spacing[4]} ${({ theme }) => theme.spacing[24]};
    margin: 0;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[4]};
`;
