import { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { captureMessage } from "@hotel-engine/utilities";

type UseIsUserEmailVerifiedReturnType = {
  isLoading: boolean;
  isEmailVerified: boolean;
  refetch: () => Promise<void>;
};

export const useIsUserEmailVerified = (): UseIsUserEmailVerifiedReturnType => {
  const { getIdTokenClaims, isLoading: isAuth0Loading } = useAuth0();
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkEmailVerified = useCallback(async () => {
    try {
      // async operation, but it doesn't actually do any asynchronous fetching
      const claims = await getIdTokenClaims();

      // don't redirect if the user isn't logged in yet
      if (claims == undefined) {
        setIsEmailVerified(true);
      } else {
        setIsEmailVerified(!!claims.email_verified);
      }
    } catch (error) {
      captureMessage("Error checking email_verified on identity token claims", { error });
      setIsEmailVerified(false);
    } finally {
      setIsLoading(false);
    }
  }, [getIdTokenClaims]);

  // check and set if the users email is verified
  useEffect(() => {
    // wait until Auth0 is done fetching a token before we try to get the claims
    if (!isAuth0Loading) {
      void checkEmailVerified();
    }
  }, [checkEmailVerified, isAuth0Loading]);

  return { isLoading, isEmailVerified, refetch: checkEmailVerified };
};
