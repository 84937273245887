import { z } from "zod";

export type Property = z.infer<typeof propertySchema>;
export type LodgingCharges = z.infer<typeof lodgingChargesSchema>;
export type LodgingBookingStatuses = z.infer<typeof lodgingBookingStatusesSchema>;
export type LodgingBookingDetails = z.infer<typeof lodgingBookingDetailsSchema>;
export type LodgingBooking = z.infer<typeof lodgingBookingSchema>;
export type LodgingTraveler = z.infer<typeof travelerSchema>;

export const propertySchema = z.object({
  city: z.string(),
  country: z.string().nullish(),
  id: z.number(),
  imageUrl: z.string().nullish(),
  latitude: z.string().nullish(),
  longitude: z.string().nullish(),
  name: z.string(),
  state: z.string().nullish(),
  street: z.string(),
});

export const lodgingChargesSchema = z
  .object({
    total: z.string(),
  })
  .passthrough();

export const lodgingBookingStatusesSchema = z.object({
  pendingModifications: z.boolean(),
  refundable: z.boolean().nullable(),
  checkoutVerification: z.boolean().nullable(),
  flexEnabled: z.boolean(),
});

export const lodgingBookingDetailsSchema = z.object({
  externalConfirmationNumber: z.string().nullable(),
  internalConfirmationNumber: z.string(),
  property: propertySchema,
  checkIn: z.string(),
  checkOut: z.string(),
  bookedAt: z.string(),
  charges: lodgingChargesSchema,
  bookingStatuses: lodgingBookingStatusesSchema,
  lateCheckInApproved: z.boolean().optional(),
});

export const travelerSchema = z.object({ name: z.string() }).passthrough();

export const lodgingBookingSchema = z.object({
  bookingType: z.literal("lodging"),
  details: lodgingBookingDetailsSchema,
  roomCount: z.number(),
  cancelledRoomCount: z.number(),
  travelers: z.array(travelerSchema),
});
