import { NoTripsMessage } from "../../components";
import * as Styled from "./styles";
import GoogleTripsMap from "@hotel-engine/app/GoogleMap/components/GoogleTripsMap";
import useTripsResult from "../../hooks/useTripsResult";
import ErrorWatchdog from "@hotel-engine/app/ErrorWatchdog";

const TripsMapView = () => {
  const result = useTripsResult();

  return (
    <ErrorWatchdog view="trips-map">
      {result.data?.trips.length || 0 > 0 ? (
        <Styled.TripsMapView>
          <Styled.MapView data-testid="trips-map">
            <ErrorWatchdog view="trips-map" scope="google-map">
              <GoogleTripsMap loading={result.isLoading} />
            </ErrorWatchdog>
          </Styled.MapView>
        </Styled.TripsMapView>
      ) : (
        <Styled.NoTripsView>
          <NoTripsMessage isEmptyMapView />
        </Styled.NoTripsView>
      )}
    </ErrorWatchdog>
  );
};

export default TripsMapView;
