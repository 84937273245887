import { ampli } from "ampli";
import { useEffect, useState } from "react";

import {
  ModificationTypes,
  useModificationsContext,
} from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useTrackBookingModifications } from "@hotel-engine/app/ItineraryContent/hooks/useTrackBookingModifications";
import TripExtensions from "@hotel-engine/app/TripExtensions";
import type { ILocationState as ITripExtensionLocationState } from "@hotel-engine/app/TripExtensions";
import { ModalSteps } from "@hotel-engine/app/TripExtensions/constants";
import LateCheckInModal from "@hotel-engine/app/modals/LateCheckInModal";
import { routes } from "@hotel-engine/constants";
import { useSearchParams } from "@hotel-engine/hooks";
import { useLocation, useNavigate } from "@hotel-engine/lib/react-router-dom";
import { useSalesforceCaseQuery } from "@hotel-engine/react-query/salesforceCase/useSalesforceCaseQuery";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { Box, Typography } from "@hotelengine/atlas-web";

import { useAppSelector } from "store/hooks";

import type { IButtonListItem } from "../ActionButtonLayout";
import ActionButtonLayout from "../ActionButtonLayout";
import { useUpcomingActiveActions } from "./useUpcomingActiveActions";

import { ManageTripTrigger } from "../ManageTripTrigger";

import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import ModificationsMenuTrigger from "../ModificationsMenuTrigger";

export type IItineraryActionsProps = {
  itinerary: IReservationBase;
};

interface IItineraryActionParams {
  lateCheckin?: string;
}

const UpcomingActiveActions = ({ itinerary }: IItineraryActionsProps) => {
  const navigate = useNavigate();
  const params = useSearchParams<IItineraryActionParams>();
  const user = useAppSelector((state) => state.Auth.user);
  const { state: locationState, search } = useLocation<ITripExtensionLocationState>();
  const isModsSidePanelEnabled = useIsFeatureFlagOn("mods-redesign-2025");
  const isViewOnlyTraveler = user?.role === "view_only_traveler";
  const showFlexMessage = itinerary.flexEnabled && !isViewOnlyTraveler;

  const [showLateCheckInModal, setShowLateCheckInModal] = useState(params?.lateCheckin === "true");
  const [modalStep, setModalStep] = useState(ModalSteps.Invisible);
  const [extendTrip, setExtendTrip] = useState(!!locationState?.startTripExtensionFlow);

  const { dispatch: modificationsDispatch, refetchReservation } = useModificationsContext();

  const { isLoading, data } = useSalesforceCaseQuery(
    {
      contractNumber: itinerary.contractNumber,
      categories: ["special"],
      subcategories: ["late_checkin"],
    },
    {
      enabled: !!itinerary.contractNumber,
    }
  );

  const hasLateCheckInRequest = !isLoading && data && data.length > 0;

  const {
    showModifyReservationButton,
    showLateCheckInAction,
    showExtendReservationAction,
    showCancelBooking,
    hideForNonPrimary,
  } = useUpcomingActiveActions(itinerary);

  const { trackEnterModificationFlow } = useTrackBookingModifications();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);

    if (!extendTrip && queryParams.has("extendedContractId")) {
      queryParams.delete("extendedContractId");
      navigate(
        {
          search: queryParams.toString(),
        },
        {
          replace: true,
        }
      );
    }
  }, [extendTrip, navigate, search]);

  const toggleLateCheckInModal = () => {
    setShowLateCheckInModal(!showLateCheckInModal);
  };

  const handleModifyReservation = () => {
    ampli.clickModifyTripAtCheckout({
      propertyId: Number(itinerary.propertyId),
    });

    navigate(`${routes.modifyReservation}/${itinerary.id}`, {
      state: { itinerary },
    });
    ampli.clickModifyThisReservation({
      bookingId: itinerary.id,
    });
  };

  const handleSetActiveModification = (type: ModificationTypes) => {
    trackEnterModificationFlow(type);
    modificationsDispatch({
      type: "SET_MODIFICATION_VIEW",
      payload: type,
    });
  };

  const handleExtendTripItinerary = () => {
    setModalStep(ModalSteps.Calendar);
    setExtendTrip(true);
  };

  const buttonList: IButtonListItem[] = [
    {
      action: handleModifyReservation,
      dataTestId: "modify-or-cancel",
      displayCriteria: showModifyReservationButton && !!showCancelBooking,
      text: "Modify trip",
    },
    {
      action: handleExtendTripItinerary,
      dataTestId: "extend-reservation",
      displayCriteria: showExtendReservationAction,
      text: "Extend reservation",
    },
    {
      action: toggleLateCheckInModal,
      dataTestId: "late-check-in",
      displayCriteria: showLateCheckInAction && !hasLateCheckInRequest,
      text: "Late check-in",
    },
    {
      action: handleModifyReservation,
      dataTestId: "modify-or-cancel",
      displayCriteria: showModifyReservationButton && !showCancelBooking,
      text: "Modify trip",
    },
    {
      action: () => handleSetActiveModification(ModificationTypes.Cancel),
      dataTestId: "cancel-booking",
      displayCriteria: showCancelBooking,
      text: "Need to cancel?",
    },
  ];

  const noButtons = buttonList.every((button) => !button.displayCriteria);

  const Actions = () => (
    <>
      {!hideForNonPrimary && (
        <Typography as="p" variant="body/md" marginTop={12}>
          Life happens, plans change! Explore your options below and easily modify your stay.
        </Typography>
      )}
      <Box paddingTop={hideForNonPrimary ? 12 : 24} gap={16}>
        <ActionButtonLayout showFlexMessage={showFlexMessage} renderList={buttonList} />
        <LateCheckInModal
          contract={itinerary}
          onClose={toggleLateCheckInModal}
          visible={showLateCheckInModal}
          refetchReservation={() => refetchReservation()}
        />
        {!!extendTrip && (
          <TripExtensions requestedModalStep={modalStep} setIsSearching={setExtendTrip} />
        )}
      </Box>
    </>
  );

  if (noButtons) return null;

  return isModsSidePanelEnabled ? (
    <ModificationsMenuTrigger itinerary={itinerary} />
  ) : (
    <ManageTripTrigger itinerary={itinerary}>
      <Actions />
    </ManageTripTrigger>
  );
};

export default UpcomingActiveActions;
