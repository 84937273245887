import { useEffect, useMemo, useRef, useState } from "react";
import { isEqual } from "lodash";
import { ampli } from "ampli";

import { Badge, Box, Checkbox, Typography } from "@hotelengine/atlas-web";
import FilterPill from "@hotel-engine/app/FilterPill";
import { useBreakpoint } from "@hotel-engine/hooks";
import { useSearchParams } from "@hotel-engine/lib/react-router-dom";

import type { BookingType, TripsFilter } from "pages/Trips/data/querySchema";
import useTripsControl from "pages/Trips/hooks/useTripsControl";
import useTripsAdminAccess from "pages/Trips/hooks/useTripsAdminAccess";
import useTripsResult from "pages/Trips/hooks/useTripsResult";
import { useTranslation } from "react-i18next";

import type { IFilterRefProps } from "./components/FilterButtonPriceRange";
import FilterButtonPriceRange from "./components/FilterButtonPriceRange";

export type FilterOptions = Omit<TripsFilter, "timezone" | "group">;

const ACCEPTED_FILTERS = /booking_type|only_my_trips|total_gt|total_lt/;

function FilterOptions() {
  const { t } = useTranslation("trips");
  const isMobile = useBreakpoint("md", "max");
  const hasClearance = useTripsAdminAccess();
  const sliderRef = useRef<IFilterRefProps>(null);
  const [params, { setParams, unsetParams }] = useTripsControl();
  const { isLoading } = useTripsResult();
  const [temporaryFilters, setTemporaryFilters] = useState<FilterOptions>(params);
  const temporaryBookingType = temporaryFilters["booking_type"];
  const [qs] = useSearchParams();
  const view = qs.get("view");
  const isListView = view === "list";
  const filterOptionsLabel = t("filterOptions.filters");

  useEffect(() => {
    if (!isListView && params.booking_type?.some((type) => ["car", "flight"].includes(type))) {
      setParams({ booking_type: ["lodging"] });
    }
  }, [isListView, params.booking_type, setParams, temporaryFilters]);

  const invalidPriceRange =
    (temporaryFilters["total_gt"] || 0) > (temporaryFilters["total_lt"] || 0);

  const relevantParams = useMemo(
    () => ({
      booking_type: (isListView && params["booking_type"]?.length) || 0,
      only_my_trips: params["only_my_trips"] ? 1 : 0,
      total_gt: params["total_gt"] ? 1 : 0,
      total_lt: params["total_lt"] ? 1 : 0,
    }),
    [isListView, params]
  );

  const isFilterApplied = Object.values(relevantParams).reduce((sum, value) => sum + value, 0);
  const filterPillLabel =
    !isLoading && isFilterApplied ? (
      <Badge text={String(isFilterApplied)} color="achromatic" style={{ display: "flex" }} />
    ) : (
      filterOptionsLabel
    );

  const isFilterSelected = useMemo(() => {
    const filteredTemporaryFilters = Object.fromEntries(
      Object.entries(temporaryFilters)
        .filter(([key]) => ACCEPTED_FILTERS.test(key))
        .filter(([, value]) => value != null)
    );

    const lodgingOnlyViewTypeApplied =
      isEqual(params["booking_type"], ["lodging"]) &&
      Object.keys(filteredTemporaryFilters).length === 1 &&
      view !== "list";

    return (
      !isEqual(relevantParams, filteredTemporaryFilters) &&
      Object.keys(filteredTemporaryFilters).length > 0 &&
      !lodgingOnlyViewTypeApplied
    );
  }, [params, relevantParams, temporaryFilters, view]);

  const processBookingType = (value: BookingType, array: FilterOptions["booking_type"] = []) => {
    const index = array.indexOf(value);

    if (index === -1) {
      return [...array, value];
    }

    array.splice(index, 1);

    return array;
  };

  const handleBookingType = (value: BookingType) => () => {
    ampli.clickCheckboxFilterInTrips({
      filterName: value,
      selectedFilter: !temporaryBookingType?.includes(value),
    });

    setTemporaryFilters((prev) => ({
      ...prev,
      booking_type: processBookingType(value, temporaryBookingType),
    }));
  };

  const handleToggleOnlyMyTrips = () => {
    ampli.clickCheckboxFilterInTrips({
      filterName: "only_my_trips",
      selectedFilter: !temporaryFilters["only_my_trips"],
    });

    setTemporaryFilters((prev) => ({
      ...prev,
      only_my_trips: !prev["only_my_trips"],
    }));
  };

  const onClear = () => {
    const filteredTemporaryFilters = Object.fromEntries(
      Object.entries(temporaryFilters)
        .filter(([key]) => ACCEPTED_FILTERS.test(key))
        .filter(([, value]) => value != null)
    );

    if (isFilterApplied > 0) {
      unsetParams(...Object.keys(filteredTemporaryFilters));
    }

    setTemporaryFilters(isListView ? {} : { booking_type: ["lodging"] });

    if (sliderRef.current) sliderRef.current.onReset();
  };

  const onSubmit = () => {
    if (!sliderRef.current || !isFilterSelected) return;

    setParams(temporaryFilters);

    if (temporaryFilters.total_gt || temporaryFilters.total_lt) {
      ampli.bookingTableSearchedByPriceRange({
        minValue: temporaryFilters.total_gt,
        maxValue: temporaryFilters.total_lt,
      });
    }
  };

  /* This effect is used to hydrate the form based on the deferred state */
  useEffect(() => {
    setTemporaryFilters(params);
  }, [params]);

  return (
    <FilterPill
      isMobile={isMobile}
      labels={[filterPillLabel]}
      leadingIcon="sliders"
      data-testid="filter-options-trigger"
      isApplied={isFilterApplied > 0}
      isTemporaryApplied={isFilterSelected}
      isDisabled={isLoading || invalidPriceRange}
      hideResetIcon={true}
      hideChevron={true}
      onReset={onClear}
      onSubmit={onSubmit}
      aria-label={filterOptionsLabel}
    >
      <Box display="flex" flexDirection="column" gap={24} paddingLeft={2} paddingRight={2}>
        {!!isListView && (
          <Box display="flex" flexDirection="column" gap={16}>
            <Typography
              variant="body/md-strong"
              color="foregroundPrimary"
              id="bookings-filters-heading"
            >
              {t("filterOptions.bookings")}
            </Typography>
            <Box
              role="group"
              aria-labelledby="bookings-filters-heading"
              display="flex"
              flexDirection="column"
              gap={16}
            >
              <Checkbox
                isChecked={temporaryBookingType?.includes("lodging")}
                onCheckedChange={handleBookingType("lodging")}
                data-testid="select-hotels"
              >
                {t("filterOptions.hotels")}
              </Checkbox>
              <Checkbox
                isChecked={temporaryBookingType?.includes("flight")}
                onCheckedChange={handleBookingType("flight")}
                data-testid="select-flights"
              >
                {t("filterOptions.flights")}
              </Checkbox>
              <Checkbox
                isChecked={temporaryBookingType?.includes("car")}
                onCheckedChange={handleBookingType("car")}
                data-testid="select-cars"
              >
                {t("filterOptions.cars")}
              </Checkbox>
            </Box>
          </Box>
        )}

        {!!hasClearance && (
          <Box display="flex" flexDirection="column" gap={16}>
            <Typography
              variant="body/md-strong"
              color="foregroundPrimary"
              id="trip-details-heading"
            >
              {t("filterOptions.tripDetails")}
            </Typography>
            <Box role="group" aria-labelledby="trip-details-heading">
              <Checkbox
                isChecked={!!temporaryFilters?.["only_my_trips"]}
                onCheckedChange={handleToggleOnlyMyTrips}
                data-testid="only-my-trips-checkbox"
              >
                {t("filterOptions.onlyMyTrips")}
              </Checkbox>
            </Box>
          </Box>
        )}

        <Box style={{ width: isMobile ? "100%" : 325 }}>
          <FilterButtonPriceRange
            ref={sliderRef}
            setTemporary={setTemporaryFilters}
            invalidPriceRange={invalidPriceRange}
          />
        </Box>
      </Box>
    </FilterPill>
  );
}

export { FilterOptions };
