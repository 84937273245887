import moment from "moment";

import { Typography, type TypographyVariant } from "@hotelengine/atlas-web";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import type { ICurrency } from "@hotel-engine/constants";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { formatCurrency } from "@hotel-engine/utilities";

import * as Styled from "../../styles";

interface INightlyRateBreakdownProps {
  reservation: IReservationBase;
  currencyCode: ICurrency;
  typography?: TypographyVariant;
}

const NightlyRateBreakdown = ({
  reservation,
  currencyCode,
  typography,
}: INightlyRateBreakdownProps) => {
  const { typographyVariants } = useTypographyScaling();

  const nightlyRateRoomsText = `x ${reservation.roomCount} rooms`;

  const singleRoomSubtotal = formatCurrency(
    Number(reservation.charges?.roomSubtotal) / reservation.roomCount,
    true,
    currencyCode
  );

  return (
    <>
      {reservation.nightlyRates?.map((rate, index) => (
        <Styled.FieldLine key={`${rate.date}${index}`}>
          <Typography variant={typography || typographyVariants.body} color="foregroundSecondary">
            {moment(rate.date).format("l")}
          </Typography>
          <Styled.RateContainer>
            <Typography variant={typography || typographyVariants.body} color="foregroundPrimary">
              {`${formatCurrency(rate.rate, true, currencyCode)}`}
            </Typography>
            {!!rate.gsaRate && (
              <Typography variant={typographyVariants.finePrint} color="foregroundSecondary">
                {`GSA rate: ${formatCurrency(rate.gsaRate || 0, true, currencyCode)}`}
              </Typography>
            )}
          </Styled.RateContainer>
        </Styled.FieldLine>
      ))}
      {!!(reservation.roomCount > 1) && (
        <Styled.FieldLine>
          <Typography variant={typography || typographyVariants.body} color="foregroundSecondary">
            Subtotal
          </Typography>
          <Typography variant={typography || typographyVariants.body} color="foregroundPrimary">
            {`${singleRoomSubtotal} ${nightlyRateRoomsText}`}
          </Typography>
        </Styled.FieldLine>
      )}
      <Styled.Divider />
    </>
  );
};

export default NightlyRateBreakdown;
