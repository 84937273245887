import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import GoogleMap from "@hotel-engine/app/GoogleMap";
import GoogleMapsPropertyMarker from "@hotel-engine/app/GoogleMap/components/Markers/GoogleMapsPropertyMarker";
import PropertyMarker from "@hotel-engine/app/GoogleMap/components/Markers/PropertyMarker";
import { GoogleMapStaticImage } from "@hotel-engine/app/GoogleMapStaticImage";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useBreakpoint, useWindowSize } from "@hotel-engine/hooks";
import { Box } from "@hotelengine/atlas-web";
import { breakpoints } from "@hotel-engine/styles/variables/sizes";
import { sidebarMdDesktopWidth } from "@hotel-engine/app/ItineraryPreviewPanel/styles";
import { MapStreet } from "./MapStreet";

export interface IGooglePreviewMapProps {
  handleView: () => void;
  longitude: number;
  latitude: number;
  walkscorePercentage: string;
  walkscoreDescription: string;
  borderRadius?: string;
}

const GooglePreviewMap = ({
  handleView,
  longitude,
  latitude,
  walkscorePercentage,
  walkscoreDescription,
  borderRadius,
}: IGooglePreviewMapProps) => {
  const isMobile = useBreakpoint("xl", "max");
  const context = useModificationsContext();
  const { width, desktop } = useWindowSize();
  const showStaticMap = useIsFeatureFlagOn("members-show-static-itinerary-map");

  if (!!context?.state?.modificationView) return null;

  if (showStaticMap) {
    const isLargeDesktop = width >= breakpoints.xxxxl;
    const desktopWidth = isLargeDesktop ? 500 : sidebarMdDesktopWidth;

    return (
      <GoogleMapStaticImage
        location={{ longitude, latitude }}
        zoom={13}
        size={{ width: desktop ? desktopWidth : width, height: 260 }}
        title="Property Location"
        customStyle={{ minHeight: isMobile ? "100%" : 260, borderRadius: borderRadius }}
      >
        <GoogleMapStaticImage.Marker>
          <PropertyMarker />
        </GoogleMapStaticImage.Marker>

        <MapStreet
          handleView={handleView}
          walkscorePercentage={walkscorePercentage}
          walkscoreDescription={walkscoreDescription}
        />
      </GoogleMapStaticImage>
    );
  }

  return (
    <Box
      position="relative"
      zIndex="backdropAbove"
      style={{
        height: 260,
        width: "100%",
        overflow: "hidden",
      }}
    >
      <GoogleMap
        mapOptions={{
          center: {
            lat: latitude,
            lng: longitude,
          },
          draggable: false,
        }}
        controlOptions={{
          showExpand: !isMobile,
          showSearchControls: false,
          showType: false,
          showZoom: false,
          isMapExpanded: false,
        }}
        customMapStyle={{ borderRadius: borderRadius || 0, height: 260 }}
        dataTestId="trips-preview-panel-map"
      >
        {(map) => (
          <GoogleMapsPropertyMarker
            map={map as google.maps.Map}
            position={{
              lat: latitude,
              lng: longitude,
            }}
          />
        )}
      </GoogleMap>
      <MapStreet
        handleView={handleView}
        walkscorePercentage={walkscorePercentage}
        walkscoreDescription={walkscoreDescription}
      />

      <div data-testid="map test" style={{ display: "none" }} />
    </Box>
  );
};
export default GooglePreviewMap;
