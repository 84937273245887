import type { IUser } from "@hotel-engine/types/user";

import type { AuthValidatorFn } from "../helpers";
import { Role } from "../../types/role";

/** Blocks route access if user account does not have reporting access enabled */
const canViewTrends: AuthValidatorFn = (user: IUser) => {
  return user.hasReportingAccess || user.role === Role.departmentManager;
};
const hasReportingAccess: AuthValidatorFn = (user: IUser) => {
  return user.hasReportingAccess;
};

export { canViewTrends, hasReportingAccess };
