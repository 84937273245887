import type { IReduxStore } from "store";

import { createSelector } from "@reduxjs/toolkit";

import {
  selectFlightsSelectedFilters,
  selectSortByOption,
} from "../FlightsFilters/flights.filters.selectors";
import { sortFunctionsMap } from "../FlightsFilters/flights.filters.helpers";
import { filterFlightsResults } from "./flights.results.helpers";
import { resultsAdapter } from "./flights.results.constants";
import { getTravelerCount } from "pages/Flights/hooks/useFlightSearchParams";
import type { Passengers } from "@hotel-engine/types/flights/flights.form.types";
import type { IFlightSlice } from "../FlightsShop/flights.shop.types";

export const {
  selectAll: selectAllFlightsResults,
  selectById: selectFlightByContinuationToken,
  selectIds: selectFlightsContinuationTokens,
  selectTotal: selectFlightsResultsCount,
} = resultsAdapter.getSelectors((state: IReduxStore) => state.FlightsSearchResults);

interface ISelectAllFlightsResultsInput {
  passengers: Passengers;
  departureDate: string | null;
  originIataCode: string | null;
  destinationIataCode: string | null;
}

export const selectFilteredFlights = createSelector(
  [
    selectAllFlightsResults,
    selectFlightsSelectedFilters,
    (_state, input: ISelectAllFlightsResultsInput) => getTravelerCount(input.passengers),
    (_state, input: ISelectAllFlightsResultsInput) => input.departureDate,
  ],
  (results, { selectedFilters }, passengersCount, departureDate) => {
    return filterFlightsResults({
      results,
      selectedFilters,
      departureDate,
      passengersCount,
    });
  }
);

export const selectSortedFlights = createSelector(
  [
    (state, input: ISelectAllFlightsResultsInput) => selectFilteredFlights(state, input),
    (state) => selectSortByOption(state),
    (_state, _input, selectedFlightAndFare: IFlightSlice | undefined) => selectedFlightAndFare,
  ],
  ({ filteredResults }, sortByOption, selectedFlightAndFare) => {
    const flightSelectionToken =
      (selectedFlightAndFare?.fare && selectedFlightAndFare?.flight?.continuationToken) ?? null;

    return [...filteredResults].sort((a, b) => {
      if (a.continuationToken === flightSelectionToken) {
        return Number.NEGATIVE_INFINITY;
      }
      if (b.continuationToken === flightSelectionToken) {
        return Number.POSITIVE_INFINITY;
      }
      return sortFunctionsMap[sortByOption](a, b);
    });
  }
);

export const selectFilteredResultsCount = createSelector(
  [(state, input: ISelectAllFlightsResultsInput) => selectFilteredFlights(state, input)],
  ({ filteredResults }) => filteredResults.length
);

export const selectTravelerManifest = (state: IReduxStore) =>
  state.FlightsSearchResults.travelerManifest;

export const selectUserTravelPolicy = (state: IReduxStore) =>
  state.FlightsSearchResults.userTravelPolicy;

export const selectFlightsSearchError = (state: IReduxStore) => {
  return state.FlightsSearchResults.error;
};

export const selectFlightsSearchIsCancelled = (state: IReduxStore) => {
  return state.FlightsSearchResults.error?.code === "ERR_CANCELED";
};

export const selectSearchId = (state: IReduxStore) => state.FlightsSearchResults.searchId;

export const selectMinPriceNonStopResults = createSelector(selectAllFlightsResults, (results) =>
  results
    .filter((result) => result.segments.length === 1)
    .reduce((minPrice, result) => {
      let currentMinPrice = minPrice;
      Object.values(result.fareSummaries).forEach((fareSummary) => {
        currentMinPrice = Math.min(currentMinPrice, fareSummary.price.totalValue);
      });
      return currentMinPrice;
    }, Number.POSITIVE_INFINITY)
);
