import type { ReactElement, ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";

import { routes } from "@hotel-engine/constants";
import { useBreakpoint, useCurrencyCode } from "@hotel-engine/hooks";
import { Link, useLocation, useNavigate } from "@hotel-engine/lib/react-router-dom";
import { formatCurrency } from "@hotel-engine/utilities";
import { internalLinkNewTabTarget } from "@hotel-engine/utilities/helpers/navigationHelpers";
import {
  Icon as AtlasIcon,
  BottomSheet,
  BottomSheetContent,
  BottomSheetTrigger,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogClose,
  DialogTrigger,
  IconButton,
  RadioGroup,
  RadioGroupItem,
  Typography,
  useTheme,
  toast,
} from "@hotelengine/atlas-web";
import config from "config";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { EditPaymentProfileForm } from "@hotel-engine/app/EditPaymentProfileForm";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import NuclearErrorModal from "@hotel-engine/app/modals/NuclearErrorModal";
import Image from "@hotel-engine/common/Image";
import { endpoints } from "@hotel-engine/react-query/constants";
import { COMPANY_NAME, COMPANY_ROUTES } from "@hotel-engine/constants/companyNames";
import { useCreateFlexProSubscription } from "@hotel-engine/react-query/flexPro/useCreateFlexProSubscription";
import { useFlexProPricingQuery } from "@hotel-engine/react-query/flexPro/useFlexProPricingQuery";
import { useFlexProSubscriptionQuery } from "@hotel-engine/react-query/flexPro/useFlexProSubscriptionQuery";
import { useResubscribeFlexProSubscription } from "@hotel-engine/react-query/flexPro/useResubscribeFlexProSubscription";
import { usePaymentProfileQuery } from "@hotel-engine/react-query/paymentProfile/usePaymentProfileQuery";
import { User } from "@hotel-engine/services";
import type { IDirectBill } from "@hotel-engine/types/booking";
import type { IFlexProPricing } from "@hotel-engine/types/flexPro";
import { type IPaymentProfile, isPaymentMethodValid } from "@hotel-engine/types/paymentProfile";
import { ampli } from "ampli";
import { useQueryClient } from "react-query";
import { AuthActions } from "store/Auth/AuthRedux";
import * as Styled from "./styles";
import { directBillLegacy } from "pages/Checkout/Lodging/legacy/constants/directBillLegacy";
import { isDirectBillLegacy } from "pages/Checkout/Lodging/legacy/utils/isDirectBill.util";

enum FlexProModalSteps {
  SignUp = "SignUp",
  ChangePayment = "ChangePayment",
  AddPayment = "AddPayment",
  Success = "Success",
  Active = "Active",
  Error = "Error",
  MobileActive = "MobileActive",
  MobileError = "MobileError",
  MobileSignUpPart1 = "MobileSignUpPart1",
  MobileSignUpPart2 = "MobileSignUpPart2",
  MobileChangePayment = "MobileChangePayment",
  MobileAddPayment = "MobileAddPayment",
  MobileSuccess = "MobileSuccess",
}

const listCopy = [
  "All your bookings are automatically covered, so you'll never pay for another unused night�",
  "Hassle-free changes and cancellations, no questions asked�",
  "Refunds are instant and automatic (no phone calls needed)",
];

const FlexProSignUpModalContent = ({
  modalStep,
  setModalStep,
}: { modalStep: FlexProModalSteps; setModalStep(step: FlexProModalSteps): void }) => {
  const queryClient = useQueryClient();
  const isMobile = useBreakpoint("md", "max");

  const user = useAppSelector((state) => state.Auth.user);
  const currentUserRole = user?.role;
  const theme = useTheme();

  useEffect(() => {
    // Since the Dialog provider gets replaced with the BottomSheet provider when mobile view is entered, we need to reset the modal state to the correct style or else the page will not be interactable
    const mobileViews = [
      FlexProModalSteps.MobileSignUpPart1,
      FlexProModalSteps.MobileSignUpPart2,
      FlexProModalSteps.MobileChangePayment,
      FlexProModalSteps.MobileAddPayment,
      FlexProModalSteps.MobileSuccess,
      FlexProModalSteps.MobileActive,
      FlexProModalSteps.MobileError,
    ];

    const desktopViews = [
      FlexProModalSteps.SignUp,
      FlexProModalSteps.ChangePayment,
      FlexProModalSteps.AddPayment,
      FlexProModalSteps.Success,
      FlexProModalSteps.Active,
      FlexProModalSteps.Error,
    ];

    if (isMobile && !mobileViews.includes(modalStep)) {
      setModalStep(FlexProModalSteps.MobileSignUpPart1);
    } else if (!isMobile && !desktopViews.includes(modalStep)) {
      setModalStep(FlexProModalSteps.SignUp);
    }
  }, [setModalStep, modalStep, isMobile]);

  // checks if the user can pay with DirctBill
  // subscriptionBillingAllowed is an alias on nexus that confirms direct_bill_allowed && has_available_credit
  // https://github.com/HotelEngine/he-api/blob/b8f73cecf4a4ccb4b1c2dcfc8a862664e63760b5/app/concerns/direct_billable.rb#L131
  const hasDirectBill = !!user?.business?.subscriptionBillingAllowed;

  const currencyCode = useCurrencyCode();
  const [understandCheckbox, setUnderstandCheckbox] = useState(false);
  const [authorizeCheckbox, setAuthorizeCheckbox] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<IPaymentProfile | IDirectBill | undefined>(
    undefined
  );
  const paymentProfileQuery = usePaymentProfileQuery();
  const paymentProfiles = useMemo(
    () =>
      paymentProfileQuery.data?.results.filter(
        (paymentProfile) => paymentProfile?.type?.toLowerCase() !== "wex"
      ),
    [paymentProfileQuery.data?.results]
  );
  const isError = paymentProfileQuery.isError;
  const [selectedSubscriptionCycle, setSelectedSubscriptionCycle] = useState<"month" | "year">(
    "month"
  );
  const flexProPricingResponse = useFlexProPricingQuery();
  const monthlyCost = flexProPricingResponse?.data?.month.discountAmount || 200;
  const flexProPricing = flexProPricingResponse.data;
  const flexProPricingBreakdown = flexProPricing
    ? flexProPricing[selectedSubscriptionCycle]
    : undefined;
  const createFlexProSubscription = useCreateFlexProSubscription();
  const flexProEnrolled = user?.business.flexProEnrolled; //already subscribed
  const { data: currentSubscription } = useFlexProSubscriptionQuery();
  const resubscribeFlexProSubscription = useResubscribeFlexProSubscription();
  const canViewFlexProViewTrial = useIsFeatureFlagOn("flexpro_free_trial");
  const currentPath = useLocation().pathname;
  const onOverviewPage = currentPath === routes.flexPassFAQ;

  const promoListCopy = [
    "Change, cancel or shorten any reservation, no strings attached.",
    `One low monthly fee of ${formatCurrency(
      monthlyCost,
      false,
      currencyCode
    )}, regardless of how much you book.`,
    "No commitments — cancel anytime.",
  ];

  const currentListCopy = canViewFlexProViewTrial ? promoListCopy : listCopy;

  /** Determines need to return default payment method on paymentProfileQuery load
   * Check for existence of paymentId
   * Check if paymentId is 0 (Direct Bill - should always be 0)
   * if paymentId does not exist
   * Check paymentProfileQuery results for valid payment method
   * if no payment profile getDefaultPayment
   * */
  useEffect(() => {
    const hasPaymentId = selectedPayment?.id || selectedPayment?.id === 0;
    if (!hasPaymentId) {
      const paymentProfile = paymentProfiles?.find(
        (paymentMethod) => paymentMethod.id === selectedPayment?.id
      );

      if (!paymentProfile) {
        const userDefaultPayment = paymentProfiles?.find((p: IPaymentProfile) => p.default);

        // user chosen default payment
        if (isPaymentMethodValid(userDefaultPayment)) {
          setSelectedPayment(userDefaultPayment);
          return;
        }

        // direct bill payment method
        if (hasDirectBill) {
          setSelectedPayment(directBillLegacy);
          return;
        }

        const validPaymentProfile = paymentProfiles?.find((p: IPaymentProfile) =>
          isPaymentMethodValid(p)
        );

        // check for any valid payment method as a fallback
        if (validPaymentProfile) {
          setSelectedPayment(validPaymentProfile);
          return;
        }

        // If we have invalid payment methods, show them as options, otherwise show the add payment method form
        setSelectedPayment(undefined);
      }
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentProfiles]);

  const handleJoinFlexProButton = async () => {
    if (!!selectedPayment && !!selectedSubscriptionCycle && !!flexProPricingBreakdown) {
      const transactionType = selectedPayment.type === "directbill" ? "bill" : "charge";
      const frequency = selectedSubscriptionCycle;
      const paymentProfileId = selectedPayment?.id;
      const amount = flexProPricingBreakdown?.amount;

      if (currentSubscription) {
        if (currentSubscription.status === "active") {
          setModalStep(isMobile ? FlexProModalSteps.MobileActive : FlexProModalSteps.Active);
        } else {
          resubscribeFlexProSubscription.mutate(
            { transactionType, frequency, paymentProfileId },
            {
              onSuccess: () => {
                setModalStep(
                  isMobile ? FlexProModalSteps.MobileSuccess : FlexProModalSteps.Success
                );
              },
            }
          );
        }
      }

      if (!flexProEnrolled) {
        createFlexProSubscription.mutate(
          {
            transactionType,
            frequency,
            paymentProfileId,
            amount,
          },
          {
            onSuccess: () => {
              ampli.clickFlexProPayNow({
                userId: user?.id,
                subscriptionType: selectedSubscriptionCycle,
              });
              setModalStep(isMobile ? FlexProModalSteps.MobileSuccess : FlexProModalSteps.Success);
              pendo &&
                pendo.track("flexpro-subscription-purchased", {
                  selectedSubscriptionCycle: String(selectedSubscriptionCycle),
                });
            },
            onError: () => {
              setModalStep(isMobile ? FlexProModalSteps.MobileError : FlexProModalSteps.Error);
            },
          }
        );
      }
    }
  };

  const ErrorMessage = () => (
    <Box display="flex" flexDirection="column" gap={16} padding={20}>
      <AtlasIcon
        name="circle-xmark--solid"
        color="accentRedLight"
        style={{ width: "64px", height: "64px", margin: "auto" }}
      />
      <Typography as="h1" variant="heading/lg" style={{ textAlign: "center" }} margin={0}>
        Error Processing Payment
      </Typography>
      <Typography variant="body/md" style={{ textAlign: "center" }}>
        There was a problem processing your credit card. Please contact your credit card company or
        add a new payment method.
      </Typography>
      <Button
        onClick={() =>
          setModalStep(isMobile ? FlexProModalSteps.MobileSignUpPart1 : FlexProModalSteps.SignUp)
        }
        size="lg"
      >
        Continue
      </Button>
    </Box>
  );

  const ActiveMessage = () => (
    <Box display="flex" flexDirection="column" gap={16} padding={20}>
      <AtlasIcon
        name="circle-check--solid"
        color="accentGreenLight"
        style={{ width: "64px", height: "64px", margin: "auto" }}
      />
      <Typography as="h1" variant="heading/lg" style={{ textAlign: "center" }} margin={0}>
        Good news! Looks like your company already has a FlexPro subscription.
      </Typography>
      <Typography variant="body/md" style={{ textAlign: "center" }}>
        This trip will be covered by FlexPro.
      </Typography>
      <DialogClose>
        <Button size="lg">Continue</Button>
      </DialogClose>
    </Box>
  );

  /** The actual saving is handled in {@link EditPaymentProfileForm} form. Currently, just handles any side effects */
  const handleAddCardPayment = async (payment: IPaymentProfile) => {
    const isExistingPayment = paymentProfiles?.find(
      (existingPayment: IPaymentProfile) => existingPayment.id === payment.id
    );

    // if duplicate card, throw error, otherwise save
    if (isExistingPayment) {
      toast({
        title: "Duplicate Payment Method",
        icon: "circle-exclamation",
        sentiment: "critical",
        description: `The card ending in ${payment.last4} already exists.`,
      });
    } else {
      toast({
        title: "Payment method saved",
        icon: "circle-exclamation",
        sentiment: "positive",
      });
      // set selected payment as newly added payment card
      setSelectedPayment(payment);
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    queryClient.invalidateQueries(endpoints.paymentProfiles);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    queryClient.invalidateQueries(endpoints.flexProSubscriptions);
    globalThis.Spreedly.removeHandlers();
    setModalStep(
      isMobile ? FlexProModalSteps.MobileChangePayment : FlexProModalSteps.ChangePayment
    );
  };

  let title = selectedPayment?.name ?? "";
  let label = "";
  let description = "";
  let subDescription: ReactElement | undefined = undefined;
  if (selectedPayment && !isDirectBillLegacy(selectedPayment)) {
    title = selectedPayment.nickname || selectedPayment.name;
    label = selectedPayment.default ? "Default" : "";
    description = `ending in ${
      selectedPayment.last4 || selectedPayment.cardNumber.replaceAll("-", "")
    }`;
    subDescription = selectedPayment?.isExpired ? (
      <Typography variant="body/sm" color="accentRedLight" id="expired-payment-method">
        (Expired)
      </Typography>
    ) : undefined;
  }

  if (isError) {
    return <NuclearErrorModal hasPageError />;
  }

  const SignUpForm = () =>
    !!flexProPricing && !!flexProPricingBreakdown ? (
      <>
        <Typography as="h4" variant="heading/xl" margin={0} color="foregroundPrimary">
          Sign Up Today
        </Typography>

        <RadioGroup
          style={{ width: "100%" }}
          isBoxed
          onValueChange={(val) => setSelectedSubscriptionCycle(val as keyof IFlexProPricing)}
          defaultChecked
          defaultValue={selectedSubscriptionCycle}
        >
          {Object.entries(flexProPricing).map(([cycle, subscription]) => {
            const { amount, discountAmount } = subscription;
            return (
              <RadioGroupItem
                style={{ height: "72px" }}
                key={cycle}
                value={cycle}
                onClick={() =>
                  cycle === "monthly"
                    ? ampli.clickFlexProMonthlyOption({
                        userId: user?.id,
                      })
                    : ampli.clickFlexProYearlyOption({
                        userId: user?.id,
                      })
                }
                data-testid={`radio-${amount}`}
              >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  {!canViewFlexProViewTrial ? (
                    <Box display="flex" flexDirection="column">
                      <Typography as="h4" variant="heading/md">
                        {cycle === "month" ? "Monthly" : "Yearly"}
                      </Typography>
                      <Box display="flex" alignItems="center" gap={16}>
                        <Typography variant="body/md">
                          {formatCurrency(discountAmount, true, currencyCode)}
                        </Typography>
                        {cycle === "month" && (
                          <Typography variant="body/sm" color="foregroundDisabled">
                            <s>{formatCurrency(amount, true, currencyCode)}</s>
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <Box display="flex" flexDirection="column">
                      <Typography as="h4" variant="heading/md" margin={0} color="foregroundPrimary">
                        {cycle === "month" ? "Monthly - " : "Annually - "}
                        {formatCurrency(discountAmount, false, currencyCode)}
                      </Typography>
                      <Typography variant="body/sm">
                        {cycle === "month" && `Cancel Anytime`}
                      </Typography>
                    </Box>
                  )}

                  {cycle === "year" && <Chip label="Best Value" color="yellow" />}
                </Box>
              </RadioGroupItem>
            );
          })}
        </RadioGroup>

        <Box display="flex" flexDirection="column" gap={8}>
          <Typography as="h4" variant="body/sm" color="foregroundPrimary">
            Payment Method
          </Typography>
          {selectedPayment ? (
            <Card marginBottom={16} style={{ borderRadius: theme.tokens.borderRadius.md }}>
              <CardContent>
                <Box display="flex" alignItems="center" gap={12}>
                  <Image
                    src={`${config.cdnHost}/assets/creditcards/${selectedPayment.type
                      .split(" ")
                      .join("")}.png`}
                    alt={`${selectedPayment.type}`}
                    fallbackSrc={`${config.cdnHost}/assets/creditcards/default.png`}
                    width={27}
                    height={21}
                    setContainerSize={false}
                  />
                  <Box display="flex" flexDirection="column" style={{ flexGrow: 1 }}>
                    <Box display="flex" alignItems="center" gap={8}>
                      <Typography variant="body/md" color="foregroundPrimary">
                        {title}
                      </Typography>
                      {!!label && <Chip label={label} size="xs" color="gray" />}
                    </Box>
                    <Box display="flex" alignItems="center" gap={4}>
                      {!!description && <Typography variant="body/xs">{description}</Typography>}
                      {description && subDescription ? "•" : null}
                      {!!subDescription && (
                        <Typography variant="body/xs">{subDescription}</Typography>
                      )}
                    </Box>
                  </Box>
                  <Button
                    variant="plain"
                    size="sm"
                    color="primary"
                    onClick={() => {
                      ampli.clickFlexProChangePaymentMethod({
                        userId: user?.id,
                      });
                      setModalStep(
                        isMobile
                          ? FlexProModalSteps.MobileChangePayment
                          : FlexProModalSteps.ChangePayment
                      );
                    }}
                  >
                    Change
                  </Button>
                </Box>
              </CardContent>
            </Card>
          ) : (
            <Card marginBottom={16}>
              <CardContent>
                <Box display="flex" alignItems="center" gap={12}>
                  <AtlasIcon name="credit-card" />
                  <Box display="flex" flexDirection="column" style={{ flexGrow: 1 }}>
                    <Box display="flex" alignItems="center" gap={8}>
                      <Typography variant="body/md">No cards available</Typography>
                    </Box>
                    <Typography variant="body/xs">Please add one to continue</Typography>
                  </Box>
                  <Button
                    variant="plain"
                    size="sm"
                    color="secondary"
                    onClick={() => {
                      setModalStep(FlexProModalSteps.ChangePayment);
                    }}
                  >
                    Add Card
                  </Button>
                </Box>
              </CardContent>
            </Card>
          )}
          <Box display="flex" flexDirection="column" gap={24}>
            <Styled.Checkbox
              isChecked={understandCheckbox}
              onCheckedChange={(state) => setUnderstandCheckbox(Boolean(state))}
            >
              <Typography variant="body/sm">
                I understand this applies to all future bookings and only applies to bookings with
                an active FlexPro subscription.
              </Typography>
            </Styled.Checkbox>

            <Styled.Checkbox
              isChecked={authorizeCheckbox}
              onCheckedChange={(e) => setAuthorizeCheckbox(Boolean(e))}
            >
              <Typography variant="body/sm">
                I authorize {COMPANY_NAME} to charge{" "}
                {formatCurrency(flexProPricingBreakdown.discountAmount, true, currencyCode)} on any
                payment method on your account, and{" "}
                {selectedSubscriptionCycle === "month" ? "monthly" : "yearly"} thereafter, based on
                the terms, until you cancel.{" "}
                <a href={COMPANY_ROUTES.terms} target="_blank" rel="noreferrer noopener">
                  View terms and conditions
                </a>
                .
              </Typography>
            </Styled.Checkbox>
          </Box>
        </Box>
      </>
    ) : null;

  switch (modalStep) {
    case FlexProModalSteps.SignUp:
      return (
        <Styled.Dialog>
          <Styled.FlexProSignUpModalContainer>
            {!!currentUserRole && (
              <Styled.FlexProModalSection $isSignUp={false}>
                <div>
                  <DialogClose>
                    <IconButton
                      shape="circle"
                      icon="xmark"
                      size="lg"
                      data-testid="modal-close-button"
                      onClick={() => {
                        ampli.clickFlexProCloseSignUp({ userId: user?.id });
                      }}
                    />
                  </DialogClose>
                </div>
                <Styled.FlexProColGapContainer $onOverviewPage={onOverviewPage} $gap="32px">
                  <Styled.FlexProLogo name="flex-pro--lockup" />
                  <div>
                    <Typography as="h1" variant="heading/lg" color="foregroundPrimary">
                      {canViewFlexProViewTrial
                        ? "Get unlimited Flex, first month is on us."
                        : `Total flexibility for just ${formatCurrency(
                            monthlyCost,
                            false,
                            currencyCode
                          )}/month.`}
                    </Typography>
                    <Typography variant="body/md" margin={0} color="foregroundPrimary">
                      {canViewFlexProViewTrial
                        ? "Enjoy the ultimate in travel flexibility with unlimited coverage for every trip, every hotel."
                        : "Opt in to complete peace of mind with FlexPro and get coverage for every booking, every trip."}
                    </Typography>
                  </div>
                  <Styled.FlexProColGapContainer $gap="10px">
                    {currentListCopy.map((listItem, i) => (
                      <Styled.FlexProModalItemContainer key={`item-${i}`}>
                        <Styled.FlexProModalItemIconContainer>
                          <AtlasIcon name="check" />
                        </Styled.FlexProModalItemIconContainer>
                        <Typography variant="body/md" color="foregroundPrimary">
                          {listItem}
                        </Typography>
                      </Styled.FlexProModalItemContainer>
                    ))}
                  </Styled.FlexProColGapContainer>
                </Styled.FlexProColGapContainer>
                {!onOverviewPage ? (
                  <Link
                    to={routes.flexPassFAQ + "?int=true"}
                    target={internalLinkNewTabTarget()}
                    onClick={() => {
                      ampli.viewFlexProOverviewPage({
                        businessId: user?.business.id,
                        userId: user?.id,
                        previousPage: "Flex Pro Sign Up Modal",
                      });
                      ampli.clickFlexProLearnMore({ userId: user?.id });
                    }}
                  >
                    <Typography variant="link/sm" color="brandSecondary">
                      Learn More
                    </Typography>
                  </Link>
                ) : null}
              </Styled.FlexProModalSection>
            )}

            {!!flexProPricing && !!flexProPricingBreakdown && (
              <Styled.FlexProModalSection $isSignUp={true}>
                <SignUpForm />

                <Button
                  isDisabled={!(understandCheckbox && authorizeCheckbox && selectedPayment)}
                  onClick={handleJoinFlexProButton}
                  id="flexProJoinButton"
                  size="lg"
                  isLoading={createFlexProSubscription.isLoading}
                >
                  Join FlexPro
                </Button>
              </Styled.FlexProModalSection>
            )}
          </Styled.FlexProSignUpModalContainer>
        </Styled.Dialog>
      );

    case FlexProModalSteps.ChangePayment:
      return (
        <Styled.Dialog>
          <Box display="flex" flexDirection="column" gap={16}>
            <div>
              <IconButton
                icon="arrow-left"
                size="md"
                shape="circle"
                onClick={() => setModalStep(FlexProModalSteps.SignUp)}
              />
            </div>

            <Typography as="h1" variant="heading/xl" color="foregroundPrimary">
              Payment Method
            </Typography>

            <RadioGroup
              defaultChecked
              defaultValue={String(selectedPayment?.id)}
              onValueChange={(val) => {
                const paymentProfile = paymentProfiles?.find((p) => p.id === Number(val));
                const isDirectBillOption = Number(val) === directBillLegacy.id;
                setSelectedPayment(isDirectBillOption ? directBillLegacy : paymentProfile);
              }}
            >
              {!!paymentProfiles &&
                !!selectedPayment &&
                paymentProfiles?.map((paymentItem) => {
                  return (
                    <RadioGroupItem
                      key={paymentItem.id}
                      value={String(paymentItem.id)}
                      data-testid={`radio-${paymentItem.name}`}
                    >
                      <Box display="flex" alignItems="center" gap={16}>
                        <Image
                          src={`${config.cdnHost}/assets/creditcards/${paymentItem.type
                            .split(" ")
                            .join("")}.png`}
                          alt={`${paymentItem.type}`}
                          fallbackSrc={`${config.cdnHost}/assets/creditcards/default.png`}
                          width={27}
                          height={21}
                          setContainerSize={false}
                        />
                        <Typography as="h4" variant="body/md" margin={0} color="foregroundPrimary">
                          {paymentItem.nickname} ••••{paymentItem.last4}
                        </Typography>
                      </Box>
                    </RadioGroupItem>
                  );
                })}
              {!!hasDirectBill && (
                <RadioGroupItem key={directBillLegacy.id} value={String(directBillLegacy.id)}>
                  <Box display="flex" alignItems="center" gap={16}>
                    <Image
                      src={`${config.cdnHost}/assets/creditcards/${directBillLegacy.type
                        .split(" ")
                        .join("")}.png`}
                      alt={`${directBillLegacy.type}`}
                      fallbackSrc={`${config.cdnHost}/assets/creditcards/default.png`}
                      width={27}
                      height={21}
                      setContainerSize={false}
                    />
                    <Typography as="h4" variant="body/md" margin={0} color="foregroundPrimary">
                      {directBillLegacy.name}
                    </Typography>
                  </Box>
                </RadioGroupItem>
              )}
              <Button
                variant="plain"
                size="sm"
                onClick={() => {
                  ampli.clickFlexProAddNewCreditDebitCard({ userId: user?.id });
                  setModalStep(FlexProModalSteps.AddPayment);
                }}
              >
                + Add credit or debit card
              </Button>
            </RadioGroup>

            <Button
              onClick={() => {
                ampli.clickFlexProSavePaymentMethodAndContinue({
                  paymentMethod: selectedPayment?.type,
                  userId: user?.id,
                });
                setModalStep(FlexProModalSteps.SignUp);
              }}
              id="flexProSavePaymentButton"
            >
              Save & Continue
            </Button>
          </Box>
        </Styled.Dialog>
      );

    case FlexProModalSteps.AddPayment:
      return (
        <Styled.Dialog>
          <Box display="flex" flexDirection="column" gap={16}>
            <div>
              <IconButton
                icon="arrow-left"
                size="md"
                shape="circle"
                onClick={() => setModalStep(FlexProModalSteps.ChangePayment)}
              />
            </div>
            <Typography as="h1" variant="heading/xl" color="foregroundPrimary">
              Add Credit Card
            </Typography>
            <EditPaymentProfileForm
              paymentProfiles={paymentProfiles}
              onCancel={() => setModalStep(FlexProModalSteps.ChangePayment)}
              onSubmit={handleAddCardPayment}
              isNewCheckout
              isRenderedInModal
            />
          </Box>
        </Styled.Dialog>
      );

    case FlexProModalSteps.Success:
      return (
        <Styled.Dialog>
          <Box
            display="flex"
            flexDirection="column"
            gap={16}
            padding={16}
            style={{ textAlign: "center" }}
          >
            <AtlasIcon
              name="circle-check--solid"
              color="accentGreenLight"
              style={{ width: "64px", height: "64px", margin: "auto" }}
            />
            <Typography as="h1" variant="heading/xl" color="foregroundPrimary" margin={0}>
              Welcome to FlexPro!
            </Typography>
            <Typography variant="body/md">
              Your coverage is effective immediately for all future bookings.
            </Typography>

            <DialogClose>
              <Button id="flexProGotItButton" size="lg">
                Got it
              </Button>
            </DialogClose>
          </Box>
        </Styled.Dialog>
      );

    case FlexProModalSteps.MobileSignUpPart1:
      return (
        <BottomSheetContent>
          <Styled.MobileDrawerSection>
            <Styled.FlexProLogo name="flex-pro--lockup" />
            <div>
              <Typography as="h1" variant="heading/md" color="foregroundPrimary">
                {canViewFlexProViewTrial
                  ? `Get unlimited Flex, first month is on us.`
                  : `Modify any trip, any time, with FlexPro`}
              </Typography>
              <Typography variant="body/md" color="foregroundPrimary">
                {canViewFlexProViewTrial
                  ? `Enjoy the ultimate in travel flexibility with unlimited coverage for every trip, every hotel.`
                  : `Join now to enjoy the benefits of Flex coverage on all of your trips.`}
              </Typography>
            </div>
            <Box display="flex" flexDirection="column" gap={12}>
              {currentListCopy.map((listItem, i) => (
                <Box display="flex" gap={8} key={`item-${i}`}>
                  <Styled.FlexProModalItemIconContainer>
                    <AtlasIcon name="check" />
                  </Styled.FlexProModalItemIconContainer>
                  <Typography variant="body/md" color="foregroundPrimary">
                    {listItem}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box display="flex" flexDirection="column" gap={12} style={{ width: "100%" }}>
              {!onOverviewPage ? (
                <Link
                  to={routes.flexPassFAQ + "?int=true"}
                  target={internalLinkNewTabTarget()}
                  onClick={() => {
                    ampli.viewFlexProOverviewPage({
                      businessId: user?.business.id,
                      userId: user?.id,
                      previousPage: "Flex Pro Sign Up Modal -- Mobile View",
                    });
                    ampli.clickFlexProLearnMore({
                      userId: user?.id,
                    });
                  }}
                >
                  <Typography variant="link/sm" color="brandSecondary">
                    Learn More
                  </Typography>
                </Link>
              ) : null}
              <Button
                onClick={() => setModalStep(FlexProModalSteps.MobileSignUpPart2)}
                id="flexProMobileContinue"
                size="lg"
              >
                Continue
              </Button>
              <DialogClose>
                <Button id="flexProMobileCancel" variant="outlined" size="lg">
                  Cancel
                </Button>
              </DialogClose>
            </Box>
          </Styled.MobileDrawerSection>
        </BottomSheetContent>
      );

    case FlexProModalSteps.MobileSignUpPart2:
      return (
        <BottomSheetContent>
          <Styled.MobileDrawerSection>
            <SignUpForm />

            <Box display="flex" flexDirection="column" gap={12} style={{ width: "100%" }}>
              {currentUserRole === "admin" && (
                <Button
                  isDisabled={!(understandCheckbox && authorizeCheckbox && selectedPayment)}
                  onClick={handleJoinFlexProButton}
                  isLoading={createFlexProSubscription.isLoading}
                  id="flexProMobileContinue"
                  size="lg"
                >
                  Pay Now
                </Button>
              )}
              <Button
                id="flexProMobileCancel"
                variant="outlined"
                onClick={() => {
                  setModalStep(FlexProModalSteps.MobileSignUpPart1);
                }}
              >
                Cancel
              </Button>
            </Box>
          </Styled.MobileDrawerSection>
        </BottomSheetContent>
      );

    case FlexProModalSteps.MobileChangePayment:
      return (
        <BottomSheetContent>
          <Styled.MobileDrawerSection>
            <Typography variant="heading/xl">Payment Method</Typography>

            <RadioGroup
              style={{ width: "100%" }}
              isBoxed
              defaultChecked
              defaultValue={String(selectedPayment?.id)}
              onValueChange={(val) => {
                const paymentProfile = paymentProfiles?.find((p) => p.id === Number(val));
                const isDirectBillOption = Number(val) === directBillLegacy.id;
                setSelectedPayment(isDirectBillOption ? directBillLegacy : paymentProfile);
              }}
            >
              {!!paymentProfiles &&
                !!selectedPayment &&
                paymentProfiles?.map((paymentItem) => {
                  return (
                    <RadioGroupItem
                      key={paymentItem.id}
                      value={String(paymentItem.id)}
                      data-testid={`radio-${paymentItem.name}`}
                    >
                      <Box display="flex" alignItems="center" gap={16}>
                        <Image
                          src={`${config.cdnHost}/assets/creditcards/${paymentItem.type
                            .split(" ")
                            .join("")}.png`}
                          alt={`${paymentItem.type}`}
                          fallbackSrc={`${config.cdnHost}/assets/creditcards/default.png`}
                          width={27}
                          height={21}
                          setContainerSize={false}
                        />

                        <Box display="flex" flexDirection="column">
                          {!!paymentItem.nickname && (
                            <Typography
                              as="h4"
                              variant="body/md"
                              margin={0}
                              color="foregroundPrimary"
                            >
                              {paymentItem.nickname}
                            </Typography>
                          )}
                          {!!paymentItem.last4 && (
                            <Typography
                              as="h4"
                              variant="body/md"
                              margin={0}
                              color="foregroundPrimary"
                            >
                              ending in {paymentItem.last4} | Exp: {paymentItem.expirationMonth}/
                              {paymentItem.expirationYear}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </RadioGroupItem>
                  );
                })}
              {!!hasDirectBill && (
                <RadioGroupItem key={directBillLegacy.id} value={String(directBillLegacy.id)}>
                  <Box display="flex" alignItems="center" gap={16}>
                    <Image
                      src={`${config.cdnHost}/assets/creditcards/${directBillLegacy.type
                        .split(" ")
                        .join("")}.png`}
                      alt={`${directBillLegacy.type}`}
                      fallbackSrc={`${config.cdnHost}/assets/creditcards/default.png`}
                      width={27}
                      height={21}
                      setContainerSize={false}
                    />
                    <Typography as="h4" variant="body/md" margin={0} color="foregroundPrimary">
                      {directBillLegacy.name}
                    </Typography>
                  </Box>
                </RadioGroupItem>
              )}
            </RadioGroup>

            <Styled.MobileBottomContainer>
              <Button
                variant="plain"
                size="sm"
                onClick={() => {
                  ampli.clickFlexProAddNewCreditDebitCard({ userId: user?.id });
                  setModalStep(FlexProModalSteps.MobileAddPayment);
                }}
              >
                + Add credit or debit card
              </Button>
              <Button
                onClick={() => {
                  ampli.clickFlexProSavePaymentMethodAndContinue({
                    paymentMethod: selectedPayment?.type,
                    userId: user?.id,
                  });
                  setModalStep(FlexProModalSteps.MobileSignUpPart2);
                }}
                id="flexProSavePaymentButton"
              >
                Save & Continue
              </Button>
              <Button
                id="flexProMobileCancel"
                variant="outlined"
                size="lg"
                onClick={() => {
                  setModalStep(FlexProModalSteps.MobileSignUpPart2);
                }}
              >
                Cancel
              </Button>
            </Styled.MobileBottomContainer>
          </Styled.MobileDrawerSection>
        </BottomSheetContent>
      );

    case FlexProModalSteps.MobileAddPayment:
      return (
        <BottomSheetContent>
          <Styled.MobileDrawerSection>
            <Typography as="h1" variant="heading/lg" color="foregroundPrimary">
              Add Credit Card
            </Typography>
            <EditPaymentProfileForm
              isRenderedInModal
              paymentProfiles={paymentProfiles}
              shouldAutoSubmit
              isNewCheckout
              onCancel={() => setModalStep(FlexProModalSteps.MobileChangePayment)}
              onSubmit={handleAddCardPayment}
            />
          </Styled.MobileDrawerSection>
        </BottomSheetContent>
      );

    case FlexProModalSteps.MobileSuccess:
      return (
        <BottomSheetContent>
          <Box
            display="flex"
            flexDirection="column"
            gap={16}
            padding={16}
            style={{ textAlign: "center" }}
          >
            <AtlasIcon
              name="circle-check--solid"
              color="accentGreenLight"
              style={{ width: "64px", height: "64px", margin: "auto" }}
            />
            <Typography as="h1" variant="heading/xl" color="foregroundPrimary" margin={0}>
              Welcome to FlexPro!
            </Typography>
            <Typography variant="body/md">
              Cancel or shorten your trips and receive travel credit for unused nights. Your
              coverage is effective immediately.
            </Typography>

            <DialogClose>
              <Button id="flexProGotItButton" size="lg">
                Got it
              </Button>
            </DialogClose>
          </Box>
        </BottomSheetContent>
      );

    case FlexProModalSteps.Active:
      return (
        <Styled.Dialog>
          <ActiveMessage />
        </Styled.Dialog>
      );

    case FlexProModalSteps.MobileActive:
      return (
        <BottomSheetContent>
          <ActiveMessage />
        </BottomSheetContent>
      );

    case FlexProModalSteps.Error:
      return (
        <Styled.Dialog>
          <ErrorMessage />
        </Styled.Dialog>
      );

    case FlexProModalSteps.MobileError:
      return (
        <BottomSheetContent>
          <ErrorMessage />
        </BottomSheetContent>
      );

    default:
      return null;
  }
};

export const FlexProSignUpModal = ({
  children,
  isOpenByDefault,
}: { children: ReactNode; isOpenByDefault?: boolean }) => {
  const isMobile = useBreakpoint("md", "max");
  const defaultStep = isMobile ? FlexProModalSteps.MobileSignUpPart1 : FlexProModalSteps.SignUp;
  const [modalStep, setModalStep] = useState<FlexProModalSteps>(defaultStep);
  const currentPath = useLocation().pathname;
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const updateUser = async () => {
    const updatedUser = await User.getSelf();
    if (updatedUser) {
      dispatch(AuthActions.setUser(updatedUser));
    }
  };

  // redirect from /flex to /flex-pro if joining from company settings
  const companySettingsRedirect = () => {
    if (currentPath.includes("company-settings")) {
      navigate(routes.companySettings.flexPro);
    }
  };

  const completeFlow = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    updateUser();
    companySettingsRedirect();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    queryClient.invalidateQueries(endpoints.flexProSubscriptions);
  };

  const onChange = () => {
    if (
      [
        FlexProModalSteps.Success,
        FlexProModalSteps.MobileSuccess,
        FlexProModalSteps.Active,
        FlexProModalSteps.MobileActive,
      ].includes(modalStep)
    ) {
      completeFlow();
    }
    if (defaultStep !== modalStep) setModalStep(defaultStep);
  };

  return isMobile ? (
    <BottomSheet isOpenByDefault={isOpenByDefault} onIsOpenChange={onChange}>
      <BottomSheetTrigger asChild>{children}</BottomSheetTrigger>
      <FlexProSignUpModalContent setModalStep={setModalStep} modalStep={modalStep} />
    </BottomSheet>
  ) : (
    <Dialog isOpenByDefault={isOpenByDefault} onOpenChange={onChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <FlexProSignUpModalContent setModalStep={setModalStep} modalStep={modalStep} />
    </Dialog>
  );
};
