import { Box } from "@hotelengine/atlas-web";
import { AirlineLogo } from "@hotel-engine/assets/flights/AirlineLogo";
import type { IDetails, ISegment } from "@hotel-engine/types/trips";

import { useAirlineInfo } from "pages/Flights/hooks/useAirlineInfo";
import * as Styled from "pages/Trips/views/TripsList/styles";

export interface IFlightDetailCellProps {
  details: IDetails;
  statusChip: React.ReactNode;
}

const FlightDetailCell = ({ details, statusChip }: IFlightDetailCellProps) => {
  const { getAirlineNameFromIataAirlineCode } = useAirlineInfo();
  const flightConfirmation = details.flightConfirmation;

  if (!details?.slices?.length) {
    return <Styled.PrimaryText>No flight details available</Styled.PrimaryText>;
  }

  const airlinesDisplay = new Set(
    details.slices[0].segments.reduce((acc: string[], cur: ISegment) => {
      acc.push(cur.origin.carrierCode);
      return acc;
    }, [])
  );

  const airlinesCopy =
    airlinesDisplay.size > 1
      ? "Multiple carriers"
      : getAirlineNameFromIataAirlineCode([...airlinesDisplay][0]);

  return (
    <Styled.TripDetailWrapper>
      <AirlineLogo iata={[...airlinesDisplay]} size={32} />
      <Box marginLeft={4} display="flex" flexDirection="column" gap={4} alignItems="flex-start">
        <div>
          <Styled.PrimaryText>
            {airlinesCopy}
            {"  •  "}
            {details?.slices[0].segments[0].origin.carrierCode}
            {details?.slices[0].segments[0].origin.flightNumber}
          </Styled.PrimaryText>
          <Styled.SubText>#{flightConfirmation}</Styled.SubText>
        </div>
        {statusChip}
      </Box>
    </Styled.TripDetailWrapper>
  );
};

export default FlightDetailCell;
