import { Chip } from "@hotelengine/atlas-web";
import type { ChipSizes } from "@hotelengine/atlas-web/dist/components/Chip/types";
import { useTranslation } from "react-i18next";

interface ICorporateDiscountBadge {
  size?: ChipSizes;
}

export function CorporateDiscountBadge({ size }: ICorporateDiscountBadge) {
  const { t } = useTranslation("cars");

  return (
    <Chip
      size={size || "sm"}
      variant="filled"
      label={t("corporateDiscount.badge")}
      leadingIcon="badge-percent--solid"
      color="purple"
      data-testid="corporate-discount-badge"
    />
  );
}
