import { routes } from "@hotel-engine/constants";
import { getRedirectMatches } from "./getRedirectMatches";
/**
 *
 * @returns redirectPath: the path to redirect to after login
 */
export const getLogoutRedirectLocation = () => {
  const matches = getRedirectMatches();
  let path = "";

  if (matches && matches.length > 1) {
    const url = new URL(decodeURIComponent(matches[1]));
    path =
      url.pathname == "/login"
        ? ""
        : `?return=${encodeURIComponent(globalThis.location.origin + url.pathname + url.search)}`;
  }

  if (globalThis.location.pathname !== "/login") {
    const returnParam =
      globalThis.location.pathname == "/"
        ? path
        : `?return=${encodeURIComponent(globalThis.location.href)}`;
    const redirectPath = `${routes.login}${returnParam}`;
    return redirectPath;
  }

  return null;
};
