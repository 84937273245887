import { Box, Divider, Typography } from "@hotelengine/atlas-web";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  childCount?: number | null;
};

export const TravelerChild: React.FC<Props> = ({ childCount }) => {
  const { t } = useTranslation("itinerary");
  const hasChild = childCount && childCount > 0;

  if (!hasChild) {
    return null;
  }

  return (
    <Box marginTop={40}>
      <Box paddingBottom={8}>
        <Typography color="uiPrimary" variant="body/md">
          {t("preview.child", { count: childCount || 0 })}
        </Typography>
      </Box>
      <Divider variant="dotted" />
    </Box>
  );
};
