import { createContext, useContext, useReducer } from "react";

import type { ICarItinerary, IFlightItinerary } from "@hotel-engine/types/itinerary";
import type { IReservationBase } from "@hotel-engine/types/reservation";

import type { RefundType } from "./shared/ModficationViews/components/CancelRouter/hooks/useCancelRefundCalculator";
import type { FlightRefundType } from "./shared/ModficationViews/components/CancelRouter/hooks/usePreviewFlightRefund";
import type { CarRefundType } from "./shared/ModficationViews/components/CancelRouter/hooks/usePreviewCarRefund";

/** Each of these values corresponds to a router that can be rendered in ModificationViews/index.tsx,
 * each for a different type of modification flow
 */
export enum ModificationTypes {
  Cancel = "cancel",
  CancelInsurance = "cancelInsurance",
  ModifyDates = "modifyDates",
  ChangeGuests = "changeGuests",
  SpecialRequest = "specialRequest",
  LateCheckIn = "lateCheckIn",
  RequestNewDates = "requestNewDates",
  ShortenBooking = "shortenBooking",
}

type ModificationViewType = ModificationTypes | undefined;

export interface IModificationStatus {
  isError: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
}

export interface IModificationInfo {
  penaltyFee?: number;
  refundAmount: number | undefined;
  refundType: RefundType | FlightRefundType | CarRefundType | undefined;
  hadNonRefundableBookingFee?: boolean;
  roomsModified?: number;
  bookingType: "flight" | "car" | "lodging" | null;
  modificationType: ModificationViewType;
  showRefundInfo?: boolean;
}

type DispatchActions =
  | {
      type: "SET_SHOW_CONFIRMATION_MODAL";
      payload: boolean;
    }
  | {
      type: "SET_SHOW_STATUS_MODAL";
      payload: boolean;
    }
  | {
      type: "SET_SHOW_NAVIGATION_MODAL";
      payload: boolean;
    }
  | {
      type: "SET_NAVIGATION_ROUTE";
      payload: string | undefined;
    }
  | {
      type: "SET_MODIFICATION_VIEW";
      payload: ModificationViewType;
    }
  | {
      type: "SET_MODIFICATION_INFO";
      payload: IModificationInfo;
    }
  | {
      type: "SET_MODIFICATION_STATUS";
      payload: Partial<IModificationStatus>;
    }
  | {
      type: "ABANDON_MODIFICATION";
    };

export type Dispatch = (action: DispatchActions) => void;

interface IInitialState {
  /** Used to set the type of modification flow being entered (cancel, modify dates, etc), undefined used for "no active flow" */
  modificationView: ModificationViewType;
  /** Used to show/hide confirmation modal on flows that ask "are you sure" before submitting */
  showConfirmationModal: boolean;
  /** Used to show/hide modal confirming that user watns to nagivate away from active modification */
  showNavigationModal: boolean;
  /** The route to navigate to after confirming the user wants to abandon the modification */
  navigationRoute: string | undefined;
  /** Used to show/hide status modal, which manages the loading and success state of the modification */
  showStatusModal: boolean;
  /** Stores information needed for end of flow (like success modal) after queries and states have cleared elsewhere */
  modificationInfo: IModificationInfo;
  /** Object to manage request states: isError, isLoading, isSubmitted */
  modificationStatus: IModificationStatus;
}

export interface IModificationsContext<
  T extends IReservationBase | IFlightItinerary | ICarItinerary =
    | IReservationBase
    | IFlightItinerary
    | ICarItinerary,
> {
  isPreview: boolean;
  reservation: T extends IReservationBase
    ? IReservationBase
    : T extends IFlightItinerary
      ? IFlightItinerary
      : ICarItinerary;
  state: IInitialState;
  dispatch: Dispatch;
  refetchReservation: () => Promise<void>;
}

const initialState = {
  modificationView: undefined,
  showConfirmationModal: false,
  showStatusModal: false,
  showNavigationModal: false,
  navigationRoute: undefined,
  modificationInfo: {
    refundAmount: undefined,
    refundType: undefined,
    roomsModified: 0,
    modificationType: undefined,
    bookingType: null,
  },
  modificationStatus: {
    isError: false,
    isLoading: false,
    isSubmitted: false,
  },
};

export const ModificationsContext = createContext({} as IModificationsContext);

/** The idea in this context is to have everything set up to be able to be used non-specifically
 * across any type of modification. We should avoid adding anything here that is logic specific to
 * only a single modification type, that logic should instead go directly in that specific flow.
 */
const modificationViewsReducer = (state: IInitialState, action: DispatchActions): IInitialState => {
  switch (action.type) {
    case "SET_SHOW_CONFIRMATION_MODAL": {
      return {
        ...state,
        showConfirmationModal: action.payload,
      };
    }
    case "SET_SHOW_NAVIGATION_MODAL": {
      return {
        ...state,
        showNavigationModal: action.payload,
      };
    }
    case "SET_NAVIGATION_ROUTE": {
      return {
        ...state,
        navigationRoute: action.payload,
      };
    }
    case "SET_SHOW_STATUS_MODAL": {
      return {
        ...state,
        showStatusModal: action.payload,
      };
    }
    case "SET_MODIFICATION_VIEW": {
      return {
        ...state,
        modificationView: action.payload,
      };
    }
    case "SET_MODIFICATION_INFO": {
      return {
        ...state,
        modificationInfo: action.payload,
      };
    }
    case "SET_MODIFICATION_STATUS": {
      return {
        ...state,
        modificationStatus: { ...state.modificationStatus, ...action.payload },
      };
    }
    case "ABANDON_MODIFICATION": {
      return { ...initialState, navigationRoute: state.navigationRoute };
    }

    default: {
      return state;
    }
  }
};

/** This is the provider we are using to share state across itinerary modification components.
 * It is helping to manage things like showing various modification flows, showing/hiding common modals,
 * and error and loading states. There are a few props that are passed in directly to the provider,
 * these don't ever need to update, we just need access to them throughout the child components.
 * @prop reservation - IReservationBase | IFlightItinerary - the reservation object that the current itinerary is for
 * @prop isPreview - boolean - whether we are on the TripsPreviewPanel (true) or Itinerary (false)
 * @prop refetchReservation - () => Promise<void> - this is used after a successful modification to
 * refetch the current reservation in order to update the components accordingly, managed differently in
 * Trips vs Itinerary so it needs to be passed down from the parent location
 */
const ModificationsProvider = ({ children, reservation, isPreview, refetchReservation }) => {
  const [state, dispatch] = useReducer(modificationViewsReducer, initialState);

  return (
    <ModificationsContext.Provider
      value={{
        state,
        dispatch,
        reservation,
        isPreview,
        refetchReservation,
      }}
    >
      {children}
    </ModificationsContext.Provider>
  );
};

/**
 * @property state - IInitialState
 ```
  modificationView: ModificationViewType;
  showConfirmationModal: boolean;
  showNavigationModal: boolean;
  navigationRoute: string | undefined;
  showStatusModal: boolean;
  modificationInfo: IModificationInfo;
  modificationStatus: IModificationStatus;
```
 * @property dispatch - used for reducer of above state
 * @property reservation - provides access to the current reservation object anywhere in ItineraryContent
 * @property isPreview - provides access to the isPreview boolean anywhere in ItineraryContent
 */

const useBaseModificationsContext = <
  T extends IReservationBase | IFlightItinerary | ICarItinerary,
>() => {
  const context = useContext(ModificationsContext);
  if (context === undefined) {
    throw new Error("useModificationsContext must be used within the ModificationsProvider");
  }

  return context as IModificationsContext<T>;
};

const useModificationsContext = () => {
  return useBaseModificationsContext<IReservationBase>();
};

const useFlightModificationsContext = () => {
  return useBaseModificationsContext<IFlightItinerary>();
};

const useCarModificationsContext = () => {
  return useBaseModificationsContext<ICarItinerary>();
};

export {
  ModificationsProvider,
  useBaseModificationsContext,
  useFlightModificationsContext,
  useCarModificationsContext,
  useModificationsContext,
};
