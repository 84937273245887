import type { Ref } from "react";
import { forwardRef } from "react";

import { Formik } from "formik";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";

import type { IFeedbackForm, RatingValues } from "../../constants";
import { calloutsMap, ratingOptions } from "../../constants";
import * as Styled from "./styles";
import { Alert, Button, FormControl } from "@hotelengine/atlas-web";

interface IFeedbackFormProps {
  close: () => void;
  isMobile: boolean;
  isError: boolean | undefined;
  submit: (
    val: { satisfaction: RatingValues | undefined; multiline: string },
    { setSubmitting }: { setSubmitting: (val: boolean) => void }
  ) => void;
}

const getCalloutText = (values) =>
  `${values.satisfaction ? calloutsMap[values.satisfaction] : ""} ${
    ["veryDissatisfied", "dissatisfied"].includes(values.satisfaction)
      ? "Please help us improve by sharing your experience."
      : `We'd love to hear${values.satisfaction ? " more" : ""} about your experience.`
  }`;

const FeedbackForm = forwardRef(function FeedbackForm(
  { close, isMobile, isError, submit }: IFeedbackFormProps,
  ref: Ref<IFeedbackForm>
) {
  return (
    <Formik innerRef={ref} onSubmit={submit} initialValues={{ satisfaction: null, multiline: "" }}>
      {({ values, isSubmitting, setFieldValue }) => {
        return (
          <Styled.ModalForm>
            <Styled.RadioContainer>
              <p>Overall, how satisfied are you with {COMPANY_NAME}?</p>
              <Styled.MoodContainer>
                {ratingOptions.map((rating) => (
                  <Styled.MoodWrapper
                    key={rating.value}
                    onClick={() => setFieldValue("satisfaction", rating.value)}
                    type="button"
                  >
                    <Styled.MoodIcon
                      $activeColor={rating.color}
                      selected={values.satisfaction === rating.value}
                      name={rating.icon}
                    />
                    <Styled.MoodLabel
                      variant="body/sm"
                      selected={values.satisfaction === rating.value}
                    >
                      {rating.rating}
                    </Styled.MoodLabel>
                  </Styled.MoodWrapper>
                ))}
              </Styled.MoodContainer>
            </Styled.RadioContainer>
            <hr />
            <Styled.CommentContainer>
              <FormControl label={getCalloutText(values)}>
                <Styled.CommentText
                  name="multiline"
                  placeholder="Enter feedback comments"
                  onChange={(e) => setFieldValue("multiline", e.target.value)}
                  style={{ height: "100px" }}
                />
              </FormControl>

              {!!isError && (
                <Alert
                  sentiment="critical"
                  title="Something happened on our end. Please try again."
                />
              )}
            </Styled.CommentContainer>
            <Styled.ButtonsContainer>
              {!!isMobile && (
                <Button variant="outlined" onClick={close}>
                  Cancel
                </Button>
              )}
              <Button isDisabled={!values.satisfaction} isLoading={isSubmitting}>
                Submit
              </Button>
            </Styled.ButtonsContainer>
          </Styled.ModalForm>
        );
      }}
    </Formik>
  );
});

export default FeedbackForm;
