import { useEffect, useRef } from "react";
import { useFormikContext } from "formik";
import { capitalize } from "lodash";
import moment from "moment";

import FilterPill from "@hotel-engine/app/FilterPill";

import type { DateRange } from "@hotelengine/atlas-web";
import {
  Box,
  DatePicker,
  FormControl,
  RadioGroup,
  RadioGroupItem,
  Typography,
} from "@hotelengine/atlas-web";
import { useBreakpoint, usePrevious } from "@hotel-engine/hooks";
import { useParams } from "@hotel-engine/lib/react-router-dom";

import useTripsControl from "pages/Trips/hooks/useTripsControl";
import useTripsResult from "pages/Trips/hooks/useTripsResult";

import type { IDateFiltersValues } from "../..";
import { useTranslation } from "react-i18next";

type DateFilterStatus = "upcoming" | "today" | "past" | "all";

const filterGroups = ["upcoming", "today", "past", "all"];

const DateFiltersForm = () => {
  const { t } = useTranslation("trips");
  const { values, handleSubmit, setFieldValue, resetForm, setFieldError, errors } =
    useFormikContext<IDateFiltersValues>();

  const { isLoading } = useTripsResult();

  const { status } = useParams();
  const previousStatus = usePrevious(status);
  const hidePill = useRef(false);
  const [params, tripsControl] = useTripsControl();
  const containerRef = useRef<HTMLDivElement>(null);
  const isMobile = useBreakpoint("md", "max");

  const dateRangeFrom = params["start_time_gt"];
  const dateRangeTo = params["start_time_lt"];
  const previousDateRange = usePrevious({ from: dateRangeFrom, to: dateRangeTo });

  const dateRangeFilterApplied = !!(dateRangeFrom && dateRangeTo);
  const groupFilterApplied = !dateRangeFrom && !dateRangeTo;

  const isFilterPillApplied =
    values.group !== status ||
    !!values.dateRange.from ||
    !!values.dateRange.to ||
    dateRangeFilterApplied;
  const filterPillLabel = dateRangeFilterApplied
    ? `${moment(dateRangeFrom).format("ddd, MMM DD")} - ${moment(dateRangeTo).format(
        "ddd, MMM DD"
      )}`
    : capitalize(t(`dateFilters.${status as DateFilterStatus}`));

  useEffect(() => {
    if (status !== previousStatus) {
      resetForm({
        values: {
          group: dateRangeFilterApplied ? "dateRange" : (status as IDateFiltersValues["group"]),
          dateRange: {
            from: dateRangeFrom ? moment(dateRangeFrom).toDate() : undefined,
            to: dateRangeTo ? moment(dateRangeTo).toDate() : undefined,
          },
        },
      });
    }
  }, [dateRangeFilterApplied, dateRangeFrom, dateRangeTo, previousStatus, resetForm, status]);

  useEffect(() => {
    if (previousDateRange?.from !== dateRangeFrom || previousDateRange?.to !== dateRangeTo) {
      setFieldValue("dateRange", {
        from: dateRangeFrom ? moment(dateRangeFrom).toDate() : undefined,
        to: dateRangeTo ? moment(dateRangeTo).toDate() : undefined,
      });

      setFieldValue("group", dateRangeFrom && dateRangeTo ? "dateRange" : status);
    }
  }, [
    dateRangeFrom,
    dateRangeTo,
    previousDateRange?.from,
    previousDateRange?.to,
    setFieldValue,
    status,
  ]);

  useEffect(() => {
    if (hidePill.current && !isLoading) {
      hidePill.current = false;
    }
  }, [isLoading]);

  const handleFilterPillSubmit = () => {
    const invalidDateRange =
      (values.dateRange.from && !values.dateRange.to) ||
      (values.dateRange.to && !values.dateRange.from) ||
      (!values.dateRange.from && !values.dateRange.to && values.group === "dateRange");

    if (invalidDateRange) {
      setFieldError("dateRange", t("dateFilters.dateRangeError"));
      return false;
    } else {
      handleSubmit();
      hidePill.current = true;
      return true;
    }
  };

  const handleFilterPillReset = () => {
    if (!!dateRangeFilterApplied && isFilterPillApplied) {
      tripsControl.unsetParams("start_time_gt", "start_time_lt");
    }
    resetForm({
      values: {
        group: status as IDateFiltersValues["group"],
        dateRange: { from: undefined, to: undefined },
      },
    });
  };

  const handleRadioGroupChange = (value: string) => {
    if (value !== "dateRange") {
      setFieldValue("dateRange", { from: undefined, to: undefined });
    }
    setFieldValue("group", value);
  };

  const handleDateRangeChange = (range: DateRange) => {
    setFieldValue("dateRange", range);
    (range.from || range.to) && setFieldValue("group", "dateRange");
  };

  if (hidePill.current && isLoading) {
    return null;
  }

  return (
    <FilterPill
      isMobile={isMobile}
      labels={[filterPillLabel]}
      leadingIcon="calendar-day-flipped"
      data-testid="trips-group-filter-trigger"
      isApplied={isFilterPillApplied}
      isDisabled={isLoading}
      hideResetIcon={groupFilterApplied}
      onReset={handleFilterPillReset}
      onSubmit={handleFilterPillSubmit}
      keepOpenIfInvalid
    >
      <Box display="flex" flexDirection="column" gap={16} paddingLeft={2} paddingRight={2}>
        <Typography variant="body/md-strong" color="foregroundPrimary" id="date-filter-group-label">
          {t("dateFilters.date")}
        </Typography>
        <RadioGroup
          onValueChange={handleRadioGroupChange}
          defaultValue={values.group}
          value={values.group}
          aria-labelledby="date-filter-group-label"
        >
          {filterGroups.map((group) => (
            <RadioGroupItem key={group} value={group} data-testid={`${group}-group`}>
              {t(`dateFilters.${group as DateFilterStatus}`)}
            </RadioGroupItem>
          ))}
          <RadioGroupItem value="dateRange" style={{ display: "none" }}>
            {t("dateFilters.hiddenDateRange")}
          </RadioGroupItem>
        </RadioGroup>

        <Typography
          variant="body/md-strong"
          color="foregroundPrimary"
          marginTop={12}
          id="date-range-label"
        >
          {t("dateFilters.dateRange")}
        </Typography>
        <FormControl
          label=""
          status={errors.dateRange ? "error" : undefined}
          errorText={errors.dateRange as string}
          style={{ marginTop: -8 }}
          aria-labelledby="date-range-label"
        >
          <Box ref={containerRef} paddingBottom={2} style={{ width: isMobile ? "100%" : 325 }}>
            <DatePicker
              data-testid="trips_datesPicker"
              mode="range"
              calendarAlignment="center"
              formatString="MM/dd/yyyy"
              value={values.dateRange}
              onValueChange={handleDateRangeChange}
              rangePlaceholder={{ from: "MM/DD/YYYY", to: "MM/DD/YYYY" }}
              isTooltipEnabled={false}
              hasLeadingAdornment
            />
          </Box>
        </FormControl>
      </Box>
    </FilterPill>
  );
};

export default DateFiltersForm;
