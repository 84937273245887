import { createGlobalStyle } from "styled-components";
import { sizes } from "../variables";

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        outline:0;
        box-sizing:border-box;
        -webkit-font-smoothing: antialiased
    }
    html,
    body {
        background-color: ${({ theme }) => theme.colors.backgroundPrimary};
        color: ${({ theme }) => theme.legacy.colors.blackPanther};
        font-family: ${({ theme }) => theme.legacy.fontFamily.primary};
        font-size: ${({ theme }) => theme.legacy.fontSize.sm};
        font-weight: ${({ theme }) => theme.legacy.fontWeight.normal};
        height: 100%;
        min-height: 100%;
        font-variant: unset;
        font-feature-settings: unset;
    }
    body {
        line-height: 1.428571429;
    }
    // override antd styles
    h1, h2, h3, h4 {
        margin-bottom: 0;
        color: inherit;
    }
    a {
        color: ${({ theme }) => theme.legacy.colors.retroBlue};
        text-decoration: none;
    }
    hr {
        border: 0;
        border-top: 1px solid #eee;
    }
    .hideSalesforceFab {
        @media (max-width: ${sizes.breakpoints.xl}) {
            display: none !important;
        }
    }

    body.seatMapMode {
      [data-testid="members-devtools-trigger"], #embedded-messaging {
        display: none;
      }
    }

    .has-debug-boxes * {
      border: 1px solid ${({ theme }) => theme.colors.accentRed};
    }
 `;

export const GlobalBackgroundColorOverride = createGlobalStyle<{
  $backgroundColorTokenName: "backgroundPrimary" | "backgroundSecondary" | "accentBeigeLightest";
}>`
    html,
    body {
        background-color: ${({ theme, $backgroundColorTokenName }) =>
          !!$backgroundColorTokenName
            ? `${theme.colors[$backgroundColorTokenName]} !important`
            : "inherit"};
    }
 `;
