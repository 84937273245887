import { Fragment } from "react";
import { Typography } from "@hotelengine/atlas-web";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useAppSelector } from "store/hooks";

import * as Styled from "./styles";
import RefundDetailsItem from "./RefundDetailsItem";
import { FlexClawbackList } from "./FlexClawbackList";

const RefundDetails = () => {
  const userBusiness = useAppSelector((state) => state.Auth.user?.business);
  const { reservation } = useModificationsContext();
  const { refundBreakdowns } = reservation;

  if (!refundBreakdowns.length) return null;

  return (
    <Styled.RefundDetailsContainer>
      <Styled.HeaderContainer>
        <Typography variant="uppercase/xs-strong" color="foregroundPrimary">
          Refund details
        </Typography>
      </Styled.HeaderContainer>
      {refundBreakdowns.map((item, index) => (
        <Fragment key={item.createdAt}>
          <RefundDetailsItem business={userBusiness} refundBreakdown={item} />
          {index < refundBreakdowns.length - 1 && <Styled.InnerDivider />}
        </Fragment>
      ))}
      <FlexClawbackList />
    </Styled.RefundDetailsContainer>
  );
};

export default RefundDetails;
