import type { ErrorInfo, ReactNode } from "react";
import { Component } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

export type ErrorMetadata = Record<string, unknown>;

export interface IErrorWatchdogState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

export type FallbackComponent = (error: Error, errorInfo: ErrorInfo) => ReactNode;

export interface IErrorWatchdogProps {
  view?: string;
  scope?: string;
  children: ReactNode;
  fallback?: ReactNode | FallbackComponent;
}

/**
 * Logs an error to Datadog RUM with enriched metadata.
 *
 * @param error - The error that was thrown.
 * @param metadata - Additional metadata to be sent.
 */
function reportErrorRum(error: Error, metadata: ErrorMetadata) {
  try {
    datadogRum?.addError(error, metadata);
  } catch (exception) {
    console.error("Failed to send error to Datadog RUM:", exception);
  }
}

/**
 * Logs an error to Datadog Logs with enriched metadata.
 *
 * @param error - The error that was thrown.
 * @param metadata - Additional metadata to be sent.
 */
function reportErrorLogs(error: Error, metadata: ErrorMetadata) {
  try {
    if (datadogLogs?.logger && typeof datadogLogs.logger.error === "function") {
      datadogLogs.logger.error(`ErrorWatchdog component error: ${error.message}`, {
        error,
        ...metadata,
      });
    }
  } catch (exception) {
    console.error("Failed to send error to Datadog Logs:", exception);
  }
}

/**
 * ErrorWatchdog component acts as an error boundary.
 * It captures errors from its child components and dispatches crash alerts with enriched metadata.
 */
class ErrorWatchdog extends Component<IErrorWatchdogProps, IErrorWatchdogState> {
  constructor(props: IErrorWatchdogProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  /**
   * Updates the state to indicate that an error has been encountered.
   *
   * @param error - The error that was thrown.
   * @returns The updated state with error information.
   */
  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error, errorInfo: null };
  }

  /**
   * Lifecycle method invoked after an error has been thrown by a descendant component.
   * It captures error details and dispatches a crash alert.
   *
   * @param error - The error thrown by a descendant component.
   * @param errorInfo - Additional error information including the component stack.
   */
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const metadata: ErrorMetadata = {
      scope: this.props?.scope,
      view: this.props?.view,
      errorStack: errorInfo?.componentStack,
      timestamp: new Date().toISOString(),
    };

    reportErrorRum(error, metadata);
    reportErrorLogs(error, metadata);

    this.setState({ error, errorInfo });
  }

  /**
   * If an error occurs, it either:
   * - Renders nothing if no fallback is provided
   * - Renders the fallback component/element directly
   * - Calls the fallback function with error details if provided as a function
   * Otherwise, renders the children normally.
   *
   * @returns {ReactNode} The rendered content
   */
  render(): ReactNode {
    if (this.state.hasError) {
      if (!this.props?.fallback) return null;

      const isErrorRenderer = typeof this.props.fallback === "function";
      const fallback = isErrorRenderer
        ? this.props.fallback(this.state.error as Error, this.state.errorInfo as ErrorInfo)
        : this.props.fallback;

      return fallback;
    }

    return this.props.children;
  }
}

export default ErrorWatchdog;
