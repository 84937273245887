import { useCarModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { CollapsibleContent } from "@hotel-engine/app/ItineraryContent/shared/CollapsibleContent";
import { COMPANY_BRANDMARK, COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import { Box, BrandLogo, Typography, useTheme } from "@hotelengine/atlas-web";
import { ampli } from "ampli";
import config from "config";
import { useAppSelector } from "store/hooks";

const NeedHelp = () => {
  const user = useAppSelector((state) => state.Auth.user);
  const { reservation } = useCarModificationsContext();
  const { tokens } = useTheme();

  const currencyCode = user?.business?.currencyCode || "USD";

  return (
    <CollapsibleContent
      toggleTestID="need-help"
      toggleBtnLabel={<Typography variant="heading/lg">Need help?</Typography>}
    >
      <Box display="flex" alignItems="self-start" gap={16} padding={24}>
        <BrandLogo
          name={COMPANY_BRANDMARK}
          size={36}
          style={{
            width: 36,
            borderRadius: tokens.borderRadius.xs,
            flexGrow: 0,
            flexShrink: 0,
          }}
        />
        <Box display="flex" flexDirection="column" gap={16}>
          <Typography variant="body/md" color="foregroundPrimary">
            Contact {COMPANY_NAME} support to manage or change your booking at&nbsp;
            <Box
              as="a"
              href={`tel:+${config.supportNumber(currencyCode)}`}
              color="foregroundPrimary"
              data-testid="call-support-number"
              style={{ textDecorationLine: "underline" }}
              onClick={() => {
                if (user) {
                  ampli.clickCallMemberSupportFromItinerary({
                    carBookingID: reservation.bookingNumber,
                    userId: user.id,
                  });
                }
              }}
            >
              {config.supportNumber(currencyCode)}
            </Box>
            .
          </Typography>
          <Typography variant="body/md" color="foregroundPrimary">
            For faster support, mention{" "}
            <Typography variant="body/md" color="foregroundPrimary" as="p" marginBottom={0}>
              #{reservation.bookingNumber}.
            </Typography>
          </Typography>
        </Box>
      </Box>
    </CollapsibleContent>
  );
};

export default NeedHelp;
