/* eslint-disable @typescript-eslint/naming-convention */
import type { FC } from "react";
import styled, { css } from "styled-components";

import {
  DropdownMenuTrigger,
  Icon,
  DropdownMenuContent,
  Typography,
  Button,
  BottomSheetTrigger as AtlasBottomSheetTrigger,
  BottomSheetHeader as AtlasBottomSheetHeader,
  BottomSheetContent as AtlasBottomSheetContent,
  BottomSheetClose as AtlasBottomSheetClose,
  type BottomSheetHeaderProps,
  type TypographyProps,
  type DropdownMenuContentProps,
  DropdownMenuItem,
} from "@hotelengine/atlas-web";
import { lookupTierColor } from "@hotel-engine/app/Header/helpers";
import type { Rewards } from "@hotel-engine/types/rewards";

type PropsOf<T> = T extends (...args: [infer A, ...never[]]) => unknown ? A : never;

// Types
type IStyledDropdownContentProps = FC<
  DropdownMenuContentProps & {
    $isInternalUser?: boolean;
    $extraSpace?: boolean;
  }
>;
type SecondaryInfoProps = FC<TypographyProps & { $rewardsTier?: string | null }>;
type BottomSheetHeader = FC<BottomSheetHeaderProps>;
export interface ProgressBar extends ProgressA11yAttrs {
  $percent: number;
  $rewardsTier: Rewards.Tier;
}

// Shared styles
const sharedUserNavTriggerStyles = css`
  background-color: transparent;

  ${({ theme }) => css`
    border-color: ${theme.colors.borderDefault};
    margin-left: ${theme.spacing[8]};
    padding: 0 4px 0 2px;

    &:hover,
    &:focus {
      background-color: ${theme.colors.backgroundPrimaryActive} !important;
    }
  `}
`;

const sharedUserNavHeaderStyles = css`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.neutralN700};
`;

const sharedNavItemStyles = css`
  padding: 8px 16px;

  ${({ theme }) => css`
    color: ${theme.colors.neutralN50};
    border-bottom: 1px solid ${theme.colors.borderStrong};

    svg {
      color: ${theme.colors.neutralN50};
    }

    &:hover {
      color: unset;
      background-color: ${theme.colors.backgroundTransparentPrimaryHover};
    }

    &:focus {
      background-color: ${theme.colors.backgroundTransparentPrimaryActive};
    }
  `};
`;

// Misc styles
export const StyledIcon = styled(Icon)`
  position: relative;
  top: unset;
  left: 7.5px;
`;

export const SecondaryInfo = styled<SecondaryInfoProps>(Typography)`
   color: ${lookupTierColor};
`;

export const HeaderCTAWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing[16]};
`;
export const HeaderCTAItem = styled(DropdownMenuItem)`
  &:focus {
    background-color: ${({ theme }) => theme.colors.neutralN800};
  }

  &:hover {
    background-color: transparent;
  }
`;

export const AccountSwitchButton = styled(Button)`
  white-space: nowrap;
`;

export const Ul = styled.ul`
  list-style-type: none;
`;
export const Li = styled.li`
  ${sharedNavItemStyles};
`;

export const SignOutButton = styled(Button)`
  width: 100%;
  white-space: nowrap;
`;

// Dropdown styles
export const DropdownTrigger = styled(DropdownMenuTrigger)`
  ${sharedUserNavTriggerStyles};
`;

export const DropdownHeader = styled.div`
  ${sharedUserNavHeaderStyles};
  padding-bottom: 12px;
`;

type IDropdownTriggerButton = FC<{ $isRewards?: boolean } & PropsOf<typeof Button>>;
export const DropdownTriggerButton = styled<IDropdownTriggerButton>(Button)`
  min-width: 72px;

  span[data-ac="typography"] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${({ $isRewards }) => ($isRewards ? 7.5 : 15)}px;
  }
`;

export const DropdownContent = styled<IStyledDropdownContentProps>(DropdownMenuContent)`
  ${({ theme, $extraSpace }) => {
    return css`
    color: ${theme.colors.neutralN50};
    border-radius: ${theme.borderRadius.md};
    min-width: ${$extraSpace ? "352px" : "320px"};
  `;
  }};

  ${({ $isInternalUser }) => {
    const bottomColor = $isInternalUser ? "rgba(199,200,206,1)" : "rgba(94,95,100,1)";
    return css`
      background: linear-gradient(0deg, ${bottomColor}, 50%, rgba(74,75,82,1)  50%);
    `;
  }}
`;

export const StyledDropdownItem = styled(DropdownMenuItem)`
  ${sharedNavItemStyles};
`;

export const DropdownFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.neutralN600};
`;

// Mobile bottom sheet styles
export const BottomSheetTrigger = styled(AtlasBottomSheetTrigger)`
  ${sharedUserNavTriggerStyles};
  min-width: 72px;

  span[data-ac="typography"] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }
  &:focus {
    background-color: transparent !important;
  }
`;

export const BottomSheetClose = styled(AtlasBottomSheetClose)`
  svg {
    color: ${({ theme }) => theme.colors.foregroundEverlight};
  }
`;

export const BottomSheetHeader = styled<BottomSheetHeader>(AtlasBottomSheetHeader)`
  ${sharedUserNavHeaderStyles};
  margin-top: -12px;
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    border-top-right-radius: ${theme.spacing[16]};
    border-top-left-radius: ${theme.spacing[16]};
    padding: ${theme.spacing[16]};
  `};
`;

export const BottomSheetContent = styled(AtlasBottomSheetContent)`
  padding: 0 !important;

  ${({ theme }) => css`
    background-color: ${theme.colors.neutralN600};
    color: ${theme.colors.neutralN50};
  `};
`;

export const Progress = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.neutralN800};
  border-radius: ${({ theme }) => theme.borderRadius[8]};
`;

/**
 * ### {@link ProgressA11yAttrs `ProgressA11yAttrs`}
 *
 * Accessibility attributes to give screen readers the info they
 * need. Helpful here since we're using a `div` instead of the
 * native `progress` HTML element.
 */
interface ProgressA11yAttrs {
  role: string;
  "aria-valuenow": number;
  "aria-valuemin": number;
  "aria-valuemax": number;
}

export const ProgressBar = styled.div<ProgressBar>`
  width: calc(${({ $percent }) => $percent}% - 4px);
  min-width: 14px;
  height: 12px;
  position: relative;
  top: 2px;
  left: 2px;
  background: ${lookupTierColor};
  border-radius: ${({ theme }) => theme.borderRadius[8]};
`;

export const HighlightNextTierColor = styled.span<{ $rewardsTier: Rewards.Tier }>`
  color: ${lookupTierColor}
`;
