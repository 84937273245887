import { Box, Button } from "@hotelengine/atlas-web";
import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import { useIsPendoInitialized } from "@hotel-engine/contexts/Pendo";
import { ampli } from "ampli";

export type PendoGuideProps = {
  pendoTargetingID: string;
  pendoGuideId: string;
};

type PendoGuideButtonProps = ComponentProps<typeof Button> & PendoGuideProps;
/**
 * PendoGuideButton is a component that serves as an anchor point for Pendo guides.
 * It renders a button that can be targeted by Pendo for displaying interactive guides and walkthroughs.
 *
 * @param {Object} props - The component props
 * @param {ReactNode} props.children - The content to display inside the button
 * @param {string} props.pendoGuideId - The ID of the Pendo guide to associate with this button
 * @param {string} props.pendoTargetingID - The DOM element ID that Pendo will use to target this button
 * @returns {ReactElement|null} Returns a Button component or null if no pendoGuideId is provided
 */
const PendoGuideButton = ({
  children,
  pendoGuideId,
  pendoTargetingID,
  ...buttonProps
}: PendoGuideButtonProps) => {
  const { isInitialized } = useIsPendoInitialized();

  if (!isInitialized) {
    return null;
  }

  if (!pendoGuideId) {
    return null;
  }

  const handleClick = () => {
    ampli.clickCompanySettingsPendoGuide({
      pendoGuideId: pendoGuideId,
      pendoGuideName: pendoTargetingID,
    });
  };

  return (
    <Box>
      <Button id={pendoTargetingID} onClick={handleClick} size="sm" {...buttonProps}>
        {children}
      </Button>
    </Box>
  );
};

export type CompanySettingsPendoGuideButtonProps = PendoGuideProps & {
  copyKey?: "components.pendoGuideButton.showMeHow" | "components.pendoGuideButton.learnMore";
};

const CompanySettingsPendoGuideButton = ({
  copyKey = "components.pendoGuideButton.showMeHow",
  ...props
}: CompanySettingsPendoGuideButtonProps) => {
  const { t } = useTranslation();

  return (
    <PendoGuideButton variant="outlined" leadingIcon="circle-info" color="accent" {...props}>
      {t(copyKey, "Show me how")}
    </PendoGuideButton>
  );
};

export { CompanySettingsPendoGuideButton };
