import { ampli } from "ampli";
import { Formik } from "formik";
import { Suspense } from "react";

import ItineraryPreviewPanel from "@hotel-engine/app/ItineraryPreviewPanel";
import {
  PREVIEW_BOOKING_NUMBER,
  PREVIEW_BOOKING_TYPE,
} from "@hotel-engine/app/ItineraryPreviewPanel/constants";
import PageLayout from "@hotel-engine/app/PageLayout";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import { useGlobalTheme } from "@hotel-engine/contexts/GlobalThemeContext";
import { useBreakpoint } from "@hotel-engine/hooks";
import { Await, useLoaderData, useSearchParams } from "@hotel-engine/lib/react-router-dom";
import {
  Box,
  Chip,
  SegmentedControl,
  SegmentedControlItem,
  Typography,
} from "@hotelengine/atlas-web";

import { useAppSelector } from "store/hooks";

import TripsCalendarView from "./views/TripsCalendar";
import TripsListView from "./views/TripsList";
import TripsMapView from "./views/TripsMap";
import { TripsError, TripsFilters } from "./components";
import { tripsSearchInitialValues } from "./components/TripsFilters/SearchForm";
import useSearchForTrips from "./components/TripsFilters/SearchForm/hooks/useSearchForTrips";
import { TripsSearchSchema } from "./components/TripsFilters/SearchForm/schema";
import useAmplitudeViewTripsOnMount from "./hooks/useAmplitudeViewTripsOnMount";
import useMonthlyStatsEmailTracking from "./hooks/useMonthlyStatsEmailTracking";
import useResponsiveViewAdjustment from "./hooks/useResponsiveViewAdjustment";
import type { TripsView } from "./hooks/useTripsControl";
import useTripsControl from "./hooks/useTripsControl";
import * as Styled from "./styles";
import ErrorWatchdog from "@hotel-engine/app/ErrorWatchdog";
import { useTranslation } from "react-i18next";
import TripsSkeleton from "./components/TripsSkeleton";

const TripsContent = () => {
  const { t } = useTranslation("trips");
  const user = useAppSelector((state) => state.Auth.user);
  const [searchParams] = useSearchParams();
  const { tokens } = useGlobalTheme();
  const isMobileScreen = useBreakpoint("xxl", "max");
  const [, { setParams, unsetParams }] = useTripsControl();
  const { triggerSearch } = useSearchForTrips();
  const type = searchParams.get(PREVIEW_BOOKING_TYPE);
  const view = searchParams.get("view") || "list";
  const canViewCalendarView = ["admin", "coordinator"].includes(user?.role ?? "");

  const handleViewChange = (value: string) => {
    if (user) {
      const events: Record<string, () => void> = {
        list: () => ampli.clickListView({ userId: user.id }),
        map: () => ampli.clickMapView({ userId: user.id }),
        calendar: () => ampli.clickCalendarView({ userId: user.id }),
      };
      events[value]?.();
    }

    if (/calendar|map/.test(value)) {
      setParams({ booking_type: ["lodging"] }, { mergeStrategy: "shallow-merge" });
    }

    if (type) {
      unsetParams("booking_type");
    }

    setParams({ view: value as TripsView });
  };

  const getSegmentChildren = () => {
    const children = [
      <SegmentedControlItem
        value="list"
        key="list"
        icon="list"
        id="listView"
        aria-label="List View"
      />,
      <SegmentedControlItem value="map" key="map" icon="map" id="mapView" aria-label="Map View" />,
    ];

    if (!!canViewCalendarView && !isMobileScreen) {
      children.push(
        <SegmentedControlItem
          value="calendar"
          key="calendar"
          icon="calendar"
          id="calendarView"
          aria-label="Calendar View"
        />
      );
    }

    return children;
  };

  useAmplitudeViewTripsOnMount();
  useMonthlyStatsEmailTracking();
  useResponsiveViewAdjustment();

  if (!user) {
    return null;
  }

  return (
    <Formik
      onSubmit={triggerSearch}
      initialValues={tripsSearchInitialValues}
      validationSchema={TripsSearchSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      <Styled.ViewWrapper>
        <Styled.Trips
          $mapView={view === "map"}
          $showPreview={!!searchParams.get(PREVIEW_BOOKING_NUMBER) && !!type && !isMobileScreen}
        >
          <Styled.UpperContainer $isMobile={isMobileScreen}>
            <Styled.Header>
              <Typography variant="heading/xl" color="foregroundPrimary">
                {t("tripsPage.title")}
              </Typography>
              <ErrorWatchdog view="trips-page" scope="segmented-control">
                <SegmentedControl
                  shape="rectangle"
                  style={{
                    backgroundColor: tokens.colors.backgroundPrimary,
                    border: `${tokens.borderWidth.normal} solid ${tokens.colors.borderSubtle}`,
                  }}
                  defaultValue="list"
                  onValueChange={handleViewChange}
                  value={view}
                >
                  {getSegmentChildren()}
                </SegmentedControl>
              </ErrorWatchdog>
            </Styled.Header>
            <ErrorWatchdog view="trips-page" scope="trips-filters">
              <TripsFilters
                isCalendarView={view === "calendar" && canViewCalendarView}
                isPreviewOpen={
                  !!searchParams.get(PREVIEW_BOOKING_NUMBER) && !!type && !isMobileScreen
                }
              />
            </ErrorWatchdog>
            {view === "map" && !isMobileScreen && (
              <Chip
                label={t("tripsPage.showingHotelsOnlyInMapView")}
                size="md"
                color="everlight"
                variant="strong"
                leadingIcon="circle-exclamation"
                style={{
                  padding: `${tokens.spacing[20]} ${tokens.spacing[12]}`,
                  border: `${tokens.borderWidth.normal} solid ${tokens.colors.sentimentHelpfulSubtle}`,
                }}
              />
            )}
          </Styled.UpperContainer>
          <ErrorWatchdog view="trips-page" scope="views-container" fallback={<TripsError />}>
            <Styled.Inner>
              {view === "list" && <TripsListView />}
              {view === "map" && <TripsMapView />}
              {view === "calendar" && !!canViewCalendarView && <TripsCalendarView user={user} />}
            </Styled.Inner>
          </ErrorWatchdog>
        </Styled.Trips>
        <ErrorWatchdog view="trips-page" scope="itinerary-preview-panel">
          <ItineraryPreviewPanel />
        </ErrorWatchdog>
      </Styled.ViewWrapper>
    </Formik>
  );
};

const TripsPage = () => {
  const isOnColor = useBreakpoint("xxl", "max");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = useLoaderData() as any;

  if (data === null) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Styled.BigLoader size="lg" color="foregroundPrimary" />
      </Box>
    );
  }

  return (
    <PageLayout
      className="trips-page"
      title={`Trips | ${COMPANY_NAME}`}
      contentWidth="100%"
      bodyBackgroundColor={isOnColor ? "backgroundPrimary" : "backgroundSecondary"}
      hasPadding={false}
    >
      <Suspense fallback={<TripsSkeleton isMobileScreen={isOnColor} />}>
        <Await
          resolve={data?.trips}
          errorElement={
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100vh",
              }}
            >
              <TripsError />
            </Box>
          }
        >
          <TripsContent />
        </Await>
      </Suspense>
    </PageLayout>
  );
};

export default TripsPage;
