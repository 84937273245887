import { useCallback, useEffect, useState } from "react";
import { Alert, Box, Typography } from "@hotelengine/atlas-web";
import { useAuth0 } from "@auth0/auth0-react";
import * as Styled from "./styles";
import { ChangePasswordButton as StyledChangePasswordButton } from "../../../MemberDetails/components/MemberDetailsHeader/components/MemberDetailsActions/styles";
import ChangePasswordModal from "pages/MemberDetails/components/MemberDetailsHeader/components/ChangePasswordModal";

import config from "../../../../config";
import { routes } from "@hotel-engine/constants";
import { useAppSelector } from "../../../../store/hooks";
import { AuthenticatorCard } from "./AuthenticatorCard";
import { type IAuth0MFAEntry, type IAuthenticators } from "./authenticator";
import { hasAuth0AccessToken } from "@hotel-engine/utilities/auth";
import { captureMessage } from "@hotel-engine/utilities/logger";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import { Loader } from "@hotel-engine/components/Loader";
import SettingsHeader from "pages/CompanySettingsNew/components/SettingsHeader";

const MFA_AUDIENCE = `https://${config.auth0Tenant}.us.auth0.com/mfa/`;
const MFA_SCOPES = "read:authenticators remove:authenticators enroll";

const redirectPath = encodeURIComponent(
  `${globalThis.location.origin}${routes.settings.accountSecurity}`
);
const MFA_REDIRECT_URI = `${globalThis.location.origin}/?return=${redirectPath}`;

const AccountSecurity = () => {
  const { isAuthenticated, loginWithRedirect, getIdTokenClaims } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();
  const user = useAppSelector((state) => state.Auth.user);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const [authenticators, setAuthenticators] = useState<IAuthenticators>({
    email: {
      value: user?.email,
      kind: "email",
    },
    sms: {
      kind: "sms",
    },
    voice: {
      kind: "voice",
    },
  });
  const [mfaEnabled, setMfaEnabled] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const genericErrorMessage =
    "There was a problem loading your account security settings. Please try again later.";
  const getToken = useCallback(async () => {
    const tokenHasMfaClaim = async () => {
      const claims = await getIdTokenClaims();
      return claims?.amr?.includes("mfa");
    };

    const hasPermissions = async () => {
      return hasAuth0AccessToken(MFA_AUDIENCE) && (await tokenHasMfaClaim());
    };

    const getAccessToken = async () => {
      const options = {
        authorizationParams: {
          scope: MFA_SCOPES,
          audience: MFA_AUDIENCE,
        },
      };
      return await getAccessTokenSilently(options);
    };

    const stepUpAuthenticate = async () => {
      await loginWithRedirect({
        authorizationParams: {
          redirect_uri: MFA_REDIRECT_URI,
          audience: MFA_AUDIENCE,
          login_hint: user?.email,
        },
      });
      return null;
    };

    if (await hasPermissions()) {
      return await getAccessToken();
    } else {
      return await stepUpAuthenticate();
    }
  }, [getIdTokenClaims, getAccessTokenSilently, loginWithRedirect, user?.email]);

  const updateAuthenticators = (entry: IAuth0MFAEntry) => {
    setAuthenticators((prev) => {
      const updated = {
        ...prev,
      };
      if (entry.active) {
        updated[entry.oob_channel] = {
          id: entry.id,
          value: entry.name,
          ...prev[entry.oob_channel],
        };
      }

      return updated;
    });
  };

  const listAuthenticators = useCallback(async () => {
    if (!isAuthenticated) return;
    if (!isLoading) return;
    try {
      if (user?.mfaMode === "required") {
        setMfaEnabled(true);
        const token = await getToken();
        if (token === null) {
          return;
        } else {
          const url = `${MFA_AUDIENCE}authenticators`;
          const response = await fetch(url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          if (
            !response?.ok ||
            !response.headers.get("content-type")?.includes("application/json")
          ) {
            const error = await response.text();
            captureMessage(`Error listing authenticators in the Account Security tab`, {
              error,
            });
            setHasErrors(true);
            return;
          }
          const json = await response.json();
          for (const entry of json) {
            updateAuthenticators(entry);
          }
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      captureMessage(`Error listing authenticators in the Account Security tab`, {
        error,
      });
      setHasErrors(true);
      setIsLoading(false);
    }
  }, [user?.mfaMode, getToken, isAuthenticated, isLoading]);

  useEffect(() => {
    const list = async () => {
      await listAuthenticators();
    };
    void list();
  }, [listAuthenticators, isAuthenticated, authenticators, isLoading]);

  return isLoading ? (
    <Styled.LoaderContainer>
      <Loader size="lg" />
    </Styled.LoaderContainer>
  ) : (
    <>
      {!!hasErrors && (
        <Box marginBottom={24}>
          <Alert
            title={genericErrorMessage}
            variant="filled"
            sentiment="critical"
            onDismiss={() => setHasErrors(false)}
          />
        </Box>
      )}
      <Box key="account-security">
        <header>
          <SettingsHeader
            title="Account security"
            subTitle={`Manage how you log in to ${COMPANY_NAME}`}
          />
        </header>
        <Styled.Container>
          <Typography as="h2" color="foregroundPrimary" marginBottom={24} variant="heading/lg">
            Multi-factor authentication (MFA)
          </Typography>

          <AuthenticatorCard
            authenticator={authenticators.email}
            mfaEnabled={mfaEnabled}
            setHasErrors={setHasErrors}
          />
          <AuthenticatorCard
            authenticator={authenticators.sms}
            mfaEnabled={mfaEnabled}
            setHasErrors={setHasErrors}
          />
          <AuthenticatorCard
            authenticator={authenticators.voice}
            mfaEnabled={mfaEnabled}
            setHasErrors={setHasErrors}
          />

          <Styled.SectionDivider variant="dotted" />

          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography as="h2" color="foregroundPrimary" marginBottom={0} variant="heading/lg">
              Account password
            </Typography>

            <StyledChangePasswordButton
              variant="outlined"
              color="secondary"
              size="md"
              onClick={() => setOpenPasswordModal(true)}
            >
              <Typography data-testid="account-security-change-password-button" variant="body/md">
                Change password
              </Typography>
            </StyledChangePasswordButton>
          </Box>

          <ChangePasswordModal openModal={openPasswordModal} setOpenModal={setOpenPasswordModal} />
        </Styled.Container>
      </Box>
    </>
  );
};

export default AccountSecurity;
export { MFA_AUDIENCE, MFA_REDIRECT_URI, MFA_SCOPES };
