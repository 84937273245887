import type { Moment } from "moment";
import moment from "moment";

/**
 * Checks if the given date is two weeks after the current date.
 * @param date - The date to check.
 * @returns true if the date is two weeks after the current date, false otherwise.
 */
export const isTodayTwoWeeksAfterDate = (date: Moment): boolean => {
  const twoWeeksPostEnrollment = date.add(14, "days").startOf("day");
  const today = moment();
  return today.isAfter(twoWeeksPostEnrollment);
};
