import { ampli } from "ampli";

import type { ICourierNotificationInbox } from "@hotel-engine/types/courierNotifications";

import { NotificationInboxHeader } from "./NotificationInboxHeader";
import { NotificationInboxContent } from "./NotificationInboxContent";
import { useNotificationsInbox } from "../useNotificationsInbox";
import * as Styled from "./styles";

const NotificationInbox = ({
  fetchMessages,
  hasUnreadMessages,
  isError,
  isLoading,
  markAllAsRead,
  markMessageArchived,
  markMessageRead,
  markMessageUnread,
  messages = [],
  accountType,
}: ICourierNotificationInbox) => {
  const { isMobile, hasMessages, isEmpty } = useNotificationsInbox({
    accountType,
    fetchMessages,
    hasUnreadMessages,
    messages,
  });

  const handleMarkAllAsRead = () => {
    const unreadMessages = messages.filter((message) => !message.read);
    const readMessages = messages.filter((message) => message.read);
    const unreadMessagesSummary = unreadMessages?.map((message) =>
      String(message.data.notification_type)
    );
    const readMessagesSummary = readMessages?.map((message) =>
      String(message.data.notification_type)
    );

    ampli.clickMarkAllAsRead({
      messages: {
        unread: unreadMessagesSummary,
        read: readMessagesSummary,
      },
      unreadCount: unreadMessages.length,
      readCount: readMessages.length,
    });

    markAllAsRead();
  };

  return (
    <Styled.NotificationInbox data-testid="notification-inbox">
      <NotificationInboxHeader
        isMobile={isMobile}
        hasMessages={hasMessages}
        markAllAsRead={handleMarkAllAsRead}
      />

      <NotificationInboxContent
        isLoading={isLoading}
        isEmpty={isEmpty}
        isError={isError}
        messages={messages}
        markMessageArchived={markMessageArchived}
        markMessageRead={markMessageRead}
        markMessageUnread={markMessageUnread}
      />
    </Styled.NotificationInbox>
  );
};

export default NotificationInbox;
