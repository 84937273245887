import { Typography } from "@hotelengine/atlas-web";
import * as Styled from "./styles";
import type { ReactNode } from "react";
import type { CompanySettingsPendoGuideButtonProps } from "@hotel-engine/components/PendoGuideButton";
import { CompanySettingsPendoGuideButton } from "@hotel-engine/components/PendoGuideButton";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
interface ISettingsHeader {
  title: string;
  subTitle?: ReactNode;
  pendoGuideProps?: CompanySettingsPendoGuideButtonProps;
  as?: "h1" | "h2";
}

const SettingsHeader = ({ title, subTitle, as, pendoGuideProps }: ISettingsHeader) => {
  const arePendosEnabled = useIsFeatureFlagOn("web-platform-company-settings-pendos");
  return (
    <Styled.Container>
      <Typography as={as || "h1"} variant="display/xs" color="uiPrimary">
        {title}
      </Typography>
      {!!subTitle && (
        <Typography variant="body/md" color="foregroundSecondary">
          {subTitle}
        </Typography>
      )}
      {!!pendoGuideProps && arePendosEnabled ? (
        <CompanySettingsPendoGuideButton {...pendoGuideProps} />
      ) : null}
    </Styled.Container>
  );
};

export default SettingsHeader;
