import useTripsControl from "pages/Trips/hooks/useTripsControl";
import { Box, Button, Checkbox } from "@hotelengine/atlas-web";
import useTripsResult from "pages/Trips/hooks/useTripsResult";
import { useTranslation } from "react-i18next";
function CancelledTripsToggle() {
  const { t } = useTranslation("trips");
  const { isLoading } = useTripsResult();
  const [params, tripsControl] = useTripsControl();
  const isCancelledTripsOnly = !!params["only_cancelled_trips"];
  const cancelledTripsLabel = t("cancelledTripsToggle.label");

  const handleChange = () => {
    if (isCancelledTripsOnly) {
      tripsControl.unsetParams("only_cancelled_trips");
    } else {
      tripsControl.setParams({ only_cancelled_trips: !isCancelledTripsOnly });
    }
  };

  return (
    <Button
      data-testid="cancelled-trips-toggle"
      variant="outlined"
      onClick={handleChange}
      isDisabled={isLoading}
      asChild
      aria-label={cancelledTripsLabel}
    >
      <Box style={{ whiteSpace: "nowrap", minWidth: "176px" }}>
        <Checkbox
          isChecked={isCancelledTripsOnly}
          isDisabled={isLoading}
          style={{ marginRight: 8 }}
          aria-label={cancelledTripsLabel}
        />
        {cancelledTripsLabel}
      </Box>
    </Button>
  );
}

export default CancelledTripsToggle;
