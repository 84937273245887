import { z } from "zod";

export type Airport = z.infer<typeof airportSchema>;
export type FlightSegment = z.infer<typeof flightSegmentSchema>;
export type FlightSlice = z.infer<typeof flightSliceSchema>;
export type FlightCharges = z.infer<typeof flightChargesSchema>;
export type FlightBookingStatuses = z.infer<typeof flightBookingStatusesSchema>;
export type FlightBookingDetails = z.infer<typeof flightBookingDetailsSchema>;
export type FlightTraveler = z.infer<typeof travelerSchema>;
export type FlightBooking = z.infer<typeof flightBookingSchema>;

export const airportSchema = z.object({
  airportCode: z.string(),
  flightTime: z.string(),
  carrierCode: z.string(),
  flightNumber: z.string(),
});

export const flightSegmentSchema = z.object({
  origin: airportSchema,
  destination: airportSchema,
});

export const flightSliceSchema = z.object({
  segments: z.array(flightSegmentSchema),
});

export const flightChargesSchema = z.object({ total: z.string() }).passthrough();

export const flightBookingStatusesSchema = z.object({
  pendingModifications: z.boolean(),
  pendingCancellation: z.boolean(),
  refundable: z.boolean(),
});

export const flightBookingDetailsSchema = z.object({
  slices: z.array(flightSliceSchema).min(1),
  charges: flightChargesSchema,
  startTime: z.string(),
  endTime: z.string(),
  flightConfirmation: z.string(),
  flightTravelType: z.enum(["round_trip", "multi_city", "one_way"]),
  bookingStatuses: flightBookingStatusesSchema,
});

export const travelerSchema = z.object({ name: z.string() }).passthrough();

export const flightBookingSchema = z.object({
  guid: z.string(),
  bookingNumber: z.string(),
  bookingType: z.literal("flight"),
  details: flightBookingDetailsSchema,
  travelers: z.array(travelerSchema),
});
