import styled from "styled-components";

import Image from "@hotel-engine/common/Image";
import { Link } from "@hotel-engine/lib/react-router-dom";

const PropertyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[8]};
  padding: 8px 0 0;
`;

const PropertyImage = styled(Image)`
  width: 100%;
  max-height: 206px;
  border-radius: ${({ theme }) => theme.borderRadius[16]};
`;

const PropertyName = styled(Link)`
  width: 100%;
`;

export { PropertyContainer, PropertyName, PropertyImage };
