import { useCarModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { CollapsibleContent } from "@hotel-engine/app/ItineraryContent/shared/CollapsibleContent";
import ResponsiveAtlasDialog from "@hotel-engine/app/ResponsiveAtlasDialog";
import { Box, Button, Chip, Icon, Typography } from "@hotelengine/atlas-web";

import { useCarsFF } from "@hotel-engine/hooks/useCarsFF";
import { useLocation, useNavigate } from "@hotel-engine/lib/react-router-dom";
import { useGetCarInsuranceQuery } from "@hotel-engine/react-query/reservation/useCarInsurancePolicy";
import { ampli } from "ampli";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { ManagePolicyModal } from "./components/ManagePolicyModal";

interface IInsurance {
  hideManage?: boolean;
  collapseOpenByDefault?: boolean;
}

const Insurance = ({ hideManage, collapseOpenByDefault }: IInsurance) => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("cars_trips");
  const user = useAppSelector((state) => state.Auth.user);
  const {
    reservation,
    state: {
      modificationStatus: { isSubmitted },
    },
  } = useCarModificationsContext();
  const { isInsuranceEnabled } = useCarsFF();
  const $buttonOpenModal = useRef<HTMLButtonElement>(null);

  const {
    isError,
    data: insurancePolicy,
    refetch,
  } = useGetCarInsuranceQuery(reservation?.insurancePolicyId, {
    enabled: !!reservation?.insurancePolicyId,
  });

  const onOpenByDefault = useCallback(() => {
    $buttonOpenModal.current?.click();

    const searchParams = new URLSearchParams(search);
    searchParams.delete("manage_policy");

    navigate(`${pathname}?${searchParams.toString()}`, { replace: true });
  }, [pathname, search, navigate]);

  useEffect(() => {
    if (isSubmitted && !!reservation?.insurancePolicyId) {
      void refetch();
    }
  }, [isSubmitted, refetch, reservation?.insurancePolicyId]);

  useEffect(() => {
    if (collapseOpenByDefault && insurancePolicy?.status === "confirmed") {
      onOpenByDefault();
    }
  }, [collapseOpenByDefault, insurancePolicy, onOpenByDefault]);

  if (!isInsuranceEnabled || !insurancePolicy || isError) {
    return null;
  }

  const contentManage = (
    <ManagePolicyModal insurancePolicy={insurancePolicy} reservation={reservation} />
  );

  return (
    <CollapsibleContent
      toggleTestID="insurance-information"
      suppressBottomMargin={hideManage}
      defaultOpen={collapseOpenByDefault || hideManage}
      toggleBtnLabel={
        <Box display="flex" flexDirection="column" gap={16}>
          {insurancePolicy.status === "cancelled" && (
            <Box display="flex" justifyContent="flex-start">
              <Chip
                label="CANCELLED"
                color="red"
                size="xs"
                leadingIcon="circle-exclamation"
                style={{ borderRadius: "4px" }}
              />
            </Box>
          )}
          <Box display="flex" gap={12} alignItems="center">
            <Typography variant="heading/lg">{t("helpAndPolicies.insurance.title")}</Typography>
          </Box>
        </Box>
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={32}
        padding={24}
        data-testid="insurance-information-content"
      >
        <Box display="flex" alignItems="center" gap={8}>
          {insurancePolicy.status === "confirmed" && (
            <Icon name="circle-check" color="sentimentPositiveStrong" />
          )}

          <Typography variant="body/md-strong" color="foregroundPrimary">
            {t("helpAndPolicies.insurance.content", {
              policyId: insurancePolicy?.policyId,
            })}
          </Typography>
        </Box>

        {Boolean(!hideManage && insurancePolicy.status === "confirmed") && (
          <ResponsiveAtlasDialog
            title="Coverage details"
            content={contentManage}
            triggerComponent={
              <Button
                variant="outlined"
                size="lg"
                ref={$buttonOpenModal}
                data-testid="insurance-trigger-bottom-sheet"
                onClick={() => {
                  ampli.clickManagePolicy({
                    policyReferenceNumber: insurancePolicy?.policyId,
                    contractNumber: reservation.bookingNumber,
                    userId: user?.id,
                  });
                }}
              >
                {t("helpAndPolicies.insurance.button")}
              </Button>
            }
            isModal
          />
        )}
      </Box>
    </CollapsibleContent>
  );
};

export default Insurance;
