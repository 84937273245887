import { Link } from "@hotel-engine/lib/react-router-dom";
import { truncate } from "lodash";

import { routes } from "@hotel-engine/constants";
import { Box, BrandLogo, Typography } from "@hotelengine/atlas-web";
import { useAppSelector } from "store/hooks";

import FocusableLink from "@hotel-engine/app/FocusableLink/FocusableLink";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import { useBusinessLogo } from "@hotel-engine/hooks";
import { User } from "@hotel-engine/types/user";
import * as Styled from "./styles";

const height = 32;

export function Logo() {
  const user = useAppSelector((state) => state.Auth.user);
  const maxChars = globalThis.innerWidth < 940 ? 14 : 30;

  const businessLogoUrl = useBusinessLogo();

  if (!user) {
    return null;
  }

  return (
    <FocusableLink as={Link} $hasUnderlineOnHover={false} to={routes.dashboard} style={{ height }}>
      {User.isPersonalTravel(user) ? (
        <BrandLogo
          name="engine--lockup"
          size={height}
          role="img"
          aria-label={`${COMPANY_NAME}-logo`}
        />
      ) : businessLogoUrl ? (
        <Styled.Logo src={businessLogoUrl} alt={user.business.name} size={height} />
      ) : (
        <Box display="flex" alignItems="center" gap={8}>
          <BrandLogo name="engine" size={height} role="img" aria-label={`${COMPANY_NAME}-logo`} />
          <Typography variant="body/sm" color="foregroundPrimary" style={{ whiteSpace: "nowrap" }}>
            {truncate(user.business.name, { length: maxChars })}
          </Typography>
        </Box>
      )}
    </FocusableLink>
  );
}
