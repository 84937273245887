import type { ISummaryOfChargesItemProps } from "@hotel-engine/app/ItineraryContent/shared/SummaryOfChargesItem";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import { useFormatDate } from "@hotel-engine/hooks";
import { EPaymentType } from "@hotel-engine/types/cars/cars.shared";
import type { ICarSummaryOfCharges } from "@hotel-engine/types/itinerary";
import { formatCurrency } from "@hotel-engine/utilities";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import { useIsFeatureFlagOn } from "../../../../Experiments";

export enum ESpecialLabels {
  divider = "Divider",
  corporateBadge = "CorporateBadge",
}

export const useCarSummaryOfCharges = (
  charges: ICarSummaryOfCharges,
  paymentType: EPaymentType,
  pastReservation: boolean
) => {
  const user = useAppSelector((state) => state.Auth?.user);
  const currencyCode = user?.business?.currencyCode || "USD";
  const paidOn = useFormatDate(charges.paidOn, "MMM Do, YYYY", true) ?? "";
  const isPostPaid = paymentType === EPaymentType.Postpaid;
  const showCorporateDiscount = useIsFeatureFlagOn("cars_corporate_code");
  const hidePaidOn = !paidOn || paidOn === "Invalid date";
  const { t } = useTranslation("cars_trips", {
    keyPrefix: "summaryOfCharges.titles",
  });

  const shouldShowCorporateDiscount =
    showCorporateDiscount &&
    !!charges.discountAmount &&
    charges.discountAmount !== "0" &&
    (Number.parseFloat(charges.discountAmount) || 0) >
      Number.parseFloat(charges.strikeAmount) * 0.1;

  const items: ISummaryOfChargesItemProps[] = useMemo(
    () =>
      [
        {
          label: t("billingName"),
          value: charges.billingName || "",
        },
        {
          label: t("paymentMethod"),
          value: isPostPaid ? "Pay at pick-up" : charges.paymentMethod || "",
        },
        {
          label: t("paidOn"),
          value: paidOn || "",
          hideItem: hidePaidOn,
        },
        {
          label: ESpecialLabels.divider,
          value: "divider",
        },
        {
          label: ESpecialLabels.corporateBadge,
          value: "corporate badge",
          hideItem: !shouldShowCorporateDiscount,
        },
        {
          label: t("baseRental"),
          value: formatCurrency(
            (shouldShowCorporateDiscount ? charges.strikeAmount : charges.baseRental) || "0",
            true,
            currencyCode
          ),
        },
        {
          label: t("corporateDiscount"),
          value: `-${formatCurrency(charges.discountAmount || "0", true, currencyCode)}`,
          hideItem: !shouldShowCorporateDiscount,
          labelColor: "sentimentPositiveDefault" as ISummaryOfChargesItemProps["labelColor"],
          valueColor: "sentimentPositiveDefault" as ISummaryOfChargesItemProps["valueColor"],
        },
        {
          label: t("redeemedRewardsPoints", { COMPANY_ABBREVIATION: COMPANY_NAME }),
          value: `-${formatCurrency(charges.redeemedRewardsPoints || "0", true, currencyCode)}`,
          labelColor: "sentimentPositiveDefault" as ISummaryOfChargesItemProps["labelColor"],
          valueColor: "sentimentPositiveDefault" as ISummaryOfChargesItemProps["valueColor"],
          hideItem: charges.redeemedRewardsPoints === "0.0",
        },
        {
          label: t("redeemedTravelCredits"),
          value: `-${formatCurrency(charges.redeemedTravelCredits || "0", true, currencyCode)}`,
          labelColor: "sentimentPositiveDefault" as ISummaryOfChargesItemProps["labelColor"],
          valueColor: "sentimentPositiveDefault" as ISummaryOfChargesItemProps["valueColor"],
          hideItem: charges.redeemedTravelCredits === "0.0",
        },
        {
          label: t("protection"),
          value: formatCurrency(charges.insuranceAmount || "0", true, currencyCode),
          hideItem:
            Number.parseFloat(charges.insuranceAmount) === 0 ||
            Number.isNaN(Number.parseFloat(charges.insuranceAmount)),
        },
        {
          label: t("taxesFees"),
          value: formatCurrency(charges.taxesFees || "0", true, currencyCode),
        },
      ].filter((item) => item.value && !item.hideItem),
    [
      t,
      charges.billingName,
      charges.paymentMethod,
      charges.strikeAmount,
      charges.baseRental,
      charges.insuranceAmount,
      charges.discountAmount,
      charges.taxesFees,
      charges.redeemedRewardsPoints,
      charges.redeemedTravelCredits,
      isPostPaid,
      paidOn,
      hidePaidOn,
      shouldShowCorporateDiscount,
      currencyCode,
    ]
  );

  const getDueAtPickup = useCallback(() => {
    if (!isPostPaid) return "0";

    // For postpaid reservations, the total amount due at pickup is the
    // totalCharges minus the insurance amount
    // (as the insurance is the only amount that could be charged)
    const totalCharges = Number.parseFloat(charges.totalCharges || "0");
    const insuranceAmount = Number.parseFloat(charges.insuranceAmount || "0");

    return totalCharges - insuranceAmount;
  }, [charges, isPostPaid]);

  const getPaidAtCheckout = useCallback(() => {
    const totalCredits = Number.parseFloat(charges.redeemedTravelCredits || "0");
    const totalRewards = Number.parseFloat(charges.redeemedRewardsPoints || "0");

    if (isPostPaid) {
      // As in postpaid we do not allow the use of DB or TravelCredits/Rewards, the only
      // amount the customer could be charged would be the insurance.
      // So we can use the "totalCustomerCharge" to get this value
      const reservationAndInsurance = Number.parseFloat(charges.totalCustomerCharge || "0");
      return reservationAndInsurance;
    }

    return Number.parseFloat(charges.totalCharges) - totalCredits - totalRewards || "0";
  }, [charges, isPostPaid]);

  const payLaterItems: ISummaryOfChargesItemProps[] = useMemo(
    () => [
      {
        label: t(`totalCharges.${pastReservation ? "paid" : "due"}`),
        value: formatCurrency(getDueAtPickup(), true, currencyCode),
      },
      {
        label: t("totalCustomerCharge.paid"),
        value: formatCurrency(getPaidAtCheckout(), true, currencyCode),
      },
    ],
    [t, pastReservation, getDueAtPickup, currencyCode, getPaidAtCheckout]
  );

  return {
    items,
    payLaterItems: payLaterItems,
    totalCharge: formatCurrency(charges.totalCharges || "0", true, currencyCode),
  };
};
