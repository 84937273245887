import { Button, Typography } from "@hotelengine/atlas-web";
import config from "config";
import * as Styled from "./styles";
import { useTranslation } from "react-i18next";

const TripsError = () => {
  const { t } = useTranslation("trips");

  return (
    <Styled.TripsError data-testid="trips-error">
      <img src={`${config.cdnHost}/assets/trips/error-trips.svg`} alt={t("tripsError.altText")} />
      <Typography variant="heading/md" color="foregroundPrimary" data-testid="trips-error">
        {t("tripsError.title")}
      </Typography>
      <Button
        id="book-trip"
        onClick={() => {
          location.reload();
        }}
      >
        {t("tripsError.tryAgain")}
      </Button>
    </Styled.TripsError>
  );
};

export default TripsError;
