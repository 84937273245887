import { Box, Button, Icon, Typography } from "@hotelengine/atlas-web";
import { COMPANY_SUPPORT_EMAIL } from "@hotel-engine/constants/companyNames";

export interface IErrorModalProps {
  /** function that handles closing modal */
  handleCancel: () => void;
}

const ErrorModal = ({ handleCancel }: IErrorModalProps) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex">
        <Icon name="circle-exclamation" size="lg" color="accentOrange" />
        <Typography variant="heading/lg" color="foregroundPrimary" marginLeft={8}>
          It's not you, it's us.
        </Typography>
      </Box>
      <div>
        <Typography variant="body/md" color="foregroundPrimary" as="p" marginTop={8}>
          An error occurred while we were pulling up your results. Please try again. If this keeps
          happening, reach out to Member Support via live chat or {COMPANY_SUPPORT_EMAIL}.
        </Typography>
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={handleCancel}>Try again</Button>
        </Box>
      </div>
    </Box>
  );
};

export default ErrorModal;
