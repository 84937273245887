import { Box, FormControl, Slider, TextInput, Typography } from "@hotelengine/atlas-web";
import { formatCurrency } from "@hotel-engine/utilities";
import useTripsResult from "pages/Trips/hooks/useTripsResult";
import useTripsControl from "pages/Trips/hooks/useTripsControl";
import { useTranslation } from "react-i18next";

import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import type { FilterOptions } from "../..";
interface IFilterButtonProps {
  setTemporary: React.Dispatch<React.SetStateAction<FilterOptions>>;
  invalidPriceRange: boolean;
}

export interface IFilterRefProps {
  onReset: () => void;
  getValues: () => [number, number];
}

const FilterButtonPriceRange = forwardRef<IFilterRefProps, IFilterButtonProps>(
  ({ setTemporary, invalidPriceRange }, ref) => {
    const { t } = useTranslation("trips");
    const result = useTripsResult();
    const [param] = useTripsControl();
    const mostExpensiveTrip = Number(result.data?.mostExpensiveTrip);
    const leastExpensiveTrip = Number(result.data?.leastExpensiveTrip);
    const [displayMin, setDisplayMin] = useState(leastExpensiveTrip || 0);
    const [displayMax, setDisplayMax] = useState(mostExpensiveTrip);
    const deferredTotalGt = param?.["total_gt"];
    const deferredTotalLt = param?.["total_lt"];

    const handleSliderChange = (value: [number, number]) => {
      const [min, max] = value;

      setDisplayMin(min);
      setDisplayMax(max);
    };

    const handleSliderCommit = (value: [number, number]) => {
      const [min, max] = value;

      setTemporary((prev) => ({
        ...prev,
        total_gt: min,
        total_lt: max,
      }));
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, type: "min" | "max") => {
      const inputValue = e.target.value;

      // Allow only numbers, commas, and decimals
      const numericValue = inputValue.replace(/[^\d.,]/g, "");
      const newValue = Number(numericValue.replace(/,/g, ""));

      if (!isNaN(newValue)) {
        if (type === "min") {
          setDisplayMin(newValue);
          handleSliderCommit([newValue, displayMax]);
        } else {
          setDisplayMax(newValue);
          handleSliderCommit([displayMin, newValue]);
        }
      }
    };

    useImperativeHandle(
      ref,
      () => ({
        onReset: () => {
          setDisplayMin(leastExpensiveTrip || 0);
          setDisplayMax(mostExpensiveTrip);
        },
        getValues: () => [displayMin, displayMax],
      }),
      [displayMax, displayMin, leastExpensiveTrip, mostExpensiveTrip]
    );

    const formattedPriceMin = formatCurrency(displayMin, false);
    const formattedPriceMax = formatCurrency(displayMax, false);

    useEffect(() => {
      if (deferredTotalGt || deferredTotalLt) {
        setDisplayMin(deferredTotalGt || leastExpensiveTrip || 0);
        setDisplayMax(deferredTotalLt || mostExpensiveTrip);
      }
    }, [deferredTotalGt, deferredTotalLt, leastExpensiveTrip, mostExpensiveTrip]);

    return (
      <FormControl
        label=""
        style={{ marginTop: -8 }}
        errorText={t("filterOptions.priceRangeError")}
        status={invalidPriceRange ? "error" : "default"}
        aria-labelledby="price-range-heading"
      >
        <Box display="flex" flexDirection="column" gap={16}>
          <Typography variant="body/md-strong" color="foregroundPrimary" id="price-range-heading">
            {t("filterOptions.priceRange")}
          </Typography>
          <Slider
            variant="pill"
            data-testid="price-range-slider"
            value={[displayMin, displayMax]}
            minValue={leastExpensiveTrip || 0}
            maxValue={mostExpensiveTrip}
            onValueChange={handleSliderChange}
            onValueCommit={handleSliderCommit}
            isDisabled={!result}
            aria-labelledby="price-range-heading"
          />
          <Box display="flex" flexDirection="row" alignItems="center" gap={16} paddingBottom={2}>
            <TextInput
              value={formattedPriceMin}
              onChange={(e) => {
                handleInputChange(e, "min");
              }}
              aria-label={t("filterOptions.minimumPriceLabel", { price: formattedPriceMin })}
            />
            <Typography variant="body/sm"> - </Typography>
            <TextInput
              value={formattedPriceMax}
              onChange={(e) => {
                handleInputChange(e, "max");
              }}
              aria-label={t("filterOptions.maximumPriceLabel", { price: formattedPriceMax })}
            />
          </Box>
        </Box>
      </FormControl>
    );
  }
);

FilterButtonPriceRange.displayName = "FilterButtonPriceRange";

export default FilterButtonPriceRange;
