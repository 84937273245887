import { useEffect } from "react";
import { useBreakpoint } from "@hotel-engine/hooks/useBreakpoint";

import { useAppSelector } from "store/hooks";
import config from "config";

export const toggleSalesForceChat = (show: boolean) => {
  const helpButton = document.querySelector<HTMLDivElement>(".embedded-messaging");
  if (helpButton) {
    if (!show) {
      helpButton.style.display = "none";
    } else {
      helpButton.style.display = "block";
    }
  }
};

export const openSalesForceChat = () => {
  const helpButton = document.querySelector<HTMLButtonElement>(".helpButtonEnabled");
  if (helpButton) {
    helpButton.click();
  }
};

export function useInitSalesforceChat(featureFlagDisabled = false) {
  useEffect(() => {
    if (featureFlagDisabled && config.stage !== "dev") {
      document.body.insertAdjacentHTML(
        "beforeend",
        `
        <style type='text/css'>
            .embeddedServiceHelpButton .helpButton .uiButton {
                background-color: #0000ff;
                font-family: "Arial", sans-serif;
            }
            .embeddedServiceHelpButton .helpButton .uiButton:focus {
                outline: 1px solid #0000ff;
            }

            [embeddedservice-chatheader_chatheader-host] {
              background-color: white !important;
            }

            .embeddedServiceSidebarMinimizedDefaultUI .minimizedImage img, .embeddedServiceSidebarMinimizedDefaultUI .minimizedImage .uiImage {
              width: 100% !important;
              border-radius: 0 !important;
              background-color: white !important;
            }

            embeddedservice-chat-header lightning-icon, embeddedservice-chat-header svg.slds-icon-text-default {
              fill: black !important;
            }
        </style>
    `
      );

      const initESW = function (gslbBaseURL) {
        globalThis.embedded_svc.settings.displayHelpButton = true; //Or false
        globalThis.embedded_svc.settings.language = ""; //For example, enter 'en' or 'en-US'
        globalThis.embedded_svc.settings.defaultMinimizedText = "Chat with us";

        // DONE(rebrand): this URL will stay the same
        globalThis.embedded_svc.settings.smallCompanyLogoImgURL = `${config.cdnHost}/assets/HE-logo-mark.svg`;

        /**
          globalThis.embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)
          globalThis.embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
          globalThis.embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)
          Settings for Chat
          globalThis.embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
          Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
          Returns a valid button ID.
          };
          globalThis.embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
          globalThis.embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
          globalThis.embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)
        */
        globalThis.embedded_svc.settings.enabledFeatures = ["LiveAgent"];
        globalThis.embedded_svc.settings.entryFeature = "LiveAgent";
        if (config.stage === "production") {
          globalThis.embedded_svc.init(
            // DONE(rebrand): this URL will stay the same
            "https://hotelengine.my.salesforce.com",
            // DONE(rebrand): this URL will stay the same
            "https://hotelengineforms.secure.force.com",
            gslbBaseURL,
            "00D61000000Zy59",
            "MS_Chat_Users",
            {
              baseLiveAgentContentURL: "https://c.la5-c1-ia4.salesforceliveagent.com/content",
              deploymentId: "5728X000000oLkP",
              buttonId: "5738X000000sXtR",
              baseLiveAgentURL: "https://d.la5-c1-ia4.salesforceliveagent.com/chat",
              eswLiveAgentDevName: "MS_Chat_Users",
              isOfflineSupportEnabled: true,
            }
          );
        } else {
          globalThis.embedded_svc.init(
            // DONE(rebrand): this URL will stay the same
            "https://hotelengine--stage.my.salesforce.com",
            // DONE(rebrand): this URL will stay the same
            "https://stage-hotelengine1.cs2.force.com/Chat",
            gslbBaseURL,
            "00DR0000001zeF0",
            "MS_Chat_Users",
            {
              baseLiveAgentContentURL: "https://c.la1-c1cs-ph2.salesforceliveagent.com/content",
              deploymentId: "572R00000008bwW",
              buttonId: "573R0000000CpLS",
              baseLiveAgentURL: "https://d.la1-c1cs-ph2.salesforceliveagent.com/chat",
              eswLiveAgentDevName: "MS_Chat_Users",
              isOfflineSupportEnabled: true,
            }
          );
        }
      };
      if (!globalThis.embedded_svc) {
        const s = document.createElement("script");
        s.setAttribute(
          "src",
          // DONE(rebrand): this URL will stay the same
          `https://hotelengine${
            config.stage !== "production" ? "--stage" : ""
          }.my.salesforce.com/embeddedservice/5.0/esw.min.js`
        );
        s.onload = function () {
          initESW(null);
        };
        document.body.appendChild(s);
      } else {
        initESW("https://service.force.com");
      }
    }
  }, [featureFlagDisabled]);
}

// New MIAW salesforce chat setup

type SFEnvConfg = {
  initUrl: string;
  configId: string;
  scrt2URL: string;
};

function getSFEnvConfig(): SFEnvConfg {
  switch (config.stage) {
    case "production":
      return {
        // DONE(rebrand): this URL will stay the same
        initUrl: "https://hotelengine.my.site.com/ESWMIAWForWeb1692894819893",
        configId: "00D61000000Zy59",
        // DONE(rebrand): this URL will stay the same
        scrt2URL: "https://hotelengine.my.salesforce-scrt.com",
      };
    default:
      return {
        // DONE(rebrand): this URL will stay the same
        initUrl: "https://hotelengine--stage.sandbox.my.site.com/ESWMIAWForWeb1691511743702",
        configId: "00D760000008juO",
        // DONE(rebrand): this URL will stay the same
        scrt2URL: "https://hotelengine--stage.sandbox.my.salesforce-scrt.com",
      };
  }
}

function initMIAWChat(prechatListenerCallback: () => void, sfConfig: SFEnvConfg) {
  try {
    globalThis.embeddedservice_bootstrap.settings.language = "en_US";
    globalThis.addEventListener("onEmbeddedMessagingReady", prechatListenerCallback);

    globalThis.embeddedservice_bootstrap.init(sfConfig.configId, "MIAW_For_Web", sfConfig.initUrl, {
      scrt2URL: sfConfig.scrt2URL,
    });
  } catch (err) {
    console.error("Error loading Embedded Messaging: ", err);
  }
}

// enabled feature flag boolean, should remove post rollout
export function useInitMIAWSalesforceChat(featureFlagEnabled = false) {
  const userId = useAppSelector((state) => state.Auth.user?.id);
  const isMobile = useBreakpoint("md", "max");

  useEffect(() => {
    if (userId && featureFlagEnabled && !config.stage.includes("dev")) {
      const prechatListenerCallback = () => {
        globalThis.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
          He_Id: String(userId),
          Device_Type: isMobile ? "Mobile" : "Desktop",
        });
      };

      const sfConfig = getSFEnvConfig();

      if (!globalThis.embeddedservice_bootstrap) {
        const s = document.createElement("script");
        s.setAttribute("src", `${sfConfig.initUrl}/assets/js/bootstrap.min.js`);
        s.onload = function () {
          initMIAWChat(prechatListenerCallback, sfConfig);
        };
        document.body.appendChild(s);
      } else {
        initMIAWChat(prechatListenerCallback, sfConfig);
      }

      return () => {
        globalThis.removeEventListener("onEmbeddedMessagingReady", prechatListenerCallback);
      };
    }

    return;
  }, [featureFlagEnabled, userId, isMobile]);
}
