import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./flights.shop.constants";
import {
  reset,
  setFlight,
  setFare,
  setIsLoadingFlights,
  setStartSearchTime,
  setEndSearchTime,
  setFirstResultRenderedTime,
  setEntryPoint,
} from "./flights.shop.reducers";

export const flightsShopSlice = createSlice({
  name: "flightsShop",
  initialState,
  reducers: {
    reset,
    setFlight,
    setFare,
    setIsLoadingFlights,
    setStartSearchTime,
    setEndSearchTime,
    setFirstResultRenderedTime,
    setEntryPoint,
  },
});

export const flightsShopActions = flightsShopSlice.actions;

export default flightsShopSlice.reducer;
