import { ampli } from "ampli";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";

import {
  Box,
  Button,
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetClose,
  SheetScrollView,
  Typography,
  useTheme,
} from "@hotelengine/atlas-web";
import type { IReservationBase } from "@hotel-engine/types/reservation";

import MenuCard from "./MenuCard";

const SideSheetMenu = ({ itinerary }: { itinerary: IReservationBase }) => {
  const user = useAppSelector((state) => state.Auth.user);
  const { t } = useTranslation("itinerary");
  const { tokens } = useTheme();

  const openManageTripOptions = useCallback(() => {
    ampli.clickManageTrip({
      userId: user?.id,
      lodgingBookingID: itinerary.contractNumber,
    });
  }, [itinerary.contractNumber, user?.id]);

  return (
    <Sheet>
      <SheetTrigger>
        <Button style={{ width: "100%" }} size="lg" onClick={openManageTripOptions}>
          {t("modifications.title")}
        </Button>
      </SheetTrigger>

      <SheetContent align="right">
        <SheetHeader bleed="full" style={{ padding: `${tokens.spacing[0]} ${tokens.spacing[24]}` }}>
          <Box display="flex" gap={4} alignItems="center">
            <SheetTitle>
              <Typography variant="body/md" color="foregroundPrimary">
                {t("modifications.title")}
              </Typography>
            </SheetTitle>
          </Box>
          <SheetClose />
        </SheetHeader>
        <SheetScrollView>
          <MenuCard itinerary={itinerary} />
        </SheetScrollView>
      </SheetContent>
    </Sheet>
  );
};

export default SideSheetMenu;
