import { FlightCard } from "../FlightCard";
import { LodgingCard } from "../LodgingCard";
import CarCard from "../CarCard";
import useHideCars from "pages/Trips/hooks/useHideCars";
import type { TripData } from "pages/Trips/data/schemas/trips";
import { assertBookingType, isBookingType } from "pages/Trips/utils/bookingAssertions";

export const TripsListItemContent = ({ trip }: { trip: TripData }) => {
  const shouldHideCars = useHideCars();
  const booking = trip.bookings[0];
  const travelers = booking.travelers;

  if (booking.bookingType === "flight") {
    const bookingNumber = booking.details.flightConfirmation;
    const slices = booking?.details?.slices;
    const item = slices?.[0];

    return (
      <FlightCard
        item={item}
        travelers={travelers}
        bookingNumber={bookingNumber}
        flightType={booking?.details?.flightTravelType}
        isFullyCancelled={trip.isFullyCancelled}
      />
    );
  }

  if (isBookingType(booking, "car")) {
    if (shouldHideCars) return null;

    return <CarCard {...booking} isFullyCancelled={trip.isFullyCancelled} />;
  }

  assertBookingType(booking, "lodging");

  return (
    <LodgingCard booking={booking} travelers={travelers} isFullyCancelled={trip.isFullyCancelled} />
  );
};
