import { useLocation } from "../../../../../lib/react-router-dom";
import CancellationPolicy from "./CancellationPolicy";
import Insurance from "./Insurance";
import NeedHelp from "./NeedHelp";
import PolicyInformation from "./PolicyInformation";

const HelpAndPolicies = () => {
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  // Check if the manage_policy query param is set to true in URL
  // If it is, open the insurance manage policy modal by default
  const hasManagePolicyQueryParam = queryParams.get("manage_policy") === "true";

  return (
    <>
      <NeedHelp />
      <PolicyInformation />
      <Insurance collapseOpenByDefault={hasManagePolicyQueryParam} />
      <CancellationPolicy />
    </>
  );
};

export default HelpAndPolicies;
