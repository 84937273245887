import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/browser";

interface IExtra {
  [x: string]: unknown /** Additional info to send to sentry */;
}

interface IExtraError extends IExtra {
  error?: unknown /** The error to send */;
}

function setExtras(scope: Sentry.Scope, extra: IExtra) {
  Object.keys(extra).forEach((item) => {
    scope.setExtra(item, JSON.stringify(extra[item]));
  });
}

export function captureMessage(
  message: string,
  extra: IExtra,
  level: Sentry.SeverityLevel = "error"
) {
  // User context is being set in the User.current method
  Sentry.withScope((scope) => {
    setExtras(scope, extra);
    Sentry.captureMessage(message, level);
  });

  datadogLogs.logger[level](message, extra);
}

export function captureException(exception: unknown, extra?: IExtraError) {
  Sentry.withScope((scope) => {
    if (extra) {
      setExtras(scope, extra);
    }
    Sentry.captureException(exception);
  });

  datadogRum.addError(exception, extra);
}
