import type { ISearchFiltersState } from "store/Search/SearchFilters/types";
import type { IResult } from "@hotel-engine/types/search";
import { SEARCH_FILTERS } from "@hotel-engine/constants";
import { AmenityAvailability } from "@hotel-engine/types/room";

export const OR_FILTER_KEYS = [
  "propertyNames",
  "propertyTypes",
  "stayHotels",
  "stayAlternative",
  "stayTypes",
  "suppliers",
  "starRating",
  "loyaltyPrograms",
];

type RoomKeys = (keyof ISearchFiltersState["room"])[];
type PopularKeys = (keyof ISearchFiltersState["popular"])[];
type PropertyAmenitiesKeys = (keyof ISearchFiltersState["propertyAmenities"])[];

const roomAmenitiesKeys: RoomKeys = [
  "airConditioning",
  "coffeeMaker",
  "internet",
  "kitchen",
  "refrigerator",
];

const propertyAmenitiesKey: PropertyAmenitiesKeys = [
  "ecoFriendly",
  "dining",
  "fitnessCenter",
  "meetingSpace",
  "petFriendly",
  "swimmingPool",
  "selfServiceLaundry",
  "truckParking",
  "dryCleaning",
  "shuttle",
  "spa",
  "valetParking",
];

const popularAmenitiesKeys: PopularKeys = ["breakfast", "parking", "wifi"];

const buildAmenityFunction = (amenityKey: string) => (_value: boolean, result: IResult) =>
  result.availableAmenities?.[amenityKey] ||
  result.rateIncludedAmenities?.[amenityKey] === AmenityAvailability.AMENITY_AVAILABLE;

const buildAmenitiesFunctions = (amenityType: string, amenitiesKeys: string[]) => {
  const filterFunctions = {};
  amenitiesKeys.forEach((filterKey) => {
    Object.assign(filterFunctions, {
      [`${amenityType}.${filterKey}`]: buildAmenityFunction(filterKey),
    });
  });
  return filterFunctions;
};

const buildPopularFunctions = () => ({
  ...buildAmenitiesFunctions("popular", popularAmenitiesKeys),
  "popular.clientProgram": (_value: string, result: IResult) => !!result.clientProgramName,
  "popular.doubleRewards": (_value: string, result: IResult) => !!result.doubleRewards,
  "popular.gsaOrLower": (_value: number, result: IResult) =>
    (result.gsaRate ?? 0) >= (result.customerRate ?? 0),
  "popular.incidentalCoverage": (_value: string, result: IResult) => result.allowIncidentals,
  "popular.refundable": (_value: string, result: IResult) => !!result.isCancellable,
});

const buildPropertyAmenitiesFunctions = () => ({
  ...buildAmenitiesFunctions("propertyAmenities", propertyAmenitiesKey),
  "propertyAmenities.ecoFriendly": (_value, result: IResult) => result.badges.ecoFriendly,
});

export const FILTER_FUNCTIONS = {
  loyaltyPrograms: (loyaltyPrograms: string[], result: IResult) => {
    const { loyaltyRewardName } = result;
    const isAll = loyaltyPrograms.some((lp) => lp.toLocaleLowerCase() === "all");
    if (isAll) return !!loyaltyRewardName;

    return loyaltyPrograms.some(
      (loyaltyProgram) =>
        loyaltyProgram.toLocaleLowerCase() === loyaltyRewardName?.toLocaleLowerCase()
    );
  },
  ...buildPopularFunctions(),
  priceRange: (priceRange: Array<number | null>, result: IResult) => {
    const { customerRate } = result;
    const [min, max] = priceRange;

    if (
      customerRate !== undefined &&
      ((min !== null && Number.isFinite(min) && customerRate < min) ||
        (max !== null && Number.isFinite(max) && customerRate > max))
    ) {
      return false;
    }

    return true;
  },
  propertyNames: (propertyNames: string[], result: IResult) => {
    return propertyNames.some((propertyName) => {
      const wordsToMatch = propertyName.toLowerCase().split(" ");
      return wordsToMatch.every((substring) => result.name.toLowerCase().includes(substring));
    });
  },
  stayHotels: (stayHotelValues: string[], result: IResult) => {
    return stayHotelValues
      .filter((value) => value.toLocaleLowerCase() !== "all")
      .some(
        (stayHotel) => stayHotel.toLocaleLowerCase() === result.propertyType?.toLocaleLowerCase()
      );
  },
  stayAlternative: (stayAlternativeValues: string[], result: IResult) => {
    return stayAlternativeValues
      .filter((value) => value.toLocaleLowerCase() !== "all")
      .some(
        (stayAlternative) =>
          stayAlternative.toLocaleLowerCase() === result.propertyType?.toLocaleLowerCase()
      );
  },
  stayTypes: (stayTypeValues: string[], result: IResult) => {
    const isAll = stayTypeValues.some((st) => st.toLocaleLowerCase() === "all");
    if (isAll) return true;

    const valuesOnlyIncludeHotelsMotelsAndMore =
      SEARCH_FILTERS.stayTypes.hotelsAndMore.values.every((stayType) =>
        stayTypeValues.includes(stayType)
      ) && stayTypeValues.length === SEARCH_FILTERS.stayTypes.hotelsAndMore.values.length;
    if (valuesOnlyIncludeHotelsMotelsAndMore) {
      // If the property type is not in the list of apartments and more,
      // we want to include it in the Hotels, motels + more filter. This
      // will include property types that aren't defined in our constants
      // and falsy property types.
      return (
        !result.propertyType ||
        (result.propertyType &&
          !SEARCH_FILTERS.stayTypes.apartmentsAndMore.values.includes(
            result.propertyType?.toLocaleLowerCase()
          ))
      );
    }

    return stayTypeValues.some(
      (stayType) => stayType.toLocaleLowerCase() === result.propertyType?.toLocaleLowerCase()
    );
  },
  propertyTypes: (propertyTypeValues: string[], result: IResult) => {
    // Use Array#some to end the loop as soon as a match is found
    return propertyTypeValues.some(
      (propertyType) =>
        propertyType.toLocaleLowerCase() === result.propertyType?.toLocaleLowerCase()
    );
  },
  ...buildAmenitiesFunctions("room", roomAmenitiesKeys),
  ...buildPropertyAmenitiesFunctions(),
  starRating: (starRatings: number[], result: IResult) => {
    return starRatings.some(
      (starRating) => result.starRating >= starRating && result.starRating < starRating + 1
    );
  },
  suppliers: (supplierNames: string[], result: IResult) => {
    return supplierNames.some(
      (supplierName) => result.supplier?.toLocaleLowerCase() === supplierName.toLocaleLowerCase()
    );
  },
};
