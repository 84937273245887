import type { IActionOption, ICollapsibleOption, OptionItem } from "../types";
import CollapsibleOptionItem from "./CollapsibleOptionItem";
import LinkOptionItem from "./LinkOptionItem";

const OptionItemFactory = {
  createComponent: (option: OptionItem) => {
    switch (option.type) {
      case "link":
        return <LinkOptionItem option={option as IActionOption} />;
      case "collapsible":
        return <CollapsibleOptionItem option={option as ICollapsibleOption} />;
      default:
        const exhaustiveCheck: never = option;
        throw new Error(`Unhandled option type: ${exhaustiveCheck}`);
    }
  },
};

export default OptionItemFactory;
