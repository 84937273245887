import { Alert, Button, Icon, Typography } from "@hotelengine/atlas-web";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import type { IReservationBase } from "@hotel-engine/types/reservation";

import { ampli } from "ampli";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useAppSelector } from "store/hooks";

import * as Styled from "./styles";

export interface ICancelGuestChangeBoxProps {
  reservation: IReservationBase;
  isLoading: boolean;
}

const CancelGuestChangeBox = ({ reservation, isLoading }: ICancelGuestChangeBoxProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation("lodging");
  const user = useAppSelector((state) => state.Auth.user);

  const handleModifyReservation = useCallback(() => {
    if (user && reservation && navigate) {
      ampli.clickNameChangeOnCancelFlow({
        viewUrl: globalThis.location.href,
        contractNumber: reservation.contractNumber,
        userId: user.id,
        selectedRooms: reservation.rooms.map((room) => room.contractNumber),
        flexType: reservation.flexType,
      });

      navigate({
        pathname: `/modify-reservation/${reservation.contractNumber}`,
      });
    }
  }, [reservation, navigate, user]);

  return (
    <Styled.InfoBox key="cancel-guest-change-box">
      {isLoading ? (
        <Icon
          name="spinner-third"
          color="foregroundPrimary"
          animationStyle="spin"
          style={{ margin: "24px auto", width: "100%" }}
          data-testid="cancel-info-loader"
        />
      ) : (
        <Alert
          key="cancel-guest-change-box-alert"
          leadingIcon="circle-question"
          sentiment="informative"
          size="sm"
          title={t("trip.cancelGuestChange.title")}
          variant="outlined"
          data-testid="cancel-guest-change-box-alert"
        >
          <Typography variant="body/sm">
            {t("trip.cancelGuestChange.description")}{" "}
            <Button variant="link" size="sm" onClick={handleModifyReservation}>
              <Typography
                as="span"
                variant="link/sm"
                data-testid="cancel-guest-change-box-alert-link"
              >
                {t("trip.cancelGuestChange.link")}
              </Typography>
            </Button>
          </Typography>
        </Alert>
      )}
    </Styled.InfoBox>
  );
};
export default CancelGuestChangeBox;
