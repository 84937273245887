import { Typography, Icon } from "@hotelengine/atlas-web";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import * as Styled from "./styles";
import ModificationMenu from "../ModificationMenu";
import { useAppSelector } from "store/hooks";
import { useTranslation } from "react-i18next";

type ModificationsMenuTriggerProps = {
  itinerary: IReservationBase;
};

const ModificationsMenuTrigger = ({ itinerary }: ModificationsMenuTriggerProps) => {
  const user = useAppSelector((state) => state.Auth.user);
  const { t } = useTranslation("itinerary");

  const isViewOnlyTraveler = user?.role === "view_only_traveler";
  const showFlexMessage = itinerary.flexEnabled && !isViewOnlyTraveler;
  return (
    <Styled.ModificationsMenuTrigger data-testid="modification-side-sheet-trigger">
      <ModificationMenu itinerary={itinerary} />
      {!!showFlexMessage && (
        <Styled.FlexMessage>
          <Typography variant="body/sm">{t("flex.modifications")}</Typography>
          <Icon
            id="shield-check"
            name="he-flex"
            color="foregroundPrimary"
            data-testid="flex-pro-logo"
            size="sm"
          />{" "}
          <Typography variant="body/sm-strong">{t("flex.flex")}</Typography>
        </Styled.FlexMessage>
      )}
    </Styled.ModificationsMenuTrigger>
  );
};

export default ModificationsMenuTrigger;
