import { createContext, useContext, useState } from "react";

import type { Dispatch, SetStateAction, ReactNode } from "react";

type DatadogInitializedContext = [boolean, Dispatch<SetStateAction<boolean>>];

const DatadogInitializedContext = createContext<DatadogInitializedContext>([
  false,
  () => undefined,
]);

export const DatadogInitializedProvider = ({ children }: { children: ReactNode }) => {
  const value = useState(false);

  return (
    <DatadogInitializedContext.Provider value={value}>
      {children}
    </DatadogInitializedContext.Provider>
  );
};

export const useDatadogInitialized = () => useContext(DatadogInitializedContext);
