import type { UseMutationOptions } from "react-query";
import { useMutation } from "react-query";
import type { AxiosError } from "axios";

import type {
  ICheckoutCart,
  ICheckoutCartCreateParams,
} from "../../../pages/Checkout/types/Cart.types";

import { endpoints } from "../constants";
import { useApi } from "../useApi";

export const useCreateCheckoutCart = (
  options?: UseMutationOptions<ICheckoutCart, AxiosError, ICheckoutCartCreateParams>
) => {
  const post = useApi("post");

  return useMutation(async (payload) => {
    const data = await post<ICheckoutCart>(endpoints.carts, payload);

    return {
      ...data,
    };
  }, options);
};
