import { Avatar, Box, Typography } from "@hotelengine/atlas-web";
import { useTranslation } from "react-i18next";

export const FooterContent = ({ childCount }: { childCount?: number | null }) => {
  const { t } = useTranslation("itinerary");

  return (
    <Box display="flex" flexDirection="row" alignItems="center" gap={12}>
      <Avatar name="Child" size={32} />
      <Typography variant="body/md" color="foregroundPrimary">
        {t("preview.child", { count: childCount || 0 })}
      </Typography>
    </Box>
  );
};
