import { ampli } from "ampli";
import { useEffect, useRef, type ChangeEvent } from "react";
import { useFormikContext } from "formik";

import { InputGroup } from "@hotelengine/atlas-web";
import { useBreakpoint } from "@hotel-engine/hooks";

import useTripsControl from "pages/Trips/hooks/useTripsControl";
import useTripsResult from "pages/Trips/hooks/useTripsResult";

import useSearchForTrips from "./hooks/useSearchForTrips";
import FacetSelect from "./components/FacetSelect";
import SearchInput from "./components/SearchInput";
import SearchFormControl from "./components/SearchFormControl";
import * as Styled from "./styles";
import MobileSearchForm from "./components/MobileSearchForm";
import type { SearchFacet } from "pages/Trips/data/querySchema";

export interface ITripsSearch {
  search: string;
  search_facet: SearchFacet;
}

export const tripsSearchInitialValues: ITripsSearch = {
  search: "",
  search_facet: "traveler",
};

const SearchForm = ({ isMobile, isOnColor }: { isMobile: boolean; isOnColor: boolean }) => {
  const useMobileSearchForm = useBreakpoint("md", "max");
  const initialLoad = useRef(true);
  const result = useTripsResult();
  const [params, { setParams, unsetParams }] = useTripsControl();
  const { values, setFieldValue, handleSubmit, errors } = useFormikContext<ITripsSearch>();

  const { getUpdatedQuery } = useSearchForTrips();

  useEffect(() => {
    // On the initial load only
    if (initialLoad.current) {
      // If there is a search facet in SessionStorage or in the URL, set it in the form
      const searchFacet = sessionStorage.getItem("search_facet");
      if (searchFacet || params.search_facet) {
        setFieldValue("search_facet", searchFacet || params.search_facet);
      }

      // If there is a search query in the URL, set it in the form
      if (!values.search && params.search) {
        setFieldValue("search", params.search);
      }
      initialLoad.current = false;
    }
  }, [params.search, params.search_facet, setFieldValue, values.search]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const sanitizedQuery = getUpdatedQuery(value);

    setFieldValue("search", sanitizedQuery);
  };

  const handleSearchFacetChange = (value: string) => {
    if (params.search) {
      setParams({ filter: { search_facet: value } });
    }

    setFieldValue("search_facet", value);
    sessionStorage.setItem("search_facet", value);

    if (values.search_facet) {
      ampli.bookingTableChangedSearchFacet({
        filterType: value as SearchFacet,
      });
    }
  };

  return useMobileSearchForm ? (
    <MobileSearchForm
      result={result}
      handleSearchFacetChange={handleSearchFacetChange}
      isLoading={result.isLoading}
      handleOnChange={handleOnChange}
      params={params}
      unsetParams={unsetParams}
    />
  ) : (
    <SearchFormControl result={result} errors={errors}>
      <InputGroup id="search-filter-box">
        <FacetSelect
          isOnColor={isOnColor}
          values={values}
          isLoading={result.isLoading}
          handleSearchFacetChange={handleSearchFacetChange}
        />
        <SearchInput
          values={values}
          isOnColor={isOnColor}
          isLoading={result.isLoading}
          handleOnChange={handleOnChange}
          handleSubmit={handleSubmit}
          isMobile={isMobile}
        />
        <Styled.SearchButton
          icon="magnifying-glass"
          aria-label="Submit search"
          type="submit"
          color="primary"
          isLoading={result.isLoading}
          isDisabled={values.search.length < 2 || result.isLoading}
          onClick={() => handleSubmit()}
        />
      </InputGroup>
    </SearchFormControl>
  );
};

export default SearchForm;
