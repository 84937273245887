import { useEffect, useRef } from "react";
import { datadogRum } from "@datadog/browser-rum";

import config from "config";
import { useDatadogInitialized } from "../datadog/DatadogInitializedProvider";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments/FeatureFlag";
import { useAppSelector } from "store/hooks";
import { useLocation } from "@hotel-engine/lib/react-router-dom";

const notCypressRun = !globalThis.Cypress;

function trackPageView() {
  if (globalThis._sift) {
    globalThis._sift.push(["_trackPageview"]);
  }
}

function setUserId(userId: number) {
  if (globalThis._sift) {
    globalThis._sift.push(["_setUserId", String(userId)]);
  }
}

export function useInitSift() {
  const { pathname } = useLocation();
  const [isDatadogInitialized] = useDatadogInitialized();
  const isSiftFlagEnabled = useIsFeatureFlagOn("web-platform-sift-integration");
  const userId = useAppSelector((state) => state.Auth.user?.id);
  const impersonatorId = useAppSelector((state) => state.Auth.user?.impersonatorId);
  const siftSessionId = useRef<string | undefined>(undefined);
  const siftUserIdSet = useRef(false);
  const siftScriptSet = useRef(false);

  useEffect(() => {
    if (!isDatadogInitialized) {
      return;
    }

    if (!siftSessionId.current) {
      siftSessionId.current = datadogRum.getInternalContext()?.session_id;
    }

    if (isSiftFlagEnabled && notCypressRun && !impersonatorId && !siftScriptSet.current) {
      try {
        const _sift = (globalThis._sift = globalThis._sift || []);
        _sift.push(["_setAccount", config.siftBeaconKey]);
        _sift.push(["_setSessionId", siftSessionId.current]);
        _sift.push(["_trackPageview"]);

        const existingScript = document.querySelector("script[nonce]");
        const nonce = existingScript?.getAttribute("nonce") || "";

        const s = document.createElement("script");
        s.src = "https://cdn.sift.com/s.js";
        s.type = "text/javascript";
        s.setAttribute("nonce", nonce);
        document.body.appendChild(s);
        siftScriptSet.current = true;
        console.log("SIFT_INITIALIZED");
      } catch (e) {
        console.error("Error initializing Sift", e);
      }
    }
  }, [impersonatorId, isSiftFlagEnabled, isDatadogInitialized]);

  useEffect(() => {
    if (isSiftFlagEnabled && notCypressRun && userId && !siftUserIdSet.current) {
      setUserId(userId);
      siftUserIdSet.current = true;
    }
  }, [userId, isSiftFlagEnabled]);

  useEffect(() => {
    if (isSiftFlagEnabled && notCypressRun) {
      trackPageView();
    }
  }, [pathname, isSiftFlagEnabled]);
}
