import type { UseQueryOptions } from "react-query";

import type { IDepartmentRecord } from "@hotel-engine/types/department";
import type { IErrorResponse } from "@hotel-engine/types/errors";
import type { PaginatedData } from "@hotel-engine/types/generic";

import { useApi } from "../useApi";
import { useExtendedQuery } from "../useExtendedQuery";
import { cacheKeys, endpoints } from "../constants";

type IDepartmentsResponse = PaginatedData<IDepartmentRecord>;

interface IParams {
  /** Response limit */
  limit?: number;
  /** Search term */
  term?: string;
  /** If true the default department is always returned first in the list */
  includeDefaultAtFirst?: boolean;
}

export interface IDepartmentsQueryConfig {
  options?: UseQueryOptions<IDepartmentsResponse, IErrorResponse, IDepartmentRecord[]>;
  params?: IParams;
}

const useDepartmentsQuery = ({
  options = {},
  params = { limit: 5000 },
}: IDepartmentsQueryConfig = {}) => {
  const get = useApi("get");
  const { limit, term, includeDefaultAtFirst } = params;

  const queryKey = [cacheKeys.useDepartmentsQuery, term, limit];

  const queryFn = () =>
    get<IDepartmentsResponse>(endpoints.departments, {
      ...params,
      term,
      limit,
      includeDefaultAtFirst,
    });

  return useExtendedQuery<IDepartmentsResponse, IErrorResponse, IDepartmentRecord[]>(
    queryKey,
    queryFn,
    options
  );
};

export { useDepartmentsQuery };
