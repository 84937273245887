import type { UseQueryOptions } from "react-query";

import type { IRoomsResponse } from "@hotel-engine/types/room";
import { checkAndHandleDuplicateRooms } from "./helpers";

import { endpoints } from "../constants";
import { useExtendedQuery } from "../useExtendedQuery";
import { useLodgingSearchApi } from "../lodgingSearch/useLodgingSearchApi";
import type { AxiosError } from "axios";

export interface IRoomOffersParams {
  continuationToken: string;
}

export const useRoomOffersQuery = (
  params: IRoomOffersParams,
  options?: UseQueryOptions<IRoomsResponse, AxiosError>
) => {
  const { executeRequest } = useLodgingSearchApi<IRoomsResponse>({
    options,
    requestMethod: "post",
  });

  return useExtendedQuery(
    [endpoints.roomOffers, params],
    () => executeRequest(endpoints.roomOffers, params),
    {
      onSuccess: (data) => {
        const { rooms } = data;
        checkAndHandleDuplicateRooms(rooms);
      },
      ...options,
    }
  );
};
