import styled from "styled-components";

const FlexAdjustmentFieldLine = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  column-gap: ${({ theme }) => theme.spacing[16]};
  flex-direction: column;
`;

const Divider = styled.div<{ dashed?: boolean; dotted?: boolean }>`
  height: 1px;
  border-top: ${({ theme, dashed, dotted }) => {
    if (dashed) {
      return `${theme.borderWidth.normal} dashed ${theme.colors.borderSubtle}`;
    } else if (dotted) {
      return `${theme.borderWidth.normal} dotted ${theme.colors.borderSubtle}`;
    } else {
      return `${theme.borderWidth.normal} solid ${theme.colors.borderMinimal}`;
    }
  }};
  margin-block: ${({ theme }) => theme.spacing[8]};
`;

export { FlexAdjustmentFieldLine, Divider };
