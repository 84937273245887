import { useState } from "react";

import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import { ampli } from "ampli";
import config from "config";
import { Formik } from "formik";
import moment from "moment";
import { DayPickerSingleDateController, SingleDatePicker } from "react-dates";
import { VERTICAL_SCROLLABLE } from "react-dates/constants";
import * as yup from "yup";

import FullscreenModal from "@hotel-engine/app/FullscreenModal";
import { routes } from "@hotel-engine/constants";
import { dateRangePickerYearFormats } from "@hotel-engine/constants/dateRangePickerSelections";
import { useBreakpoint, useSearchParams } from "@hotel-engine/hooks";
import useIsContractExtendable from "@hotel-engine/hooks/useIsContractExtendable";
import { useReservationQuery } from "@hotel-engine/react-query/reservation/useReservationQuery";
import { Box, Button, Icon, RadioGroup, RadioGroupItem, Typography } from "@hotelengine/atlas-web";

import { useAppSelector } from "store/hooks";

import * as Styled from "../styles";

export interface ICalendarModalProps {
  /** handles the date submissions */
  handleDateChoice: (string) => void;
  /** sets initial checkout date value */
  preFilledCheckoutDate?: string;
}

const options = {
  SINGLE_DATE: "single date",
  MULTI_DATE: "multi date",
};
interface IFormikValues {
  /** the current checkout date */
  currentCheckout: string;
  /** the new checkout date we want to extend our stay to */
  newCheckout: string;
  /** the mode option selected by the user- extending all rooms to a single date or to different dates */
  option: string;
}

const yupValidate = (currentCheckout: string) => {
  return yup.object().shape({
    option: yup.string(),
    currentCheckout: yup.string().required("This is a required field"),
    newCheckout: yup.string().when("option", {
      is: options.SINGLE_DATE,
      then: (stringSchema) =>
        stringSchema
          .required("This is a required field")
          .test("newCheckout", "Must be later than current checkout date", (value) => {
            return moment(value).isAfter(moment(currentCheckout));
          }),
    }),
  });
};

const CalendarModal = ({ handleDateChoice, preFilledCheckoutDate }: ICalendarModalProps) => {
  const preferredDateFormat =
    useAppSelector((state) => state.Auth.user?.business.preferredDateFormat) || "mdy";
  const { contract_number, extendedContractId, booking_number } = useSearchParams<{
    contract_number: string;
    extendedContractId: string;
    booking_number?: string;
  }>();
  const contractNumber = contract_number || extendedContractId || booking_number;

  const navigate = useNavigate();
  const { data: reservation } = useReservationQuery({
    id: contractNumber,
  });
  const accountType = useAppSelector((state) => state.Auth?.user?.accountType);
  const currentCheckout = moment(reservation?.checkOut).format("YYYY-MM-DD");
  const [focus, setFocus] = useState(false);
  const [pickerDate, setPickerDate] = useState(
    preFilledCheckoutDate ? moment(preFilledCheckoutDate) : null
  );
  const [isFullScreenDatePickerVisible, setIsFullScreenDatePickerVisible] = useState(false);
  const isMobile = useBreakpoint("lg", "max");
  const dayAfterCurrentCheckout = moment(currentCheckout).add(1, "days").format("YYYY-MM-DD");

  const { showMultiDateTripExtensions, extendableRoomCount } = useIsContractExtendable();
  const isMultiRoomExtension = extendableRoomCount > 1;

  /** Initial formik values */
  const initialValues: IFormikValues = {
    currentCheckout: currentCheckout,
    newCheckout: preFilledCheckoutDate || "",
    option: showMultiDateTripExtensions ? "" : options.SINGLE_DATE,
  };

  /** Calculates number of days being extended */
  const calculateExtensionDays = (newCheckout) => {
    const extensionInDays = moment(newCheckout).diff(moment(currentCheckout), "days");

    if (extensionInDays > 0) {
      return extensionInDays;
    }

    return null;
  };

  const getCtaCopy = (option) =>
    option === options.MULTI_DATE ? "Select rooms" : "Extend my trip";

  const getSubtextCopy = () => {
    if (showMultiDateTripExtensions) {
      return "What rooms will you be extending?";
    } else if (isMultiRoomExtension) {
      return "Extend all rooms instantly.";
    } else {
      return "Extend your stay instantly.";
    }
  };

  // Function for submitting date choice and kicking off hotel / room search
  const onSubmit = (values: IFormikValues) => {
    if (values.option === options.SINGLE_DATE) {
      handleDateChoice(values.newCheckout);

      // Send Amplitude event for tracking conversion on Trip Extension initiation / flow
      ampli.clickInitiateTripExtensionFlow({
        parentContractNumber: contractNumber,
        accountType: accountType,
      });
    }

    if (values.option === options.MULTI_DATE) {
      navigate(`${routes.extendTrips}/${reservation?.id}`);

      // Send Amplitude event for tracking conversion on multi-date Trip Extension initiation / flow
      ampli.clickExtendCertainRooms({
        bookingId: contractNumber,
        propertyId: reservation?.propertyId,
        userId: reservation?.userId,
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={() => yupValidate(currentCheckout)}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ errors, handleSubmit, setFieldValue, values }) => (
        <>
          <Box marginBottom={24} style={{ textAlign: "center" }}>
            <Styled.ModalImage
              src={`${config.cdnHost}/assets/trip-extensions/book-more-nights.svg`}
              alt={`book-more-nights`}
            />
            <Typography variant="heading/lg" as="h2" color="foregroundPrimary">
              Book more nights
            </Typography>
            <Typography variant="body/md" color="foregroundPrimary">
              {getSubtextCopy()}
            </Typography>
          </Box>
          <Box marginBottom={16} display="flex" flexDirection="column">
            {!!showMultiDateTripExtensions && (
              <Box marginBottom={16}>
                <RadioGroup
                  onValueChange={(e) => setFieldValue("option", e)}
                  defaultValue={values.option}
                >
                  <RadioGroupItem
                    value={options.SINGLE_DATE}
                    data-testid="extend-all-rooms-radio"
                  >{`Extend trip for all ${extendableRoomCount} rooms`}</RadioGroupItem>
                  <RadioGroupItem value={options.MULTI_DATE}>
                    Select certain rooms to extend
                  </RadioGroupItem>
                </RadioGroup>
              </Box>
            )}
            {(!showMultiDateTripExtensions || values.option === options.SINGLE_DATE) && (
              <>
                <Styled.SingleDatePickerWrapper>
                  <Styled.SingleDatePickerContainer disabled>
                    <Typography variant="body/sm" marginBottom={4} as="p">
                      Current checkout
                    </Typography>
                    <SingleDatePicker
                      id="currentCheckout"
                      ariaLabel="Current Checkout Input"
                      onDateChange={() => null}
                      onFocusChange={() => null}
                      focused={false}
                      date={moment(currentCheckout)}
                      displayFormat={dateRangePickerYearFormats[preferredDateFormat]}
                      customInputIcon={<Icon name="calendar" size="sm" />}
                      inputIconPosition="after"
                      disabled
                      openDirection="up"
                    />
                    {!!errors.currentCheckout && (
                      <Typography variant="body/sm" color="accentRed">
                        {errors.currentCheckout}
                      </Typography>
                    )}
                  </Styled.SingleDatePickerContainer>
                  <Styled.SingleDatePickerContainer>
                    <Typography variant="body/sm" marginBottom={4} as="p">
                      New checkout
                    </Typography>
                    <SingleDatePicker
                      id="newCheckout"
                      ariaLabel="New Checkout Input"
                      onDateChange={(date) => {
                        setPickerDate(date);
                        setFieldValue("newCheckout", moment(date).format("YYYY-MM-DD"));
                      }}
                      displayFormat={dateRangePickerYearFormats[preferredDateFormat]}
                      onFocusChange={({ focused }) => {
                        if (isMobile) setIsFullScreenDatePickerVisible(true);
                        setFocus(focused);
                      }}
                      focused={isMobile ? false : focus}
                      date={pickerDate}
                      customInputIcon={<Icon name="calendar" size="sm" />}
                      inputIconPosition="after"
                      numberOfMonths={1}
                      placeholder="Select date"
                      isOutsideRange={(day) => day.isBefore(dayAfterCurrentCheckout)}
                      initialVisibleMonth={() => moment(dayAfterCurrentCheckout)}
                      readOnly
                      openDirection="up"
                    />
                    {!!errors.newCheckout && (
                      <Typography variant="body/sm" color="accentRed">
                        {errors.newCheckout}
                      </Typography>
                    )}
                  </Styled.SingleDatePickerContainer>
                </Styled.SingleDatePickerWrapper>

                {calculateExtensionDays(values.newCheckout) && (
                  <Typography
                    variant="body/sm"
                    color="foregroundPrimary"
                    marginBottom={8}
                    style={{ textAlign: "center" }}
                  >{`You are requesting a ${calculateExtensionDays(
                    values.newCheckout
                  )}-day extension.`}</Typography>
                )}
              </>
            )}
          </Box>
          <Button
            onClick={() => handleSubmit()}
            data-testid="extend-my-trip-button"
            style={{ width: "100%" }}
            isDisabled={
              (isMultiRoomExtension && values.option === "") ||
              (!isMultiRoomExtension && values.newCheckout === "") ||
              (isMultiRoomExtension &&
                values.option === options.SINGLE_DATE &&
                values.newCheckout === "")
            }
          >
            {getCtaCopy(values.option)}
          </Button>

          {/* Mobile Full Screen Calendar Picker Modal */}
          <FullscreenModal
            visible={isFullScreenDatePickerVisible}
            title="Book more nights"
            hasDoneButton
            onClose={() => {
              setIsFullScreenDatePickerVisible(false);
              setFocus(false);
            }}
            // renders above antD modals which are set to 1000
            modifiedZIndex={1001}
          >
            <Styled.CalendarPickerDatesContainer>
              <div>
                <Typography variant="body/sm">Check-in</Typography>
                <Typography variant="heading/md" color="foregroundPrimary" as="h3">
                  {moment(currentCheckout).format("M/DD/YY")}
                </Typography>
              </div>
              <div>
                <Typography variant="body/md">-</Typography>
              </div>
              <div>
                <Typography variant="body/sm">Checkout</Typography>
                <Typography variant="heading/md" color="foregroundPrimary" as="h3">
                  {pickerDate ? moment(pickerDate).format("M/DD/YY") : "Select"}
                </Typography>
              </div>
            </Styled.CalendarPickerDatesContainer>
            <Styled.DayPickerSingleDateControllerWrapper isFocused>
              <DayPickerSingleDateController
                onDateChange={(date) => {
                  setPickerDate(date);
                  setFieldValue("newCheckout", moment(date).format("YYYY-MM-DD"));
                }}
                onFocusChange={({ focused }) => {
                  setFocus(focused);
                }}
                focused={focus}
                date={pickerDate}
                isOutsideRange={(day) => day.isBefore(dayAfterCurrentCheckout)}
                initialVisibleMonth={() => moment(dayAfterCurrentCheckout)}
                orientation={VERTICAL_SCROLLABLE}
                numberOfMonths={12}
                navPrev={<div />}
              />
            </Styled.DayPickerSingleDateControllerWrapper>
          </FullscreenModal>
        </>
      )}
    </Formik>
  );
};

export default CalendarModal;
