import { createSlice } from "@reduxjs/toolkit";
import { initialFlightsFilterState } from "store/Flights/FlightsFilters/flights.filters.constants";
import {
  clearSelectedFilters,
  initFlightsFilters,
  resetFilters,
  setDurationFilter,
  setFlightsFilterStopOption,
  setInPolicyFilter,
  setLandingTimesFilter,
  setSearchFilters,
  setSelectedShelfBlock,
  setSortByOption,
  setTakeOffTimesFilter,
  setUserPriceFilter,
  toggleAirlineFilter,
  toggleCabinClassFilter,
} from "store/Flights/FlightsFilters/flights.filters.reducers";

export { initialFlightsFilterState } from "./flights.filters.constants";

export const flightsFiltersSlice = createSlice({
  name: "flightsSearchFilters",
  initialState: initialFlightsFilterState,
  reducers: {
    clearSelectedFilters,
    initFlightsFilters,
    resetFilters,
    setDurationFilter,
    setFlightsFilterStopOption,
    setInPolicyFilter,
    setLandingTimesFilter,
    setSearchFilters,
    setSelectedShelfBlock,
    setSortByOption,
    setTakeOffTimesFilter,
    setUserPriceFilter,
    toggleAirlineFilter,
    toggleCabinClassFilter,
  },
});

export const flightsSearchFiltersActions = flightsFiltersSlice.actions;

export default flightsFiltersSlice.reducer;
