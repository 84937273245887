import * as Styled from "./styles";

/**
 * @deprecated This component utilizes AntD and should not be used. Please use the `FormControl` component from `@hotelengine/atlas-web` instead.
 * @see {@link https://atlas.he-dev.com/latest/components/form-control-bFx0WsJj Atlas Web FormControl Documentation}
 * @see {@link https://chromatic-atlas-web.he-dev.com/?path=/docs/components-form-control--docs Atlas Web FormControl Storybook}
 */
export const FormItem = Styled.AntFormItem;

export type { FormItemProps } from "antd/lib/form";
