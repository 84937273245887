import { useState } from "react";

import { useNavigate } from "@hotel-engine/lib/react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";

import { routes } from "@hotel-engine/constants";
import { useBreakpoint, useSearchParams } from "@hotel-engine/hooks";
import { useAccountMode } from "@hotel-engine/react-query/users/useAccountMode";
import { toggleSalesForceChat } from "@hotel-engine/scripts/hooks";
import { User } from "@hotel-engine/services";
import { EnterVerificationCode } from "pages/PersonalTravel/PersonalTravel2FV/EnterVerificationCode";
import { VerifyTwoFactorStep } from "pages/PersonalTravel/PersonalTravel2FV/VerifyTwoFactor";
import { AuthActions } from "store/Auth/AuthRedux";
import { useAppDispatch } from "store/hooks";

import type { PersonalAccountCreationStatus } from "../../../PersonalAccountLanding";
import { ConfirmEmailMessageStep } from "./Steps";
import ClaimYourPersonalAccount from "./Steps/ClaimYourPersonalAccount";
import * as Styled from "./styles";
import { Unsafe } from "@hotel-engine/data";
import SignUp from "./SignUp";

import { Dialog, DialogContent } from "@hotelengine/atlas-web";

export interface IPersonalAccountCreationProps {
  visible: boolean;
  onClose: () => void;
  startAt?: IPersonalAccountCreationScreen;
}

export type IPersonalAccountCreationScreen =
  | "value-proposition"
  | "confirm-email-message"
  | "request-2fv-code"
  | "submit-2fv-code"
  | "sign-up";

export const PersonalAccountCreationModal = ({
  visible,
  onClose,
  startAt,
}: IPersonalAccountCreationProps) => {
  const params = useSearchParams<{
    confirmPersonal2fv?: boolean;
    launchPersonalFlow?: PersonalAccountCreationStatus;
  }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [, toggleAccountMode] = useAccountMode({
    onFailure: () => navigate(routes.dashboard.concat(`?toggleAccount=personal`)),
  });
  const isMobile = useBreakpoint("md", "max");
  const showSignUpFlow = params.launchPersonalFlow === "default" || !params.launchPersonalFlow;
  const [screen, setScreen] = useState<IPersonalAccountCreationScreen>(
    startAt ?? showSignUpFlow ? "sign-up" : "value-proposition"
  );
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const onCloseModal = async (escapeToBusiness?: boolean) => {
    if (escapeToBusiness) {
      toggleAccountMode().then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
    }

    if (
      params?.confirmPersonal2fv &&
      (screen === "request-2fv-code" || screen === "submit-2fv-code")
    ) {
      await User.getSelf();
      dispatch(AuthActions.setUser(User.current()));
      // Stops the 2FV modal from re-rendering //
      navigate("/", { replace: true });
    }

    onClose();
    setScreen(startAt || "value-proposition");
  };

  if (isMobile && visible) {
    toggleSalesForceChat(false);
  } else {
    toggleSalesForceChat(true);
  }

  const handlePushToSignUp = () => setScreen("sign-up");

  const renderSteps = () => {
    switch (screen) {
      case "value-proposition":
        return <ClaimYourPersonalAccount onContinue={handlePushToSignUp} />;
      case "sign-up":
        return <SignUp onNavigate={setScreen} onClose={onCloseModal} onSetEmail={setEmail} />;
      case "confirm-email-message":
        return <ConfirmEmailMessageStep email={email} />;
      case "request-2fv-code":
        return (
          <VerifyTwoFactorStep
            onSubmit={(submittedPhone) => {
              setPhone(submittedPhone);
              setScreen("submit-2fv-code");
            }}
            isInModal
            onClose={onCloseModal}
          />
        );
      case "submit-2fv-code":
        return (
          <EnterVerificationCode
            phone={phone}
            isInModal
            onClose={onCloseModal}
            onGoBack={() => setScreen("request-2fv-code")}
          />
        );
      default:
        return null;
    }
  };

  if (isMobile) {
    return (
      <>
        <Styled.GlobalBottomSheetStyles />
        <BottomSheet
          onDismiss={onClose}
          open={visible}
          aria-label="personal-account-creation-bottom-sheet"
          style={{ zIndex: 999, position: "absolute" }}
        >
          <Styled.BottomSheetContent>{renderSteps()}</Styled.BottomSheetContent>
        </BottomSheet>
      </>
    );
  }

  return (
    <Dialog
      onOpenChange={(open) => {
        if (!open) onClose();
      }}
      isOpen={visible}
      aria-label="personal-account-creation-modal"
    >
      <DialogContent>{renderSteps()}</DialogContent>
    </Dialog>
  );
};
