import type { IUser } from "@hotel-engine/types/user";
import type { IReservationBase } from "@hotel-engine/types/reservation";

/** Checks based on name and user role if they can modify the booking
 * @param itinerary - The itinerary object
 * @param user - The user object
 * @returns true if the user can modify the booking, false otherwise
 */
export const userRoleCanModifyBooking = (itinerary: IReservationBase, user: IUser | null) => {
  const isAdmin = user?.role === "admin";
  const isCoordinator = user?.role === "coordinator";
  const isBooker = itinerary.bookedBy === `${user?.firstName} ${user?.lastName}`;
  const hasCoordinatorDashboardAccess = !!user?.business?.showCoordinatorDashboard;
  const hasAdminAccess =
    !!user && ((isCoordinator && hasCoordinatorDashboardAccess) || isAdmin || isBooker);

  return hasAdminAccess;
};
