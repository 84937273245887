import { format, parseISO } from "date-fns";
import type { TripData } from "pages/Trips/data/schemas/trips";

const now = new Date();

export function groupTripsByDay(trips: TripData[]) {
  const groupedTrips: { [day: string]: TripData[] } = {};

  trips.forEach((trip) => {
    trip.bookings.forEach((booking) => {
      const date =
        (booking.bookingType === "lodging" && booking.details.checkIn) ||
        (booking.bookingType === "flight" &&
          booking.details.slices?.[0]?.segments[0].origin.flightTime) ||
        (booking.bookingType === "car" && booking.details?.startTime);

      if (date) {
        const tripDate = parseISO(date);
        const sameYear = tripDate.getFullYear() !== now.getFullYear();

        const dayFormat = format(tripDate, sameYear ? "MMMM d, yyyy" : "MMM d");
        if (!groupedTrips[dayFormat]) {
          groupedTrips[dayFormat] = [];
        }
        groupedTrips[dayFormat].push({
          ...trip,
        });
      }
    });
  });

  return Object.entries(groupedTrips)
    .sort(([dateA], [dateB]) => parseISO(dateA).getTime() - parseISO(dateB).getTime())
    .map(([title, data]) => ({
      title,
      data,
    }));
}
