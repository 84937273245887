import type { Moment } from "moment";
import { parse, stringify } from "qs";

import { AmenityAvailability, type IRoom, type IRoomCreateParams } from "@hotel-engine/types/room";
import { isRateRefundable } from "@hotel-engine/utilities/helpers/isRateRefundable";

import type { IPropertySearchParams } from "../../hooks/usePropertySearchParams";
import type { IFilterState } from "./filtersReducer";

export interface IModifySearchForm {
  checkIn: Moment;
  checkOut: Moment;
  defaultCheckIn?: Moment;
  defaultCheckOut?: Moment;
  roomCount: number;
  guestCount: number;
  childCount?: number;
  childGuestAges?: number[];
  propertyAddress: string;
  propertyId: number;
}

export const ROOM_RATE_TIMEOUT = 7 * 60000; // 7 minutes;

export function getBedTypes(rooms: IRoom[] | undefined) {
  const bedTypes: string[] = [];
  if (!rooms?.length) return bedTypes;

  rooms.forEach((room) => {
    room.beddings?.forEach((bed) => {
      if (bed.type && !bedTypes.includes(bed.type)) {
        bedTypes.push(bed.type);
      }
    });
  });

  return bedTypes;
}

export function getClientPrograms(rooms: IRoom[] | undefined) {
  const clientPrograms: string[] = [];
  if (!rooms?.length) return clientPrograms;

  rooms.forEach((room) => {
    room.rates.forEach((rate) => {
      if (rate.clientProgramName && !clientPrograms.includes(rate.clientProgramName)) {
        clientPrograms.push(rate.clientProgramName);
      }
    });
  });

  return clientPrograms;
}

const _pipe2FuncWithFilterState =
  (
    func1: (rooms: IRoom[], filterState?: IFilterState) => IRoom[],
    func2: (rooms: IRoom[], filterState?: IFilterState) => IRoom[]
  ) =>
  (arg, filterState) =>
    func2(func1(arg, filterState), filterState);

const pipe = (
  ...functions: ((rooms: IRoom[] | undefined, filterState?: IFilterState) => IRoom[])[]
) => functions.reduce(_pipe2FuncWithFilterState);

const filterRoomsWithoutRates = (rooms: IRoom[] | undefined) =>
  !rooms?.length ? [] : rooms.filter((room) => room.rates.length);

const filterRoomsByLoyalty = (rooms: IRoom[] | undefined = [], filterState?: IFilterState) =>
  !filterState?.loyaltyEligible
    ? rooms
    : rooms
        .map((room) => ({
          ...room,
          rates: room.rates.filter((rate) => rate.loyaltyEligible),
        }))
        .filter((room) => room.rates.length > 0);

const filterRoomsByRefundable = (rooms: IRoom[] | undefined = [], filterState?: IFilterState) =>
  !filterState?.refundable
    ? rooms
    : rooms
        .map((room) => ({
          ...room,
          rates: room.rates.filter((rate) => isRateRefundable(rate.cancelBy)),
        }))
        .filter((room) => room.rates.length > 0);

const filterRoomsByGsa = (rooms: IRoom[] | undefined = [], filterState?: IFilterState) =>
  !filterState?.gsaOrLower
    ? rooms
    : rooms
        .map((room) => ({
          ...room,
          rates: room.rates.filter((rate) =>
            rate.nightlyRates?.some(
              (nightlyRate) => !nightlyRate.gsaRate || nightlyRate.rate < nightlyRate.gsaRate
            )
          ),
        }))
        .filter((room) => room.rates.length > 0);

const filterRoomsByBreakfast = (rooms: IRoom[] | undefined = [], filterState?: IFilterState) =>
  !filterState?.freeBreakfast
    ? rooms
    : rooms
        .map((room) => ({
          ...room,
          rates: room.rates.filter(
            (rate) => rate.includedAmenities.breakfast === AmenityAvailability.AMENITY_AVAILABLE
          ),
        }))
        .filter((room) => room.rates.length > 0);

const filterRoomsByParking = (rooms: IRoom[] | undefined = [], filterState?: IFilterState) =>
  !filterState?.freeParking
    ? rooms
    : rooms
        .map((room) => ({
          ...room,
          rates: room.rates.filter(
            (rate) => rate.includedAmenities.parking === AmenityAvailability.AMENITY_AVAILABLE
          ),
        }))
        .filter((room) => room.rates.length > 0);

const filterRoomsByClientProgram = (rooms: IRoom[] | undefined = [], filterState?: IFilterState) =>
  !filterState?.clientPrograms.length
    ? rooms
    : rooms
        .map((room) => ({
          ...room,
          rates: room.rates.filter(
            (rate) =>
              rate.clientProgramName && filterState.clientPrograms.includes(rate.clientProgramName)
          ),
        }))
        .filter((room) => room.rates.length > 0);

const filterRoomsByBeds = (rooms: IRoom[] | undefined = [], filterState?: IFilterState) =>
  !filterState?.bedType.length
    ? rooms
    : rooms.filter((room) =>
        filterState.bedType.every((bedType) => room.beddings?.some((bed) => bed.type === bedType))
      );

export const filterRooms = pipe(
  filterRoomsWithoutRates,
  filterRoomsByLoyalty,
  filterRoomsByRefundable,
  filterRoomsByGsa,
  filterRoomsByBreakfast,
  filterRoomsByParking,
  filterRoomsByBeds,
  filterRoomsByClientProgram
);

export const buildRoomsCreateParams = (
  { checkIn, checkOut, searchId }: IPropertySearchParams,
  propertyId: number
): IRoomCreateParams => ({
  checkIn: checkIn?.format?.("YYYY-MM-DD"),
  checkOut: checkOut?.format?.("YYYY-MM-DD"),
  propertyId,
  searchId,
});

/**
 * Adds property page filters to the URL query params.
 * These are used to filter similar rooms if the attempted booking room was unavailable or there was a rate increase.
 * @param filterName name of the property filter
 * @param value value of the filter
 */
export const updateFilterURLParams = (filterName: string, value: boolean | string[]) => {
  const params = parse(globalThis.location.search.slice(1));

  if (Array.isArray(value) && value.length) {
    params[filterName] = value;
  } else if (!Array.isArray(value) && value) {
    params[filterName] = String(value);
  } else if ((Array.isArray(value) && !value.length) || !value) {
    delete params[filterName];
  }
  const updatedParams = stringify(params, { arrayFormat: "comma" });

  globalThis.history.replaceState({}, "", `${location.pathname}?${updatedParams}`);
};

/**
 * Grabs room filters from the query params.
 * Used on the booking page to find similar rooms if the booking was unavailable or there was a rate increase.
 * Used to persist filters on the property page.
 * @param routeSearchParamString search param string from react-router useNavigate hook
 * @param originalRoomIsRefundable applied from the booking page - will make the refundable filter true if the original booking was refundable
 * @returns property filters from the URL params packaged into an object
 */
export const getRoomFiltersFromQueryParams = (
  routeSearchParamString: string,
  originalRoomIsRefundable?: boolean
): IFilterState => {
  const params = parse(routeSearchParamString.slice(1));
  const parseBoolean = (boolString) => boolString === "true";
  const filters = {
    refundable: originalRoomIsRefundable || parseBoolean(params.refundable),
    loyaltyEligible: parseBoolean(params.loyaltyEligible),
    gsaOrLower: parseBoolean(params.gsaOrLower),
    freeBreakfast: parseBoolean(params.breakfast),
    freeParking: parseBoolean(params.parking),
    bedType: params.bedType ? (params.bedType as string).split(",") : [],
    clientPrograms: params.clientPrograms ? (params.clientPrograms as string).split(",") : [],
  };

  return filters;
};
