import { usePendo } from "@hotel-engine/scripts/hooks";
import type { ReactNode } from "react";
import { createContext, useContext } from "react";

interface IPendoContextType {
  isInitialized: boolean;
}

const PendoContext = createContext<IPendoContextType | undefined>(undefined);

interface IPendoProviderProps {
  children: ReactNode;
}

export const PendoContextProvider = ({ children }: IPendoProviderProps) => {
  const { isPendoInitialized } = usePendo();

  return (
    <PendoContext.Provider value={{ isInitialized: isPendoInitialized }}>
      {children}
    </PendoContext.Provider>
  );
};

export const useIsPendoInitialized = (): IPendoContextType => {
  const context = useContext(PendoContext);

  if (context === undefined) {
    throw new Error("useIsPendoInitialized must be used within a PendoContextProvider");
  }

  return context;
};
