import { useState } from "react";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { PropertyActions } from "store/containers/Property/PropertyRedux";

import { ampli } from "ampli";

import { FavoriteToggle } from "@hotel-engine/app/FavoriteToggle/FavoriteToggle";
import type { IReservationBase } from "@hotel-engine/types/reservation";
import { Icon as AtlasIcon, IconButton, toast, Typography, Tooltip } from "@hotelengine/atlas-web";
import { usePropertyQuery } from "@hotel-engine/react-query/property/usePropertyQuery";
import { useDownloadItineraryDocument } from "@hotel-engine/react-query/downloadItineraryDocument/useDownloadItineraryDocument";
import { AddToCalendarModal } from "@hotel-engine/app/modals/AddToCalendarModal";
import { useNavigate, useSearchParams } from "@hotel-engine/lib/react-router-dom";
import SendItineraryModal from "@hotel-engine/app/modals/SendItineraryModal";
import { captureMessage } from "@hotel-engine/utilities";

import { useBaseModificationsContext } from "../../ModificationsContext";
import * as Styled from "./styles";
import { isFlight, isLodging } from "../isLodging";

interface IHeaderActionsProps {
  onClose?: () => void;
}

const HeaderActions = ({ onClose }: IHeaderActionsProps) => {
  const user = useAppSelector((state) => state.Auth.user);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    state: { modificationView },
    reservation,
    isPreview,
    dispatch: modificationsDispatch,
  } = useBaseModificationsContext();
  const [qs] = useSearchParams();
  const showUpsellParam = qs.get("showUpsell") === "true";

  const isLodgingItinerary = isLodging(reservation);
  const isFlightItinerary = isFlight(reservation);

  const itineraryId = isLodgingItinerary ? reservation.id : reservation.bookingNumber;
  const upsellModalIsVisible = isFlightItinerary && showUpsellParam;

  const {
    refetch: fetchPrintPdf,
    isLoading: loadingPrintPdf,
    isFetching: fetchingPrintPdf,
  } = useDownloadItineraryDocument({
    contractNumber: itineraryId,
    documentType: isLodgingItinerary
      ? "itinerary"
      : isFlightItinerary
        ? "flightItinerary"
        : "carItinerary",
  });

  const toggleShowShareModal = () => {
    ampli.clickShareItinerary({
      userId: user?.id,
      bookingId: itineraryId,
      viewUrl: globalThis?.location.href,
    });
    setShowShareModal(!showShareModal);
  };

  const handleCloseAction = () => {
    if (!!modificationView) {
      modificationsDispatch({
        type: "SET_SHOW_NAVIGATION_MODAL",
        payload: true,
      });
    } else if (isPreview) {
      onClose?.();
    } else if (upsellModalIsVisible) {
      navigate("/");
    } else {
      navigate("/trips/upcoming");
    }
  };

  const handleDownloadAction = async () => {
    try {
      ampli.clickDownloadItinerary({
        userId: user?.id,
        bookingId: itineraryId,
        viewUrl: globalThis?.location.href,
      });
      await fetchPrintPdf();
    } catch (err) {
      toast({
        title: "There was an error getting your document",
        description: "Please try again later or contact support for assistance.",
        icon: "circle-exclamation",
        sentiment: "critical",
      });

      captureMessage("Error fetching document", {
        error: new Error(`Could not fetch itinerary: ${err}`),
      });
    }
  };

  /** This is needed here to update the reservation properly after performing header actions like clicking the favorite button */
  usePropertyQuery((reservation as IReservationBase)?.propertyId, {
    enabled: isLodgingItinerary,
    cacheTime: 0,
    onSuccess(property) {
      dispatch(PropertyActions.getPropertySuccess(property));
    },
  });

  const getBackText = () => {
    if (!!modificationView) {
      return "Return to itinerary";
    } else if (upsellModalIsVisible) {
      return "";
    } else {
      return isPreview ? "Close" : "Trips";
    }
  };

  return (
    <Styled.ItineraryPanelActions className="no-padding" isLodgingItinerary={!!isLodgingItinerary}>
      {isPreview ? (
        !!modificationView ? (
          <Styled.CloseButton data-testid="close-preview-panel" onClick={handleCloseAction}>
            <AtlasIcon
              name={!!modificationView ? "chevron-left" : "xmark"}
              color="foregroundPrimary"
              size="sm"
            />
            <Typography variant="body/sm" color="foregroundPrimary">
              {getBackText()}
            </Typography>
          </Styled.CloseButton>
        ) : (
          <IconButton
            aria-label="Close action"
            onClick={handleCloseAction}
            icon="xmark"
            variant="outlined"
            color="secondary"
            size="sm"
          />
        )
      ) : (
        <Styled.NewReturnLink data-testid="close-preview-panel">
          <AtlasIcon
            name={upsellModalIsVisible ? "house--solid" : "chevron-left"}
            size={upsellModalIsVisible ? "md" : "sm"}
            onClick={upsellModalIsVisible ? handleCloseAction : undefined}
          />
          <span onClick={handleCloseAction} role="link">
            {getBackText()}
          </span>
        </Styled.NewReturnLink>
      )}
      <Styled.ButtonGroup>
        {!!isLodgingItinerary && (
          <FavoriteToggle
            className="itinerary-panel-header"
            propertyId={(reservation as IReservationBase).propertyId}
            propertyName={(reservation as IReservationBase).propertyName}
            useAtlas
          />
        )}
        <Tooltip content="Download itinerary">
          <Styled.ActionItem
            icon="print"
            variant="plain"
            color="everdark"
            isDisabled={loadingPrintPdf || fetchingPrintPdf}
            isLoading={loadingPrintPdf || fetchingPrintPdf}
            onClick={handleDownloadAction}
          />
        </Tooltip>
        {!!isLodgingItinerary && (
          <Tooltip content="Add to calendar">
            <Styled.ActionItem
              icon="calendar-plus"
              variant="plain"
              color="everdark"
              onClick={() => setShowCalendarModal(true)}
            />
          </Tooltip>
        )}
        <Tooltip content="Share itinerary">
          <Styled.ActionItem
            icon="share"
            variant="plain"
            color="everdark"
            onClick={() => toggleShowShareModal()}
            id="share"
          />
        </Tooltip>
      </Styled.ButtonGroup>
      {!!showCalendarModal && (
        <AddToCalendarModal
          reservation={reservation as IReservationBase}
          onClose={() => setShowCalendarModal(false)}
        />
      )}
      <SendItineraryModal
        contract={reservation}
        onCancel={toggleShowShareModal}
        visible={showShareModal}
      />
    </Styled.ItineraryPanelActions>
  );
};

export default HeaderActions;
