import { DirectBillStatus, LandingView } from "./types";
import type { ICreditData } from "./types";

class CreditClient {
  private creditData: ICreditData;
  constructor(creditData: ICreditData) {
    this.creditData = creditData;
  }

  get getAvailableCredit() {
    return this.creditData?.availableCredit;
  }

  get getBalance() {
    return this.creditData?.balance;
  }

  get getCreditLimit() {
    return this.creditData?.creditLimit;
  }

  get getDirectBillEnabledDate() {
    return this.creditData?.directBillEnabledAt;
  }

  isDirectBillUserDefault() {
    return !!this.creditData?.directBillUserDefault;
  }

  canBookWithDirectBill(total?: number) {
    return !!(this.isEnabled() && (!total || this.hasSufficientBalance(total)));
  }

  canUseAchForDirectBillPayment() {
    return !!this.creditData?.directBillAchEnabled;
  }

  canUseCreditCardForDirectBillPayment() {
    return !!this.creditData?.directBillCreditCardEnabled;
  }

  canSeeDirectBillPromotion() {
    return !!this.creditData?.directBillPromotionEnabled;
  }

  canSeeDirectBillVerification() {
    return !!this.creditData?.directBillVerificationRequired;
  }

  hasExistingDBTransactions() {
    return !!this.creditData?.hasInvoices;
  }

  hasSufficientBalance(total: number) {
    return !!(Number(this.creditData?.availableCredit) >= total);
  }

  hasCreditAvailable() {
    return this.isEnabled() && !!this.creditData?.creditLimit && this.creditData?.creditLimit > 0;
  }

  isEnabled() {
    return !!(this.creditData?.directBillStatus === DirectBillStatus.enabled);
  }

  isSuspended() {
    return !!(this.creditData?.directBillStatus === DirectBillStatus.suspended);
  }

  isDisabled() {
    return !!(this.creditData?.directBillStatus === DirectBillStatus.disabled);
  }

  defaultLandingView(): LandingView {
    if (this.canBookWithDirectBill() || this.isSuspended()) {
      return LandingView.directBill;
    }
    return LandingView.transactions;
  }

  canViewSwitcher() {
    return !!(
      this.canBookWithDirectBill() ||
      this.isSuspended() ||
      this.hasExistingDBTransactions()
    );
  }
}
export { CreditClient };
