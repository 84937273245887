import { Divider, useTheme } from "@hotelengine/atlas-web";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import type { IChangeDatesContent } from "../types";
import ChangeDatesContentItem from "./ChangeDatesContentItem";
import { ModificationTypes } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useCallback } from "react";
import * as Styled from "../styles";
const ChangeDatesContent = ({
  flexEnabled,
  extendTripHandler,
  modifyTripHandler,
  modificationActions,
}: IChangeDatesContent) => {
  const { tokens } = useTheme();
  const { t } = useTranslation("itinerary");
  const user = useAppSelector((state) => state.Auth.user);

  const isViewOnlyTraveler = user?.role === "view_only_traveler";
  const showFlexMessage = flexEnabled && !isViewOnlyTraveler;

  // TODO: Amplitude tracking [LODGE-6482]
  const handleShortenBooking = useCallback(() => {
    // ampli.clickShortenBooking({
    //   viewUrl: globalThis?.location.href,
    //   userId: user?.id,
    //   bookingId: bookingId,
    // });

    modifyTripHandler(ModificationTypes.ShortenBooking);
  }, [modifyTripHandler]);

  // TODO: Amplitude tracking [LODGE-6482]
  const handleChangeDates = useCallback(() => {
    // ampli.clickExpandChangeDates({
    //   viewUrl: globalThis?.location.href,
    //   userId: user?.id,
    //   bookingId: bookingId,
    // });
    modifyTripHandler(ModificationTypes.RequestNewDates);
  }, [modifyTripHandler]);

  return (
    <Styled.ChangeDatesContent data-testid="change-dates-content">
      {(!!modificationActions.shortenable || !!modificationActions.flexShortenable) && (
        <>
          <ChangeDatesContentItem
            title={t("modifications.options.shortenBooking.title")}
            description={t("modifications.options.shortenBooking.description")}
            showFlexMessage={showFlexMessage}
            action={handleShortenBooking}
            dataTestId="shorten-booking-item"
          />
          <Divider style={{ borderColor: tokens.colors.borderMinimal }} />
        </>
      )}

      {!!modificationActions.extendable && (
        <>
          <ChangeDatesContentItem
            title={t("modifications.options.extendBooking.title")}
            description={t("modifications.options.extendBooking.description")}
            action={extendTripHandler}
            dataTestId="extend-booking-item"
          />
          <Divider style={{ borderColor: tokens.colors.borderMinimal }} />
        </>
      )}

      {!!modificationActions.modifiable && (
        <ChangeDatesContentItem
          title={t("modifications.options.requestNewDates.title")}
          description={t("modifications.options.requestNewDates.description")}
          action={handleChangeDates}
          dataTestId="request-new-dates-item"
        />
      )}
    </Styled.ChangeDatesContent>
  );
};

export default ChangeDatesContent;
