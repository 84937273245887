import { Suspense } from "react";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { datadogRum } from "@datadog/browser-rum";

import { InstallPrompt } from "@hotel-engine/app/InstallPrompt";
import { NotificationsModal } from "@hotel-engine/app/modals/NotificationsModal";
import NuclearErrorModal, {
  NuclearErrorRedirectOption,
} from "@hotel-engine/app/modals/NuclearErrorModal";
import DevTools from "@hotel-engine/app/DevTools";
import { ImpersonationBanner } from "@hotel-engine/app/ImpersonationBanner";
import { SystemOutageBanner } from "@hotel-engine/app/SystemOutageBanner";
import { PostCheckoutActionsProvider } from "@hotel-engine/contexts/PostCheckoutActionsContext";
import QueryClientProvider from "@hotel-engine/contexts/DataContext/QueryClientProvider";
import CheckoutProvider from "@hotel-engine/contexts/CheckoutContext";
import { NotificationsProvider } from "@hotel-engine/notifications";
import { OnRouterInit } from "@hotel-engine/react-router/routers/OnRouterInit";
import ThirdPartyScripts from "@hotel-engine/scripts/ThirdPartyScripts/ThirdPartyScripts";
import { ModalStyles } from "@hotel-engine/styles";
import { GlobalStyles } from "@hotel-engine/styles/GlobalStyles";
import { router } from "./App";
import { store } from "store";
import PageLayout from "@hotel-engine/app/PageLayout";
import { DatadogInitializedProvider } from "@hotel-engine/scripts/datadog/DatadogInitializedProvider";

import { CheckSessionInterval } from "@hotel-engine/app/CheckSessionInterval/CheckSessionInterval";
import CheckCSPValue from "@hotel-engine/app/CheckCSPValue";
import CloudFlareChallenge from "@hotel-engine/CloudFlareChallenge";
import { GlobalThemeProvider } from "@hotel-engine/contexts/GlobalThemeContext";
import { Auth0ContextProvider } from "@hotel-engine/contexts/Auth0";
import { Outlet, RouterProvider } from "@hotel-engine/lib/react-router-dom";
import { HeaderOutletProvider } from "@hotel-engine/app/Header/HeaderOutletProvider";
import { Toaster } from "@hotelengine/atlas-web";

export const persistor = persistStore(store);

/**
 * Everything that should be within the React Router
 */
export function ProvidersWithinRouter() {
  return (
    <OnRouterInit>
      <GlobalThemeProvider>
        <DatadogInitializedProvider>
          <ThirdPartyScripts>
            <Toaster />
            <Sentry.ErrorBoundary
              beforeCapture={(scope, error) => {
                scope.setTag("is_crash", true);
                datadogRum.addError(error, { is_crash: true });
              }}
              fallback={
                <NuclearErrorModal
                  hasPageError={true}
                  redirectOption={NuclearErrorRedirectOption.HOMEPAGE}
                />
              }
            >
              <HeaderOutletProvider>
                <CheckSessionInterval />
                {/* This is a temporary feature flag to roll out CSP to production */}
                <CheckCSPValue />
                <NotificationsProvider>
                  <CloudFlareChallenge />
                  <SystemOutageBanner />
                  <ImpersonationBanner />
                  <InstallPrompt />

                  {/* These styles are just temporary until we can figure out the best way to handle styles for UX concepts that append elements to the DOM. Hopefully we'll be able to create a scoped common component for notifications and modals that utilize the Ant D. components method technique to hide and show the components */}
                  <ModalStyles />

                  <GlobalStyles />
                  <DevTools />
                  <CheckoutProvider>
                    <PostCheckoutActionsProvider>
                      <NotificationsModal />
                      <Suspense
                        fallback={
                          <PageLayout
                            contentWidth="100%"
                            noFooter
                            bodyBackgroundColor="backgroundPrimary"
                          >
                            <></>
                          </PageLayout>
                        }
                      >
                        {/* Actual Routes will be rendered here in this Outlet: */}
                        <Outlet />
                      </Suspense>
                    </PostCheckoutActionsProvider>
                  </CheckoutProvider>
                </NotificationsProvider>
              </HeaderOutletProvider>
            </Sentry.ErrorBoundary>
          </ThirdPartyScripts>
        </DatadogInitializedProvider>
      </GlobalThemeProvider>
    </OnRouterInit>
  );
}

/**
 * Everything that should wrap the React Router
 */
function Providers() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider>
          <Auth0ContextProvider>
            <RouterProvider router={router} />
          </Auth0ContextProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default Providers;
