import { useCarModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { CollapsibleContent } from "@hotel-engine/app/ItineraryContent/shared/CollapsibleContent";
import { CancellationPolicyText } from "@hotel-engine/constants/cars";
import { Box, Typography } from "@hotelengine/atlas-web";
import { ampli } from "ampli";
import { CancellationPolicy as CheckoutCancellationPolicy } from "pages/Checkout/Cars/components/CancellationPolicy";
import { useAppSelector } from "store/hooks";

const CancellationPolicy = () => {
  const user = useAppSelector((state) => state.Auth.user);
  const { reservation } = useCarModificationsContext();

  const policyItems = reservation.cancellationPolicy
    .split(".")
    .filter(Boolean)
    .map((text) => `${text}.`);

  return (
    <CollapsibleContent
      toggleTestID="cancellation-policy"
      toggleBtnLabel={<Typography variant="heading/lg">Cancellation policy</Typography>}
      onChangeState={() => {
        if (user) {
          ampli.clickCarsCancellationPolicy({
            carBookingID: reservation.bookingNumber,
            userId: user.id,
          });
        }
      }}
    >
      <Box padding={24} paddingTop={0}>
        <CheckoutCancellationPolicy
          hideTitle
          isPlain
          cancelType={reservation.cancelType}
          policyItems={
            policyItems.length > 0 ? policyItems : [CancellationPolicyText[reservation.cancelType]]
          }
        />
      </Box>
    </CollapsibleContent>
  );
};

export default CancellationPolicy;
