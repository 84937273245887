import * as Styled from "../styles";
import ModificationsHeader from "./ModificationsHeader";
import ModificationOptions from "./ModificationOptions";
import PropertyCard from "./PropertyCard";

import { withModificationsModals } from "../hooks/withModificationsModals";
import type { IMenuCardProps } from "../types";

const MenuCard = ({
  itinerary,
  handleLateCheckInModal,
  handleTripExtensionModal,
}: IMenuCardProps) => {
  return (
    <Styled.ModificationSideSheetContent>
      <ModificationsHeader />
      <PropertyCard itinerary={itinerary} />
      <ModificationOptions
        itinerary={itinerary}
        handleLateCheckInModal={handleLateCheckInModal}
        handleTripExtensionModal={handleTripExtensionModal}
      />
    </Styled.ModificationSideSheetContent>
  );
};

export default withModificationsModals(MenuCard);
