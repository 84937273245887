import { ampli } from "ampli";

import { Typography, Button, Icon } from "@hotelengine/atlas-web";
import ResponsiveAtlasDialog from "@hotel-engine/app/ResponsiveAtlasDialog";
import type { IReservationBase } from "@hotel-engine/types/reservation";

import { useAppSelector } from "store/hooks";

import * as Styled from "./styles";

export const ManageTripTrigger = ({
  children,
  itinerary,
}: { children: React.ReactNode; itinerary: IReservationBase }) => {
  const user = useAppSelector((state) => state.Auth.user);

  const isViewOnlyTraveler = user?.role === "view_only_traveler";
  const showFlexMessage = itinerary.flexEnabled && !isViewOnlyTraveler;

  const openManageTripOptions = () => {
    ampli.clickManageTrip({
      userId: user?.id,
      lodgingBookingID: itinerary.contractNumber,
    });
  };

  return (
    <>
      <Styled.ManageTripContainer>
        <ResponsiveAtlasDialog
          title="Manage trip"
          content={children}
          triggerComponent={
            <Button style={{ width: "100%" }} size="lg" onClick={openManageTripOptions}>
              Manage trip
            </Button>
          }
          isModal
        />
        {!!showFlexMessage && (
          <Styled.FlexMessage>
            <Typography variant="body/sm">Modifications are covered by </Typography>
            <Icon
              id="shield-check"
              name="he-flex"
              color="foregroundPrimary"
              data-testid="flex-pro-logo"
              size="sm"
            />{" "}
            <Typography variant="body/sm-strong">Flex</Typography>
          </Styled.FlexMessage>
        )}
      </Styled.ManageTripContainer>
    </>
  );
};
