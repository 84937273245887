import { useNavigate } from "@hotel-engine/lib/react-router-dom";

import type { ILocationState } from "@hotel-engine/app/ItineraryContent";
import { formatUrl } from "@hotel-engine/react-router";

import type { IReservationBase } from "@hotel-engine/types/reservation";
import { Box, Button, Icon, Typography } from "@hotelengine/atlas-web";

import isStateOfTexasUser from "@hotel-engine/utilities/helpers/isStateOfTexasUser";
import { useAppSelector } from "store/hooks";
import Api from "@hotel-engine/services/Api";
import config from "config";
import { captureMessage } from "@hotel-engine/utilities";
import { ampli } from "ampli";

export interface ITopActionsProps {
  itinerary: IReservationBase;
  locationState?: ILocationState;
}

// Theme doesn't exist here so we manually define the theme.border-radius
// eslint-disable-next-line custom-rules/themed-border-radius
const folioTabInitialHtml = `
  <html lang="en">
    <head>
      <title>Loading Folio Receipt...</title>
      <style>
        body {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100vh;
          margin: 0;
          font-family: Arial, sans-serif;
        }
        .spinner {
          border: 8px solid #f3f3f3;
          border-top: 8px solid #3498db;
          border-radius: 50%;
          width: 60px;
          height: 60px;
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      </style>
    </head>
    <body>
      <div class="spinner"></div>
    </body>
  </html>
`;

const TopActions = ({ itinerary, locationState }: ITopActionsProps) => {
  const navigate = useNavigate();
  const propertyId = itinerary.propertyId;

  const user = useAppSelector((state) => state.Auth.user);
  const isSot = isStateOfTexasUser(user);

  const onBookAgainClicked = () => {
    ampli.clickBookAgain({
      bookingId: itinerary.id,
    });
    navigate(`/properties/${propertyId}${formatUrl(locationState || {})}`);
  };

  const getDocument = async (contractNumber, documentName) => {
    const isIE = Boolean(globalThis.navigator && globalThis.navigator.msSaveOrOpenBlob);
    // The following line is so safari will not block the event...
    // safari will prevent window.open if doesn't occur immediately following click
    // in this case safari disdains the api request happening before window.open
    const preflightWindow = isIE ? null : globalThis.open("about:blank", "_blank");
    preflightWindow?.document.write(folioTabInitialHtml);

    const res = await Api.download(
      `${config.apiHostV2}/contracts/${contractNumber}/${documentName}.pdf`,
      {},
      { responseType: "blob" }
    );

    const blob = new Blob([res], { type: "application/pdf" });
    if (isIE) {
      globalThis.navigator.msSaveOrOpenBlob &&
        globalThis.navigator.msSaveOrOpenBlob(blob, "itinerary.pdf");
    } else {
      const url = globalThis.URL.createObjectURL(blob);
      preflightWindow
        ? (preflightWindow.location.href = url)
        : captureMessage("Error opening itinerary window", {
            error: new Error("Could not open window with window.open"),
          });
    }
  };
  const openFolioReceiptTab = async () => {
    ampli.clickViewFolio({
      bookingId: itinerary.id,
    });
    await getDocument(itinerary.id, "folio");
  };

  return (
    <Box gap={16} flexDirection="row" display="flex">
      {!isSot && (
        <Button
          variant="filled"
          color="everlight"
          size="sm"
          style={{ width: "100%" }}
          onClick={openFolioReceiptTab}
        >
          <Box display="flex" alignItems="center">
            <Icon name="file-lines" style={{ marginRight: 8 }} size="md" />
            <Typography variant="body/md">View folio</Typography>
          </Box>
        </Button>
      )}
      <Button
        variant="filled"
        color="everlight"
        size="sm"
        style={{ width: "100%", justifyContent: "center" }}
        isDisabled={propertyId === undefined}
        onClick={onBookAgainClicked}
      >
        <Box display="flex" alignItems="center">
          <Icon name="arrow-rotate-right" style={{ marginRight: 8 }} size="md" />
          <Typography variant="body/md">Book again</Typography>
        </Box>
      </Button>
    </Box>
  );
};

export default TopActions;
