import { RouteAuthWrapper } from "@hotel-engine/react-router";
import TripsPage from "./TripsPage";

export function TripsController() {
  return (
    <RouteAuthWrapper minRole="view_only_traveler">
      <TripsPage />
    </RouteAuthWrapper>
  );
}
