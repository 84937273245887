import ItineraryContent from "@hotel-engine/app/ItineraryContent";
import { useRevalidator } from "@hotel-engine/lib/react-router-dom";
import { useItineraryQuery } from "@hotel-engine/react-query/itinerary/useItineraryQuery";
import type { IFlightItinerary } from "@hotel-engine/types/itinerary";
import type { AxiosError } from "axios";
import { useCallback, useEffect } from "react";

import { endpoints } from "@hotel-engine/react-query/constants";
import { useQueryClient } from "react-query";
import ItineraryError from "../components/ItineraryError";
import * as Styled from "../styles";

export interface IFlightItineraryWithId extends IFlightItinerary {
  tripId: number;
  bookingNumber: string;
}

export interface ITripsItineraryPreviewPanelProps {
  itineraryId: string | number;
  onClose: () => void;
}

const FlightItineraryPanel = ({ onClose, itineraryId }: ITripsItineraryPreviewPanelProps) => {
  const queryClient = useQueryClient();
  const { revalidate } = useRevalidator();

  const {
    data,
    isError,
    error,
    isLoading,
    refetch: refetchItinerary,
  } = useItineraryQuery({
    id: itineraryId,
  });

  const tripItinerary = {
    tripId: itineraryId,
    bookingNumber: data?.bookings[0].bookingNumber,
    ...data?.bookings?.[0]?.details,
  } as IFlightItineraryWithId;

  const handleScroll = useCallback(() => {
    const panel = document.getElementById("preview-panel");

    if (!!panel) {
      panel.style.height = `calc(100vh - 90px + ${`${globalThis.scrollY}px`})`;
    }
  }, []);

  const handleRevalidate = async () => {
    queryClient.removeQueries(endpoints.trips);
    revalidate();
  };

  useEffect(() => {
    globalThis.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      globalThis.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Styled.PreviewPanelContainer>
      <Styled.PreviewPanel id="preview-panel" data-testid="preview-panel" $isFlight>
        {!!isError || (!isLoading && !tripItinerary) ? (
          <ItineraryError
            reload={refetchItinerary}
            goBack={onClose}
            error={error as AxiosError | null}
          />
        ) : (
          <Styled.ScrollContainer
            // This id is needed for post-modification scroll to top
            id="itinerary-scroll-container"
          >
            <ItineraryContent
              isLoading={isLoading}
              itinerary={tripItinerary}
              isPreview={true}
              onClose={onClose}
              refetchReservation={handleRevalidate}
              bookingType="flight"
            />
          </Styled.ScrollContainer>
        )}
      </Styled.PreviewPanel>
    </Styled.PreviewPanelContainer>
  );
};

export default FlightItineraryPanel;
