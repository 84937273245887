import { Box, Chip } from "@hotelengine/atlas-web";

import { getUserRoleEnabledFeatures } from "../../../helpers";
import Notifications from "@hotel-engine/app/HeaderNotifications";
import { MobileNotificationsTray } from "@hotel-engine/app/HeaderNotifications/MobileNotificationsTray";
import { UserDropdown } from "../UserDropdown";
import { MobileUserBottomSheet } from "../UserDropdown/MobileUserBottomSheet";

import { useUser } from "@hotel-engine/hooks";
import type { ITokenMode } from "@hotel-engine/react-query/users/useAccountMode";
import { useIsFeatureFlagOn } from "../../../../Experiments";

export interface IDesktopComponents {
  mode: ITokenMode;
  onInviteTraveler: () => void;
  onPersonalAccountCreation: () => void;
  showUnreadNotifications: boolean;
  setShowUnreadNotifications: (setUnread: boolean) => void;
  onToggleBizPersonalAccount: () => void;
}
type MobileComponentsProps = IDesktopComponents;

export const MobileComponents = ({
  mode,
  onInviteTraveler,
  onPersonalAccountCreation,
  setShowUnreadNotifications,
  showUnreadNotifications,
  onToggleBizPersonalAccount,
}: MobileComponentsProps) => {
  const user = useUser();
  const adminRoleRequestsEnabled = useIsFeatureFlagOn("admin-role-requests");
  const {
    canCreatePersonalAccount,
    canSwitchToPersonal,
    canSwitchToBusiness,
    shouldShowReferAFriend,
    shouldShowMyProperties,
    shouldShowTravelCredits,
    shouldShowRequestAdmin,
  } = getUserRoleEnabledFeatures(user);

  return (
    <Box
      style={{ width: "100%" }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <MobileNotificationsTray
        setShowUnreadNotifications={setShowUnreadNotifications}
        hasUnreadNotifications={showUnreadNotifications}
      />
      <Box display="flex" justifyContent="center" gap={16}>
        {Boolean(user.isDemo) && <Chip label="Demo" color="gray" variant="outlined" />}
        <MobileUserBottomSheet
          onPersonalAccountCreation={onPersonalAccountCreation}
          onInviteTraveler={onInviteTraveler}
          userTokenMode={mode}
          onToggleBizPersonalAccount={onToggleBizPersonalAccount}
          canCreatePersonalAccount={canCreatePersonalAccount}
          showSwitch={!!canSwitchToBusiness || !!canSwitchToPersonal}
          shouldShowRequestAdmin={!!shouldShowRequestAdmin && adminRoleRequestsEnabled}
          shouldShowReferAFriend={shouldShowReferAFriend}
          shouldShowMyProperties={shouldShowMyProperties}
          shouldShowTravelCredits={shouldShowTravelCredits}
        />
      </Box>
    </Box>
  );
};

export const DesktopComponents = ({
  mode,
  onInviteTraveler,
  onPersonalAccountCreation,
  setShowUnreadNotifications,
  showUnreadNotifications,
  onToggleBizPersonalAccount,
}: IDesktopComponents) => {
  const user = useUser();
  const adminRoleRequestsEnabled = useIsFeatureFlagOn("admin-role-requests");

  const {
    canCreatePersonalAccount,
    canSwitchToPersonal,
    canSwitchToBusiness,
    shouldShowReferAFriend,
    shouldShowMyProperties,
    shouldShowTravelCredits,
    shouldShowRequestAdmin,
  } = getUserRoleEnabledFeatures(user);

  return (
    <>
      <Notifications
        accountType={user.accountType}
        hasUnreadNotifications={showUnreadNotifications}
        setShowUnreadNotifications={setShowUnreadNotifications}
      />
      <UserDropdown
        userTokenMode={mode}
        onToggleBizPersonalAccount={onToggleBizPersonalAccount}
        shouldShowReferAFriend={shouldShowReferAFriend}
        shouldShowTravelCredits={shouldShowTravelCredits}
        shouldShowRequestAdmin={!!shouldShowRequestAdmin && adminRoleRequestsEnabled}
        shouldShowMyProperties={shouldShowMyProperties}
        canCreatePersonalAccount={canCreatePersonalAccount}
        showSwitch={!!canSwitchToBusiness || !!canSwitchToPersonal}
        onInviteTraveler={onInviteTraveler}
        onPersonalAccountCreation={onPersonalAccountCreation}
      />
    </>
  );
};
