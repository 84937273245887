import { useState } from "react";

import { Formik } from "formik";

import { useBulkMembersMutation } from "@hotel-engine/react-query/members/useBulkMembersMutation";
import { isIBatchError, isIErrorResponse } from "@hotel-engine/types/errors";
import { isIE11 } from "@hotel-engine/utilities";
import { captureMessage } from "@hotel-engine/utilities/logger";
import config from "config";
import { useAppSelector } from "store/hooks";

import * as Styled from "./styles";
import { Unsafe } from "@hotel-engine/data";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  TextArea,
  toast,
} from "@hotelengine/atlas-web";

export const InviteUserModal = ({
  onCancel,
  visible,
}: {
  onCancel: (resetForm: () => void) => void;
  visible: boolean;
}) => {
  const business = useAppSelector((state) => state.Auth.user?.business);
  const shareLink = `${globalThis.location.origin}/join/${business?.code}`;
  const { mutateAsync, reset } = useBulkMembersMutation({ method: "post" });
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const initialValues = {
    emails: "",
  };

  const handleInviteUsers = async (values, { setFieldError, resetForm }) => {
    try {
      const addresses = values.emails.split(",").map((a: string) => ({
        email: a.trim(),
        employeeId: "",
        role: "user",
      }));
      await mutateAsync(addresses);

      toast({
        title: "Success!",
        icon: "circle-exclamation",
        sentiment: "positive",
        description:
          addresses.length > 1 ? `${addresses.length} user invites sent.` : "User invite sent",
      });

      return onCancel(resetForm);
    } catch (error) {
      captureMessage("Bulk user invite error", { error });
      if (isIErrorResponse(error)) {
        const errorType = error?.errors?.[0];
        if (isIBatchError(errorType)) {
          let errorReason = errorType.invalidParams?.[0].reasons[0];
          // Capitalize first letter of error phrase
          if (errorReason) errorReason = errorReason[0].toUpperCase() + errorReason.substring(1);
          setFieldError("emails", errorReason || [config.defaultErrorMessage]);
          console.log(errorReason);
        }
      }
    } finally {
      reset();
    }
  };

  const handleCopyShareLink = () => {
    if (isLinkCopied) return;

    try {
      if (isIE11) {
        globalThis.clipboardData.setData("Text", shareLink);
      } else {
        navigator.clipboard.writeText(shareLink).then(Unsafe.DO_NOTHING, Unsafe.IGNORE_ERROR);
      }
      setIsLinkCopied(true);
      setTimeout(() => {
        setIsLinkCopied(false);
      }, 3000);
    } catch (error) {
      // shareLink is a full url (like https://engine/com/join/abc123),
      // so we can't pass that to navigate().
      // I'm going to leave this current behavior of opening a new tab,
      // but it seems to me that if this current user, who is already logged in,
      // opens a new tab with this `/join` url, they will just be redirected
      // back to the members dashboard, having lost the original url to be copied.
      // The url would only function for another visitor, who isn't already signed in.
      // So this is probably not the best fallback behavior.
      globalThis.open(shareLink, "_blank");
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleInviteUsers}>
      {({ isSubmitting, handleSubmit, values, resetForm, setFieldValue, errors }) => (
        <Dialog isOpen={visible} onOpenChange={(open) => !open && onCancel(resetForm)}>
          <DialogContent>
            <DialogTitle>Invite new members</DialogTitle>
            <div>
              <Styled.CopyLabel>Share your link, click to copy</Styled.CopyLabel>
              <Styled.CopyLink
                data-testid="share-link"
                onClick={handleCopyShareLink}
                isLinkCopied={isLinkCopied}
              >
                {!isLinkCopied ? (
                  <>
                    {shareLink} <span>Copy Link</span>
                  </>
                ) : (
                  <>
                    Link copied to clipboard<span>Copy Link</span>
                  </>
                )}
              </Styled.CopyLink>
            </div>
            <Styled.OrDivider>
              <Styled.OrText>or</Styled.OrText>
            </Styled.OrDivider>
            <FormControl
              label="Invite members by email"
              errorText={errors.emails}
              status={!!errors.emails ? "error" : "default"}
            >
              <TextArea
                name="emails"
                placeholder="Separate multiple emails with a comma"
                onChange={(e) => setFieldValue("emails", e.target.value)}
                isInvalid={!!errors.emails}
              />
            </FormControl>
            <Styled.FooterButtons>
              <Button
                id="cancel"
                variant="outlined"
                data-testid="cancel-button"
                isDisabled={isSubmitting}
                onClick={() => onCancel(resetForm)}
              >
                Cancel
              </Button>
              <Button
                id="invite"
                data-testid="invite-button"
                isLoading={isSubmitting}
                isDisabled={!values.emails}
                onClick={() => handleSubmit()}
              >
                Invite Members
              </Button>
            </Styled.FooterButtons>
          </DialogContent>
        </Dialog>
      )}
    </Formik>
  );
};

export default InviteUserModal;
