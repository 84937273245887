import type { BoxProps } from "@hotelengine/atlas-web";
import { Box } from "@hotelengine/atlas-web";
import Image from "@hotel-engine/common/Image";
import styled from "styled-components";

type DeviceContentBoxProps = BoxProps & { $isMobile?: boolean; $isSmallMobile?: boolean };

export const FlexMessage = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[4]};
  margin-top: ${({ theme }) => theme.spacing[8]};
`;

export const ModificationsHeader = styled.span`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing[4]};
`;

export const ModificationSideSheetContent = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
  padding: ${({ theme }) => `${theme.spacing[8]} ${theme.spacing[8]}`};
`;

export const ModificationSideSheetItineraryDetails = styled(Box).attrs({
  display: "flex",
  flexDirection: "row",
})`
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.borderMinimal}`};
  margin-top: ${({ theme }) => theme.spacing[20]};
  margin-bottom: ${({ theme }) => theme.spacing[32]};
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const PropertyImage = styled(Image)`
  min-width: 74px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  margin: ${({ theme }) => theme.spacing[8]};
`;

export const PropertyDetails = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[8]};
  flex: 1;
`;

export const BoxedSelectorList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[8]};
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const BoxedSelector = styled<React.FC<DeviceContentBoxProps>>(Box)`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: ${({ theme }) => `${theme.borderWidth.normal} solid ${theme.colors.borderMinimal}`};
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding: ${({ $isSmallMobile, $isMobile, theme }) =>
    $isSmallMobile ? theme.spacing[12] : $isMobile ? theme.spacing[12] : theme.spacing[16]};
  padding-left: ${({ $isSmallMobile, $isMobile, theme }) =>
    $isSmallMobile ? theme.spacing[16] : $isMobile ? theme.spacing[16] : theme.spacing[16]};
`;

export const OptionContent = styled(Box).attrs({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
})`
  gap: ${({ theme }) => theme.spacing[16]};
`;

export const BoxedSelectorIconContainer = styled.div<{
  $bgColor?: string;
  $isSmallMobile?: boolean;
  $isMobile?: boolean;
}>`
  width: ${({ theme }) => theme.spacing[40]};
  height: ${({ theme }) => theme.spacing[40]};
  padding: ${({ theme }) => theme.spacing[12]};
  border-radius: ${({ theme, $isSmallMobile, $isMobile }) =>
    $isSmallMobile
      ? theme.borderRadius.xs
      : $isMobile
        ? theme.borderRadius.xs
        : theme.borderRadius.md};
  background-color: ${({ theme, $bgColor = "brandAccent" }) =>
    theme.colors[$bgColor as keyof typeof theme.colors] || theme.colors.brandAccent};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CollapsibleContent = styled<React.FC<DeviceContentBoxProps>>(Box)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  margin-top: ${({ theme }) => theme.spacing[16]};

`;

export const ChangeDatesContent = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[12]};
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: ${({ theme }) => theme.spacing[18]} ${({ theme }) => theme.spacing[20]};
`;

export const ChangeDatesContentItem = styled<React.FC<DeviceContentBoxProps>>(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
