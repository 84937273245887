import { useMemo, useState } from "react";

import moment from "moment";

import { useAppSelector } from "store/hooks";
import type { IAdminOnboardingTasks } from "@hotel-engine/types/onboardingEvents";
import { useAdminOnboardingEventQuery } from "@hotel-engine/react-query/onboardingEvents/useAdminOnboardingEventQuery";

export interface IOnboardingEvent {
  eventType: (typeof AdminOnboardingEvents)[keyof typeof AdminOnboardingEvents];
  createdAt: string;
}

const SEVEN_DAYS_AGO = moment().subtract(7, "days");

export const AdminOnboardingEvents = {
  MODAL_REMINDER_SCHEDULED: "admin_onboarding_modal_reminder_scheduled",
  MODAL_COMPLETED: "admin_onboarding_modal_completed",
  FEATURE_DISMISSED: "admin_onboarding_feature_dismissed",
} as const;

function getModalReminderScheduled({ eventType }: IOnboardingEvent) {
  return eventType === AdminOnboardingEvents.MODAL_REMINDER_SCHEDULED;
}

function getModalCompleted({ eventType }: IOnboardingEvent) {
  return eventType === AdminOnboardingEvents.MODAL_COMPLETED;
}

function getTaskCompleted({ isComplete }: IAdminOnboardingTasks) {
  return isComplete;
}

function getIsEventRecent(unprocessedEvent: IOnboardingEvent | undefined) {
  if (!unprocessedEvent) return false;

  return moment(unprocessedEvent.createdAt).isAfter(SEVEN_DAYS_AGO);
}

const MANAGEMENT_ROLE_PATTERN = /admin|department_manager/;

function useAdminExperienceFlag() {
  const user = useAppSelector(({ Auth }) => Auth.user);
  const isAdmin = MANAGEMENT_ROLE_PATTERN.test(user?.role || "");
  const { data } = useAdminOnboardingEventQuery({
    enabled: !!isAdmin,
  });
  const [initialDisplayCriteria, setInitialDisplayCriteria] = useState<boolean | null>(null);

  const areTasksComplete = useMemo(() => {
    return !!data?.tasks.every(getTaskCompleted);
  }, [data?.tasks]);

  const eligibilityForAdminExperience = useMemo(() => {
    if (!user) return false;

    const isCurrencyUSD = user.business?.currencyCode === "USD";
    const isAdminOnboardingEnabled = user.business?.adminOnboardingActive;
    const isUserAdminRole = MANAGEMENT_ROLE_PATTERN.test(user.role || "");

    return isCurrencyUSD && isAdminOnboardingEnabled && isUserAdminRole;
  }, [user]);

  const displayWelcomeModalCriteria = useMemo(() => {
    if (!data) return null;

    const { unassignedEvents } = data;
    const unprocessedEvent = unassignedEvents.find(getModalReminderScheduled);
    const isAnyModalCompleted = unassignedEvents.some(getModalCompleted);
    const isRecentEvent = getIsEventRecent(unprocessedEvent);
    const isCriteriaAttended = !areTasksComplete && !(isRecentEvent || isAnyModalCompleted);

    // Memorize the initial display criteria to prevent multiple modals
    // (Enhance Admin Experience and FlexPro) from showing one after another.
    // Once the initial criteria is set, it is preserved in state.
    if (initialDisplayCriteria === null) {
      setInitialDisplayCriteria(isCriteriaAttended);
    }

    return isCriteriaAttended;
    // Keep this dependency array to prevent infinite loops.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areTasksComplete, data?.unassignedEvents]);

  return {
    initialDisplayCriteria,
    eligibilityForAdminExperience,
    displayWelcomeModalCriteria,
    areTasksComplete,
  };
}

export default useAdminExperienceFlag;
