import type { IReservationBase } from "@hotel-engine/types/reservation";

import { useDeviceBreakpoints } from "pages/Checkout/Lodging/hooks/useDeviceBreakpoints";

import SideSheetMenu from "./components/SideSheetMenu";
import BottomSheetMenu from "./components/BottomSheetMenu";
import { useCallback } from "react";

const ModificationMenu = ({ itinerary }: { itinerary: IReservationBase }) => {
  const device = useDeviceBreakpoints();

  const renderMenu = useCallback(() => {
    return device.isMobile || device.isSmallMobile ? (
      <BottomSheetMenu itinerary={itinerary} />
    ) : (
      <SideSheetMenu itinerary={itinerary} />
    );
  }, [device, itinerary]);

  return renderMenu();
};

export default ModificationMenu;
