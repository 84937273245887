import * as Styled from "../styles";
import { Typography, Icon, Box } from "@hotelengine/atlas-web";
import { useMemo, useState } from "react";
import type { ICollapsibleOption } from "../types";
import { useDeviceBreakpoints } from "pages/Checkout/Lodging/hooks/useDeviceBreakpoints";

const CollapsibleOptionItem = ({ option }: { option: ICollapsibleOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const device = useDeviceBreakpoints();

  const deviceVariant = useMemo(() => {
    if (device.isSmallMobile) {
      return { title: "body/sm-strong", description: "body/xs", button: "sm" } as const;
    }
    if (device.isMobile) {
      return { title: "body/sm-strong", description: "body/xs", button: "sm" } as const;
    }
    return { title: "body/sm-strong", description: "body/xs", button: "sm" } as const;
  }, [device]);

  return (
    <Styled.BoxedSelector
      onClick={() => setIsOpen(!isOpen)}
      data-testid="collapsible-option-item"
      $isMobile={device.isMobile}
      $isSmallMobile={device.isSmallMobile}
    >
      <Styled.OptionContent display="flex" flexDirection="row" alignItems="center">
        <Styled.BoxedSelectorIconContainer
          $bgColor={option.iconColor}
          $isMobile={device.isMobile}
          $isSmallMobile={device.isSmallMobile}
        >
          <Icon name={option.icon} size="lg" color="foregroundInverse" />
        </Styled.BoxedSelectorIconContainer>
        <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Typography variant={deviceVariant.title}>{option.title}</Typography>
          <Typography variant={deviceVariant.description} color="foregroundSecondary">
            {option.description}
          </Typography>
        </Box>
        <Icon name={isOpen ? "chevron-up" : "chevron-down"} size={deviceVariant.button} />
      </Styled.OptionContent>

      {!!isOpen && !!option.content && (
        <Styled.CollapsibleContent
          data-testid="collapsible-content"
          $isMobile={device.isMobile}
          $isSmallMobile={device.isSmallMobile}
        >
          {option.content}
        </Styled.CollapsibleContent>
      )}
    </Styled.BoxedSelector>
  );
};

export default CollapsibleOptionItem;
