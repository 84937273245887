import styled from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";

export const RoomDetails = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing[16]};
  padding-left: ${({ theme }) => theme.spacing[24]};
  padding-right: ${({ theme }) => theme.spacing[24]};
  `;

export const DetailsList = styled.div<{ $isLast?: boolean; $isOnly?: boolean }>`
  ${flexbox({
    direction: "column",
  })}

  padding-bottom: ${({ theme, $isLast, $isOnly }) => theme.spacing[$isOnly ? 0 : $isLast ? 0 : 40]};
  gap: ${({ theme }) => theme.spacing[12]};
`;

export const ListItem = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: ${({ theme }) => theme.spacing[8]};
`;

export const ShowRoomsContainer = styled.div`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacing[24]};
`;
