import { z } from "zod";

import { type CarBooking, carBookingSchema } from "./car";
import { type FlightBooking, flightBookingSchema } from "./flight";
import { type LodgingBooking, lodgingBookingSchema } from "./lodging";

export type AnyBooking = CarBooking | FlightBooking | LodgingBooking;
export type Booking = z.infer<typeof bookingSchema>;
export type TripData = z.infer<typeof tripSchema>;
export type TripsResult = z.infer<typeof tripsSchema>;

/**
 * Discriminated union of different booking types using 'bookingType' as the discriminator.
 * This allows TypeScript to narrow down the specific booking type based on the 'bookingType' field,
 * providing type-safe access to booking-specific properties.
 *
 * Example:
 * ```ts
 * // Direct type narrowing using discriminator
 * if (booking.bookingType === 'flight') {
 *   // TypeScript knows this is a FlightBooking
 *   booking.details.flightConfirmation
 * }
 *
 * // Using assertion utility
 * assertBookingType(booking, 'flight');
 * booking.details.flightConfirmation; // TypeScript knows this is a FlightBooking
 *
 * // Using type guard utility
 * if (isBookingType(booking, 'flight')) {
 *   // TypeScript knows this is a FlightBooking
 *   booking.details.flightConfirmation;
 * }
 * ```
 */
export const bookingSchema = z.discriminatedUnion("bookingType", [
  carBookingSchema,
  flightBookingSchema,
  lodgingBookingSchema,
]);

export const tripSchema = z.object({
  id: z.number(),
  tripNumber: z.string(),
  businessId: z.number(),
  isFullyCancelled: z.boolean(),
  bookings: z.array(bookingSchema),
});

export const tripsSchema = z.object({
  limit: z.coerce.number(),
  offset: z.coerce.number(),
  mostExpensiveTrip: z.coerce.number(),
  leastExpensiveTrip: z.coerce.number(),
  totalToday: z.coerce.number(),
  totalCancelled: z.number(),
  totalUpcoming: z.number(),
  trips: z.array(tripSchema),
});
