import styled from "styled-components";
import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { Button, IconButton } from "@hotelengine/atlas-web";

export const MapWrapper = styled.div`
  ${flexbox({
    alignItems: "center",
    justifyContent: "center",
  })};
  flex: 1;
  height: auto;
  min-height: 100%;
  position: relative;
`;

export const Map = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.backdropAbove};
`;

export const MapErrorMessage = styled.div`
  padding-top: 15vh;
  text-align: center;

  @media screen and (min-width: 960px) {
    padding-top: 35vh;
  }
`;

export const GoogleMapControlButtonRebrand = styled(Button).attrs({
  size: "sm",
  color: "everlight",
})`
  pointer-events: auto;
  width: 89px;
`;

export const GoogleMapControlIconButtonRebrand = styled(IconButton).attrs({
  size: "sm",
  color: "everlight",
})`
  pointer-events: auto;
  width: 32px;
`;

export const ZoomControl = styled.div`
  ${flexbox({
    direction: "column",
  })};

  button {
    &:first-of-type {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:last-of-type {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }
`;
