import type { TextInputProps } from "@hotelengine/atlas-web";
import { InputIconButtonAdornment, TextInput } from "@hotelengine/atlas-web";
import { useFormikContext } from "formik";
import useTripsControl from "pages/Trips/hooks/useTripsControl";
import type { ITripsSearch } from "../..";
import { useTranslation } from "react-i18next";

const placeholderMap = {
  traveler: "searchInput.traveler",
  location: "searchInput.location",
  confirmation_number: "searchInput.confirmation_number",
  all: "searchInput.all",
  booker: "searchInput.booker",
};

export interface ISearchInputProps {
  values: ITripsSearch;
  isOnColor: boolean;
  isLoading: boolean;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  isMobile: boolean;
  size?: TextInputProps["size"];
}

const SearchInput = ({
  values,
  isOnColor,
  isLoading,
  handleOnChange,
  handleSubmit,
  isMobile,
  size,
}: ISearchInputProps) => {
  const { t } = useTranslation("trips");
  const [params, tripsControl] = useTripsControl();
  const { setFieldValue } = useFormikContext<ITripsSearch>();

  const handleClearSearch = () => {
    setFieldValue("search", "");

    if (params.search) {
      tripsControl.unsetParams("search", "search_facet");
    }
  };

  const placeholder = t(placeholderMap[values.search_facet as string]) || t("searchInput.all");

  return (
    <TextInput
      id="searchTrips"
      aria-label="Search trips"
      placeholder={placeholder}
      value={values.search}
      isOnColor={!isOnColor}
      isDisabled={isLoading}
      onChange={handleOnChange}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleSubmit();
        }
      }}
      style={{ width: isMobile ? "100%" : 336 }}
      size={size || "md"}
      enterKeyHint="search"
      trailingAdornment={
        !!(values.search || params.search) ? (
          <InputIconButtonAdornment
            icon="circle-xmark"
            aria-label="clear search"
            onClick={handleClearSearch}
          />
        ) : undefined
      }
    />
  );
};

export default SearchInput;
