import styled from "styled-components";
import type { BoxProps } from "@hotelengine/atlas-web";
import { Box } from "@hotelengine/atlas-web";

export const FooterBox = styled<React.FC<BoxProps>>(Box)`
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto;

  .cookieButton {
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;
