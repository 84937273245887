import type { PayloadAction } from "@reduxjs/toolkit";

import {
  buildResultAvailableAmenities,
  setAvailableAmenities as setAvailableAmenitiesHelper,
} from "@hotel-engine/helpers/search/results/buildAvailableAmenities";

import { buildFilteredResults } from "@hotel-engine/helpers/search/results/buildFilteredResults";
import type { IFilterOptions } from "@hotel-engine/helpers/search/results/buildFilteredResults/helpers";
import type { ISearchRatesServiceOutput } from "@hotel-engine/types/search";
import type { SelectedFilter } from "store/Search/SearchFilters/types";
import {
  pickDecoratedSpotlightPropertyId,
  setSearchDetails,
  sortMethodMap,
  updateStreamResult,
} from "store/Search/SearchResults/helpers";
import type { ISearchResultsState } from "store/Search/SearchResults/types";
import { SortOptions } from "store/Search/constants";

export const setStreamLoading = (state: ISearchResultsState, action: PayloadAction<boolean>) => {
  state.streamLoading = action.payload;
};

export const setSpecificSearchLoading = (
  state: ISearchResultsState,
  action: PayloadAction<boolean>
) => {
  state.specificSearchLoading = action.payload;
};

export const setResultsExpired = (state: ISearchResultsState) => {
  Object.values(state.results).forEach((result) => {
    result.expired = true;
  });
};

export const removeExpiredResults = (state: ISearchResultsState) => {
  const { results, visibleHiddenResultIds, visibleResultIds } = state;
  const visibleHiddenResultIdsSet = new Set(visibleHiddenResultIds);
  const visibleResultIdsSet = new Set(visibleResultIds);

  Object.keys(results).forEach((resultId) => {
    if (!results[resultId].expired) return;

    const numericResultId = Number(resultId);
    delete results[resultId];
    visibleHiddenResultIdsSet.delete(numericResultId);
    visibleResultIdsSet.delete(numericResultId);
  });

  state.visibleHiddenResultIds = Array.from(visibleHiddenResultIdsSet);
  state.visibleResultIds = Array.from(visibleResultIdsSet);
};

export const setAvailableAmenities = (state: ISearchResultsState) => {
  setAvailableAmenitiesHelper(state.results);
};

export const setStreamResults = (
  state: ISearchResultsState,
  action: PayloadAction<ISearchRatesServiceOutput>
) => {
  const { results: currentResults, ...existingSearch } = state;
  const { results: newResults, ...newSearch } = action.payload;

  const propertyIdsSpotlightSet = new Set(existingSearch.propertyIdsSpotlight);
  const visibleResultIdsSet = new Set(existingSearch.visibleResultIds);
  const visibleHiddenResultIdsSet = new Set(existingSearch.visibleHiddenResultIds);
  const mergedPropertyIdsPreferredSet = new Set([
    ...existingSearch.propertyIdsPreferred,
    ...newSearch.propertyIdsPreferred,
  ]);
  const mergedPropertyIdsHiddenSet = new Set([
    ...existingSearch.propertyIdsHidden,
    ...newSearch.propertyIdsHidden,
  ]);

  newResults.forEach((newResult) => {
    updateStreamResult(
      currentResults,
      mergedPropertyIdsPreferredSet,
      mergedPropertyIdsHiddenSet,
      propertyIdsSpotlightSet,
      visibleHiddenResultIdsSet,
      visibleResultIdsSet,
      newResult
    );
  });

  setSearchDetails(state, action.payload);

  state.visibleResultIds = Array.from(visibleResultIdsSet);
  state.visibleHiddenResultIds = Array.from(visibleHiddenResultIdsSet);
  state.propertyIdsHidden = Array.from(mergedPropertyIdsHiddenSet);
  state.propertyIdsPreferred = Array.from(mergedPropertyIdsPreferredSet);
  state.propertyIdsSpotlight = Array.from(propertyIdsSpotlightSet);
  if (newSearch.refreshContinuationToken) {
    state.refreshContinuationTokens = [
      ...new Set([...state.refreshContinuationTokens, newSearch.refreshContinuationToken]),
    ];
  }
};

export const updatePropertyFavoriteId = (
  state: ISearchResultsState,
  action: PayloadAction<{
    propertyId: number;
    favoriteId: number | null;
  }>
) => {
  const { propertyId, favoriteId } = action.payload;

  if (state.specificResult?.propertyId === propertyId) {
    state.specificResult.favoriteId = favoriteId;
  }

  const property = state.results[propertyId];
  if (!property) return;

  property.favoriteId = favoriteId;
};

export const setSpecificSearchResult = (
  state: ISearchResultsState,
  action: PayloadAction<ISearchRatesServiceOutput>
) => {
  const { results, id, ...specificSearch } = action.payload;
  const specificResult = results[0];

  /**
   * Specific searches have their own propertyIdsHidden and propertyIdsPreferred.
   * We need to merge them together.
   */
  const mergedPropertyIdsHiddenSet = new Set([
    ...specificSearch.propertyIdsHidden,
    ...state.propertyIdsHidden,
  ]);
  const mergedPropertyIdsPreferredSet = new Set([
    ...specificSearch.propertyIdsPreferred,
    ...state.propertyIdsPreferred,
  ]);

  state.specificSearchId = id;
  state.specificResult = {
    ...specificResult,
    hidden: mergedPropertyIdsHiddenSet.has(specificResult.propertyId),
    preferred: mergedPropertyIdsPreferredSet.has(specificResult.propertyId),
    expired: false,
    availableAmenities: buildResultAvailableAmenities(specificResult),
    outOfPolicyReasons: specificSearch.outOfPolicyReasons || [],
  };
  state.propertyIdsHidden = Array.from(mergedPropertyIdsHiddenSet);
  state.propertyIdsPreferred = Array.from(mergedPropertyIdsPreferredSet);
  if (specificSearch.refreshContinuationToken) {
    state.refreshContinuationTokens = [
      ...new Set([...state.refreshContinuationTokens, specificSearch.refreshContinuationToken]),
    ];
  }
};

export const filterResults = (
  state: ISearchResultsState,
  action: PayloadAction<{
    selectedFilters: SelectedFilter[];
    options?: IFilterOptions;
  }>
) => {
  const { selectedFilters, options } = action.payload;
  const { visibleResultIds, visibleHiddenResultIds } = buildFilteredResults({
    results: state.results,
    selectedFilters,
    options,
  });
  state.visibleResultIds = visibleResultIds;
  state.visibleHiddenResultIds = visibleHiddenResultIds;
};

export const sortResults = (
  state: ISearchResultsState,
  action: PayloadAction<SortOptions | unknown>
) => {
  const sortOption = Object.values(SortOptions).includes(action.payload as SortOptions)
    ? (action.payload as SortOptions)
    : SortOptions.Recommended;

  const validResultIds = state.visibleResultIds;

  const pickSortMethod = sortMethodMap[sortOption];

  state.visibleResultIds = pickSortMethod({
    ids: validResultIds,
    results: state.results,
    decoratedSpotlightPropertyId: state.decoratedSpotlightPropertyId,
  });

  state.visibleHiddenResultIds = pickSortMethod({
    ids: state.visibleHiddenResultIds,
    results: state.results,
    decoratedSpotlightPropertyId: state.decoratedSpotlightPropertyId,
  });
};

export const selectDecoratedSpotlightResult = (state: ISearchResultsState) => {
  const { propertyIdsSpotlight, specificResult, visibleHiddenResultIds, visibleResultIds } = state;
  const mutablePropertyIdsSpotlight = [...propertyIdsSpotlight];
  const visibleResultIdsSet = new Set(visibleResultIds);
  const visibleHiddenResultIdsSet = new Set(visibleHiddenResultIds);

  const validSpotlightPropertyIds = mutablePropertyIdsSpotlight.filter(
    (id) => !visibleHiddenResultIdsSet.has(id) && visibleResultIdsSet.has(id)
  );

  const decoratedSpotlightPropertyId = pickDecoratedSpotlightPropertyId({
    propertyIdsSpotlight: validSpotlightPropertyIds,
    specificPropertyId: specificResult?.propertyId,
  });

  state.decoratedSpotlightPropertyId = decoratedSpotlightPropertyId;
};

export const setStreamError = (state: ISearchResultsState, action: PayloadAction<boolean>) => {
  state.streamError = action.payload;
};

export const setSpecificSearchError = (
  state: ISearchResultsState,
  action: PayloadAction<boolean>
) => {
  state.specificSearchError = action.payload;
};

export const setRateRefreshFailed = (
  state: ISearchResultsState,
  action: PayloadAction<string | null>
) => {
  state.rateRefreshFailed = action.payload;
};

export const setRateRefreshLoading = (
  state: ISearchResultsState,
  action: PayloadAction<boolean>
) => {
  state.rateRefreshLoading = action.payload;
};

export const setRateRefreshSuccess = (
  state: ISearchResultsState,
  action: PayloadAction<boolean>
) => {
  state.rateRefreshSuccess = action.payload;
};

export const toggleIsMapSearch = (state: ISearchResultsState, action: PayloadAction<boolean>) => {
  state.isMapSearch = action.payload;
};

export const setSessionId = (state: ISearchResultsState, action: PayloadAction<string>) => {
  state.sessionId = action.payload;
};

export const clearRefreshContinuationTokens = (state: ISearchResultsState) => {
  state.refreshContinuationTokens = [];
};
