import { Box, Typography } from "@hotelengine/atlas-web";
import { useDeviceBreakpoints } from "pages/Checkout/Lodging/hooks/useDeviceBreakpoints";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import * as Styled from "../styles";

const ModificationsHeader = () => {
  const { t } = useTranslation("itinerary");
  const device = useDeviceBreakpoints();

  const deviceVariant = useMemo(() => {
    if (device.isSmallMobile) {
      return {
        header: "body/md-strong",
        headerLight: "body/md-light",
        subheader: "body/sm-light",
      } as const;
    }
    if (device.isMobile) {
      return { header: "body/lg", headerLight: "body/lg-light", subheader: "body/sm" } as const;
    }
    return { header: "body/xl", headerLight: "body/xl-light", subheader: "body/md" } as const;
  }, [device]);

  return (
    <Box>
      <Styled.ModificationsHeader>
        <Typography variant={deviceVariant.header} display="flex" color="foregroundPrimary">
          {t("modifications.header")}
        </Typography>
        <Typography variant={deviceVariant.headerLight} display="flex" color="foregroundPrimary">
          {t("modifications.headerSpan")}
        </Typography>
      </Styled.ModificationsHeader>
      <Typography variant={deviceVariant.subheader} color="foregroundPrimary">
        {t("modifications.subheader")}
      </Typography>
    </Box>
  );
};

export default ModificationsHeader;
