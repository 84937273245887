import * as Styled from "../styles";
import { Typography, Icon, Box } from "@hotelengine/atlas-web";
import type { IActionOption } from "../types";
import { useDeviceBreakpoints } from "pages/Checkout/Lodging/hooks/useDeviceBreakpoints";
import { useMemo } from "react";

const LinkOptionItem = ({ option }: { option: IActionOption }) => {
  const device = useDeviceBreakpoints();

  const deviceVariant = useMemo(() => {
    if (device.isSmallMobile) {
      return { title: "body/sm-strong", description: "body/xs", button: "sm" } as const;
    }
    if (device.isMobile) {
      return { title: "body/sm-strong", description: "body/xs", button: "sm" } as const;
    }
    return { title: "body/sm-strong", description: "body/xs", button: "sm" } as const;
  }, [device]);

  return (
    <Styled.BoxedSelector
      data-testid="link-option-item"
      onClick={option.action}
      $isMobile={device.isMobile}
      $isSmallMobile={device.isSmallMobile}
    >
      <Styled.OptionContent>
        <Styled.BoxedSelectorIconContainer
          $bgColor={option.iconColor}
          $isMobile={device.isMobile}
          $isSmallMobile={device.isSmallMobile}
        >
          <Icon name={option.icon} size="lg" color="foregroundInverse" />
        </Styled.BoxedSelectorIconContainer>
        <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Typography variant={deviceVariant.title}>{option.title}</Typography>
          <Typography variant={deviceVariant.description} color="foregroundSecondary">
            {option.description}
          </Typography>
        </Box>
        <Icon name="chevron-right" size={deviceVariant.button} />
      </Styled.OptionContent>
    </Styled.BoxedSelector>
  );
};

export default LinkOptionItem;
