import { useAppSelector } from "store/hooks";
import { CreditClient } from "@hotel-engine/clients";
import type { ICreditData } from "@hotel-engine/clients/CreditClient/types";

export const useCreditClient = (): CreditClient => {
  const userBusiness = useAppSelector((state) => state.Auth.user?.business);

  const creditData: ICreditData = {
    availableCredit: userBusiness?.availableCredit ?? null,
    balance: userBusiness?.balance ?? null,
    creditLimit: userBusiness?.creditLimit ?? null,
    directBillAchEnabled: userBusiness?.directBillAchEnabled ?? false,
    directBillCreditCardEnabled: userBusiness?.directBillCreditCardEnabled ?? false,
    directBillStatus: userBusiness?.directBillStatus ?? null,
    directBillEnabledAt: userBusiness?.directBillEnabledAt ?? null,
    directBillPromotionEnabled: userBusiness?.directBillPromotionEnabled ?? false,
    directBillUserDefault: userBusiness?.directBillUserDefault ?? false,
    directBillVerificationRequired: userBusiness?.directBillVerificationRequired ?? false,
    hasInvoices: userBusiness?.hasInvoices ?? false,
  };

  return new CreditClient(creditData);
};
