import styled, { css } from "styled-components";

import { flexbox } from "@hotel-engine/styles/helpers/mixins";
import { Loader } from "@hotel-engine/components/Loader";

export const ViewWrapper = styled.div`
  ${flexbox({
    direction: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  })};
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
`;

export const Trips = styled.div<{ $mapView: boolean; $showPreview: boolean }>`
  border-right: ${({ theme, $showPreview }) =>
    $showPreview ? `${theme.borderWidth.normal} solid ${theme.colors.neutralN200}` : `none`};
  position: relative;
  width: 100%;
  height: 100%;

  ${({ $mapView }) =>
    $mapView &&
    `
    max-height: 100vh;
    overflow: hidden;
  `};

  ${({ $showPreview }) => css`
        max-width: ${$showPreview ? ` calc(100% - 450px)` : `100%`};
  `}
`;

export const Inner = styled.div`
  padding: ${({ theme }) => `${theme.spacing[0]} ${theme.spacing[16]} ${theme.spacing[16]}`};

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.md}) {
      padding: ${theme.spacing[0]} ${theme.spacing[40]} ${theme.spacing[40]};
    }
  `}
`;

export const UpperContainer = styled.div<{ $isMobile: boolean }>`
  padding: ${({ theme }) => `${theme.spacing[16]} ${theme.spacing[16]} ${theme.spacing[16]}`};

  background-color: ${({ theme, $isMobile }) =>
    $isMobile ? theme.colors.backgroundPrimary : theme.colors.backgroundSecondary};

  ${({ theme, $isMobile }) =>
    !$isMobile &&
    css`
      padding: ${theme.spacing[40]} ${theme.spacing[40]} ${theme.spacing[0]};
`}

`;

export const Header = styled.div`
  ${flexbox({
    alignItems: "center",
    justifyContent: "space-between",
  })}
    margin: ${({ theme }) => theme.spacing[0]};
    margin-bottom: ${({ theme }) => theme.spacing[12]};

`;

export const BigLoader = styled(Loader)`
  svg {
    transform: scale(3);
  }
`;
