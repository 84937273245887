import { useTheme } from "styled-components";

import { routes } from "@hotel-engine/constants";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";

import * as Styled from "./styles";
import { Box, Typography } from "@hotelengine/atlas-web";
import CopiableConfirmation from "../../../shared/CopiableConfirmation";
import TopActions from "../ItineraryActions/components/TopActions";

const PropertyDetails = () => {
  const { typography, colors } = useTheme();
  const { reservation } = useModificationsContext();
  const navigate = useNavigate();

  if (!reservation) return null;

  const confirmationNumber = reservation.propertyConfirmationNumber;

  const canNavigate = !!reservation.propertyId;

  const goToProperty = (e: React.MouseEvent) => {
    if (!canNavigate) {
      e.preventDefault();
      return;
    }

    navigate({
      pathname: `${routes.properties}/${reservation.propertyId}`,
    });
  };

  const formattedAddress = [
    reservation.propertyStreet,
    reservation.propertyCity,
    reservation.propertyState,
    reservation.propertyPostalCode,
  ]
    .filter((entry) => !!entry)
    .join(", ");

  return (
    <>
      <Styled.PropertyContainer>
        <Box>
          <Styled.PropertyName
            to={routes.properties + "/" + reservation.propertyId}
            style={{
              ...typography.desktop["heading/xl"],
              color: colors.foregroundPrimary,
            }}
            data-testid="property-name"
          >
            {reservation.propertyName}
          </Styled.PropertyName>
        </Box>
        <Box marginBottom={8}>
          <Typography color="foregroundPrimary" variant="body/sm">
            {formattedAddress}
          </Typography>
        </Box>
        <Box marginBottom={8}>
          <TopActions itinerary={reservation} />
        </Box>
        <Box
          as="a"
          data-testid="property-image"
          style={{ cursor: canNavigate ? "pointer" : "default" }}
          onClick={goToProperty}
        >
          <Styled.PropertyImage alt={reservation.propertyName} src={reservation.propertyPhotoUrl} />
        </Box>
      </Styled.PropertyContainer>
      {reservation.roomCount === 1 && !!confirmationNumber && (
        <Box paddingTop={24}>
          <CopiableConfirmation
            confirmationNumber={confirmationNumber}
            bookingNumber={reservation.id}
          />
        </Box>
      )}
    </>
  );
};

export default PropertyDetails;
