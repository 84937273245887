import type { ReactNode } from "react";
import { useEffect } from "react";
import type {
  GetTokenSilentlyOptions,
  LogoutOptions,
  RedirectLoginOptions,
} from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addAuthEventListener,
  EVENT_TYPES,
  listenForAuthStatusChanges,
  removeAuthEventListener,
  removeAuthStatusChangeListener,
} from "@hotel-engine/utilities/auth/events";
import { Auth } from "@hotel-engine/services";

import { RouteLoadingUI } from "../../../RouteLoadingUI";
import { useGetAccessTokenSilently } from "@hotel-engine/utilities/auth";
import { getRedirectMatches } from "@hotel-engine/utilities/helpers/getRedirectMatches";

interface IAuth0ContextConsumerProps {
  /** React children */
  children: ReactNode;
}

export const handleLoginEvent = () => {
  const isAuth0Callback = location.search.includes("code=");
  if (isAuth0Callback) return; // used to prevent double redirects
  // refresh page to get new user data
  const matches = getRedirectMatches();
  if (matches) {
    Auth.redirect();
  } else {
    globalThis.location.reload();
  }
};

export const handleLogoutEvent = async () => {
  await Auth.signOut("handleLogoutEvent", false);
};

export const Auth0ContextConsumer = ({ children }: IAuth0ContextConsumerProps) => {
  const { logout, isAuthenticated, isLoading } = useAuth0();
  const getToken = useGetAccessTokenSilently();

  useEffect(() => {
    if (!isLoading) {
      // override Auth0's global function to get the user's access token
      // we only want to do this once - right after the user's authentication state is confirmed
      globalThis.getAccessTokenSilently = (
        options?: GetTokenSilentlyOptions,
        redirectOptions?: RedirectLoginOptions
      ) => getToken(options, redirectOptions);

      globalThis.auth0Logout = async (opts?: LogoutOptions | undefined): Promise<void> => {
        if (isAuthenticated) {
          await logout(opts);
        }
      };
    }
  }, [logout, isAuthenticated, isLoading, getToken]);

  useEffect(() => {
    addAuthEventListener(EVENT_TYPES.signIn, handleLoginEvent);
    addAuthEventListener(EVENT_TYPES.signOut, handleLogoutEvent);
    listenForAuthStatusChanges();

    return () => {
      removeAuthEventListener(EVENT_TYPES.signIn, handleLoginEvent);
      removeAuthEventListener(EVENT_TYPES.signOut, handleLogoutEvent);
      removeAuthStatusChangeListener();
    };
  }, []);

  return isLoading ? <RouteLoadingUI /> : <>{children}</>;
};

export default Auth0ContextConsumer;
