/**
 * @deprecated
 */
export const cacheKeys = {
  useBookingsQuery: "useBookingsQuery",
  useBusinessStatsQuery: "useBusinessStatsQuery",
  usePostCheckoutActions: "useCheckoutVerificationQuery",
  useCustomFieldsQuery: "useCustomFieldsQuery",
  useDepartmentsQuery: "useDepartmentsQuery",
  useFavoriteHotelsQuery: "useFavoriteHotelsQuery",
  useMembersQuery: "useMembersQuery",
  useMemberDetailsQuery: "useMemberDetailsQuery",
  useGuestDetailsQuery: "useGuestDetailsQuery",
  useUserDetailsQuery: "useUserDetailsQuery",
  useMembersQueryLegacy: "useMembersQueryLegacy",
  useSpendingQuery: "useSpendingQuery",
  useTravelCreditsHistoryCsv: "useTravelCreditsHistoryCsv",
  useTravelCreditsHistoryTotalsCsv: "useTravelCreditsHistoryTotalsCsv",
  useTravelCreditsQuery: "useTravelCreditsQuery",
  useTravelPoliciesQuery: "useTravelPoliciesQuery",
  useUserActivationsQuery: "useUserActivationsQuery",
  useUserTravelCreditsHistoryCsv: "useUserTravelCreditsHistoryCsv",
  useUserTravelCreditsQuery: "useUserTravelCreditsQuery",
  useLastThreeBookedQuery: "useLastThreeBookedQuery",
  useMembersDownloadCsv: "useMembersDownloadCsv",
  useSupportCenterQuery: "useSupportCenterQuery",
  useEmailDomainsQuery: "useEmailDomainsQuery",
  useRiskyEmailDomains: "useRiskyEmailDomains",
};

/**  Constants for url paths: api/v2/{endpoint} */
export enum endpoints {
  achPaymentProfiles = "ach_payment_profiles",
  authStrategies = "auth_strategies",
  autopayMethods = "autopay_methods",
  autoCompleteFavoriteProperties = "autocomplete/favorite_properties",
  bestOffer = "best_offer",
  bestOffers = "/api/v1/best_offers",
  bestOffersRefresh = "best_offers/refresh",
  bookings = "bookings",
  bulkbusinessNotificationSettings = "bulk/businesses/notification_settings",
  bulkMembers = "bulk/members",
  bulkMembersImport = "businesses/members_import",
  bulkReferrals = "bulk/referrals",
  bulkUserNotificationSettings = "bulk/users/notification_settings",
  bulkWorksite = "bulk/worksite",
  business = "business",
  businessCarDiscountCodes = "businesses/{business_id}/car_discount_codes",
  businessNotificationSettings = "businesses/notification_settings",
  businesses = "businesses",
  businessesActivity = "businesses/activity",
  businessesBulkUploadTemplate = "businesses/members_import/csv_template",
  businessStats = "statistics",
  businessICPQuestions = "businesses/{business_id}/icp_questions",
  calendarTrips = "reservations/calendar_itineraries",
  cardTransactions = "card_transactions",
  checkoutVerification = "unprotected/checkout_verifications",
  preferredProperties = "preferred_properties",
  preferredPropertiesBusinessSummary = "preferred_properties/business_summary",
  carBookings = "car_bookings",
  carsRecentSearches = "searches/recent",
  carts = "carts",
  contracts = "contracts",
  contractRates = "contract_rates",
  contractsCheckIn = "contracts_check_in",
  contractsCheckOut = "contracts_checkout",
  contractsActiveTrips = "contracts_active_trips",
  customFields = "custom_fields",
  departments = "departments",
  directBillApplicationRequest = "salesforce/direct_bill_requests",
  directBill = "direct_bill",
  directBillSummary = "direct_bill_summary",
  directBillOnboardings = "direct_bill_onboardings",
  directBillPayments = "direct_bill/payments",
  emailConfirmations = "users/email_confirmations",
  emailCustomization = "email_customization",
  emailDomains = "businesses/domains",
  eventAnalytics = "campaign_interactions/notify",
  favoriteProperties = "favorite_properties",
  favoritePropertiesBusinessSummary = "favorite_properties/business_summary",
  flexProPricing = "flex_pro/pricing",
  flexProSubscriptionsResubscribe = "flex_pro/subscriptions/resubscribe",
  flexProSubscriptions = "flex_pro/subscriptions",
  flexProSubscriptionsCancel = "flex_pro/subscriptions/cancel",
  flexProSubscriptionsTransactions = "flex_pro/subscriptions/transactions",
  flexConfiguration = "flight_configuration",
  flightBookings = "flight_bookings",
  flightLocationSuggestions = "places/suggestions",
  flightFare = "fares",
  flightSearches = "searches",
  flightSearchesContinue = "searches/continue",
  flightRecentSearches = "searches/recent",
  guests = "guests",
  guestsReport = "guests/all_guests_report",
  groupBookingRequest = "salesforce/orders",
  groupRateQuote = "group_rate_quote",
  groups = "groups",
  hiddenProperties = "hidden_properties",
  invoices = "invoices",
  insuranceQuotes = "car_insurance_policies/quotes",
  insuranceOptOut = "car_insurance_policies/{policyId}/optouts",
  billingInvoices = "billing/invoices",
  billingInvoicesSearchReport = "billing/invoices/search_report",
  locations = "locations",
  locationAreas = "locations/areas",
  locationFromCoords = "locations/from_coords",
  locationHotels = "locations/hotels",
  lodgingConfig = "businesses/{business_id}/lodging_configuration",
  loyaltyRewards = "loyalty_rewards",
  members = "members",
  membersResendInvite = "members/{id}/resend_invitation",
  mergeHrisIntegrations = "businesses/merge_hris_integrations",
  mergeLinkToken = "merge/link_token",
  mergeMetadata = "merge/integrations_metadata",
  onboardingEvents = "onboarding_events",
  orders = "groups/special_booking_requests",
  pageCustomizationsBusinesses = "page_customizations/businesses",
  pageCustomizationsOrganizations = "page_customizations/organizations",
  password = "password",
  paymentProfiles = "payment_profiles",
  pendingInvoiceItems = "pending_invoice_items",
  personalUsers = "personal_users",
  overlappingContracts = "users/overlapping_contracts",
  partnerOffersBF = "/partners/builtfirst",
  partnerOffersBFAcceptTerms = "/partners/builtfirst/accept_terms",
  plaidToken = "plaid/link_token",
  plaidUpdateToken = "plaid/refresh_link_token",
  productMarketingFeatures = "product_marketing_features",
  properties = "properties",
  propertyReviews = "property_reviews",
  propertyReviewsBusinessSummary = "property_reviews/business_summary",
  propertyReviewsBusinessSummaryById = "properties/{property_id}/property_reviews/business_summary",
  propertyReviewsByAllUsersOfCompany = "properties/{property_id}/property_reviews",
  propertyReviewInfo = "property_review_requests",
  redirectsFastLane = "/internal/redirects/fast_lane",
  redirectsFastLane2 = "/internal/redirects/fast_lane_2",
  redirectsGroups = "/internal/redirects/groups",
  redirectsAuth0 = "/internal/redirects/auth0",
  rewardsActivity = "rewards_activity",
  rewardPointsActivity = "reward_points/activity",
  referrals = "referrals",
  referralsTotals = "referrals/totals",
  referralsValidation = "referrals/validation",
  referralsFirstLogin = "referrals/incoming",
  referralsCreditAmounts = "referrals/credit_amounts_configurations",
  referralBonus = "user_referral_rewards",
  referralBonusDashboard = "user_referral_rewards/referral_dashboard",
  referralBonusHome = "user_referral_rewards/home",
  requestRate = "rate_partner_requests",
  reservations = "reservations",
  carInsurancePolicies = "car_insurance_policies",
  riskyEmailDomains = "risky_domains",
  reverseGeocode = "reverse_geocode",
  roomOffers = "room_offers",
  rooms = "rooms",
  salesforce = "salesforce",
  salesforceCase = "salesforce/cases",
  salesforceCaseMultiRoomsCreate = "salesforce/cases/multi_rooms_create",
  salesforceCaseAdminRoleRequestCreate = "salesforce/cases/admin_role_request",
  salesforceLeads = "salesforce/leads",
  search = "search",
  searches = "searches",
  searchRefresh = "search/refresh",
  sessions = "sessions",
  skipPostCheckoutActions = "post_checkout_actions/dismissals",
  sourcing = "groups/sourcing/special_booking_requests",
  sourcingProposals = "groups/sourcing",
  specialBookingRequest = "special_booking_requests",
  spending = "spending",
  spendPolicies = "policies",
  spendPolicy = "policy",
  ruleTypes = "rule-types",
  surveySubmissions = "survey_submissions",
  surveys = "surveys",
  summaryMetrics = "trends/summary_metrics",
  supportCenter = "support_center",
  textMessages = "text_messages",
  timezone = "timezone",
  travelCredits = "travel_credits",
  travelCreditsHistory = "travel_credits.csv",
  travelCreditsHistoryTotals = "users/travel_credit_totals.csv",
  travelPolicies = "travel_policies",
  trendsAdr = "trends/financials/adr_by_date",
  trendsBookers = "trends/bookers/bookers_breakdown",
  trendsBookersAdr = "trends/bookers/top_bookers_by_adr",
  trendsBookersQuantity = "trends/bookers/top_bookers_by_quantity",
  trendsBookings = "trends/financials/bookings_breakdown",
  trendsTopBookersBySpend = "trends/bookers/top_bookers_by_total_spend",
  trendsTravelPolicies = "trends/travel_policies/travel_policies_breakdown",
  trendsBillboard = "trends/billboard_metrics",
  trendsCustomFields = "trends/custom_fields",
  trendsDepartmentsAdr = "trends/departments/top_departments_by_adr",
  trendsDepartmentsBreakdown = "trends/departments/departments_breakdown",
  trendsDepartmentsSpending = "trends/departments/top_departments_by_total_spend",
  trendsDepartmentsQuantity = "trends/departments/top_departments_by_quantity",
  trendsLocationsSpending = "trends/locations/top_locations_by_total_spend",
  trendsLocationsAdr = "trends/locations/top_locations_by_adr",
  trendsLocationsBreakdown = "trends/locations/locations_breakdown",
  trendsMembersTraveling = "trends/travelers/all_members_traveling",
  trendsNightsBooked = "trends/financials/nights_by_date",
  trendsScheduledReports = "trends/scheduled_reports",
  trendsSearchSuggestions = "trends/search_suggestions",
  trendsTravelersBySpending = "trends/travelers/top_travelers_by_total_spend",
  trendsTravelersBreakdown = "trends/travelers/travelers_breakdown",
  trendsTopTravelersByAdr = "trends/travelers/top_travelers_by_adr",
  trendsSpendingByDate = "trends/financials/spending_by_date",
  trendsSavingsByDate = "trends/financials/savings_by_date",
  trendsVendorsQuantity = "trends/vendors/top_vendors_by_quantity",
  trendsTopVendorsBySpend = "trends/vendors/top_vendors_by_total_spend",
  trendsVendorsBreakdown = "trends/vendors/vendors_breakdown",
  trendsVendorsByADR = "trends/vendors/top_vendors_by_adr",
  tripExtensionRequests = "trip_extension_requests",
  tripExtensionRequestsManage = "trip_extension_requests/manage",
  trips = "trips",
  twoFactorVerificationCodeRequest = "two_factor_auth/codes",
  twoFactorVerificationUnauthenticatedRequest = "two_factor_auth/verifications",
  twoFactorVerificationCodeSubmit = "two_factor_auth/verifications",
  twoFactorAuthUniqueEmails = "two_factor_auth/unique_emails",
  userTravelCreditsHistory = "user_travel_credits.csv",
  worksites = "worksites",
  userActivations = "user_activations",
  userTravelCredits = "user_travel_credits",
  user = "user",
  userActiveTokenExpiry = "user/active",
  userLoyaltyRewards = "user_loyalty_rewards",
  userNotificationSettings = "users/notification_settings",
  userEnrollRewards = "/user/enroll_rewards",
  users = "users",
  allMembersReport = "business/all_users_report",
}
