import {
  Box,
  DialogClose,
  DialogDescription,
  DialogTitle,
  Divider,
  Typography,
} from "@hotelengine/atlas-web";

import type { ICarSummaryOfCharges } from "@hotel-engine/types/itinerary";
import { PriceBreakdownField } from "pages/Checkout/shared/PriceBreakdownField";
import { useTaxesAndFees } from "./useTaxesAndFees";

export interface ITaxesAndFeesProps {
  charges: ICarSummaryOfCharges;
  formattedTaxesFees: string;
}

const TaxesAndFees = ({ charges, formattedTaxesFees }: ITaxesAndFeesProps) => {
  const { taxes, fees } = useTaxesAndFees(charges);

  return (
    <Box>
      <DialogTitle>Taxes and fees</DialogTitle>
      <DialogClose />
      <DialogDescription>
        <Box display="flex" flexDirection="column" gap={16}>
          <Typography color="foregroundPrimary" variant="body/md">
            Here is a breakdown of the estimated taxes, fees, and surcharges that apply to your
            reservation:
          </Typography>
          <Box display="flex" flexDirection="column" gap={8}>
            {[...taxes, ...fees].map((breakdown, index) => {
              return (
                <PriceBreakdownField
                  key={`breakdown.description-${index}`}
                  name={breakdown.description}
                  cost={breakdown.formattedPrice.formatted()}
                />
              );
            })}
          </Box>
          <Box paddingTop={8} paddingBottom={8}>
            <Divider variant="dotted" />
          </Box>
          <Box
            data-testid="total-taxes-fees"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            gap={8}
          >
            <Typography variant="heading/lg" color="foregroundPrimary">
              Total taxes, fees, and surcharges
            </Typography>
            <Typography variant="heading/lg" color="foregroundPrimary">
              {formattedTaxesFees}
            </Typography>
          </Box>
        </Box>
      </DialogDescription>
    </Box>
  );
};

export default TaxesAndFees;
