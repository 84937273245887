import moment from "moment-timezone";

import type { IDateFormat } from "@hotel-engine/types/business";
import type { IReservationBase } from "@hotel-engine/types/reservation";

import { formatDate } from "../formatters/formatDates";
import { getAbbreviatedTimezone } from "./getAbbreviatedTimezone";

export const getCancelDateInfo = ({
  flexEnabled,
  cancelBy,
  propertyTimezone = "UTC",
  checkIn,
  preferredDateFormat,
  flexProEnrolled,
}: Partial<IReservationBase> & {
  preferredDateFormat: IDateFormat;
  flexProEnrolled?: boolean;
}) => {
  // get formatted timezone
  const abbreviatedTimezone = flexEnabled
    ? getAbbreviatedTimezone({
        timezoneDatabaseName: propertyTimezone,
        date: checkIn,
      })
    : getAbbreviatedTimezone({
        timezoneDatabaseName: propertyTimezone,
        date: cancelBy,
      });

  // for flexpro bookings we want to display the cancel date as the latest option between
  // noon on day of checkin or the cancelby of the room
  const noonOnDayOfCheckInPropertyTimezone = moment
    .tz(checkIn, propertyTimezone)
    .set({ hour: 12, minute: 0, second: 0 });

  const cancelByIsLaterThanNoon =
    cancelBy && noonOnDayOfCheckInPropertyTimezone.isBefore(moment.tz(cancelBy, propertyTimezone));

  let cancellationTime: number | string;

  // the last time to cancel whichever is later between 12pm on the day of check in or the room's cancellation policy
  if (flexProEnrolled && cancelByIsLaterThanNoon) {
    cancellationTime = moment.tz(cancelBy, propertyTimezone).format("h:ss A");
  } else {
    cancellationTime = noonOnDayOfCheckInPropertyTimezone.format("h:ss A");
  }

  // get the date for the copy
  const formattedDate = moment.tz(cancelBy || checkIn, propertyTimezone);

  if (flexEnabled) {
    return {
      refundableString: `${cancellationTime} ${abbreviatedTimezone} on ${formatDate(
        formattedDate,
        "MMM D YYYY",
        preferredDateFormat
      )}`,
      cancelMessage: `Cancel by ${cancellationTime} ${abbreviatedTimezone} on ${formatDate(
        formattedDate,
        "MMM D YYYY",
        preferredDateFormat
      )}`,
      cancelBannerMessage: `Cancellation available before ${cancellationTime} (${abbreviatedTimezone}), ${formatDate(
        formattedDate,
        "MMM D",
        preferredDateFormat
      )}.`,
      refundPolicy: `${formatDate(
        formattedDate,
        "MMM D",
        preferredDateFormat
      )} at ${cancellationTime} ${abbreviatedTimezone}.`,
    };
  }

  const time = moment.tz(cancelBy || checkIn, propertyTimezone).format("h:mma");

  return {
    refundableString: `${time} ${abbreviatedTimezone} on ${formatDate(
      formattedDate,
      "MMM D YYYY",
      preferredDateFormat
    )}`,
    cancelByString: `${formatDate(
      moment.tz(cancelBy, propertyTimezone),
      "MMM D",
      preferredDateFormat
    )} at ${time} ${abbreviatedTimezone}`,
    cancelUntilString: formatDate(
      moment.tz(cancelBy, propertyTimezone),
      "MMM D",
      preferredDateFormat
    ),
    cancelMessage: `Refundable before ${time} (${abbreviatedTimezone}) ${formatDate(
      formattedDate,
      "MMM D",
      preferredDateFormat,
      true
    )}`,
    cancelBannerMessage: `Cancellation refundable before ${time} (${abbreviatedTimezone}), ${formatDate(
      formattedDate,
      "MMM D",
      preferredDateFormat
    )}.`,
    refundPolicy: `${formatDate(
      formattedDate,
      "MMM D",
      preferredDateFormat
    )} at ${time} ${abbreviatedTimezone}.`,
  };
};
