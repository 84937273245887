import { Button, Typography } from "@hotelengine/atlas-web";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";

import { useTrackBookingModifications } from "@hotel-engine/app/ItineraryContent/hooks/useTrackBookingModifications";
import * as Styled from "./styles";
import ModalWrapper from "../ModalWrapper";

const NavigationModal = () => {
  const {
    dispatch,
    state: { modificationView },
  } = useModificationsContext();

  const { trackAbandonModificationFlow, trackContinueModificationFlow } =
    useTrackBookingModifications();

  const abandonModification = () => {
    dispatch({ type: "ABANDON_MODIFICATION" });

    !!modificationView && trackAbandonModificationFlow(modificationView);
  };

  const continueModification = () => {
    dispatch({
      type: "SET_SHOW_NAVIGATION_MODAL",
      payload: false,
    });

    /** We need to unset any deferred navigation route so we don't have unintended side-effect if the user
     * abandons the modification in a different way. */
    dispatch({
      type: "SET_NAVIGATION_ROUTE",
      payload: undefined,
    });

    !!modificationView && trackContinueModificationFlow(modificationView);
  };

  const modalBody = (
    <>
      <Typography variant="heading/lg" color="foregroundPrimary" as="h2">
        Are you sure you want to exit?
      </Typography>
      <Typography variant="body/md" color="foregroundPrimary" as="p">
        You have not submitted your trip modifications yet.
      </Typography>
      <Styled.ButtonContainer>
        <Button color="secondary" variant="outlined" onClick={continueModification}>
          No
        </Button>
        <Button color="primary" variant="outlined" onClick={abandonModification}>
          Yes
        </Button>
      </Styled.ButtonContainer>
    </>
  );

  return (
    <ModalWrapper dataTestId="navigation-confirm-modal" onCancel={abandonModification}>
      {modalBody}
    </ModalWrapper>
  );
};

export default NavigationModal;
