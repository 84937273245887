import type { UseQueryOptions } from "react-query";
import type { AxiosError } from "axios";

import { getClient } from "@hotel-engine/client";
import { endpoints } from "@hotel-engine/react-query/constants";
import { convertToSnake, useApi } from "@hotel-engine/react-query/useApi";
import { useExtendedQuery } from "@hotel-engine/react-query/useExtendedQuery";
import type { IError } from "@hotel-engine/types/errors";
import type { IReservationBase, IReservationQueryParams } from "@hotel-engine/types/reservation";
import { isReservationV2 } from "@hotel-engine/types/reservation";
import { getAccessToken } from "@hotel-engine/utilities/auth";
import config from "config";

export const useReservationQuery = (
  params: IReservationQueryParams,
  options?: UseQueryOptions<IReservationBase, IError | AxiosError>,
  altToken?: string
) => {
  const get = useApi("get");
  return useExtendedQuery(
    [endpoints.reservations, params],
    /**
     * only rely on v2 endpoint once all of the search
     * functionality from v1's has been moved over
     * inline functions work better with typescript's inferrence.
     **/
    isReservationV2(params)
      ? async () =>
          get<IReservationBase>(`${endpoints.reservations}/${params.id}`, params, {
            headers: {
              Authorization: `Bearer ${altToken || (await getAccessToken())}`,
            },
          })
      : async () =>
          getClient(false)
            .get<IReservationBase>(`${config.apiHost}/${endpoints.reservations}`, {
              params: convertToSnake(params),
              headers: {
                Authorization: `Bearer ${altToken || (await getAccessToken())}`,
              },
            })
            .then((res) => res.data),
    options
  );
};
