import type { RumEvent, RumInitConfiguration } from "@datadog/browser-rum";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs, type LogsInitConfiguration } from "@datadog/browser-logs";
import { useDatadogInitialized } from "./DatadogInitializedProvider";

import * as Sentry from "@hotel-engine/utilities";

import { useEffect } from "react";

import config from "config";

import { useAppSelector } from "store/hooks";
import LogRocket from "logrocket";

const errorMessagesToIgnore = [
  "Object Not Found Matching Id:12, MethodName:update, ParamCount:4",
  "ResizeObserver loop completed with undelivered notifications.",
  "'eval' blocked by 'script-src' directive",
  "'inline' blocked by 'script-src' directive",
  "https://bat.bing.net",
  "https://browser-intake-datadoghq.com",
  "https://gc.kis.v2.scr.kaspersky-labs.com",
  "https://googleads.g.doubleclick.net",
  "https://localhost",
  "https://stats.g.doubleclick.net",
  "https://www.clarity.ms",
  "https://www.facebook.com/tr",
  "https://www.google.com/",
  "csp_violation",
];

const errorTypesToIgnore = [
  "img-src",
  "font-src",
  "frame-src",
  "script-src-elem",
  "manifest-src",
  "style-src-elem",
];

/**
 * Generates an array of origins based on config.  Used for connecting end-to-end traces
 * in DataDog RUM service.  Filters duplicates and bad url's
 */
const getTracingOrigins = () => {
  const extractOrigin = (url: string) => {
    try {
      return new URL(url).origin;
    } catch {
      return "";
    }
  };

  // filtering out dupes, nulls and grabbing just the origin. There's a better way to do this, but we've been burned here.
  // Do not use set until we live in that sweet sweet post-ie11 eutopia.
  const origins = [config.apiHost, config.apiHostV2, config.fssHost, config.cssHost]
    .map(extractOrigin)
    .filter((u) => !!u)
    .filter((value, index, array) => array.indexOf(value) === index);

  return origins;
};

/**
 *  This is a callback that will be called before sending the event to the DD.
 *  You can modify the event or return false to stop it from being sent.
 *  https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#before-send
 */
const beforeSend = (event: RumEvent) => {
  if (event.type === "error") {
    const ignoreErrorByMessage = errorMessagesToIgnore.some((msg) =>
      event.error.message.includes(msg)
    );
    const ignoreErrorByType = errorTypesToIgnore.some((type) => event.error.type?.includes(type));

    if (ignoreErrorByMessage || ignoreErrorByType) {
      return false;
    }
  }
  return true;
};

const datadogSharedConfiguration = {
  clientToken: config.datadogClientToken,
  env: config.stage,
  service: "members",
  /**
   * To track 100% of sessions as Browser RUM (with no Session Replays),
   * we want sessionSampleRate: 100 & sessionReplaySampleRate: 0.
   * To stop session recordings, set sessionReplaySampleRate to 0.
   */
  sessionSampleRate: 100,
  site: "datadoghq.com",
  version: config.release,
};

const datadogLogsConfiguration: LogsInitConfiguration = {
  ...datadogSharedConfiguration,
};

const dataDogRumConfiguration: RumInitConfiguration = {
  ...datadogSharedConfiguration,
  applicationId: "71436375-999d-4fe0-834d-ca230fb8e35c",
  defaultPrivacyLevel: "mask-user-input",
  /**
   * Removing marketing trackers from Browser RUM's detection of when
   * pages are finished loading, which is 100ms after the last request.
   * Since these are very chatty on purpose, it can skew metrics for page
   * load times
   */
  excludedActivityUrls: ["https://r.lr-ingest.com/i", "http://lrkt-in.com"],
  sessionReplaySampleRate: 0,
  // enables the automatic collection of user clicks in your application.
  trackUserInteractions: true,
  // A resource event is generated for images, XHR, Fetch, CSS, or JS
  // libraries loaded on a webpage. It includes detailed loading
  // timing information.
  trackResources: true,
  // A long task event is generated for any task in the browser
  // that blocks the main thread for more than 50ms.
  trackLongTasks: true,
  // A list of request URLs used to inject tracing headers.
  allowedTracingUrls: getTracingOrigins(),
  // Starting with v5.0.0 of the RUM Browser SDK, the Session Replay starts
  // recording automatically when calling init(). To conditionally start
  // the recording, set startSessionReplayRecordingManually: true,
  // and call startSessionReplayRecording().
  startSessionReplayRecordingManually: true,
  beforeSend,
};

const ALLOWED_ENVS = ["production", "staging", "development", "preview"];

/**
 * initializes datadog and updates the user information as a side effect of the user object being updated.
 **/
export function useDatadog() {
  const user = useAppSelector((state) => state.Auth.user);
  const [isDatadogInitialized, setIsDatadogInitialized] = useDatadogInitialized();

  const enabled =
    config.datadogClientToken && ALLOWED_ENVS.includes(config.stage) && !globalThis.Cypress;

  useEffect(() => {
    if (enabled) {
      /**
       * https://docs.datadoghq.com/real_user_monitoring/browser/
       */
      datadogRum.init(dataDogRumConfiguration);

      /**
       * https://docs.datadoghq.com/logs/log_collection
       */
      datadogLogs.init(datadogLogsConfiguration);

      setIsDatadogInitialized(true);
    } else {
      const disabledReasons = new Array<string>();

      if (!config.datadogClientToken) {
        disabledReasons.push("Client token not set");
      }

      if (!ALLOWED_ENVS.includes(config.stage)) {
        disabledReasons.push(`Unsupported stage: ${config.stage}`);
      }

      if (globalThis.Cypress) {
        disabledReasons.push("Cypress is enabled");
      }

      console.warn("RUM disabled reasons:", disabledReasons);
    }
  }, [enabled, setIsDatadogInitialized]);

  // set the user as a side effect of the user being loaded.
  useEffect(() => {
    if (user && isDatadogInitialized) {
      try {
        const { id, businessId, firstName, lastName, email, role } = user;
        datadogRum.setUser({
          id: String(id),
          businessId,
          name: `${firstName} ${lastName}`,
          email,
          role,
        });
      } catch (e) {
        Sentry.captureMessage(`Exception when setting user within DataDog`, {
          error: e,
          userId: user?.id,
        });
      }
    } else if (!user && isDatadogInitialized) {
      datadogRum.clearUser();
    }
  }, [user, isDatadogInitialized]);

  useEffect(() => {
    if (isDatadogInitialized) {
      LogRocket?.track("DataDog Initialized", {
        dataDogSessionId: datadogRum.getInternalContext()?.session_id || "No Session Id",
      });
    }
  }, [isDatadogInitialized]);

  useEffect(() => {
    if (isDatadogInitialized) {
      LogRocket?.getSessionURL((sessionURL) => {
        // The function name is misleading, it actually sets a property for the user session
        // https://docs.datadoghq.com/real_user_monitoring/browser/advanced_configuration/?tab=npm#addoverride-user-session-property
        datadogRum.setUserProperty("LogRocket Session Url", sessionURL);
      });
    }
  }, [isDatadogInitialized]);

  useEffect(() => {
    const ddHandleVisibilityChange = () => {
      if (datadogRum && datadogRum.addAction) {
        datadogRum.addAction("visibility-change", {
          visibilityState: document.visibilityState,
        });
      }
    };
    document.addEventListener("visibilitychange", ddHandleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", ddHandleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const ddHandleNetworkChange = (networkStatus: "online" | "offline") => {
      if (datadogRum && datadogRum.addAction) {
        datadogRum.addAction("network-status", {
          networkStatus,
        });
      }
    };
    const handleOnline = () => ddHandleNetworkChange("online");
    const handleOffline = () => ddHandleNetworkChange("offline");

    globalThis.addEventListener("online", handleOnline);
    globalThis.addEventListener("offline", handleOffline);
    return () => {
      globalThis.removeEventListener("online", handleOnline);
      globalThis.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    if (datadogRum && datadogRum.addAction) {
      try {
        datadogRum.addAction("initial-viewport", {
          clientWidth: document.documentElement.clientWidth,
          clientHeight: document.documentElement.clientHeight,
          computedClientViewport: `${document.documentElement.clientWidth}x${document.documentElement.clientHeight}`,
          innerWidth: globalThis.innerWidth,
          innerHeight: globalThis.innerHeight,
          computedInnerViewport: `${globalThis.innerWidth}x${globalThis.innerHeight}`,
        });
      } catch (e) {
        console.error("Error setting initial viewport", e);
      }
    }
  }, []);

  return isDatadogInitialized;
}
