import { Box, Icon, Typography } from "@hotelengine/atlas-web";
import type { IReservationBase } from "@hotel-engine/types/reservation";

import * as Styled from "../styles";
import { useDeviceBreakpoints } from "pages/Checkout/Lodging/hooks/useDeviceBreakpoints";
import { useMemo } from "react";

const PropertyCard = ({ itinerary }: { itinerary: IReservationBase }) => {
  const device = useDeviceBreakpoints();

  const deviceVariant = useMemo(() => {
    if (device.isSmallMobile) {
      return {
        propertyName: "heading/sm",
        propertyLocation: "body/xs",
        roomTitle: "body/xs",
        propertyImage: { width: "74px", height: "74px" },
      } as const;
    }
    if (device.isMobile) {
      return {
        propertyName: "heading/sm",
        propertyLocation: "body/xs",
        roomTitle: "body/xs",
        propertyImage: { width: "74px", height: "74px" },
      } as const;
    }
    return {
      propertyName: "heading/sm",
      propertyLocation: "body/sm",
      roomTitle: "body/xs",
      propertyImage: { width: "114px", height: "74px" },
    } as const;
  }, [device]);

  return (
    <Styled.ModificationSideSheetItineraryDetails data-testid="property-card">
      <Box>
        <Styled.PropertyImage
          src={itinerary.propertyPhotoUrl}
          alt={itinerary.propertyName}
          width={deviceVariant.propertyImage.width}
          height={deviceVariant.propertyImage.height}
        />
      </Box>
      <Styled.PropertyDetails>
        <Typography
          variant={deviceVariant.propertyName}
          color="foregroundPrimary"
          flexWrap="nowrap"
        >
          {itinerary.propertyName}
        </Typography>
        <Typography
          variant={deviceVariant.propertyLocation}
          color="foregroundPrimary"
          flexWrap="nowrap"
        >
          {itinerary.propertyCity}, {itinerary.propertyState}
        </Typography>
        {!!itinerary.roomTitle && (
          <Box display="flex" gap={6} alignItems="center" alignContent="center" marginTop={8}>
            <Icon name="bed-front" color="foregroundSecondary" size="sm" />
            <Typography
              variant={deviceVariant.roomTitle}
              color="foregroundSecondary"
              flexWrap="nowrap"
            >
              {itinerary.roomTitle}
            </Typography>
          </Box>
        )}
      </Styled.PropertyDetails>
    </Styled.ModificationSideSheetItineraryDetails>
  );
};

export default PropertyCard;
