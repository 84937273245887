import { useParams } from "@hotel-engine/lib/react-router-dom";
import { isEqual, omit } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import useTripsControl from "./useTripsControl";
import type { TripData } from "../data/schemas/trips";

export type ITripsCache = {
  resetTrips: () => void;
  accumulateTrips: (newTrips: TripData[]) => TripData[];
};

export default function useTripsCache(): ITripsCache {
  const { status } = useParams();
  const previousStatus = useRef(status);
  const [params] = useTripsControl();
  const previousParams = useRef(params);
  const [tripsCache, setTripsCache] = useState(new Map<number, TripData>());

  const accumulateTrips = useCallback(
    (newTrips: TripData[] = []): TripData[] => {
      for (const trip of newTrips) {
        tripsCache.set(trip.id, trip);
      }

      return Array.from(tripsCache.values());
    },
    [tripsCache]
  );

  const resetTrips = useCallback(() => {
    setTripsCache(new Map());
  }, [setTripsCache]);

  useEffect(() => {
    const searchParams = omit(params, ["limit", "offset", "sort", "group"]);
    const previousSearchParams = omit(previousParams.current, ["limit", "offset", "sort", "group"]);

    if (!isEqual(searchParams, previousSearchParams) || status !== previousStatus.current) {
      resetTrips();
    }

    previousParams.current = params;
    previousStatus.current = status;
  }, [params, status, resetTrips]);

  return {
    accumulateTrips,
    resetTrips,
  };
}
