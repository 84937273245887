import config from "config";

import * as Styled from "./styles";

const ThankYou = ({ close }: { close: () => void }) => {
  return (
    <Styled.ThankYouContainer>
      <Styled.ThankYouImage
        src={`${config.cdnHost}/assets/customer-insights/thank-you-customer-insights.svg`}
        alt="Accepted Feedback"
      />
      <Styled.ThankYouHeadline forwardedAs="h2" variant="heading/xl">
        Thanks for the feedback!
      </Styled.ThankYouHeadline>
      <Styled.ThankYouCopy variant="body/md">
        We love hearing from you. Your thoughts help us build an even better booking experience.
      </Styled.ThankYouCopy>
      <Styled.ModalFooter>
        <Styled.CloseButton onClick={close}>Close</Styled.CloseButton>
      </Styled.ModalFooter>
    </Styled.ThankYouContainer>
  );
};

export default ThankYou;
