import type { Booking } from "../data/schemas/trips";

export type BookingByType<T extends Booking["bookingType"]> = Extract<Booking, { bookingType: T }>;

/**
 * Asserts that the provided booking matches the expected booking type.
 * Throws an error if the booking type does not match.
 *
 * @param booking - The booking object to assert.
 * @param expectedType - The expected booking type.
 * @throws Will throw an error if booking type does not match expected type.
 */
export function assertBookingType<T extends Booking["bookingType"]>(
  booking: Booking,
  expectedType: T
): asserts booking is BookingByType<T> {
  if (booking.bookingType !== expectedType) {
    throw new Error(`Expected booking type "${expectedType}", but got "${booking.bookingType}"`);
  }
}

/**
 * Checks whether the provided booking matches the expected booking type.
 * Returns true if the booking type matches, false otherwise.
 *
 * @param booking - The booking object to check.
 * @param expectedType - The expected booking type.
 * @returns True if booking type matches expected type, false otherwise.
 */
export function isBookingType<T extends Booking["bookingType"]>(
  booking: Booking,
  expectedType: T
): booking is BookingByType<T> {
  return booking.bookingType === expectedType;
}
