import type { IOccupant } from "@hotel-engine/types/booking";
import { Box, Divider, Typography } from "@hotelengine/atlas-web";
import { parsePhoneNumber } from "libphonenumber-js";
import { TravelerChild } from "./TravelerChild";
import {
  type ITravelerInfoBlockContentProps,
  TravelerInfoBlockContent,
} from "./TravelerInfoBlockContent";

export const tryFormatPhoneNumber = (phoneNumber?: string): string | undefined => {
  if (!phoneNumber) {
    return;
  }

  try {
    return parsePhoneNumber(phoneNumber).formatInternational();
  } catch (error) {
    return phoneNumber;
  }
};

export const dedupeTravelers = (travelers: IOccupant[]) =>
  travelers.reduce((acc: IOccupant[], cur: IOccupant) => {
    const duplicateTraveler = acc.find((traveler) => {
      const idsMatch = traveler.occupantId === cur.occupantId;
      // Checking names in addition to occupantId since non-user guests will not have an occupantId, but it will match because it is null
      const namesMatch =
        `${traveler.firstName} ${traveler.lastName}` === `${cur.firstName} ${cur.lastName}`;

      return idsMatch && namesMatch;
    });

    if (!duplicateTraveler) {
      acc.push(cur);
    }

    return acc;
  }, []);

export const getTravelerSheet = (dedupedTravelers: IOccupant[], childCount?: number | null) => {
  return dedupedTravelers.map((traveler, index, collection) => {
    const isLastTraveler = index + 1 === collection.length;
    const travelerRowProps: ITravelerInfoBlockContentProps[] = [
      {
        leftContent: "Email",
        rightContent: traveler.email,
      },
      {
        leftContent: "Phone number",
        rightContent: traveler.phone,
      },
      {
        leftContent: traveler.loyaltyRewardName,
        rightContent: traveler.loyaltyRewardProgramNumber,
      },
    ];

    return (
      <Box
        key={`${traveler.id}-${traveler.firstName}`}
        paddingBottom={24}
        style={{ height: "100%" }}
      >
        <Typography variant="body/md" color="foregroundPrimary" marginBottom={12}>
          {traveler.firstName} {traveler.lastName}
        </Typography>
        <Divider variant="dotted" />
        <Box marginTop={12}>
          {travelerRowProps.map(
            (row, i) =>
              !!row.rightContent && (
                <TravelerInfoBlockContent
                  {...row}
                  key={`${row.leftContent || i}-${traveler.firstName}`}
                  isSheet
                />
              )
          )}
          {isLastTraveler ? <TravelerChild childCount={childCount} /> : null}
        </Box>
      </Box>
    );
  });
};
