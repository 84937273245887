import type { UseQueryOptions } from "react-query";

import { endpoints } from "@hotel-engine/react-query/constants";
import { useApi } from "@hotel-engine/react-query/useApi";
import { useExtendedQuery } from "@hotel-engine/react-query/useExtendedQuery";
import type {
  IAuthStrategies,
  IAuthStrategyLookupResponse,
} from "@hotel-engine/types/authStrategy";
import type { IErrorResponse } from "@hotel-engine/types/errors";
import config from "../../../config";

export const useAuthStrategiesLookupQuery = (
  email: string | null | undefined,
  options?: UseQueryOptions<IAuthStrategyLookupResponse[], IErrorResponse>
): IAuthStrategies => {
  const get = useApi("get");
  const encodedEmail = encodeURIComponent(email || "");
  const endpoint = `${endpoints.authStrategies}/lookup?email=${encodedEmail}`;

  const { data, error, isFetching, isFetched } = useExtendedQuery(
    endpoint,
    () => get<IAuthStrategyLookupResponse[]>(endpoint),
    options
  );

  const authStrategies: IAuthStrategies = {
    // loading info
    isFetching,
    isFetched,

    // data accessors
    all: data ?? [],
    get: (index) => data && data[index],

    // data descriptors
    hasMany: !!(data && data.length > 1),
    hasSome: !!(data && data.length >= 1),

    // error info
    error: error?.response?.data,

    // returns exactly one auth strategy
    // undefined when the number of auth strategies != 1
    singularStrategy: data?.length === 1 ? data[0] : undefined,

    // returns exactly one auth0-powered auth strategy
    // undefined when the number of auth0 auth strategies != 1
    singularAuth0Strategy: data?.length === 1 && !!data[0].auth0Connection ? data[0] : undefined,

    // helpful state descriptors, they are set to real values in the following lines
    // these `false` values are placeholders
    hasExactlyOneLegacyStrategy: false,
    includesInternalStrategy: false,
    allStrategiesAreAuth0: false,
    showSsoOrPassword: false,
  };

  // check if the auth strategies include an internal connection
  // we treat the login form differently in this case because
  // demo accounts use @hotelengine.com accounts but do not log in via SSO
  authStrategies.includesInternalStrategy = !!authStrategies.all.find(
    (authStrategy) => authStrategy.auth0Connection === config.auth0ImpersonationConnection
  );

  // check if there's exactly one legacy strategy
  authStrategies.hasExactlyOneLegacyStrategy = !!(
    authStrategies.singularStrategy && !authStrategies.singularStrategy.auth0Connection
  );

  // check if all strategies are auth0
  // in this case we display the business selector but no password button
  authStrategies.allStrategiesAreAuth0 = !authStrategies.all.find(
    (authStrategy) => authStrategy.auth0Connection === null
  );

  // show the sso or password step if there's one legacy strategy or if there's an internal strategy
  authStrategies.showSsoOrPassword =
    authStrategies.hasExactlyOneLegacyStrategy ||
    !!(authStrategies.singularStrategy && authStrategies.includesInternalStrategy);

  return authStrategies;
};
