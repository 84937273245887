import GoogleMap from "../..";
import GoogleMapsTripResults from "./components/GoogleMapsTripResults";

type GoogleTripsMapProps = {
  /** Used for map bounds after loading is done */
  loading: boolean;
};

const GoogleTripsMap = ({ loading }: GoogleTripsMapProps) => {
  return (
    <GoogleMap
      controlOptions={{
        showExpand: false,
      }}
      /** This allows the user to see the whole world, preventing the map from centering in an area where there
       * aren't actually any pins because it's finding the center of a group of trips far away from each other
       */
      mapOptions={{ minZoom: 2 }}
    >
      {(map) => <GoogleMapsTripResults loading={loading} map={map as google.maps.Map} />}
    </GoogleMap>
  );
};

export default GoogleTripsMap;
