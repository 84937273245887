import { Box, type BoxProps } from "@hotelengine/atlas-web";
import styled from "styled-components";

const CompanyLogosContainer = styled<React.FC<BoxProps>>(Box)`
	width: 62px;
	height: 36px;
	margin-right: ${({ theme }) => theme.spacing[16]};
`;

export const ButtonsContainer = styled<React.FC<BoxProps>>(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing[16]};
  width: 100%;
`;

const ManageFlightContainer = styled<React.FC<BoxProps>>(Box)<{
  $isPreview: boolean;
}>`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${({ theme }) => theme.spacing[16]};
  padding-bottom: ${({ theme }) => theme.spacing[20]};
  border-top: ${({ theme }) => `1px solid ${theme.colors.borderMinimal}`};
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  z-index: ${({ theme }) => theme.zIndex.backdropAbove + theme.zIndex.backdropAbove};
`;

export { CompanyLogosContainer, ManageFlightContainer };
