import styled from "styled-components";

import { colors } from "@hotel-engine/styles";
import { mediaQuery } from "@hotel-engine/styles/helpers/responsive";

export const FormTitle = styled.h1`
  ${({ theme }) => theme.typography.desktop["heading/xl"]};
  color: ${colors.grey[800]};
  margin-bottom: "0";

  margin-top: "0";

  ${mediaQuery(
    "md",
    "max"
  )(`
    margin: 0 auto;
  `)}
`;
