import styled from "styled-components";

export const TripsError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing[80]};
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  min-height: 500px;
  height: 100%;
  gap: ${({ theme }) => theme.spacing[24]};
  & > svg {
    width: 35px;
  }
`;
