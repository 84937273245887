import type { IconName } from "@hotelengine/atlas-core";

import type { IPropertyLegacy } from "@hotel-engine/types/property";

/**
 * The icon and logo to be displayed for property amenities
 */
export type IAmenity = Pick<IPropertyLegacy, "amenities"> & {
  icon: IconName;
  title: string;
};

export const amenitiesPriority: IAmenity[] = [
  {
    icon: "mug-saucer--solid",
    title: "Free breakfast",
    amenities: ["FREE BREAKFAST"],
  },
  {
    icon: "square-parking--solid",
    title: "Free parking",
    amenities: ["FREE PARKING"],
  },
  {
    icon: "wifi--solid",
    title: "Free WiFi",
    amenities: ["FREE WIFI"],
  },
  {
    icon: "oven--solid",
    title: "Kitchen",
    amenities: ["KITCHEN"],
  },
  {
    icon: "microwave--solid",
    title: "Microwave",
    amenities: ["MICROWAVE"],
  },
  {
    icon: "refrigerator--solid",
    title: "Refrigerator",
    amenities: ["REFRIGERATOR"],
  },
  {
    icon: "bell-concierge--solid",
    title: "24-hr front desk",
    amenities: ["24-HOUR FRONT DESK"],
  },
  {
    icon: "wheelchair--solid",
    title: "Handicap accessible",
    amenities: ["HANDICAP ACCESSIBLE"],
  },
  {
    icon: "dumbbell--solid",
    title: "Fitness center",
    amenities: ["GYM", "FITNESS CENTER"],
  },
  {
    icon: "washing-machine--solid",
    title: "Laundry",
    amenities: ["LAUNDRY"],
  },
  {
    icon: "print--solid",
    title: "Business center",
    amenities: ["BUSINESS CENTER"],
  },
  {
    icon: "utensils--solid",
    title: "On-site restaurant",
    amenities: ["RESTAURANT"],
  },
  {
    icon: "martini-glass--solid",
    title: "Bar / lounge",
    amenities: ["BAR/LOUNGE", "BAR / LOUNGE", "BAR", "LOUNGE"],
  },
  {
    icon: "paw--solid",
    title: "Pet-friendly",
    amenities: ["PET-FRIENDLY"],
  },
  {
    icon: "person-swimming--solid",
    title: "Pool",
    amenities: ["POOL", "OUTDOOR POOL"],
  },
  {
    icon: "snowflake--solid",
    title: "Air conditioning",
    amenities: ["AIR CONDITIONING", "AC", "A/C"],
  },
  {
    icon: "ban-smoking",
    title: "Smoke-free",
    amenities: ["SMOKE FREE", "NON-SMOKING", "NO SMOKING"],
  },
];

export const hiddenPropertyBannerText = {
  user: "You can still book this property, but you hid it because <REASON>. It won't appear in your search results or in favorite properties.",
  userOtherReason:
    "You can still book this property, but you hid it for a specific reason. It won't appear in your search results or in favorite properties.",
  business:
    "You can still book this property, but an admin hid it for all users because <REASON>. It won't appear in any search results or in your favorite properties.",
  businessOtherReason:
    "You can still book this property, but an admin hid it for all users. It won't appear in any search results or in your favorite properties.",
} as const;

export const ISO8601DateFormat = "YYYY-MM-DD";
export const MMDDYYYYDateFormat = "MM-DD-YYYY";
export const supportedDateFormats = [ISO8601DateFormat, MMDDYYYYDateFormat];
