import { Box } from "@hotelengine/atlas-web";
import type { IDetails } from "@hotel-engine/types/trips";

import { useAcrissInfo } from "pages/Cars/hooks/useAcrissInfo";
import * as Styled from "pages/Trips/views/TripsList/styles";

export interface ICarDetailCellProps {
  details: IDetails;
  statusChip: React.ReactNode;
}

const CarDetailCell = ({ details, statusChip }: ICarDetailCellProps) => {
  const { getAcrissCategoryType } = useAcrissInfo();

  if (!details.rentalCompany) {
    return <Styled.PrimaryText>No car details available</Styled.PrimaryText>;
  }

  const textLabel = details.car
    ? `${getAcrissCategoryType(details.car?.category, details.car?.type)}`
    : details.rentalCompany.name;

  return (
    <Styled.TripDetailWrapper>
      <Styled.CarImageContainer>
        <Styled.CarTripImage src={details.rentalCompany?.logo} />
      </Styled.CarImageContainer>
      <Box display="flex" flexDirection="column" gap={4} alignItems="flex-start">
        <div>
          <Styled.PrimaryText>{textLabel}</Styled.PrimaryText>
          <Styled.SubText>#{details.carConfirmation}</Styled.SubText>
        </div>
        {statusChip}
      </Box>
    </Styled.TripDetailWrapper>
  );
};

export default CarDetailCell;
