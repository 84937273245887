import type { IDirectBill } from "@hotel-engine/types/booking";
import type { IPaymentProfile } from "@hotel-engine/types/paymentProfile";

/**
 * @deprecated
 *
 * Legacy lodging util, do not use this in any new code
 */
export const isDirectBillLegacy = (
  paymentMethod?: IPaymentProfile | IDirectBill
): paymentMethod is IDirectBill => {
  return paymentMethod?.id === 0;
};
