import { Box, Skeleton } from "@hotelengine/atlas-web";

interface INotificationSkeleton {
  isPropertyReviewLoading?: boolean;
}

const NotificationSkeleton = ({ isPropertyReviewLoading }: INotificationSkeleton) => {
  return (
    <Box
      data-testid="checkout-verification-skeleton"
      display="flex"
      flexDirection="column"
      padding={24}
    >
      <Box
        display="flex"
        flexDirection="row"
        gap={24}
        backgroundColor="backgroundPrimary"
        style={{ width: "100%" }}
      >
        <Skeleton height={80} style={{ width: 80 }} />
        <Box display="flex" flexDirection="column" gap={12} style={{ flex: 1 }}>
          <Skeleton height={20} style={{ width: "70%" }} />
          <Skeleton height={12} style={{ width: "55%" }} />
          <Skeleton height={12} style={{ width: "40%" }} />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={8}
        marginTop={32}
        marginBottom={32}
        style={{ height: "86px", flex: 1 }}
      >
        <Skeleton height={24} />
        <Skeleton height={12} style={{ width: "95%" }} />
        <Skeleton height={12} style={{ width: "85%" }} />
        <Skeleton height={12} style={{ width: "60%" }} />
      </Box>
      {!isPropertyReviewLoading && (
        <>
          <Box justifyContent={"space-between"} display="flex" flexDirection="row">
            <Skeleton height={32} style={{ width: "45%" }} />
            <Skeleton height={32} style={{ width: "45%" }} />
          </Box>
          <Skeleton height={36} style={{ marginTop: 48, marginBottom: 24 }} />
        </>
      )}
    </Box>
  );
};

export default NotificationSkeleton;
