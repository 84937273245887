import { useEffect, useState } from "react";

import { useCarModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import BookingDetails from "@hotel-engine/app/ItineraryContent/cars/components/BookingDetails";
import NeedHelp from "@hotel-engine/app/ItineraryContent/cars/components/HelpAndPolicies/NeedHelp";
import Location from "@hotel-engine/app/ItineraryContent/cars/components/Location";
import Travelers from "@hotel-engine/app/ItineraryContent/cars/components/Travelers";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import { ToastErrorMessage } from "@hotel-engine/app/ToastErrorMessage";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import { Box, Typography, toast } from "@hotelengine/atlas-web";

import Insurance from "@hotel-engine/app/ItineraryContent/cars/components/HelpAndPolicies/Insurance";
import type { ICarItineraryWithId } from "@hotel-engine/types/itinerary";

import { useGetCarInsuranceQuery } from "@hotel-engine/react-query/reservation/useCarInsurancePolicy";
import { useCancelCar } from "../../../../hooks/useCancelCar";
import { usePreviewCarRefund } from "../../../../hooks/usePreviewCarRefund";
import CancelConfirmationModal from "../../../common/CancelConfirmationModal";
import CancellationButton from "../../../common/CancellationButton";
import CancellationTitle from "../../../common/CancellationTitle";
import RefundInfo from "../../../common/RefundInfo";
import * as Styled from "./../../components/styles";

export function CarBookingCancellation() {
  const { typographyVariants } = useTypographyScaling();
  const {
    isPreview,
    state: { showConfirmationModal },
    reservation,
    dispatch,
  } = useCarModificationsContext();
  const { isError: errorGettingCarInsurance, data: insurancePolicy } = useGetCarInsuranceQuery(
    reservation?.insurancePolicyId,
    {
      enabled: !!reservation?.insurancePolicyId,
    }
  );

  const {
    refundInfo,
    isLoading,
    isError: isRefundCalcError,
  } = usePreviewCarRefund((reservation as ICarItineraryWithId).bookingNumber);

  const cancelRefundAmount = refundInfo?.refundAmount;
  const cancelRefundType = refundInfo?.refundType;

  useEffect(() => {
    if (isRefundCalcError) {
      toast({
        title: "Oops! Something's not right.",
        description: (
          <ToastErrorMessage
            error={`There was an issue processing your cancellation. Please contact ${COMPANY_NAME} support at at 855-567-4683.`}
          />
        ),
        duration: 0,
        icon: "circle-exclamation",
        sentiment: "critical",
      });
    }
  }, [isRefundCalcError]);

  useEffect(() => {
    if (errorGettingCarInsurance) {
      toast({
        title: "Oops! Something's not right.",
        description: (
          <ToastErrorMessage
            error={`There was an issue processing your cancellation. Please contact ${COMPANY_NAME} support at at 855-567-4683.`}
          />
        ),
        duration: 0,
        icon: "circle-exclamation",
        sentiment: "critical",
      });
    }
  }, [errorGettingCarInsurance]);

  const setShowStatusModal = () => {
    dispatch({
      type: "SET_SHOW_STATUS_MODAL",
      payload: true,
    });
  };

  // Variants are, insurance for when error when cancelling only insurance.
  // insuranceOnly when cancelling the whole booking and only fails to cancel insurance, but not booking.
  // bookingOnly when cancelling the whole booking and only fails to cancel the booking, but not insurance.
  const [, setErrorCancelling] = useState<null | "insurance" | "insuranceOnly" | "bookingOnly">(
    "insuranceOnly"
  );

  const { submitCancelCar } = useCancelCar({
    reservation: reservation as ICarItineraryWithId,
    showStatusModal: setShowStatusModal,
    refundInfo: {
      totalRefundAmount: Number(cancelRefundAmount),
      refundType: cancelRefundType,
      penaltyFee: refundInfo?.cancellationFee,
      showRefundInfo: refundInfo?.showRefundInfo,
    },
    setErrorCancelling,
    dispatch,
    policyId: insurancePolicy?.policyId,
  });

  return (
    <Box backgroundColor="backgroundSecondary" paddingTop={!isPreview ? 16 : 0}>
      <CancellationTitle isCar />
      <Typography
        variant={typographyVariants.body}
        color="foregroundPrimary"
        as="p"
        style={{ textAlign: "center", marginBottom: 40 }}
      >
        Once confirmed, your cancellation is final and cannot be reversed.
      </Typography>
      <Insurance hideManage />
      <Travelers
        travelers={reservation.travelers || []}
        logoSrc={reservation.rentalCompany?.logo}
        padTop={false}
      />
      <BookingDetails logoSrc={reservation.rentalCompany?.logo} details={reservation} />
      <Location
        location={reservation.location}
        rentalCompany={reservation.rentalCompany}
        startTime={reservation.startTime}
        endTime={reservation.endTime}
      />
      <NeedHelp />

      <Styled.StickyContainer $isPreview={isPreview}>
        <RefundInfo isLoading={isLoading} refundInfo={refundInfo} />

        <CancellationButton text="Cancel booking" isRefundCalcLoading={isLoading} />
      </Styled.StickyContainer>

      {!!showConfirmationModal && <CancelConfirmationModal isCar onConfirm={submitCancelCar} />}
    </Box>
  );
}
