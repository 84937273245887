import { ampli } from "ampli";

import { routes } from "@hotel-engine/constants";
import { useLocation, useNavigate } from "@hotel-engine/lib/react-router-dom";
import { Box, Divider, Typography } from "@hotelengine/atlas-web";

import { PriceBreakdownField } from "pages/Checkout/shared/PriceBreakdownField";
import { useAppSelector } from "store/hooks";

import { useTranslation } from "react-i18next";
import { useCarModificationsContext } from "../../../ModificationsContext";
import SummaryOfChargesItem from "../../../shared/SummaryOfChargesItem";
import TaxesAndFees from "./TaxesAndFees";
import * as Styled from "./styles";
import { ESpecialLabels, useCarSummaryOfCharges } from "./useCarSummaryOfCharges";
import { CorporateDiscountBadge } from "../../../../CorporateDiscountBadge";

const PaymentInfo = () => {
  const navigate = useNavigate();
  const rrLocation = useLocation();
  const user = useAppSelector((state) => state.Auth?.user);
  const isViewOnlyTraveler = user?.role === "view_only_traveler";
  const { t } = useTranslation("cars_trips", {
    keyPrefix: "summaryOfCharges.titles",
  });

  const { isPreview, reservation } = useCarModificationsContext();
  const { items, payLaterItems, totalCharge } = useCarSummaryOfCharges(
    reservation.charges,
    reservation.paymentType,
    new Date(reservation.endTime).getTime() - Date.now() < 0
  );

  const handleReportBillingIssue = () => {
    navigate(`${routes.billingIssue}/${reservation?.bookingNumber}`, {
      state: {
        reservation,
        prevPath: rrLocation.pathname + rrLocation.search,
      },
    });
    ampli.clickReportBillingIssue({ bookingId: reservation?.bookingNumber });
  };

  if (!reservation?.charges) return null;

  return (
    <Styled.SummaryOfChargesPaddedContainer $isPreview={isPreview}>
      <Typography variant="uppercase/xs-strong" marginBottom={8}>
        Payment info
      </Typography>

      {items.map((item) => {
        if (item.label === t("taxesFees")) {
          return (
            <Box key={`basic-${item.label}`} marginTop={4} marginBottom={4}>
              <PriceBreakdownField
                name="Taxes and fees"
                cost={item.value}
                details={
                  <TaxesAndFees charges={reservation.charges} formattedTaxesFees={item.value} />
                }
              />
            </Box>
          );
        }
        if (item.label === ESpecialLabels.divider) {
          return (
            <Box key={`basic-${item.label}`} marginTop={12} marginBottom={12}>
              <Divider variant="dotted" />
            </Box>
          );
        }
        if (item.label === ESpecialLabels.corporateBadge) {
          return (
            <Box key={`basic-${item.label}`} marginTop={4} marginBottom={4}>
              <CorporateDiscountBadge size="xs" />
            </Box>
          );
        }

        return (
          <div key={`basic-${item.label}`}>
            <Box marginTop={4} marginBottom={4}>
              <SummaryOfChargesItem {...item} typography="body/md" />
            </Box>
          </div>
        );
      })}
      <Box marginTop={12} marginBottom={12}>
        <Divider />
      </Box>

      {payLaterItems.map((item) => {
        return (
          <div key={`basic-${item.label}`}>
            <Box marginTop={4} marginBottom={4}>
              <SummaryOfChargesItem {...item} typography="body/md" />
            </Box>
          </div>
        );
      })}
      <Box marginTop={4}>
        <SummaryOfChargesItem
          label="Total price"
          labelColor="foregroundPrimary"
          value={totalCharge}
          typography="body/md-strong"
          isBold
        />
      </Box>
      {!isViewOnlyTraveler && (
        <Box marginTop={16} display="flex" justifyContent="center">
          <Typography
            variant="link/sm"
            color="foregroundPrimary"
            onClick={handleReportBillingIssue}
            style={{ cursor: "pointer" }}
          >
            Report billing issue
          </Typography>
        </Box>
      )}
    </Styled.SummaryOfChargesPaddedContainer>
  );
};

export default PaymentInfo;
