import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import type { IRoomOffersParams } from "@hotel-engine/react-query/room/useRoomOffersQuery";
import { useRoomOffersQuery } from "@hotel-engine/react-query/room/useRoomOffersQuery";
import { useRoomsQuery } from "@hotel-engine/react-query/room/useRoomsQuery";
import type { IRoomCreateParams, IRoomsResponse } from "@hotel-engine/types/room";
import type { AxiosError } from "axios";
import type { UseQueryOptions } from "react-query";

// When morpheus flag is removed, replace useFetchRoomOffers with useRoomOffersQuery and remove this file
export const useFetchRoomOffers = (
  requestBody: IRoomOffersParams | IRoomCreateParams,
  options: UseQueryOptions<IRoomsResponse, AxiosError> = {}
) => {
  const morpheusEnabled = useIsFeatureFlagOn("morpheus");
  const isEnabledByParent = options.enabled !== undefined ? options.enabled : true;

  const {
    data: roomOffersData,
    isLoading: isRoomOffersLoading,
    error: roomOffersError,
    isFetched: areRoomOffersFetched,
    isFetching: areRoomOffersFetching,
    refetch: refetchRoomOffers,
  } = useRoomOffersQuery(requestBody as IRoomOffersParams, {
    ...options,
    enabled: isEnabledByParent && morpheusEnabled,
  });

  const {
    data: roomsData,
    isLoading: isRoomsLoading,
    error: roomsError,
    isFetched: isRoomsFetched,
    isFetching: isRoomsFetching,
    refetch: refetchRooms,
  } = useRoomsQuery(requestBody as IRoomCreateParams, {
    ...options,
    enabled: isEnabledByParent && !morpheusEnabled,
  });

  if (morpheusEnabled) {
    return {
      data: roomOffersData,
      isLoading: isRoomOffersLoading,
      error: roomOffersError,
      isFetched: areRoomOffersFetched,
      isFetching: areRoomOffersFetching,
      refetch: refetchRoomOffers,
    };
  }

  return {
    data: roomsData,
    isLoading: isRoomsLoading,
    error: roomsError,
    isFetched: isRoomsFetched,
    isFetching: isRoomsFetching,
    refetch: refetchRooms,
  };
};
