import { createSelector } from "@reduxjs/toolkit";
import type { IReduxStore } from "store";
import { buildSelectedFilters } from "store/Flights/FlightsFilters/flights.filters.helpers";

export const selectFlightsSearchFilters = (state: IReduxStore) => {
  return state.FlightsSearchFilters;
};

export const selectStopsSearchFilter = (state: IReduxStore) => state.FlightsSearchFilters.stops;

export const selectAirlinesFiltersSelector = (state: IReduxStore) =>
  state.FlightsSearchFilters.airlines;

export const selectCabinClassFiltersSelector = (state: IReduxStore) =>
  state.FlightsSearchFilters.cabinClass;

export const selectPriceRangeFiltersSelector = (state: IReduxStore) =>
  state.FlightsSearchFilters.priceRange;

export const selectDurationFiltersSelector = (state: IReduxStore) =>
  state.FlightsSearchFilters.duration;

export const selectFlightsSelectedFilters = createSelector(selectFlightsSearchFilters, (filters) =>
  buildSelectedFilters(filters)
);

export const selectTakeoffTimeFiltersSelector = (state: IReduxStore) =>
  state.FlightsSearchFilters.takeoffTime;

export const selectLandingTimeFiltersSelector = (state: IReduxStore) =>
  state.FlightsSearchFilters.landingTime;

export const selectSortByOption = (state: IReduxStore) => state.FlightsSearchFilters.selectedSort;

export const selectShelfFilters = (state: IReduxStore) => state.FlightsSearchFilters.shelf;

export const selectSelectedShelfBlock = createSelector(
  selectShelfFilters,
  (shelfFilters) =>
    Object.values(shelfFilters).find((shelfBlock) => shelfBlock.selected)?.value ?? ""
);

export const selectInPolicyFilter = (state: IReduxStore) => state.FlightsSearchFilters.inPolicy;
