import { getClient } from "@hotel-engine/client";
import { queryClient } from "@hotel-engine/contexts";
import { endpoints } from "@hotel-engine/react-query/constants";
import { AxiosError } from "axios";
import type { QueryKey } from "react-query";
import TripsError from "../utils/TripsError";
import { sanitizeTrips } from "../utils/sanitizeTrips";
import type { TripsQueryParams } from "./querySchema";
import type { TripsResult } from "./schemas/trips";

/**
 * Duration (in ms) for which trips data is considered fresh.
 * Data older than this will trigger a new API request.
 */
export const FRESHNESS_DURATION = 60000;

/**
 * Determines if cached data is still fresh based on when it was last updated.
 *
 * @param cacheKey - The React Query cache key to check
 * @returns true if data exists and is still within the freshness window
 */
function isCachedDataFresh(cacheKey: QueryKey) {
  const queryState = queryClient.getQueryState(cacheKey);

  if (!queryState || !queryState.dataUpdatedAt) {
    return false;
  }

  const timeSinceLastUpdate = Date.now() - queryState.dataUpdatedAt;

  return timeSinceLastUpdate < FRESHNESS_DURATION;
}

/**
 * Fetches trips data from the API using the QueryClient cache.
 *
 * @warning Do not convert to an async function to prevent loader blocking.
 * @param params - Query parameters for the trips request.
 * @returns A Promise containing the sanitized trips data or a TripsError.
 */
export function fetchTrips(params: TripsQueryParams) {
  const cacheKey = [endpoints.trips, params];
  const cachedData = queryClient.getQueryData<TripsResult>(cacheKey);

  if (cachedData && isCachedDataFresh(cacheKey)) {
    return Promise.resolve({ data: cachedData });
  }

  const client = getClient()
    .get<TripsResult>(endpoints.trips, { params })
    .then((response) => {
      const data = sanitizeTrips(response.data);

      queryClient.setQueryDefaults(cacheKey, {
        cacheTime: FRESHNESS_DURATION,
        staleTime: FRESHNESS_DURATION,
      });

      queryClient.setQueryData(cacheKey, data);

      return { ...response, data };
    })
    .catch((exception) => {
      if (exception instanceof AxiosError) {
        const error = exception.response?.data || {};
        const code = exception.response?.status || exception.status;

        return new TripsError({ ...error, code });
      }

      return new TripsError({
        title: (exception as Error)?.message || "Unknown error",
        code: 500,
        invalidParams: [],
        fieldErrors: [],
      });
    });

  return client;
}
