import { useMemo, useState } from "react";
import type { SelectProps } from "@hotelengine/atlas-web";
import {
  BottomSheet,
  BottomSheetClose,
  BottomSheetContent,
  BottomSheetHeader,
  BottomSheetTitle,
  BottomSheetTrigger,
  Box,
  Button,
  Select,
  SelectItem,
  useTheme,
} from "@hotelengine/atlas-web";
import type { ITripsSearch } from "../..";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { useTranslation } from "react-i18next";

export const facetNameMap = {
  traveler: "facets.traveler",
  location: "facets.location",
  confirmation_number: "facets.confirmation_number",
  booker: "facets.booker",
  all: "facets.all",
};

export interface IFacetSelectProps {
  isOnColor: boolean;
  values: ITripsSearch;
  isLoading: boolean;
  handleSearchFacetChange: (value: string) => void;
  isMobile?: boolean;
  size?: SelectProps["size"];
}

const FacetSelect = ({
  isOnColor,
  values,
  isLoading,
  handleSearchFacetChange,
  isMobile,
  size,
}: IFacetSelectProps) => {
  const { t } = useTranslation("trips");
  const { tokens } = useTheme();
  const isBookerSearchFacetOn = useIsFeatureFlagOn("trips_search_by_booker");

  const overlayIndexWithBottomSheetClosed = tokens.zIndex.modal;
  const overlayIndexWithBottomSheetOpen = tokens.zIndex.modal + tokens.zIndex.backdropAbove;
  const bottomSheetIndex =
    tokens.zIndex.modal + tokens.zIndex.backdropAbove + tokens.zIndex.backdropAbove;
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  const facetNames = useMemo(() => {
    const facets = Object.keys(facetNameMap);

    if (isBookerSearchFacetOn) return facets;

    return facets.filter((facet) => facet !== "booker");
  }, [isBookerSearchFacetOn]);

  const selectedFacet = useMemo(
    () =>
      values.search_facet && facetNames.includes(values.search_facet)
        ? values.search_facet
        : "traveler",
    [facetNames, values.search_facet]
  );

  /** Because we have a FullScreenSheet and a BottomSheet open at the same time,
   * we need to pull the overlay above the FullScreenSheet when the BottomSheet is opened
   * so that it can sit between the FullScreenSheet and the BottomSheet
   * so that the BottomSheet experience is correct.
   */
  const handleBottomSheetOpen = () => {
    setIsBottomSheetOpen(true);
    document
      .querySelector("[data-testid=dialog-overlay]")
      ?.setAttribute("style", `z-index: ${overlayIndexWithBottomSheetOpen};`);
  };

  const handleBottomSheetClose = () => {
    setIsBottomSheetOpen(false);
    document
      .querySelector("[data-testid=dialog-overlay]")
      ?.setAttribute("style", `z-index: ${overlayIndexWithBottomSheetClosed};`);
  };

  return isMobile ? (
    <BottomSheet isOpen={isBottomSheetOpen}>
      <BottomSheetTrigger
        size="xl"
        variant="outlined"
        trailingIcon="chevron-down"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          paddingLeft: 20,
          paddingRight: 20,
        }}
        onClick={handleBottomSheetOpen}
        aria-label="Search by"
      >
        {t(facetNameMap[selectedFacet as ""])}
      </BottomSheetTrigger>
      <BottomSheetContent
        onBlur={handleBottomSheetClose}
        style={{
          zIndex: bottomSheetIndex,
        }}
      >
        <BottomSheetHeader>
          <BottomSheetTitle>{t("facets.select")}</BottomSheetTitle>
          <BottomSheetClose onClick={handleBottomSheetClose} />
        </BottomSheetHeader>
        <Box display="flex" flexDirection="column" paddingBottom={12} gap={8}>
          {facetNames.map((facet) => {
            const facetLabel = t(facetNameMap[facet]);

            return (
              <Button
                key={facet}
                variant="plain"
                style={{
                  justifyContent: "left",
                  paddingLeft: selectedFacet === facet ? 8 : 32,
                }}
                leadingIcon={selectedFacet === facet ? "check" : undefined}
                onClick={() => {
                  handleSearchFacetChange(facet);
                  handleBottomSheetClose();
                }}
                aria-label={`Search by ${facetLabel}`}
              >
                {facetLabel}
              </Button>
            );
          })}
        </Box>
      </BottomSheetContent>
    </BottomSheet>
  ) : (
    <Select
      isOnColor={!isOnColor}
      defaultValue={selectedFacet}
      isDisabled={isLoading}
      style={{ width: isMobile ? "100%" : 164 }}
      size={size || "md"}
      onValueChange={handleSearchFacetChange}
      value={selectedFacet}
      aria-label={t("facets.searchBy")}
      name="Search by"
      data-testid="trips-search-facet"
    >
      <Box style={{ width: isMobile ? "100%" : 245 }}>
        {facetNames.map((facet) => {
          const facetLabel = t(facetNameMap[facet]);

          return (
            <SelectItem key={facet} value={facet} aria-label={facetLabel}>
              {facetLabel}
            </SelectItem>
          );
        })}
      </Box>
    </Select>
  );
};

export default FacetSelect;
