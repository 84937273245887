import { useCallback, useEffect, useState } from "react";
import { useTheme } from "@hotelengine/atlas-web";

type DeviceType = {
  isLargeDesktop: boolean; // tokens.breakpoints.xl 1200
  isDesktop: boolean; // tokens.breakpoints.lg 992
  isMobile: boolean; // tokens.breakpoints.sm 576
  isSmallMobile: boolean; // custom breakpoint 376px
};

export function useDeviceBreakpoints() {
  const { tokens } = useTheme();
  const [device, setDevice] = useState<DeviceType>({
    isLargeDesktop: false,
    isDesktop: false,
    isMobile: false,
    isSmallMobile: false,
  });

  const updateDeviceType = useCallback(() => {
    const width = globalThis.innerWidth;
    const breakpoints = {
      largeDesktop: parseInt(tokens.breakpoints.xl, 10),
      desktop: parseInt(tokens.breakpoints.lg, 10),
      mobile: parseInt(tokens.breakpoints.sm, 10),
      smallMobile: 376,
    };

    setDevice({
      isLargeDesktop: width >= breakpoints.largeDesktop,
      isDesktop: width >= breakpoints.desktop && width < breakpoints.largeDesktop,
      isMobile: width >= breakpoints.smallMobile && width < breakpoints.desktop,
      isSmallMobile: width < breakpoints.smallMobile,
    });
  }, [tokens]);

  useEffect(() => {
    updateDeviceType();

    globalThis.addEventListener("resize", updateDeviceType);

    return () => globalThis.removeEventListener("resize", updateDeviceType);
  }, [updateDeviceType]);

  return device;
}
