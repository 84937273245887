import { ampli } from "ampli";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";

import {
  Box,
  Typography,
  BottomSheet,
  BottomSheetTrigger,
  BottomSheetContent,
  BottomSheetHeader,
  BottomSheetTitle,
  BottomSheetClose,
  Button,
  SheetScrollView,
} from "@hotelengine/atlas-web";
import type { IReservationBase } from "@hotel-engine/types/reservation";

import MenuCard from "./MenuCard";

const BottomSheetMenu = ({ itinerary }: { itinerary: IReservationBase }) => {
  const user = useAppSelector((state) => state.Auth.user);
  const { t } = useTranslation("itinerary");

  const openManageTripOptions = useCallback(() => {
    ampli.clickManageTrip({
      userId: user?.id,
      lodgingBookingID: itinerary.contractNumber,
    });
  }, [itinerary.contractNumber, user?.id]);

  return (
    <BottomSheet>
      <BottomSheetTrigger style={{ display: "flex", flex: 1, width: "100%", margin: "auto" }}>
        <Button size="lg" onClick={openManageTripOptions}>
          {t("modifications.title")}
        </Button>
      </BottomSheetTrigger>
      <BottomSheetContent>
        <BottomSheetHeader>
          <Box display="flex" gap={4} alignItems="center">
            <BottomSheetTitle>
              <Typography variant="body/md" color="foregroundPrimary">
                {t("modifications.title")}
              </Typography>
            </BottomSheetTitle>
          </Box>
          <BottomSheetClose />
        </BottomSheetHeader>
        <SheetScrollView>
          <MenuCard itinerary={itinerary} />
        </SheetScrollView>
      </BottomSheetContent>
    </BottomSheet>
  );
};

export default BottomSheetMenu;
