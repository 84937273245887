import { createEntityAdapter } from "@reduxjs/toolkit";
import type { IFlightSlice, IFlightsShopState } from "./flights.shop.types";

export const entityAdapter = createEntityAdapter({
  selectId: (result: IFlightSlice) => result.sliceIndex,
});

export const initialState: IFlightsShopState = {
  ...entityAdapter.getInitialState(),
  isLoadingFlights: false,
  startSearchTime: null,
  endSearchTime: null,
  firstResultRenderedTime: null,
  entryPoint: null,
};

export enum EntryPoints {
  checkoutErrorRetrySearch = "CheckoutErrorRetrySearch",
  errorRetrySearch = "ErrorRetrySearch",
  flightFareSearch = "FlightFareSearch",
  newSearch = "NewSearch",
  newSearchByUrl = "NewSearchByUrl",
  updateSearch = "UpdateSearch",
  updateSearchPreFilter = "UpdateSearchPreFilter",
  updateSearchPreFilterNoResults = "UpdateSearchPreFilterNoResults",
}
