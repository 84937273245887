import { Box, Typography } from "@hotelengine/atlas-web";
import { TravelerInfoBlockContentGrid } from "./styles";
import type { ReactNode } from "react";

export interface ITravelerInfoBlockContentProps {
  leftContent: string | undefined;
  iconLogo?: ReactNode;
  rightContent?: string;
  isSheet?: boolean;
}

export const TravelerInfoBlockContent = ({
  iconLogo,
  leftContent,
  rightContent,
  isSheet,
}: ITravelerInfoBlockContentProps) => {
  return (
    <TravelerInfoBlockContentGrid noRightContent={!rightContent} isSheet={isSheet}>
      {!!iconLogo && <Box display="flex">{iconLogo}</Box>}
      <Box>
        <Typography color="linkPrimary" variant="body/sm">
          {leftContent}
        </Typography>
      </Box>
      {!!rightContent && <Box className="right">{rightContent}</Box>}
    </TravelerInfoBlockContentGrid>
  );
};
