import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { IDebugState } from "./types";

export const initialState: IDebugState = {
  featureFlagsOverriden: {},
};

export const debugSlice = createSlice({
  name: "Debug",
  initialState,
  reducers: {
    overrideState: (state, action: PayloadAction<IDebugState["featureFlagsOverriden"]>) => ({
      ...state,
      featureFlagsOverriden: {
        ...state.featureFlagsOverriden,
        ...action.payload,
      },
    }),
  },
});

export const DebugActions = debugSlice.actions;

export default debugSlice.reducer;
