import { useState } from "react";
import { useFormikContext } from "formik";

import { Box, IconButton } from "@hotelengine/atlas-web";
import { FullScreenSheet } from "@hotelengine/core-booking-web";

import type { FlexibleTripsParams, UnsetTripsParams } from "pages/Trips/hooks/useTripsControl";

import SearchFormControl from "../SearchFormControl";
import type { ITripsSearch } from "../..";
import * as Styled from "./styles";
import FacetSelect, { facetNameMap } from "../FacetSelect";
import SearchInput from "../SearchInput";
import type { ITripsResponse } from "pages/Trips/hooks/useTripsResult";
import { useTranslation } from "react-i18next";

export interface IMobileSearchFormControlProps {
  result: ITripsResponse;
  handleSearchFacetChange: (value: string) => void;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  params: NonNullable<FlexibleTripsParams>;
  unsetParams: UnsetTripsParams;
}

const MobileSearchForm = ({
  result,
  handleSearchFacetChange,
  handleOnChange,
  isLoading,
  params,
  unsetParams,
}: IMobileSearchFormControlProps) => {
  const { t } = useTranslation("trips");
  const { values, setFieldValue, handleSubmit, errors } = useFormikContext<ITripsSearch>();
  const [isMobileSheetOpen, setIsMobileSheetOpen] = useState(false);
  const searchFacet = t(facetNameMap[params.search_facet || (values.search_facet as "")]);
  const searchValue = params.search || t("facets.searchBy");
  const searchApplied = !!params.search || params.search_facet;

  const handleEditClick = () => {
    setIsMobileSheetOpen(true);
  };

  const handleClearSearch: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    unsetParams("search", "search_facet");
    setFieldValue("search", "");
  };

  return (
    <>
      <SearchFormControl result={result} errors={errors}>
        <Styled.MobileSearchHeaderFields id="edit-search-mobile" onClick={handleEditClick}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            gap={12}
          >
            <Styled.SearchIcon color="foregroundSecondary" name="magnifying-glass" />
            <Box display="flex" flexDirection="column" justifyContent="flex-start">
              <Styled.MobileSearchLocation color="foregroundPrimary" variant="body/sm-strong">
                {searchValue}
              </Styled.MobileSearchLocation>
              <Styled.MobileSearchDetails color="foregroundSecondary" variant="body/xs">
                {searchFacet}
              </Styled.MobileSearchDetails>
            </Box>
          </Box>
          {!!searchApplied && (
            <IconButton
              onClick={handleClearSearch}
              icon="circle-xmark"
              variant="plain"
              data-testid="clear-search-mobile"
            />
          )}
        </Styled.MobileSearchHeaderFields>
      </SearchFormControl>
      <FullScreenSheet
        title={t("facets.search")}
        showModal={isMobileSheetOpen}
        onClose={() => setIsMobileSheetOpen(false)}
        backGroundColor="backgroundSecondary"
      >
        <Styled.ScreenSheetWrapper>
          <Styled.LocationInputsWrapper>
            <FacetSelect
              isOnColor={false}
              values={values}
              isLoading={isLoading}
              handleSearchFacetChange={handleSearchFacetChange}
              size="xl"
              isMobile
            />
            <SearchFormControl result={result} errors={errors}>
              <SearchInput
                values={values}
                isOnColor={false}
                isLoading={isLoading}
                handleOnChange={handleOnChange}
                handleSubmit={() => {
                  handleSubmit();
                  setIsMobileSheetOpen(false);
                }}
                isMobile={true}
                size="xl"
              />
            </SearchFormControl>
          </Styled.LocationInputsWrapper>
        </Styled.ScreenSheetWrapper>
      </FullScreenSheet>
    </>
  );
};

export default MobileSearchForm;
