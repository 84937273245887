import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import type { IDateMarker } from "@hotel-engine/app/RefundPolicy/types";
import type { IReservationBase } from "@hotel-engine/types/reservation";

import type { Moment } from "moment-timezone";
import moment from "moment-timezone";
import type { FlexCutoffTimes } from "./useFlexCutoffTime";
import { useFlexCutoffTime } from "./useFlexCutoffTime";

const asStandardizedDate = (date: Moment | string, timezone: string) => {
  return moment.tz(date, timezone).startOf("day");
};

type UseFlexReservationShorten = (
  reservation: IReservationBase,
  timezone?: string
) => {
  // is flexEnabled && before end of day of flexShortenCutoffDate
  isFlexAndBeforeFlexExpiration: boolean;
  // Last day of the flex coverage period
  flexShortenCutoffDate: moment.Moment;
  // Some copy helpers for flex shorten
  // copy: FlexShortenCopy;
  // Refund date markers to be used in other areas
  shortenDateMarkers: IDateMarker | null;
  // The flex cutoff times and copy for the reservation
  flexCutoffTimes: FlexCutoffTimes;
};

// This hook is used to determine if the reservation is within the flex shorten period
// It is the flex related hook that hold functionality relative to a reservation: IReservationBase
export const useFlexReservationShorten: UseFlexReservationShorten = (reservation) => {
  const isFlex24hEnabled = useIsFeatureFlagOn("flex-policy-24h");
  const {
    cancelBy,
    checkIn,
    checkOut,
    createdAt,
    flexEnabled,
    modificationActions,
    propertyTimezone,
  } = reservation;

  // Derive the cutoff time for flex protection and build flexCutoffTimes
  const flexCutoffTimes = useFlexCutoffTime();

  const checkoutBOD = moment.tz(checkOut, propertyTimezone).startOf("day");
  // Derive flexShortenCutoffDate:
  // flex-policy-24h is enabled: until noon the day before checkout
  // flex-policy-24h is disabled: up to 2 days after checkout
  const flexShortenCutoffDate = isFlex24hEnabled
    ? checkoutBOD.subtract(12, "h")
    : checkoutBOD.add(2, "d").endOf("day");

  // Derive isFlexAndBeforeFlexExpiration from if flex enabled and before flex protection ending time
  const now = moment.tz(propertyTimezone);
  const isFlexAndBeforeFlexExpiration = flexEnabled && now.isBefore(flexShortenCutoffDate);
  // Derive the shortenDateMarkers based on the reservation
  let shortenDateMarkers: IDateMarker | null = null;

  if (modificationActions?.cancellable) {
    // Supplier Upcoming refundable OR flex
    // .cancellable means by supplier policy or because flex enabled
    shortenDateMarkers = {
      start: asStandardizedDate(createdAt, propertyTimezone),
      today: asStandardizedDate(now, propertyTimezone),
      end: asStandardizedDate(flexShortenCutoffDate, propertyTimezone),
      policyExpiration: cancelBy ? asStandardizedDate(cancelBy, propertyTimezone) : null,
    };
  } else if (isFlexAndBeforeFlexExpiration && modificationActions?.flexShortenable) {
    shortenDateMarkers = {
      start: asStandardizedDate(checkIn, propertyTimezone),
      today: asStandardizedDate(now, propertyTimezone),
      end: asStandardizedDate(flexShortenCutoffDate, propertyTimezone),
    };
  }

  return {
    isFlexAndBeforeFlexExpiration,
    flexShortenCutoffDate,
    shortenDateMarkers,
    flexCutoffTimes,
  };
};
