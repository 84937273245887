import type { FC } from "react";

import { Box, Icon, type IconProps, Typography } from "@hotelengine/atlas-web";
import { useAppSelector } from "store/hooks";
import { flexCoverageStartDate, formatDisplayDate } from "@hotel-engine/app/RefundPolicy/helpers";
import { getCancelDateInfo } from "@hotel-engine/utilities/helpers/getCancelDateInfo";

import * as Styled from "./styles";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useIsFeatureFlagOn } from "@hotel-engine/app/Experiments";
import { CollapsibleContent } from "../../../shared/CollapsibleContent";
import { useFlexReservationShorten } from "@hotel-engine/hooks/useFlexReservationShorten";

type RowProps = {
  iconName: IconProps["name"];
  highlightColor: IconProps["color"];
  title: string;
  description: string;
};

const RefundRow: FC<RowProps> = (props) => {
  return (
    <Styled.RefundRow data-testid={props["data-testid"]}>
      <Box paddingTop={4}>
        <Icon name={props.iconName} color={props.highlightColor} />
      </Box>
      <Styled.InfoContainer>
        <Typography variant="body/md" color={props.highlightColor}>
          {props.title}
        </Typography>
        <Typography variant="body/xs" color="foregroundSecondary">
          {props.description}
        </Typography>
      </Styled.InfoContainer>
    </Styled.RefundRow>
  );
};

const ItineraryRefundPolicy = () => {
  const user = useAppSelector((state) => state.Auth.user);
  const { reservation } = useModificationsContext();
  const { shortenDateMarkers } = useFlexReservationShorten(reservation);
  const isFlexPolicy24hEnabled = useIsFeatureFlagOn("flex-policy-24h");
  const preferredDateFormat = user?.business.preferredDateFormat || "mdy";

  const dateDisplay = !shortenDateMarkers
    ? undefined
    : `(${formatDisplayDate(
        flexCoverageStartDate(reservation, shortenDateMarkers),
        preferredDateFormat
      )} - ${formatDisplayDate(shortenDateMarkers?.end, preferredDateFormat)})`;

  // noRefundAvailableCopy is only used below if shortenDateMarkers is falsy, meaning no shorten path is available
  const noRefundAvailableCopy = reservation.flexEnabled
    ? "Flex has expired for this trip."
    : "No refund available for this reservation.";

  const flexCoverageCopy = isFlexPolicy24hEnabled
    ? "Receive travel credit for unused nights with Flex Coverage, if you shorten by noon on your actual checkout date"
    : "Receive travel credit for unused nights with Flex Coverage, even up to 48 hours after a trip";

  const { refundPolicy } = getCancelDateInfo({
    flexEnabled: false,
    cancelBy: reservation.cancelBy,
    propertyTimezone: reservation.propertyTimezone,
    checkIn: reservation.checkIn,
    preferredDateFormat,
    flexProEnrolled: reservation.flexType === "flex_pro",
  });

  return (
    <CollapsibleContent
      toggleTestID="toggle-refund-policy"
      toggleBtnLabel={
        <Box display="flex" gap={12} alignItems="center">
          <Typography
            variant="heading/lg"
            data-testid="ItineraryRefundPolicy_RefundRow__toggle-refund-policy"
          >
            Refund policy
          </Typography>
        </Box>
      }
    >
      <Box paddingLeft={24} paddingRight={24} marginBottom={24}>
        {shortenDateMarkers ? (
          <Styled.RefundContent>
            {Boolean(shortenDateMarkers.policyExpiration) && (
              <RefundRow
                iconName="circle-check--solid"
                highlightColor="accentGreenStrong"
                title="Fully refundable"
                description={`before ${refundPolicy}`}
                data-testid="ItineraryRefundPolicy_RefundRow__fully-refundable"
              />
            )}
            {Boolean(reservation.flexEnabled) && (
              <RefundRow
                iconName="he-flex"
                highlightColor="foregroundPrimary"
                title={`Flex Coverage ${dateDisplay}`}
                description={flexCoverageCopy}
                data-testid="ItineraryRefundPolicy_RefundRow__flex-coverage"
              />
            )}
          </Styled.RefundContent>
        ) : (
          <Styled.RefundContent>
            <RefundRow
              iconName="circle-exclamation"
              highlightColor="sentimentCautionaryDefault"
              title="Non-refundable"
              description={noRefundAvailableCopy}
              data-testid="ItineraryRefundPolicy_RefundRow__non-refundable-text"
            />
          </Styled.RefundContent>
        )}
      </Box>
    </CollapsibleContent>
  );
};

export default ItineraryRefundPolicy;
