import styled from "styled-components";

import TextAreaField from "@hotel-engine/form/TextAreaField";

export const ReviewTextArea = styled(TextAreaField)`
  width: 100%;
  height: 108px;
`;

export const Wrapper = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
`;

export const CharacterCount = styled.div`
  position: absolute;
  left: 0px;
  bottom: -22px;
  color: ${({ theme }) => theme.colors.accentGray};
  font-size: 12px;
`;

export const Divide = styled.span`
  margin-left: 0px;
  margin-right: 1px;
`;
