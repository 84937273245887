import type { Dictionary, EntityId } from "@reduxjs/toolkit";
import type { IFlightResult, ShelfOrdinalType } from "@hotelengine/core-booking-web";
import type {
  ITravelerManifest,
  IUserTravelPolicy,
} from "@hotel-engine/types/flights/flights.request.types";

export interface IFlightSearchError {
  code?: string;
  status?: number;
  message: string;
}

export interface IFlightsSearchResultsState {
  entities: Dictionary<IFlightResult>;
  error: IFlightSearchError | null;
  ids: EntityId[];
  searchId: string | null;
  travelerManifest: ITravelerManifest | null;
  userTravelPolicy: IUserTravelPolicy | null;
}

export enum MessageTypes {
  SEARCH_CANCELLED = "aborted",
  SEARCH_ERROR = "error",
  RESULTS_FILTERED = "filtered",
  NO_RESULTS = "empty",
  MISSING_PARAMS = "missingParams",
  TP_FILTERED = "travelPolicyFiltered",
}

export enum ErrorMessages {
  INTERNATIONAL_TRAVEL_NOT_ALLOWED = "International travel not allowed",
}

export interface IFlightsFilteredResults {
  filteredResults: IFlightResult[];
  inPolicyOffCount: number;
  shelfOffMinPrice: Record<ShelfOrdinalType, number>;
}
