import { Box } from "@hotelengine/atlas-web";
import type { IBooking } from "@hotel-engine/types/trips";

import * as Styled from "pages/Trips/views/TripsList/styles";
import WrapInStrong from "pages/Trips/views/TripsList/WrapInStrong";

export interface ILodgingDetailCellProps {
  data: IBooking;
  searchValue: string;
  statusChip: React.ReactNode;
}

const LodgingDetailCell = ({ data, searchValue, statusChip }: ILodgingDetailCellProps) => {
  const details = data.details;
  const roomCount = data?.roomCount || 0;
  const cancelledRoomCount = data?.cancelledRoomCount || 0;
  const roomCountCopy = `${roomCount > 1 ? roomCount : cancelledRoomCount} rooms`;
  const showRoomCountCopy = roomCount > 1 || (roomCount === 0 && cancelledRoomCount > 1);

  if (!details?.property) {
    return <Styled.PrimaryText>No lodging details available</Styled.PrimaryText>;
  }

  return (
    <Styled.TripDetailWrapper>
      <Styled.ImageContainer>
        <Styled.TripImage src={details?.property.imageUrl} />
      </Styled.ImageContainer>
      <Box display="flex" flexDirection="column" gap={4} alignItems="flex-start">
        <div>
          <Styled.PrimaryText>
            <WrapInStrong term={searchValue} target={details?.property.name} />
          </Styled.PrimaryText>
          {(!!details?.externalConfirmationNumber || !!showRoomCountCopy) && (
            <Styled.SubText>
              {showRoomCountCopy ? roomCountCopy : details.externalConfirmationNumber}
            </Styled.SubText>
          )}
        </div>
        {statusChip}
      </Box>
    </Styled.TripDetailWrapper>
  );
};

export default LodgingDetailCell;
