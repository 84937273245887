import type { AxiosError } from "axios";
import { useEffect } from "react";

import { Box, Button, IconButton, Typography } from "@hotelengine/atlas-web";
import { captureException } from "@hotel-engine/utilities/logger";
import appConfig from "config";

import * as Styled from "./styles";

interface IItineraryErrorProps {
  reload: () => void;
  goBack: () => void;
  error: AxiosError | null;
}

function sanitizeAxiosError(error: AxiosError) {
  if (!error || !error.config) return error;

  const config = { ...error.config };

  if (!!config.headers && "Authorization" in config.headers) {
    delete config.headers.Authorization;
  }

  return { ...error, config };
}

const ItineraryError = ({ reload, goBack, error }: IItineraryErrorProps) => {
  const errorStatus = error?.status;
  const isNotFound = errorStatus === 404;

  const errorMessage = isNotFound
    ? "We couldn't find that booking"
    : "There was an issue loading your booking";
  const actionText = isNotFound ? "Go back" : "Try again";
  const onAction = isNotFound ? goBack : reload;

  useEffect(() => {
    if (!error) return;

    captureException(new Error(`Error loading itinerary preview panel: ${error.message}`), {
      error: sanitizeAxiosError(error),
      context: {
        scope: "ItineraryPreviewPanel",
      },
    });
  }, [error]);

  return (
    <Box backgroundColor="backgroundSecondary" padding={8} style={{ height: "100%" }}>
      <IconButton
        aria-label="Close preview panel"
        onClick={goBack}
        icon="xmark"
        variant="outlined"
        color="secondary"
        size="sm"
      />
      <Styled.TripsError data-testid="trips-error">
        <img
          src={`${appConfig.cdnHost}/assets/trips/error-trips.svg`}
          alt="Error loading booking"
        />
        <Typography
          variant="body/md"
          color="foregroundPrimary"
          data-testid={isNotFound ? "booking-not-found-error" : "itinerary-error"}
        >
          {errorMessage}
        </Typography>
        <Button data-testid="error-action" onClick={onAction}>
          {actionText}
        </Button>
      </Styled.TripsError>
    </Box>
  );
};

export default ItineraryError;
