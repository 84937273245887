import { ampli } from "ampli";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import {
  ModificationTypes,
  useModificationsContext,
} from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { useTrackBookingModifications } from "@hotel-engine/app/ItineraryContent/hooks/useTrackBookingModifications";
import { routes } from "@hotel-engine/constants";
import { useNavigate } from "@hotel-engine/lib/react-router-dom";

import type { IModificationOptionsProps, OptionItem } from "../types";
import ChangeDatesContent from "./ChangeDatesContent";
import OptionItemFactory from "./OptionItemFactory";

import * as Styled from "../styles";

import { getCancelDateInfo } from "@hotel-engine/utilities/helpers/getCancelDateInfo";

const ModificationOptions = ({
  itinerary,
  handleLateCheckInModal,
  handleTripExtensionModal,
}: IModificationOptionsProps) => {
  const { t } = useTranslation("itinerary");
  const navigate = useNavigate();
  const { trackEnterModificationFlow } = useTrackBookingModifications();
  const { dispatch: modificationsDispatch } = useModificationsContext();

  const handleModifyReservation = useCallback(
    (modificationType: ModificationTypes) => {
      navigate(
        `${routes.modifyReservation}/${itinerary.id}?modification_type=${modificationType}`,
        {
          state: { itinerary },
        }
      );
      ampli.clickModifyThisReservation({
        bookingId: itinerary.id,
      });
    },
    [itinerary, navigate]
  );

  const handleSetActiveModification = useCallback(
    (type: ModificationTypes) => {
      trackEnterModificationFlow(type);
      modificationsDispatch({
        type: "SET_MODIFICATION_VIEW",
        payload: type,
      });
    },
    [modificationsDispatch, trackEnterModificationFlow]
  );

  // TODO: Amplitude tracking [LODGE-6482]
  const handleExtendTrip = useCallback(() => {
    // ampli.clickExtendBooking({
    //   viewUrl: globalThis?.location.href,
    //   userId: user?.id,
    //   bookingId: itinerary.id,
    // });
    handleTripExtensionModal?.();
  }, [handleTripExtensionModal]);

  // TODO: Amplitude tracking [LODGE-6482]
  const handleLateCheckIn = useCallback(() => {
    // ampli.clickRequestLateCheckIn({
    //   viewUrl: globalThis?.location.href,
    //   userId: user?.id,
    //   bookingId: itinerary.id,
    // });

    handleLateCheckInModal?.();
  }, [handleLateCheckInModal]);

  // TODO: Amplitude tracking [LODGE-6482]
  const handleChangeGuests = useCallback(() => {
    // ampli.clickChangeGuest({
    //   viewUrl: globalThis?.location.href,
    //   userId: user?.id,
    //   bookingId: itinerary.id,
    // });
    handleModifyReservation(ModificationTypes.ChangeGuests);
  }, [handleModifyReservation]);

  // TODO: Amplitude tracking [LODGE-6482]
  const handleAddSpecialRequest = useCallback(() => {
    // ampli.clickAddSpecialRequest({
    //   viewUrl: globalThis?.location.href,
    //   userId: user?.id,
    //   bookingId: itinerary.id,
    // });
    handleModifyReservation(ModificationTypes.SpecialRequest);
  }, [handleModifyReservation]);

  // TODO: Amplitude tracking [LODGE-6482]
  const handleCancelBooking = useCallback(() => {
    // ampli.clickCancelTrip({
    //   viewUrl: globalThis?.location.href,
    //   userId: user?.id,
    //   bookingId: itinerary.id,
    // });
    handleSetActiveModification(ModificationTypes.Cancel);
  }, [handleSetActiveModification]);

  const optionsList: OptionItem[] = [
    {
      type: "link",
      icon: "user-group",
      iconColor: "brandAccent",
      title: t("modifications.options.changeGuests.title"),
      description: t("modifications.options.changeGuests.description"),
      action: handleChangeGuests,
      enabled: !!itinerary.modificationActions.modifiable,
    },
    {
      type: "link",
      icon: "calendar-plus",
      iconColor: "brandExtendedYellow",
      title: t("modifications.options.extendBooking.title"),
      description: t("modifications.options.extendBooking.description"),
      action: handleExtendTrip,
      enabled: !!itinerary.modificationActions.extendable,
    },
    {
      type: "link",
      icon: "person-walking-luggage",
      iconColor: "brandExtendedGreen",
      title: t("modifications.options.lateCheckIn.title"),
      description: t("modifications.options.lateCheckIn.description"),
      action: handleLateCheckIn,
      enabled: !!itinerary.modificationActions.modifiable,
    },
    {
      type: "collapsible",
      icon: "calendar-pen",
      iconColor: "brandExtendedTeal",
      title: t("modifications.options.changeDates.title"),
      description: t("modifications.options.changeDates.description"),
      content: (
        <ChangeDatesContent
          bookingId={itinerary.id}
          modificationActions={itinerary.modificationActions}
          flexEnabled={itinerary.flexEnabled}
          extendTripHandler={handleTripExtensionModal}
          modifyTripHandler={(type: ModificationTypes) => handleModifyReservation(type)}
        />
      ),
      enabled: !!itinerary.modificationActions.modifiable,
    },
    {
      type: "link",
      icon: "bell-concierge",
      iconColor: "accentPurpleLight",
      title: t("modifications.options.addSpecialRequest.title"),
      description: t("modifications.options.addSpecialRequest.description"),
      action: handleAddSpecialRequest,
      enabled: !!itinerary.modificationActions.modifiable,
    },
    {
      type: "link",
      icon: "calendar-xmark",
      iconColor: "brandPrimary",
      title: t("modifications.options.cancelBooking.title"),
      description: t("modifications.options.cancelBooking.description", {
        date: getCancelDateInfo({
          flexEnabled: itinerary.flexEnabled,
          cancelBy: itinerary.cancelBy,
          propertyTimezone: itinerary.propertyTimezone,
          checkIn: itinerary.checkIn,
          preferredDateFormat: "mdy",
          flexProEnrolled: itinerary.flexType === "flex_pro",
        }).refundPolicy,
      }),
      action: handleCancelBooking,
      enabled: !!itinerary.modificationActions.cancellable,
    },
  ];

  return (
    <>
      <Styled.BoxedSelectorList>
        {optionsList
          .filter((option) => option.enabled)
          .map((option) => (
            <li key={option.title}>{OptionItemFactory.createComponent(option)}</li>
          ))}
      </Styled.BoxedSelectorList>
    </>
  );
};

export default ModificationOptions;
