import type { IDirectBill } from "@hotel-engine/types/booking";

/**
 * @deprecated
 *
 * Legacy lodging constant, do not use this in any new code
 */
export const directBillLegacy: IDirectBill = {
  id: 0,
  name: "Direct Bill",
  type: "directbill",
};
