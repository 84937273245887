import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";

import UpcomingActiveActions from "./components/UpcomingActiveActions";
import PastCancelledActions from "./components/PastCancelledActions";

const ItineraryActions = () => {
  const {
    reservation: itinerary,
    state: { modificationView },
  } = useModificationsContext();

  const isReservationUpcomingOrActive = ["booked", "visiting"].includes(itinerary.status);

  const isReservationPastOrCancelled = ["completed", "cancelled"].includes(itinerary.status);

  return modificationView ? null : (
    <>
      {!!isReservationUpcomingOrActive && <UpcomingActiveActions itinerary={itinerary} />}
      {!!isReservationPastOrCancelled && <PastCancelledActions itinerary={itinerary} />}
    </>
  );
};

export default ItineraryActions;
