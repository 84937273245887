import { DateTime, Interval } from "luxon";

import type {
  IFareSummaries,
  IFlightResult,
  IShelfSummary,
  KnownCabinClass,
  ShelfOrdinalType,
} from "@hotelengine/core-booking-web";
import type {
  FlightsSelectedFilter,
  IUserMinMaxNumbers,
} from "store/Flights/FlightsFilters/flights.filters.types";
import {
  StopFilterOptions,
  flightsStoreFilterKeys,
} from "store/Flights/FlightsFilters/flights.filters.constants";
import {
  type IFlightsFilteredResults,
  type IFlightSearchError,
  ErrorMessages,
  MessageTypes,
} from "store/Flights/FlightsResults/flights.results.types";

import {
  getDurationInMinutes,
  getTechnicalStopsCount,
} from "../FlightsFilters/flights.filters.helpers";
import { INTERNATIONAL_FLIGHTS_ERROR } from "./flights.results.constants";

const fromShelfSummaryArrayToFareSummaries = (shelfSummaries: IShelfSummary[]): IFareSummaries => {
  return shelfSummaries.reduce<IFareSummaries>((acc, shelfSummary) => {
    const key: keyof IFareSummaries = `shelf${
      shelfSummary.ngsShelfOrdinal as ShelfOrdinalType
    }FareSummary` as const;

    acc[key] = shelfSummary;

    return acc;
  }, {});
};

export const matchSelectedStopFilter = (
  flight: IFlightResult,
  filterValue: StopFilterOptions
): boolean => {
  const numberOfTechnicalStops = getTechnicalStopsCount(flight.segments);
  const numberOfStopsBetweenSegments = flight.segments.length - 1;
  const numberOfStops = numberOfStopsBetweenSegments + numberOfTechnicalStops;

  if (filterValue === StopFilterOptions.any) {
    return true;
  }

  if (filterValue === StopFilterOptions.nonStop) {
    return numberOfStops === 0;
  }

  if (filterValue === StopFilterOptions.oneOrNonStop) {
    return numberOfStops === 0 || numberOfStops === 1;
  }

  return false;
};

export const filterBySelectedShelfFilter = (
  shelfSummaries: IShelfSummary[],
  selectedShelfBlock: ShelfOrdinalType | null
) => {
  if (selectedShelfBlock === null) return shelfSummaries;

  return shelfSummaries.filter(
    (shelfSummary) => shelfSummary.ngsShelfOrdinal === selectedShelfBlock
  );
};

export const matchSelectedTakeoffTimeFilter = (
  result: IFlightResult,
  { userMin, userMax }: IUserMinMaxNumbers,
  departureDate: string
) => {
  if (!userMin || !userMax) {
    return true;
  }
  const originSegment = result.segments[0];
  if (!originSegment) return false;

  const segmentTakeOffDateTime = DateTime.fromISO(originSegment.origin.timestamp, {
    setZone: true,
  });

  const originTimeZone = segmentTakeOffDateTime.zoneName ?? undefined;

  const { year, month, day } = DateTime.fromISO(departureDate);

  const departureDateAtMidnight = DateTime.fromObject(
    {
      year,
      month,
      day,
    },
    { zone: originTimeZone }
  );

  const minTakeoffDateTime = departureDateAtMidnight.plus({
    minutes: userMin,
  });

  const maxTakeoffDateTime = departureDateAtMidnight.plus({
    minutes: userMax + 1,
  });

  const selectedTakeOffTimeInterval = Interval.fromDateTimes(
    minTakeoffDateTime,
    maxTakeoffDateTime
  );

  return selectedTakeOffTimeInterval.contains(segmentTakeOffDateTime);
};

export const matchSelectedAirlineFilter = (result: IFlightResult, airlineIataCodes: string[]) => {
  const selectedAirlines = new Set(airlineIataCodes);

  // If no airlines are selected, result meets the filter
  if (selectedAirlines.size === 0) return true;

  return result.segments.some(
    (segment) =>
      selectedAirlines.has(segment.owner.iataCode) ||
      selectedAirlines.has(segment.operator.iataCode)
  );
};

export const filterByCabinClass = (
  fareSummaries: IShelfSummary[],
  selectedCabinClasses: KnownCabinClass[]
) => {
  if (selectedCabinClasses.length === 0) {
    return fareSummaries;
  }

  const filteredFares = fareSummaries.filter((fareSummary) =>
    fareSummary.cabinClasses?.some((cabin) =>
      selectedCabinClasses.some(
        (selectedCabin) => selectedCabin.valueOf() === cabin.cabinClass.valueOf()
      )
    )
  );

  return filteredFares;
};

export const matchSelectedLandingTimeFilter = (
  result: IFlightResult,
  { userMin, userMax }: IUserMinMaxNumbers,
  departureDate: string
) => {
  if (!userMin || !userMax) {
    return true;
  }
  const destinationSegment = result.segments.at(-1);

  if (!destinationSegment) return false;

  const segmentLandingDateTime = DateTime.fromISO(destinationSegment.destination.timestamp, {
    setZone: true,
  });
  const originTimeZone = segmentLandingDateTime.zoneName ?? undefined;

  const { year, month, day } = DateTime.fromISO(departureDate);
  const departureDateAtMidnight = DateTime.fromObject(
    {
      year,
      month,
      day,
    },
    { zone: originTimeZone }
  );
  const minLandingDateTime = departureDateAtMidnight.plus({
    minutes: userMin,
  });
  const maxLandingDateTime = departureDateAtMidnight.plus({
    minutes: userMax + 1,
  });
  const selectedTLandingTimeInterval = Interval.fromDateTimes(
    minLandingDateTime,
    maxLandingDateTime
  );

  return selectedTLandingTimeInterval.contains(segmentLandingDateTime);
};

export const filterByInPolicyFilter = (
  shelfSummaries: (IShelfSummary | null)[],
  inPolicy: boolean
) => {
  const filteredShelfSummaries = shelfSummaries.filter((fareSummary) => fareSummary !== null);
  if (!inPolicy) {
    return filteredShelfSummaries;
  }
  return filteredShelfSummaries.filter(
    (fareSummary) =>
      !(
        fareSummary.travelPolicy.exceedsMaxPrice ||
        fareSummary.travelPolicy.exceedsCabinClassAllowance
      )
  );
};

export const filterBySelectedPriceRangeFilter = (
  shelfSummaries: (IShelfSummary | null)[],
  { userMin, userMax }: IUserMinMaxNumbers,
  selectedFilters: FlightsSelectedFilter[],
  passengersCount: number
) => {
  let filteredShelfSummaries = shelfSummaries.filter((shelfSummary) => shelfSummary !== null);
  if (!userMin || !userMax) {
    return filteredShelfSummaries;
  }
  // Check if the user has selected a specific shelf
  const selectedShelfFilter = selectedFilters.find((filter) => filter.type === "shelf");

  const userMinRounded = Math.round(userMin * passengersCount);
  const userMaxRounded = Math.round(userMax * passengersCount);

  const filterBySpecificShelf = typeof selectedShelfFilter?.value === "number";
  if (filterBySpecificShelf) {
    filteredShelfSummaries = filteredShelfSummaries.filter(
      (shelfSummary) => shelfSummary.ngsShelfOrdinal === selectedShelfFilter?.value
    );
  }
  return filteredShelfSummaries.filter((fareSummary) => {
    const price = Math.round(fareSummary.price.totalValue);
    return price >= userMinRounded && price <= userMaxRounded;
  });
};

export const matchSelectedDurationFilter = (
  result: IFlightResult,
  { userMin, userMax }: IUserMinMaxNumbers
) => {
  if (!userMin || !userMax) {
    return true;
  }
  const durationInMinutes = getDurationInMinutes(result.totalDuration);
  return durationInMinutes >= userMin && durationInMinutes <= userMax;
};

/**
 * It updates the minimum price for each shelf regardless of the active shelf filter.
 * This is used to allow the user to switch from one shelf to another shelf meets the selected filters.
 */
const getShelfOffMinPrice = (
  shelfSummaries: IShelfSummary[],
  initial: IFlightsFilteredResults["shelfOffMinPrice"]
) => {
  return shelfSummaries.reduce((acc, shelfSummary) => {
    const price = shelfSummary.price.totalValue;
    if (acc[shelfSummary.ngsShelfOrdinal] === 0 || price < acc[shelfSummary.ngsShelfOrdinal]) {
      acc[shelfSummary.ngsShelfOrdinal] = price;
    }
    return acc;
  }, initial);
};

const filterResultFares = (
  fareSummaries: IFareSummaries,
  selectedFilters: FlightsSelectedFilter[],
  passengersCount: number
) => {
  let filteredFareSummaries = (Object.values(fareSummaries) as IShelfSummary[]).filter(
    (fareSummary) => fareSummary !== null
  );

  const cabinClassFilter = selectedFilters.find(
    (filter) => filter.type === flightsStoreFilterKeys.cabinClass
  );
  if (cabinClassFilter) {
    filteredFareSummaries = filterByCabinClass(
      filteredFareSummaries,
      cabinClassFilter?.value ?? []
    );
  }

  const priceRangeFilter = selectedFilters.find(
    (filter) => filter.type === flightsStoreFilterKeys.priceRange
  );
  if (priceRangeFilter) {
    filteredFareSummaries = filterBySelectedPriceRangeFilter(
      filteredFareSummaries,
      priceRangeFilter.value,
      selectedFilters,
      passengersCount
    );
  }

  const filteredFareSummariesAllShelvesIncludingOutOfPolicies = filteredFareSummaries;

  const shelfFilter = selectedFilters.find(
    (filter) => filter.type === flightsStoreFilterKeys.shelf
  );
  if (shelfFilter) {
    filteredFareSummaries = filterBySelectedShelfFilter(filteredFareSummaries, shelfFilter.value);
  }

  const filteredFareSummariesIncludingOutOfPolicies = filteredFareSummaries;

  const inPolicyFilter = selectedFilters.find(
    (filter) => filter.type === flightsStoreFilterKeys.inPolicy
  );
  if (inPolicyFilter) {
    filteredFareSummaries = filterByInPolicyFilter(filteredFareSummaries, inPolicyFilter.value);
  }

  return {
    filteredFareSummaries,
    filteredFareSummariesAllShelvesIncludingOutOfPolicies,
    filteredFareSummariesIncludingOutOfPolicies,
  };
};

interface IFilterFlightsResultsArgs {
  results: IFlightResult[];
  selectedFilters: FlightsSelectedFilter[];
  departureDate: string | null;
  passengersCount: number;
}

/**
 * Filters the results based on the selected filters.
 *
 * The results are filtered in the following order:
 * 1. All filters except shelf and inPolicy.
 * 2. Shelf filter.
 * 3. In-policy filter.
 *
 * @returns {{ filteredResults: IFlightResult[], inPolicyOffCount: number, shelfOffMinPrice: Record<ShelfOrdinalType, number> }}
 * - filteredResults: The results that match all the selected filters.
 * - inPolicyOffCount: The number of results that will be visible if the user toggle off the in-policy filter.
 * - shelfOffMinPrice: The minimum price for each shelf regardless the active shelf filter.
 */
export const filterFlightsResults = ({
  results,
  selectedFilters,
  departureDate,
  passengersCount,
}: IFilterFlightsResultsArgs) => {
  const response: IFlightsFilteredResults = {
    filteredResults: [],
    inPolicyOffCount: 0,
    shelfOffMinPrice: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
  };

  if (results.length === 0) return response;

  results.forEach((result) => {
    const matchesRegularFilters = selectedFilters.every((selectedFilter) => {
      if (selectedFilter.type === flightsStoreFilterKeys.stops) {
        return matchSelectedStopFilter(result, selectedFilter.value);
      }

      if (selectedFilter.type === flightsStoreFilterKeys.airlines) {
        return matchSelectedAirlineFilter(result, selectedFilter.value);
      }

      if (selectedFilter.type === flightsStoreFilterKeys.takeoffTime && departureDate) {
        return matchSelectedTakeoffTimeFilter(result, selectedFilter.value, departureDate);
      }

      if (selectedFilter.type === flightsStoreFilterKeys.landingTime && departureDate) {
        return matchSelectedLandingTimeFilter(result, selectedFilter.value, departureDate);
      }

      if (selectedFilter.type === flightsStoreFilterKeys.duration) {
        return matchSelectedDurationFilter(result, selectedFilter.value);
      }

      return true;
    });

    if (!matchesRegularFilters) {
      return;
    }

    const {
      filteredFareSummaries,
      filteredFareSummariesIncludingOutOfPolicies,
      filteredFareSummariesAllShelvesIncludingOutOfPolicies,
    } = filterResultFares(result.fareSummaries, selectedFilters, passengersCount);

    if (filteredFareSummariesAllShelvesIncludingOutOfPolicies.length) {
      response.shelfOffMinPrice = getShelfOffMinPrice(
        filteredFareSummariesAllShelvesIncludingOutOfPolicies,
        response.shelfOffMinPrice
      );
    }

    const noFaresMatchingFilter = !filteredFareSummariesIncludingOutOfPolicies.length;
    if (noFaresMatchingFilter) {
      return;
    }

    response.inPolicyOffCount += 1;

    if (!filteredFareSummaries.length) {
      return;
    }

    const resultWithFilteredFares = {
      ...result,
      fareSummaries: fromShelfSummaryArrayToFareSummaries(filteredFareSummaries),
    };

    // if all the filters match, the result is visible
    response.filteredResults.push(resultWithFilteredFares);
  });

  return response;
};

export const ErrorTitle = {
  UH_OH: "UH OH",
  NO_RESULTS: "NO RESULTS",
};

const errorImageBg = "/assets/flights/search-error/tile-bg-1x.png";

export const getFlightErrorInfo = (
  searchCancelled: boolean,
  error: IFlightSearchError | null,
  resultsCount: number,
  filteredResultsCount: number,
  isFlightsMobile: boolean,
  isLoading: boolean,
  isMissingParams: boolean,
  inPolicyOffCount: number
) => {
  if (isMissingParams) {
    return {
      errorTitle: ErrorTitle.UH_OH,
      errorKey: MessageTypes.MISSING_PARAMS,
      errorMessage: "Something got mixed up.",
      btnText: "Start a new search",
      image: {
        src: errorImageBg,
        alt: "Something got mixed up.",
      },
    };
  }

  if (searchCancelled) {
    return {
      errorTitle: ErrorTitle.NO_RESULTS,
      errorKey: MessageTypes.SEARCH_CANCELLED,
      errorMessage: "Your search was cancelled so here we are. Try a new search.",
      btnText: isFlightsMobile ? "Retry search" : undefined,
      image: {
        src: errorImageBg,
        alt: "Search was cancelled.",
      },
    };
  }

  if (!!error?.status && error?.status >= 300) {
    let errorMessage = "Something got mixed up.";
    let btnText = "Retry search";
    let errorKey = MessageTypes.SEARCH_ERROR;
    if (error?.status == 422 && error?.message == ErrorMessages.INTERNATIONAL_TRAVEL_NOT_ALLOWED) {
      errorMessage = INTERNATIONAL_FLIGHTS_ERROR;
      btnText = "Edit your search";
      errorKey = MessageTypes.NO_RESULTS;
    }
    return {
      errorTitle: ErrorTitle.UH_OH,
      errorKey,
      errorMessage,
      btnText: btnText,
      image: {
        src: errorImageBg,
        alt: "Something got mixed up.",
      },
    };
  }

  if (resultsCount === 0 && !isLoading) {
    return {
      errorTitle: ErrorTitle.NO_RESULTS,
      errorKey: MessageTypes.NO_RESULTS,
      errorMessage: "Bummer, no flights were found. Try adjusting your search.",
      btnText: isFlightsMobile ? "Edit search" : undefined,
      image: {
        src: errorImageBg,
        alt: "No flights found.",
      },
    };
  }

  if (resultsCount > 0 && !isLoading && filteredResultsCount === 0) {
    if (inPolicyOffCount > 0) {
      return {
        errorTitle: ErrorTitle.NO_RESULTS,
        errorKey: MessageTypes.TP_FILTERED,
        errorSubtitle: "Your travel policy is limiting your results",
        errorMessage:
          "Turn off the travel policy filter to see additional flights that have been restricted by your travel policy.",
        btnText: "Turn off filter",
        image: {
          src: errorImageBg,
          alt: "Results filtered out.",
        },
      };
    } else {
      return {
        errorTitle: ErrorTitle.NO_RESULTS,
        errorKey: MessageTypes.RESULTS_FILTERED,
        errorMessage: "Bummer, no flights were found. Try adjusting your filters.",
        btnText: isFlightsMobile ? "Edit filters" : "Clear filters",
        image: {
          src: errorImageBg,
          alt: "Results filtered out.",
        },
      };
    }
  }

  return null;
};
