import { Box, Skeleton, Typography } from "@hotelengine/atlas-web";
import { useTranslation } from "react-i18next";
import * as Styled from "../styles";

const TripsSkeleton = ({ isMobileScreen }: { isMobileScreen: boolean }) => {
  const { t } = useTranslation("trips");

  return (
    <Styled.ViewWrapper>
      <Styled.Trips $mapView={false} $showPreview={false}>
        <Styled.UpperContainer $isMobile={isMobileScreen}>
          <Styled.Header>
            <Typography variant="heading/xl" color="foregroundPrimary">
              {t("tripsPage.title")}
            </Typography>
            <Box style={{ width: "175px" }}>
              <Skeleton height={40} />
            </Box>
          </Styled.Header>
        </Styled.UpperContainer>
        <Styled.Inner style={{ paddingTop: isMobileScreen ? "24px" : "12px" }}>
          <Skeleton height={500} />
        </Styled.Inner>
      </Styled.Trips>
    </Styled.ViewWrapper>
  );
};

export default TripsSkeleton;
