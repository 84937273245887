import { Chip } from "@hotelengine/atlas-web";

export interface IStatusChipProps {
  pendingModifications?: boolean;
  isFullyCancelled?: boolean;
  cancelledRoomCount?: number;
}

const StatusChip = ({
  pendingModifications,
  isFullyCancelled,
  cancelledRoomCount = 0,
}: IStatusChipProps) => {
  return (
    <>
      {!!isFullyCancelled && <Chip color="red" label="Booking cancelled" size="xs" />}
      {!isFullyCancelled && !!pendingModifications && (
        <Chip color="yellow" label="Request pending" size="xs" />
      )}
      {!isFullyCancelled && cancelledRoomCount > 0 && (
        <Chip
          color="orange"
          label={`${cancelledRoomCount} room${cancelledRoomCount > 1 ? "s" : ""} cancelled`}
          size="xs"
        />
      )}
    </>
  );
};

export default StatusChip;
