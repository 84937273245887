import { Str } from "@hotel-engine/data";

export const capitalize = Str.capitalize;

export const convertToKebab = (str: string) =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    ?.map((x) => x.toLowerCase())
    ?.join("-");

export const camelToSnake = (str: string) =>
  str.replace(/[\w]([A-Z])/g, (group) => `${group[0]}_${group[1]}`.toLowerCase());

export const snakeToCamel = (str: string) =>
  str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", ""));

export const generateSnakeCaseStatusFilter = (status: string[]) => {
  const statusSplitted = status.map((word: string) =>
    word
      .split(/(?=[A-Z])/)
      .join("_")
      .toLowerCase()
  );

  return statusSplitted;
};

export const capitalizeAll = (text: string) => {
  const splittedByUnderline = text.split("_");
  const allWordsCapitalized = splittedByUnderline.map((word: string) => capitalize(word));
  return allWordsCapitalized.join(" ");
};

export const capitalizeSpaceSeperated = (text: string) => {
  const splittedBySpace = text.split(" ");
  const allWordsCapitalized = splittedBySpace.map((word: string) => capitalize(word));

  return allWordsCapitalized.join(" ");
};

export const formatCommaSeparatedCapitalize = (input) => {
  const words = input.split(",");

  const formattedArray = words.map((word) => {
    const capitalizedWord = word
      .split("_")
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");
    return capitalizedWord;
  });

  return formattedArray.join(", ");
};

/**
 * Pluralizes a word based on the count
 * @param count - The count of items to display
 * @param noun - The word to pluralize
 * @param suffix - The suffix to add to the word
 * @param preventZeroCount - Set to true if you dont want to display "0"
 * @returns A string with the count and pluralized word
 */
export const pluralize = (count: number, noun: string, suffix = "s", preventZeroCount = false) =>
  `${Boolean(preventZeroCount) && count === 0 ? "" : count?.toLocaleString() || 0} ${noun}${
    count !== 1 ? suffix : ""
  }`;

/**
 * Converts a string with spaces to camelCase format.
 *
 * @param str - Any string with spaces
 * @returns A new string converted to camelCase format.
 */
export const convertStringToCamelCase = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};
