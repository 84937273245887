import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { ClusterFeature } from "supercluster";

import type { MapPosition, TripWithCluster } from "@hotel-engine/app/GoogleMap/types";
import {
  defaultClusterOptions,
  SPECIFIC_PROPERTY_ZOOM,
} from "@hotel-engine/app/GoogleMap/constants";
import GoogleMapsService from "@hotel-engine/services/GoogleMapsService";
import type { ITrip } from "@hotel-engine/types/trips";

import { supercluster } from "../../../GoogleMapsClusters/supercluster";
import PropertyCluster from "../../../GoogleMapsClusters";
import GoogleMapsTripsMarker from "../../../Markers/GoogleMapsTripsMarker";
import {
  formatTripsDataToGeoJsonPoints,
  returnTripsBounds,
  returnClusters,
} from "../../tripsHelpers";
import useTripsResult from "pages/Trips/hooks/useTripsResult";
import { useSearchParams } from "@hotel-engine/lib/react-router-dom";

type GoogleTripsProps = {
  /** Used for waiting to fit map bounds once results are loaded */
  loading: boolean;
  /** Google map instance */
  map: google.maps.Map;
};

const GoogleMapsTripResults = ({ loading, map }: GoogleTripsProps) => {
  const clustersRendered = useRef(false);
  const [clusters, setClusters] = useState<ClusterFeature<TripWithCluster>[]>([]);
  const [qs, setSearchParams] = useSearchParams();
  const { data: tripsResult } = useTripsResult();
  const bookingNumber = qs.get("booking_number");

  const googleMapsService = GoogleMapsService();

  useEffect(() => {
    const listeners = {
      zoom_changed: handleUpdateMapClusters,
      idle: handleUpdateMapClusters,
    };
    if (!!map) {
      googleMapsService.addListeners(map, listeners);
    }

    return () => {
      if (!!map) {
        googleMapsService.removeListeners(map, listeners);
      }
    };
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    if (tripsResult?.trips.length) {
      setClusters([]);
      supercluster.load(formatTripsDataToGeoJsonPoints(tripsResult.trips as ITrip[]));

      if (!loading && !!map) {
        /** If the property is undefined fit the bounds and center the map */
        map.fitBounds(
          returnTripsBounds(new google.maps.LatLngBounds(), tripsResult.trips as ITrip[])
        );

        if (!!bookingNumber) {
          const selectedTrip = tripsResult.trips.find(
            (trip) =>
              trip.bookings[0].bookingType === "lodging" &&
              trip.bookings[0].details.internalConfirmationNumber === bookingNumber
          );

          if (selectedTrip?.bookings[0].bookingType === "lodging") {
            const position = {
              lat: Number(selectedTrip?.bookings[0].details.property?.latitude),
              lng: Number(selectedTrip?.bookings[0].details.property?.longitude),
            };

            map.setOptions({ zoom: SPECIFIC_PROPERTY_ZOOM, center: position });
          }
        }
      }
      handleUpdateMapClusters();
    }
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, map, tripsResult?.trips.length, bookingNumber]);

  const handleUpdateMapClusters = useCallback(() => {
    const currentZoom = map?.get("zoom");
    const shouldUpdateClusters =
      !!bookingNumber ||
      defaultClusterOptions.maxZoom >= currentZoom ||
      tripsResult?.trips.length === 1 ||
      (!clustersRendered.current && !!currentZoom);

    /** When the map is zoomed in on a specific trip/property we generally don't want to update clusters
     * since you can't see any other markers at that zoom level. The exception is when we are arriving at
     * the map view with a selected preview property or when the count for that status is 1, we need to
     * update the clusters after zooming in on that property otherwise the marker will not render.
     * Additionally, sometimes the initial zoom level would prevent the markers from appearing, so we
     * will attempt to update the clusters for that reason as well.
     */
    if (shouldUpdateClusters) {
      const bounds = map?.getBounds()?.toJSON();
      if (!!bounds) {
        const mapClusters = returnClusters(bounds, currentZoom);
        if (mapClusters.length) {
          clustersRendered.current = true;
        }
        setClusters(mapClusters);
      }
    }
  }, [bookingNumber, map, tripsResult?.trips.length]);

  /** Used to asses whether a cluster is made up of reservations all at the same property.
   * For now, to achieve parity with Mapbox, we will select the first reservation in the list.
   * We will ultimately replace this with a better experience to choose from all reservations
   * in a cluster of the same property in Map view
   */
  const areAllLeavesSameProperty = (id: number): { areAllLeavesSame: boolean; property: ITrip } => {
    const leaves = supercluster.getLeaves(id);
    const areAllLeavesSame = leaves.every(
      (leaf) =>
        (leaf.properties as ITrip).bookings[0].details.property?.id ===
        (leaves[0].properties as ITrip).bookings[0].details.property?.id
    );

    return {
      areAllLeavesSame,
      property: leaves[0].properties as ITrip,
    };
  };

  const handleSelectCluster = (id: number, position: MapPosition) => {
    const { areAllLeavesSame, property } = areAllLeavesSameProperty(id);

    if (areAllLeavesSame) {
      handleMarkerClick(property, position);
    }

    const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(id), 22);
    map?.setZoom(expansionZoom);
    map?.panTo(position);
    const bounds = map.getBounds()?.toJSON();
    if (!!bounds) {
      const mapClusters = returnClusters(bounds, expansionZoom);
      setClusters(mapClusters);
    }
  };

  const handleMarkerClick = useCallback(
    (res: ITrip, position: MapPosition) => {
      if (!bookingNumber && !!map) {
        map.setZoom(SPECIFIC_PROPERTY_ZOOM);
        map.panTo(position);
      }
      setSearchParams((prev) => {
        prev.set("booking_number", res.bookings[0].details.internalConfirmationNumber as string);
        prev.set("type", "lodging");

        return prev;
      });
    },
    [bookingNumber, map, setSearchParams]
  );

  const mapMarkers = useMemo(() => {
    return clusters?.map((obj) => {
      const { id: clusterId, geometry, properties }: ClusterFeature<TripWithCluster> = obj;
      const [lng, lat] = geometry.coordinates;
      const { cluster, point_count, ...trip } = properties;

      const position = {
        lat: +lat,
        lng: +lng,
      };

      return cluster && !!clusterId ? (
        <PropertyCluster
          clusterObj={{
            clusterId: +clusterId,
            point_count,
          }}
          key={`cluster-${clusterId}`}
          handleSelectCluster={() => handleSelectCluster(+clusterId, position)}
          map={map as google.maps.Map}
          position={position}
        />
      ) : (
        <GoogleMapsTripsMarker
          map={map}
          position={position}
          propertyInfo={{
            name: `${(trip as ITrip).bookings[0].details.property?.name}`,
            address: `${(trip as ITrip).bookings[0].details.property?.street}`,
          }}
          trip={trip}
          onSelection={() => handleMarkerClick(trip, position)}
          key={trip.id}
        />
      );
    });
    // IGNORE-REASON ENS-2668 This still needs fixed!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusters, handleMarkerClick]);

  return <>{mapMarkers}</>;
};

export default GoogleMapsTripResults;
