import axios from "axios";
import type { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { v4 as uuidV4 } from "uuid";

import { getAccessToken } from "@hotel-engine/utilities/auth/getToken";
import { snakeToCamel } from "@hotel-engine/utilities/formatters/formatStrings";
import config from "config";
import { getLogoutRedirectLocation } from "@hotel-engine/utilities/helpers";

import { setAuthStatus } from "@hotel-engine/utilities/auth/events";
import LogRocket from "logrocket";
import { FssRequestHeaders } from "./constants/flightsSearch";

export const eventChallengePresent = "cloudflare-challenge";
export const eventChallengeSolved = "cloudflare-challenge-complete";

export const waitForAuth0ToLoad = (retries = 0) => {
  if (retries > 3) {
    throw new Error("Auth0 failed to load");
  }
  return new Promise((resolve, reject) => {
    if (typeof globalThis?.getAccessTokenSilently === "function") {
      resolve(void 0);
    } else {
      setTimeout(() => {
        waitForAuth0ToLoad(retries + 1)
          .then(resolve)
          .catch(reject);
      }, 50);
    }
  });
};

export const detectCloudFlareChallenge = (error, dataClient, originalRequest, retry = false) => {
  return new Promise((resolve, reject) => {
    if (!originalRequest.baseURL.startsWith(config.heApi)) {
      return reject(error);
    }
    const networkTest = config.heApi + "/cloudflare.txt?t=" + new Date().valueOf();
    axios
      .create({ withCredentials: true })
      .get(networkTest)
      .then(() => {
        // network connection is present and working, network may have flaked out or this is a cloudflare challenge
        console.log("CloudFlareChallenge: Challenge Present");
        const event = new CustomEvent(eventChallengePresent);
        dispatchEvent(event);
        const callback = () => {
          globalThis.removeEventListener(eventChallengeSolved, callback);
          console.log("CloudFlareChallenge: Challenge Complete");
          if (retry) {
            dataClient(originalRequest).then(resolve).catch(reject);
          } else {
            reject(error);
          }
        };
        globalThis.addEventListener(eventChallengeSolved, callback);
      })
      .catch(() => {
        reject(error);
      });
  });
};

let recordingURL;
LogRocket.getSessionURL(function (sessionURL) {
  recordingURL = sessionURL;
});

/*
 ************* WARNING When modifying headers *************
  Nexus has an whitelist of headers that are allowed to be sent to the API
  https://github.com/HotelEngine/he-api/blob/aa6bbf07e72f5871572721e37acc5597df5e380f/config/initializers/cors.rb#L30
  Update the allowlist before changing any headers here.

  Supply has an allowlist of headers that are allowed to be sent to the API
  https://github.com/HotelEngine/microservices/blob/main/projects/supply/serverless/functions.ts#L73
  Update the allowlist before changing any headers here.
*/
const overrideConfig = async (
  requestConfig: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  const { baseURL } = requestConfig;

  // Only overwrite `withCredentials` if the caller hasn't set it to something
  if (typeof requestConfig?.withCredentials === "undefined") {
    requestConfig.withCredentials = baseURL?.startsWith(config.heApi);
  }

  const isRequestToSupply = baseURL?.startsWith(config.supplyRepoHost);
  const isRequestToHeApi = baseURL?.startsWith(config.heApi);

  if (!isRequestToSupply) {
    requestConfig.headers.Accept = "application/json";
    requestConfig.headers.set("Key-Inflection", "camel");
  }

  const isRequestToFss = baseURL?.includes(config.fssHost);
  const hasFssRequestId = !!requestConfig.headers.get(FssRequestHeaders.RequestId);

  if (isRequestToFss && !hasFssRequestId) {
    requestConfig.headers.set(FssRequestHeaders.RequestId, uuidV4());
  }

  const hasFssTraceId = !!requestConfig.headers.get(FssRequestHeaders.TraceId);

  if (isRequestToFss && !hasFssTraceId) {
    requestConfig.headers.set(FssRequestHeaders.TraceId, uuidV4());
  }

  const token = await getAccessToken();
  if (token) {
    requestConfig.headers.setAuthorization(`Bearer ${token}`);
  }

  if (!!recordingURL && (isRequestToSupply || isRequestToHeApi)) {
    requestConfig.headers.set("X-LogRocket-URL", recordingURL);
  }

  return requestConfig;
};

export const performAuth0Logout = async () => {
  const redirectPath = getLogoutRedirectLocation();
  const returnTo = `${globalThis.location.origin}${redirectPath || "/"}`;
  setAuthStatus(false);
  if (typeof globalThis.auth0Logout === "function") {
    await globalThis.auth0Logout({
      logoutParams: {
        returnTo: returnTo,
      },
    });
  }
};

export const getClient = (signOutOnUnauthorized = true, version?: string) => {
  const { apiHostV2, apiHost } = config;
  const apiUrl = version === "v1" ? apiHost : apiHostV2;
  const dataClient = axios.create({ baseURL: apiUrl });
  setInterceptors(dataClient, signOutOnUnauthorized);
  return dataClient;
};

export const setInterceptors = (dataClient: AxiosInstance, signOutOnUnauthorized = true) => {
  dataClient.interceptors.request.use(
    async (requestConfig) => {
      const mutated = await overrideConfig(requestConfig);
      return mutated;
    },
    (error) => Promise.reject(error)
  );

  dataClient.interceptors.response.use(
    (response) => {
      const status = Number(response?.status);

      switch (status) {
        // handle batch partial success
        case 207:
          throw response.data;
        default:
          return response;
      }
    },
    async (error) => {
      const originalRequest = error?.config;
      const status = Number(error?.response?.status);
      const invalidRefreshTokenError = "Unknown or invalid refresh token.";
      if (typeof originalRequest === "undefined" || originalRequest?._retry === status) {
        if (signOutOnUnauthorized && error?.message === invalidRefreshTokenError) {
          await performAuth0Logout();
        }
        return Promise.reject(error);
      }
      originalRequest._retry = status;
      switch (status) {
        case 0:
          if (config.enableChallenge) {
            return detectCloudFlareChallenge(
              error,
              dataClient,
              originalRequest,
              config.retryOnChallenge
            );
          } else {
            return Promise.reject(error);
          }
        // Execute auth's signOut when 401 (Not Authorized) is response
        case 401:
          if (signOutOnUnauthorized) {
            await performAuth0Logout();
          }

          return Promise.reject(error);
        // handle field level errors
        case 422:
          if (error.response.data.invalidParams) {
            const fieldErrors = error.response.data.invalidParams.map((field) => {
              return {
                name: snakeToCamel(field.key),
                reasons: field.reasons,
              };
            });

            error.response.data.fieldErrors = fieldErrors;
          }

          if (error.response.data.clientMessages) {
            error.clientMessages = error.response.data.clientMessages;
          }

          throw error;
        default:
          return Promise.reject(error);
      }
    }
  );
};
