import type { AxiosResponse } from "axios";
import { useAsyncValue, useNavigation, useParams } from "@hotel-engine/lib/react-router-dom";
import TripsError from "../utils/TripsError";

import { endpoints } from "@hotel-engine/react-query/constants";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { ALLOWED_PARAMS, getValidatedQueryParams } from "../data/loader";
import type { TripsResult } from "../data/schemas/trips";
import { transformFilterKeys } from "../utils/filterKeys";
import useTripsControl from "./useTripsControl";
import { TRIPS_DEFAULT_GROUP } from "../data/querySchema";

export interface ITripsResponse {
  isLoading: boolean;
  data: TripsResult | null;
  error: TripsError | null;
}

/**
 * Retrieves trips route resolved data.
 *
 * @returns {ITripsResponse} The trips result.
 */
function useTripsResult(): ITripsResponse {
  const value = useAsyncValue() as AxiosResponse<TripsResult>;
  const [params] = useTripsControl();
  const navigation = useNavigation();
  const { status: group = TRIPS_DEFAULT_GROUP } = useParams();

  // validate and transform params to match the cache key structure used in
  // fetchTrips in order to retrieve the cached data using the same key
  const queryKey = useMemo(() => {
    const rawParams = { ...params };

    for (const key of ALLOWED_PARAMS) {
      delete rawParams[key];
    }

    const recomposedParams = transformFilterKeys({ ...rawParams, group });
    const validatedParams = getValidatedQueryParams(recomposedParams);

    return [endpoints.trips, validatedParams];
  }, [params, group]);

  const { data } = useQuery<unknown, unknown, TripsResult>({
    enabled: false,
    // use placeholderData to bring loader data under react query's cache management.
    // this allows us to maintain initial load performance while enabling reactive
    // updates when the cache is mutated.
    placeholderData: value?.data,
    queryKey,
    staleTime: Number.POSITIVE_INFINITY,
    keepPreviousData: true,
  });

  return useMemo(() => {
    // prioritize loader's value for errors since they don't come through cache,
    // while using react query's data for successful responses
    const error = value instanceof TripsError ? value : null;
    const isLoading = navigation.state === "loading";

    return { data: !error ? data : null, isLoading, error } as ITripsResponse;
  }, [data, navigation.state, value]);
}

export default useTripsResult;
