import { z } from "zod";

export const TRIPS_DEFAULT_TIMEZONE = "GMT";

/**
 * Retrieves the current browser's timezone using the Intl API.
 *
 * @example
 * const timezone = getBrowserTimezone();
 * // => "America/Denver" (or whatever the user's local timezone is)
 *
 * @returns {string} The IANA timezone identifier for the current browser
 */
export function getBrowserTimezone(): string {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return timeZone;
}

/**
 * Checks if a given timezone string is valid according to the IANA timezone database.
 *
 * @example
 * isValidTimezone("America/Denver") // => true
 * isValidTimezone("Invalid/Timezone") // => false
 *
 * @param timeZone - The timezone string to validate
 * @returns {boolean} True if the timezone is valid, false otherwise
 */
export function isValidTimezone(timeZone: string): boolean {
  try {
    Intl.DateTimeFormat(undefined, { timeZone });
    return true;
  } catch {
    return false;
  }
}

/**
 * Validates and resolves timezone strings using the Intl API.
 *
 * @example
 * // Valid timezone - returns the IANA timezone name
 * resolveTimezone.parse("America/Denver") // => "America/Denver"
 *
 * // Invalid timezone - returns "GMT"
 * resolveTimezone.parse("Invalid/Timezone") // => "GMT"
 */
export const resolveTimezone = z
  .preprocess((input) => {
    // If the input is not a string at all, treat it as "no input"
    // so the transform step can decide the fallback
    if (typeof input !== "string") {
      return undefined;
    }

    // If the input is an empty string, explicitly fallback to GMT,
    // otherwise Intl might interpret it as UTC
    if (input.trim() === "" || !isValidTimezone(input)) {
      return TRIPS_DEFAULT_TIMEZONE;
    }

    return input;
  }, z.string().optional())
  .transform((input) => input ?? getBrowserTimezone());
