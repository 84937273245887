import { ampli } from "ampli";

import { useSearchParams, useToggle, useURLParams } from "@hotel-engine/hooks";

import { useAppSelector } from "store/hooks";
import { selectSearchId } from "store/Search/SearchResults/selectors";

import * as Styled from "../../../../styles";
import type { MapLocations } from "../../../../types";

interface IQueryStringParams {
  s?: string;
}

type ExpandMapToggleProps = {
  handleExpandMap: () => void;
  isMapExpanded: boolean;
  mapLocation?: MapLocations;
};

const ExpandMapToggle = ({ isMapExpanded, handleExpandMap, mapLocation }: ExpandMapToggleProps) => {
  const searchId = useAppSelector(selectSearchId) ?? undefined;
  const [fullscreen, toggleFullscreen] = useToggle(false);

  const {
    params: { propertyId },
  } = useURLParams();
  const { s: propertySearchId } = useSearchParams<IQueryStringParams>();

  const mapExpanded = isMapExpanded || fullscreen;

  const handleExpansionToggle = () => {
    if (!mapExpanded) {
      if (mapLocation === "property") {
        ampli.clickEnlargeMapAroundProperty({
          searchId: Number(propertySearchId),
          propertyId: Number(propertyId),
        });
      } else {
        ampli.clickExpandMap({ searchId });
      }
    }

    handleExpandMap?.();
    toggleFullscreen();
  };

  return (
    <Styled.GoogleMapControlIconButtonRebrand
      aria-label={mapExpanded ? "compress map" : "expand map"}
      data-testid={mapExpanded ? "compress-map" : "expand-map"}
      icon={mapExpanded ? "compress" : "expand"}
      onClick={handleExpansionToggle}
    />
  );
};
export default ExpandMapToggle;
