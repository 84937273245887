import { TripType } from "@hotelengine/core-booking-web";
import { isValidPhoneNumber } from "libphonenumber-js";
import type { Moment } from "moment";
import moment from "moment";
import * as Yup from "yup";
import type { AnySchema } from "yup";

import { earliestAllowedCheckinDate } from "@hotel-engine/constants";
import { flightLocationSchema } from "@hotel-engine/types/flights/flights.form.types";
import config from "config";
import { maxGuestsPerRoom } from "@hotel-engine/app/LodgingOffersForm/components/RoomAndGuestsHandler/constants";

export const passwordRegEx = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}/;

export const password = (text?: string) => typeof text === "string" && !!text.match(passwordRegEx);

export const email = (text?: string) => typeof text === "string" && /^.+@.+\..+$/.test(text);

export const phone = (text?: string) => {
  const pattern = /s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*/;
  const valid = typeof text === "string" && !!text.match(pattern);
  return valid;
};

// Allows all international & US formats, including (),-,+ in the right places, and extensions
// Requires at least 10 characters and no more than 6 identical characters in a row
export const phoneRegEx =
  /^\s*(?:\+?(\d{1,3}))?\s?([-. (]{0,1})(\d{3})([-. )]{0,1})\s?(\d{3})([-. ]{0,1})(\d{4})(?: *x(\d+))?\s*$/;

// Allows only US formats, including (),- in the right places
// Maximum of 10 digits
export const phoneRegExUS = /^\(?([0-9]{3})\)?([-. ]{0,1})([0-9]{3})([-. ]{0,1})([0-9]{4})$/;

export const PhoneNumberSchema = (message: string) =>
  Yup.object().shape({
    phone: Yup.string()
      .matches(phoneRegEx, { message })
      .required("Please enter a valid phone number"),
  });

export const IntlPhoneNumberSchema = () =>
  Yup.object().shape({
    phone: Yup.string()
      .test("invalidPhoneNumber", "Please enter a valid phone number", (value) => {
        if (!value) return false;
        return isValidPhoneNumber(value);
      })
      .required("Please enter a valid phone number"),
  });

// This RegEx matches the backend validation, but allows uppercase characters
export const emailRegEx =
  // eslint-disable-next-line no-useless-escape
  /^([-a-z0-9!\#$%&'*+\/=?^_`{|}~]+\.)*[-a-z0-9!\#$%&'*+\/=?^_`{|}~]+@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
export const strictEmail = (text?: string) => typeof text === "string" && emailRegEx.test(text);
export const GuestSelectSchema = Yup.object().shape({
  email: Yup.string().matches(emailRegEx, { message: "Invalid email" }).required("Required"),
  name: Yup.string()
    .matches(/(\w.+\s).+/, {
      message: "First and last name required",
      excludeEmptyString: true,
    })
    .required("Guest name required"),
  phone: Yup.string().matches(phoneRegEx, { message: "Invalid phone number" }).required("Required"),
});

export const LegacySearchFormSchema = {
  shape: {
    selectedLocation: Yup.object()
      .shape({
        description: Yup.string().required(),
        latitude: Yup.mixed().test(
          "latitude",
          "Latitude must be a number or string",
          (latitude) => {
            // Can be either number or string according to existing interfaces
            // Will update as the requirements become more clear
            return (
              (typeof latitude === "string" && latitude.length > 0) || typeof latitude === "number"
            );
          }
        ),
        longitude: Yup.mixed().test(
          "longitude",
          "Longitude must be a number or string",
          (longitude) => {
            return (
              (typeof longitude === "string" && longitude.length > 0) ||
              typeof longitude === "number"
            );
          }
        ),
        types: Yup.array().of(Yup.string()),
      })
      .required(),
    checkIn: Yup.object<Record<keyof Moment, AnySchema>>()
      .required()
      .test("checkIn", "Check In date must not be earlier than today", (checkIn) => {
        return (checkIn as Moment)?.isSameOrAfter(earliestAllowedCheckinDate, "day");
      })
      .test("checkIn", "Dates must not exceed the max range", (checkIn) => {
        return (
          (checkIn &&
            (checkIn as Moment)?.isSameOrBefore(
              moment().add(config.maxSearchRange, "days"),
              "day"
            )) ||
          new Yup.ValidationError("Dates must not exceed the max range", null, "checkIn")
        );
      }),
    checkOut: Yup.object<Record<keyof Moment, AnySchema>>().required(),
    roomCount: Yup.number().required().min(1).max(8),
    guestCount: Yup.number().required().min(1),
  },
  rules: {
    checkInBeforeCheckout: () => ({
      name: "checkOut",
      message: "Check In date must precede Check Out date",
      test: (values) => {
        return (
          (values.checkIn as Moment)?.isBefore(values.checkOut) ||
          new Yup.ValidationError("Check In date must precede Check Out date", null, "checkOut")
        );
      },
    }),
    maxSearchCheckIn: (maxSearchRange: number) => ({
      name: "checkIn",
      message: "Selected check-in must not be the last possible date for search (maxSearchRange)",
      test: (values) => {
        return (
          (values.checkIn && !values.checkIn.isSame(moment().add(maxSearchRange, "days"), "day")) ||
          new Yup.ValidationError(
            "Selected check-in must not be the last possible date for search",
            null,
            "checkIn"
          )
        );
      },
    }),
    maxNights: (maxNights: number) => ({
      name: "checkOut",
      message: "Selected dates must not exceed the maximum number of nights",
      test: (values) => {
        return (
          (values.checkIn &&
            values.checkOut &&
            values.checkOut.diff(values.checkIn, "days") <= maxNights) ||
          new Yup.ValidationError(
            "Selected dates must not exceed the maximum number of nights",
            null,
            "checkOut"
          )
        );
      },
    }),
    guestCountCantBeMoreThanRooms: () => ({
      name: "guestCount",
      message: "Guest Count must at least be equal to the number of rooms",
      test: (values) => {
        return (
          values.guestCount >= values.roomCount ||
          new Yup.ValidationError(
            "Guest Count must at least be equal to the number of rooms",
            null,
            "guestCount"
          )
        );
      },
    }),
    guestCountCantBeMoreThanTwiceTheRoomCount: () => ({
      name: "guestCount",
      message: "Guest Count at most must be equal to twice the number of rooms",
      test: (values) => {
        return (
          values.guestCount <= values.roomCount * 2 ||
          new Yup.ValidationError(
            "Guest Count at most must be equal to twice the number of rooms",
            null,
            "guestCount"
          )
        );
      },
    }),
  },
};

export const SearchFormSchema = {
  shape: {
    selectedLocation: Yup.object()
      .shape({
        description: Yup.string().required(),
        latitude: Yup.mixed().test(
          "latitude",
          "Latitude must be a number or string",
          (latitude) => {
            // Can be either number or string according to existing interfaces
            // Will update as the requirements become more clear
            return (
              (typeof latitude === "string" && latitude.length > 0) || typeof latitude === "number"
            );
          }
        ),
        longitude: Yup.mixed().test(
          "longitude",
          "Longitude must be a number or string",
          (longitude) => {
            return (
              (typeof longitude === "string" && longitude.length > 0) ||
              typeof longitude === "number"
            );
          }
        ),
        types: Yup.array().of(Yup.string()),
      })
      .required(),
    checkIn: Yup.object<Record<keyof Moment, AnySchema>>()
      .required()
      .test("checkIn", "Check In date must not be earlier than today", (checkIn) => {
        return (checkIn as Moment)?.isSameOrAfter(earliestAllowedCheckinDate, "day");
      })
      .test("checkIn", "Dates must not exceed the max range", (checkIn) => {
        return (
          (checkIn &&
            (checkIn as Moment)?.isSameOrBefore(
              moment().add(config.maxSearchRange, "days"),
              "day"
            )) ||
          new Yup.ValidationError("Dates must not exceed the max range", null, "checkIn")
        );
      }),
    checkOut: Yup.object<Record<keyof Moment, AnySchema>>().required(),
    roomCount: Yup.number().required().min(1).max(8),
    guestCount: Yup.number().required().min(1),
    childCount: Yup.number().min(0),
    childGuestAges: Yup.array().of(Yup.number().min(0).max(17)),
  },
  rules: {
    checkInBeforeCheckout: () => ({
      name: "checkOut",
      message: "Check In date must precede Check Out date",
      test: (values) => {
        return (
          (values.checkIn as Moment)?.isBefore(values.checkOut) ||
          new Yup.ValidationError("Check In date must precede Check Out date", null, "checkOut")
        );
      },
    }),
    maxSearchCheckIn: (maxSearchRange: number) => ({
      name: "checkIn",
      message: "Selected check-in must not be the last possible date for search (maxSearchRange)",
      test: (values) => {
        return (
          (values.checkIn && !values.checkIn.isSame(moment().add(maxSearchRange, "days"), "day")) ||
          new Yup.ValidationError(
            "Selected check-in must not be the last possible date for search",
            null,
            "checkIn"
          )
        );
      },
    }),
    maxNights: (maxNights: number) => ({
      name: "checkOut",
      message: "Selected dates must not exceed the maximum number of nights",
      test: (values) => {
        return (
          (values.checkIn &&
            values.checkOut &&
            values.checkOut.diff(values.checkIn, "days") <= maxNights) ||
          new Yup.ValidationError(
            "Selected dates must not exceed the maximum number of nights",
            null,
            "checkOut"
          )
        );
      },
    }),
    guestCountCantBeMoreThanRooms: () => ({
      name: "guestCount",
      message: "Guest Count must at least be equal to the number of rooms",
      test: (values) => {
        return (
          values.guestCount >= values.roomCount ||
          new Yup.ValidationError(
            "Guest Count must at least be equal to the number of rooms",
            null,
            "guestCount"
          )
        );
      },
    }),
    guestCountCantBeMoreThanTwiceTheRoomCount: () => ({
      name: "guestCount",
      message: `Guest Count at most must be equal to ${maxGuestsPerRoom} the number of rooms`,
      test: (values) => {
        return (
          values.guestCount <= values.roomCount * maxGuestsPerRoom ||
          new Yup.ValidationError(
            `Guest Count at most must be equal to ${maxGuestsPerRoom} the number of rooms`,
            null,
            "guestCount"
          )
        );
      },
    }),
    childGuestAgesValidation: () => ({
      name: "childGuestAges",
      message: "All children must have a valid age between 0 and 17",
      test: (values) => {
        const { childCount = 0, childGuestAges = [] } = values;

        // If no children, empty array is valid
        if (childCount === 0) {
          return true;
        }

        // Check if array length matches childCount
        if (!childGuestAges || childGuestAges.length !== childCount) {
          return new Yup.ValidationError(
            "Number of ages must match number of children",
            null,
            "childGuestAges"
          );
        }

        // Check that all ages are valid numbers between 0 and 17
        for (let i = 0; i < childGuestAges.length; i++) {
          const age = childGuestAges[i];

          if (age === "" || !Number.isInteger(Number(age)) || Number(age) < 0 || Number(age) > 17) {
            return new Yup.ValidationError(
              "All children must have a valid age between 0 and 17",
              null,
              "childGuestAges"
            );
          }
        }

        return true;
      },
    }),
  },
};

const SliceCriteria = Yup.object().shape({
  origin: flightLocationSchema.required("Departure location is required"),
  departureDate: Yup.object<Record<keyof Moment, AnySchema>>()
    .required("Date is required")
    .test(
      "departureDate in the future",
      "Departure date must not be earlier than today",
      (departureDate) => {
        const earliestDepartureDate = moment();
        return (departureDate as Moment).isSameOrAfter(earliestDepartureDate, "day");
      }
    ),
  destination: flightLocationSchema.required("Arrival location is required"),
});

const SliceCriteriaWidgetForm = Yup.object().shape({
  origin: flightLocationSchema.required("Departure location is required"),
  departureDate: Yup.string()
    .required("Date is required")
    .test(
      "departureDate in the future",
      "Departure date must not be earlier than today",
      (departureDateString) => {
        const earliestDepartureDate = new Date();
        const departureDate = new Date(departureDateString);
        earliestDepartureDate.setHours(0, 0, 0, 0);
        departureDate.setHours(0, 0, 0, 0);
        return departureDate >= earliestDepartureDate;
      }
    ),
  destination: flightLocationSchema.required("Arrival location is required"),
});

/**
 * Schema for flight search form
 * @deprecated Will be replaced by FlightSearchFormWidgetSchema once form widget is fully implemented
 * @see FlightSearchFormWidgetSchema
 */
export const FlightSearchFormSchema = {
  shape: {
    tripType: Yup.string()
      .oneOf([TripType.roundTrip, TripType.oneWay, TripType.multiCity])
      .required(),
    slicesCriteria: Yup.array()
      .of(
        SliceCriteria.test(
          "different locations",
          "Departure and arrival locations must be different",
          (sliceCriteria, { path }) => {
            /**
             * This rule is impacted by onSelectedItemChange method in useFlightLocationAutocomplete hook.
             * It can prevent a location from being selected.
             */
            const { origin, destination } = sliceCriteria;

            const isOriginDifferentFromDestination =
              origin?.iataCode !== destination?.iataCode || origin?.type !== destination?.type;

            return (
              isOriginDifferentFromDestination ||
              new Yup.ValidationError(
                ["origin", "destination"].map(
                  (propertyName) =>
                    new Yup.ValidationError(
                      "Departure and arrival locations must be different",
                      null,
                      `${path}.${propertyName}`
                    )
                )
              )
            );
          }
        )
      )
      .test(
        "sequential departure dates",
        "Date must be later than previous flight",
        (slicesCriteria = [], { path }) => {
          const invalidDates = slicesCriteria.reduce((accInvalidDates, currentSlice, index) => {
            if (index === 0) return accInvalidDates;

            const previousSlice = slicesCriteria[index - 1];
            const isValid = (previousSlice.departureDate as Moment).isSameOrBefore(
              currentSlice.departureDate as Moment
            );

            if (!isValid) accInvalidDates.push(index);

            return accInvalidDates;
          }, [] as number[]);

          return (
            !invalidDates.length ||
            new Yup.ValidationError(
              invalidDates.map(
                (sliceNumber) =>
                  new Yup.ValidationError(
                    `Date must be later than previous flight`,
                    null,
                    `${path}[${sliceNumber}].departureDate`
                  )
              )
            )
          );
        }
      ),
    passengers: Yup.object()
      .shape({
        adult: Yup.number().required().min(0),
        child: Yup.object().shape({
          count: Yup.number().required().min(0),
          childrenAges: Yup.array()
            .of(Yup.number().required().min(0))
            .test("childrenAges", "Age is required", (childGuestAges) =>
              childGuestAges?.every((age) => age !== null)
            ),
        }),
        infant: Yup.number()
          .required()
          .min(0)
          .test("infant", "Number of infants cannot exceed number of adults", function (value) {
            const adultCount = this.parent.adult;
            return value <= adultCount;
          }),
      })
      .test(
        "passengers count",
        "Travelers count must be greater than 0 and less than 10",
        (passengers, { path }) => {
          const passengersCount = passengers.adult + passengers.child.count + passengers.infant;
          return (
            (passengersCount <= 9 && passengersCount >= 1) ||
            new Yup.ValidationError(
              "Travelers count must be greater than 0 and less than 10",
              null,
              path
            )
          );
        }
      ),
  },
};

// Schema for flights form using the new form widget
const FlightSearchFormWidgetShape = Yup.object().shape({
  tripType: Yup.string()
    .oneOf([TripType.roundTrip, TripType.oneWay, TripType.multiCity])
    .required(),
  slicesCriteria: Yup.array().of(
    SliceCriteriaWidgetForm.test(
      "different locations",
      "Departure and arrival locations must be different",
      (sliceCriteria, { path }) => {
        /**
         * This rule is impacted by onSelectedItemChange method in useFlightLocationAutocomplete hook.
         * It can prevent a location from being selected.
         */
        const { origin, destination } = sliceCriteria;

        const isOriginDifferentFromDestination =
          origin?.iataCode !== destination?.iataCode || origin?.type !== destination?.type;

        return (
          isOriginDifferentFromDestination ||
          new Yup.ValidationError(
            ["origin", "destination"].map(
              (propertyName) =>
                new Yup.ValidationError(
                  "Departure and arrival locations must be different",
                  null,
                  `${path}.${propertyName}`
                )
            )
          )
        );
      }
    )
  ),
  passengers: Yup.object()
    .shape({
      adult: Yup.number().required().min(0),
      child: Yup.object().shape({
        count: Yup.number().required().min(0),
        childrenAges: Yup.array()
          .of(Yup.number().required().min(0))
          .test("childrenAges", "Age is required", (childGuestAges) =>
            childGuestAges?.every((age) => age !== null)
          ),
      }),
      infant: Yup.number()
        .required()
        .min(0)
        .test("infant", "Number of infants cannot exceed number of adults", function (value) {
          const adultCount = this.parent.adult;
          return value <= adultCount;
        }),
    })
    .test(
      "passengers count",
      "Travelers count must be greater than 0 and less than 10",
      (passengers, { path }) => {
        const passengersCount = passengers.adult + passengers.child.count + passengers.infant;
        return (
          (passengersCount <= 9 && passengersCount >= 1) ||
          new Yup.ValidationError(
            "Travelers count must be greater than 0 and less than 10",
            null,
            path
          )
        );
      }
    ),
});

export const FlightSearchFormWidgetSchema = FlightSearchFormWidgetShape.test(
  "sequential departure dates",
  "Date must be later than previous flight",
  (formValue) => {
    const { slicesCriteria = [], tripType } = formValue;
    const invalidDates = slicesCriteria.reduce((accInvalidDates, currentSlice, index) => {
      if (index === 0) return accInvalidDates;

      const previousSlice = slicesCriteria[index - 1];
      const previousSliceDepartureDate = new Date(previousSlice.departureDate);
      previousSliceDepartureDate.setHours(0, 0, 0, 0);
      const currentSliceDepartureDate = new Date(currentSlice.departureDate);
      currentSliceDepartureDate.setHours(0, 0, 0, 0);
      const isValid =
        tripType === TripType.multiCity
          ? currentSliceDepartureDate > previousSliceDepartureDate
          : currentSliceDepartureDate >= previousSliceDepartureDate;

      if (!isValid) accInvalidDates.push(index);

      return accInvalidDates;
    }, [] as number[]);

    return (
      !invalidDates.length ||
      new Yup.ValidationError(
        invalidDates.map(
          (sliceNumber) =>
            new Yup.ValidationError(
              `Date must be later than previous flight`,
              null,
              `slicesCriteria[${sliceNumber}].departureDate`
            )
        )
      )
    );
  }
);
/**
 * Schema for cars search form
 */
export const CarsSearchFormSchema = {
  shape: {
    age: Yup.number().nullable(),
    pickupLocationId: Yup.string(),
    pickupLocationName: Yup.string(),
    pickupDate: Yup.object<Record<keyof Moment, AnySchema>>().required("Pick-up date required"),
    pickupTime: Yup.object<Record<keyof Moment, AnySchema>>().required("Pick-up time required"),
    dropoffLocationName: Yup.string(),
    dropoffDate: Yup.object<Record<keyof Moment, AnySchema>>().required("Drop-off date required"),
    dropoffTime: Yup.object<Record<keyof Moment, AnySchema>>().required("Drop-off time required"),
  },
  rules: {
    pickupValidLocation: () => ({
      test: (values) => {
        if (!values.pickupLocationId && !values.pickupLocationName) {
          return new Yup.ValidationError("Pick-up location required", null, "pickupLocationId");
        }
        if (!values.pickupLocationId) {
          return new Yup.ValidationError(
            "Valid pick-up location required",
            null,
            "pickupLocationId"
          );
        }

        return true;
      },
    }),
    dropoffValidLocation: () => ({
      test: (values) => {
        if (!values.pickupLocationId) {
          return true;
        }
        if (!values.dropoffLocationId && !values.dropoffLocationName) {
          return new Yup.ValidationError("Drop-off location required", null, "dropoffLocationId");
        }
        if (!values.dropoffLocationId) {
          return new Yup.ValidationError(
            "Valid drop-off location required",
            null,
            "dropoffLocationId"
          );
        }

        return true;
      },
    }),
    pickupDateTimeMin: () => ({
      test: (values) => {
        if (!values.pickupTime) {
          return false;
        }

        const pickupDateTime = values.pickupDate?.set({
          hour: values.pickupTime.hour(),
          minute: values.pickupTime.minute(),
        });

        return (
          pickupDateTime?.isSameOrAfter(moment().add({ hour: 1 })) ||
          new Yup.ValidationError(
            "Pick-up time must be at least 1 hour from now.",
            null,
            "pickupTime"
          )
        );
      },
    }),
    dropoffBeforePickup: () => ({
      test: (values) => {
        if (!values.pickupTime || !values.dropoffDate) {
          return false;
        }

        const pickupDateTime = values.pickupDate?.set({
          hour: values.pickupTime.hour(),
          minute: values.pickupTime.minute(),
        });
        const dropoffDateTime = values.dropoffDate?.set({
          hour: values.dropoffTime.hour(),
          minute: values.dropoffTime.minute(),
        });

        if (
          values.pickupDate.isSame(values.dropoffDate, "days") &&
          !pickupDateTime?.isSameOrBefore(dropoffDateTime)
        ) {
          return new Yup.ValidationError(
            "Drop-off time must be after pick-up.",
            null,
            "dropoffDate"
          );
        }

        return true;
      },
    }),
    validAge: () => ({
      test: (values) => {
        return (
          (values?.age ?? 25) >= 19 ||
          new Yup.ValidationError("Driver must be 19 or older.", null, "age")
        );
      },
    }),
  },
};
