import type { IOccupant } from "@hotel-engine/types/booking";
import { Avatar, Box, Icon, Typography } from "@hotelengine/atlas-web";
import { type CSSProperties, useMemo } from "react";
import {
  type ITravelerInfoBlockContentProps,
  TravelerInfoBlockContent,
} from "./TravelerInfoBlockContent";
import { TravelerInfoBlock } from "./styles";
import { CollapsibleContent } from "@hotel-engine/app/ItineraryContent/shared/CollapsibleContent";
import { FooterContent } from "./FooterContent";
import { tryFormatPhoneNumber } from "./helpers";

export const MAX_TRAVELERS_DISPLAYED = 4;

export const TravelerList: React.FC<{
  childCount?: number | null;
  dedupedTravelers: IOccupant[];
  styles?: CSSProperties;
}> = ({ childCount, dedupedTravelers, styles }) => {
  const hasChild = childCount && childCount > 0;

  const travelersContent = useMemo(() => {
    return dedupedTravelers.slice(0, MAX_TRAVELERS_DISPLAYED).map((traveler, i, collection) => {
      const isLastTraveler = i + 1 === collection.length;

      const visibleContent = (
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row" alignItems="center" gap={12}>
            <Avatar name={`${traveler.firstName} ${traveler.lastName}`} size={32} />
            <Typography variant="body/md" color="foregroundPrimary">
              {`${traveler.firstName} ${traveler.lastName}`}
            </Typography>
          </Box>
        </Box>
      );

      const hideDropdown = !traveler.phone && !traveler.email && !traveler.loyaltyRewardName;

      const travelerRowProps: ITravelerInfoBlockContentProps[] = [
        {
          iconLogo: <Icon name="circle-info" color="foregroundPrimary" />,
          leftContent: traveler.email,
        },
        {
          iconLogo: <Icon name="phone" color="foregroundPrimary" />,
          leftContent: tryFormatPhoneNumber(traveler.phone),
        },
        {
          iconLogo: <Icon name="he-loyalty--solid" color="accentPurple" />,
          leftContent: traveler.loyaltyRewardName,
          rightContent: traveler.loyaltyRewardProgramNumber,
        },
      ];

      const hiddenContent = (
        <Box display="flex" flexDirection="column">
          {travelerRowProps.map(
            (row) =>
              !!row.leftContent && (
                <TravelerInfoBlock key={row.leftContent}>
                  <TravelerInfoBlockContent {...row} />
                </TravelerInfoBlock>
              )
          )}
        </Box>
      );

      return (
        <CollapsibleContent
          key={`${traveler.firstName}__${traveler.id}`}
          toggleBtnLabel={visibleContent}
          hideDropdown={hideDropdown}
          styles={styles}
          footerContent={
            hasChild && isLastTraveler ? <FooterContent childCount={childCount} /> : null
          }
        >
          {hiddenContent}
        </CollapsibleContent>
      );
    });
  }, [dedupedTravelers, childCount, hasChild, styles]);

  return <>{travelersContent}</>;
};
