import { useCallback, type KeyboardEvent, type MouseEvent as ReactMouseEvent } from "react";

import { Typography, Checkbox } from "@hotelengine/atlas-web";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { UserPrefsActions } from "store/UserPrefs/UserPrefsRedux";
import { getInternalBusinessNavLinks } from "../../../constants";

import { InternalSettingsWrapper } from "./styles";
import { HeaderCTAItem } from "../UserDropdown/styles";

interface IInternalUserSettingsProps {
  isInternalUser: boolean;
  isMobile?: boolean;
}

export const InternalUserSettings = ({ isInternalUser, isMobile }: IInternalUserSettingsProps) => {
  const { showEngines, showRatelessProperties, showSearchRadiusCircle, showSearchMapTools } =
    useAppSelector((state) => state.UserPrefs);
  const dispatch = useAppDispatch();

  const toggleEnginePreferences = useCallback(
    (toggle: boolean) => {
      dispatch(UserPrefsActions.toggleEnginePreferences(toggle));
    },
    [dispatch]
  );
  const toggleRatelessProperties = useCallback(
    (toggle: boolean) => {
      dispatch(UserPrefsActions.toggleRatelessProperties(toggle));
    },
    [dispatch]
  );
  const toggleSearchRadiusCircle = useCallback(
    (toggle: boolean) => {
      dispatch(UserPrefsActions.toggleSearchRadiusCircle(toggle));
    },
    [dispatch]
  );
  const toggleSearchMapTools = useCallback(
    (toggle: boolean) => {
      dispatch(UserPrefsActions.toggleSearchMapTools(toggle));
    },
    [dispatch]
  );
  const onChangeKeyDown = useCallback((e: KeyboardEvent, cb: () => void) => {
    if (e.code === "Enter") {
      e.preventDefault();
      cb();
    }
  }, []);
  const onClick = useCallback((e: ReactMouseEvent, cb: () => void) => {
    e.preventDefault();
    cb();
  }, []);

  if (!isInternalUser) {
    return null;
  }

  const internalBusinessNavLinks = getInternalBusinessNavLinks({
    showEngines,
    showRatelessProperties,
    showSearchRadiusCircle,
    showSearchMapTools,
    toggleEnginePreferences,
    toggleRatelessProperties,
    toggleSearchRadiusCircle,
    toggleSearchMapTools,
  });

  return (
    <InternalSettingsWrapper>
      <Typography variant="heading/md" color="foregroundPrimary" paddingBottom={8}>
        Internal tools
      </Typography>
      {internalBusinessNavLinks.map(({ label, isChecked, onChange }) => {
        if (isMobile) {
          return (
            <Checkbox isChecked={isChecked} onCheckedChange={onChange} key={label}>
              {label}
            </Checkbox>
          );
        }
        return (
          <HeaderCTAItem
            key={label}
            onKeyDown={(e) => onChangeKeyDown(e, onChange)}
            onClick={(e) => onClick(e, onChange)}
            style={{ padding: "0px" }}
          >
            <Checkbox isChecked={isChecked} onCheckedChange={onChange}>
              {label}
            </Checkbox>
          </HeaderCTAItem>
        );
      })}
    </InternalSettingsWrapper>
  );
};
