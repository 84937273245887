// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace object {
  export const get = <T>(tree: object, path: string, separator = "."): T | undefined => {
    try {
      separator = separator || ".";

      return path
        .replace("[", separator)
        .replace("]", "")
        .split(separator)
        .reduce((node, k) => node[k], tree) as T;
    } catch (err) {
      return undefined;
    }
  };
}
