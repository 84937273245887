import { useMemo } from "react";
import { Box, Icon, Typography } from "@hotelengine/atlas-web";
import { useTranslation } from "react-i18next";
import * as Styled from "../styles";
import type { IChangeDatesContentItem } from "../types";
import { useDeviceBreakpoints } from "pages/Checkout/Lodging/hooks/useDeviceBreakpoints";

const ChangeDatesContentItem = ({
  title,
  description,
  showFlexMessage = false,
  action,
  dataTestId,
}: IChangeDatesContentItem) => {
  const { t } = useTranslation("itinerary");
  const device = useDeviceBreakpoints();

  const deviceVariant = useMemo(() => {
    if (device.isSmallMobile) {
      return { title: "body/sm-strong", description: "body/xs", button: "sm" } as const;
    }
    if (device.isMobile) {
      return { title: "body/sm-strong", description: "body/xs", button: "sm" } as const;
    }
    return { title: "body/sm-strong", description: "body/xs", button: "sm" } as const;
  }, [device]);

  return (
    <Styled.ChangeDatesContentItem onClick={action} data-testid={dataTestId}>
      <Box display="flex" flexDirection="column" style={{ flex: 1 }}>
        <Box display="flex" gap={8} flexDirection="row">
          <Typography variant={deviceVariant.title} color="foregroundPrimary">
            {title}
          </Typography>
          {showFlexMessage ? (
            <Box display="flex" gap={4} alignItems="center" flexDirection="row">
              <Typography variant={deviceVariant.description}>{t("flex.coveredBy")}</Typography>
              <Icon
                id="shield-check"
                name="he-flex"
                color="brandExtendedGreen"
                data-testid="flex-pro-logo"
                size={deviceVariant.button}
              />{" "}
              <Typography variant="body/xs">{t("flex.flex")}</Typography>
            </Box>
          ) : null}
        </Box>

        <Typography variant={deviceVariant.description} color="foregroundTertiary">
          {description}
        </Typography>
      </Box>
      <Icon name="chevron-right" size={deviceVariant.button} />
    </Styled.ChangeDatesContentItem>
  );
};

export default ChangeDatesContentItem;
