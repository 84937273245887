import { useEffect } from "react";
import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import { ToastErrorMessage } from "@hotel-engine/app/ToastErrorMessage";

import { useCancelBooking } from "../../hooks/useCancelBooking";
import { useCancelFormValues } from "../../hooks/useCancelFormValues";
import { useCancelRefundCalculator } from "../../hooks/useCancelRefundCalculator";
import CancelConfirmationModal from "../common/CancelConfirmationModal";
import CancellationButton from "../common/CancellationButton";
import CancellationTitle from "../common/CancellationTitle";
import CancelInfoBox from "../common/CancelInfoBox";
import CancelGuestChangeBox from "../common/CancelGuestChangeBox";
import { useQueryClient } from "react-query";
import { endpoints } from "@hotel-engine/react-query/constants";
import { toast } from "@hotelengine/atlas-web";

const SingleRoomCancellation = () => {
  const queryClient = useQueryClient();
  const {
    state: { showConfirmationModal, modificationStatus },
    reservation,
    dispatch,
  } = useModificationsContext();

  const { formValues } = useCancelFormValues({ reservation });

  const {
    cancelRefundAmount,
    cancelRefundType,
    isError: isRefundCalcError,
    isLoading: isRefundCalcLoading,
  } = useCancelRefundCalculator({
    reservation,
    cancelRooms: formValues.cancelRooms,
    modificationStatus,
  });

  const setShowStatusModal = () => {
    dispatch({
      type: "SET_SHOW_STATUS_MODAL",
      payload: true,
    });
  };

  const { submitCancelSingleRoomBooking } = useCancelBooking({
    reservation,
    cancelRoomsValue: formValues.cancelRooms,
    showStatusModal: setShowStatusModal,
    refundInfo: {
      totalRefundAmount: Number(cancelRefundAmount),
      refundType: cancelRefundType,
    },
    dispatch,
  });

  useEffect(() => {
    if (isRefundCalcError) {
      toast({
        title: "Oops! Something's not right.",
        description: (
          <ToastErrorMessage
            error={`There was an issue processing your cancellation. Please contact ${COMPANY_NAME} support at at 855-567-4683.`}
          />
        ),
        duration: 0,
        icon: "circle-exclamation",
        sentiment: "critical",
      });
    }
  }, [isRefundCalcError]);

  const handleCancelSingleRoom = async () => {
    await submitCancelSingleRoomBooking();
    queryClient.removeQueries(endpoints.trips);
  };

  return (
    <>
      <CancellationTitle />
      <CancelInfoBox
        cancelRefundAmount={cancelRefundAmount}
        cancelRefundType={cancelRefundType}
        cancelRooms={formValues.cancelRooms}
        isLoading={isRefundCalcLoading}
      />
      <CancelGuestChangeBox isLoading={isRefundCalcLoading} reservation={reservation} />
      <CancellationButton text="Cancel trip" isRefundCalcLoading={isRefundCalcLoading} />
      {!!showConfirmationModal && <CancelConfirmationModal onConfirm={handleCancelSingleRoom} />}
    </>
  );
};

export default SingleRoomCancellation;
