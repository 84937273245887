import { lazy, Suspense } from "react";

import config from "config";
const DevTools = lazy(() => import(/* webpackChunkName: "DevTools" */ "./DevTools"));

export const DevToolsIfProductionEnv = () => {
  if (config.stage === "production") {
    return null;
  }

  return (
    // Don't want to trigger full screen loading spinner fallback for DevTools
    <Suspense fallback={null}>
      <DevTools />
    </Suspense>
  );
};

export default DevToolsIfProductionEnv;
