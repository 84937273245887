import { Auth, User } from "@hotel-engine/services";
import { cleanAccessToken } from "@hotel-engine/utilities/auth";
import { setAuthStatus } from "@hotel-engine/utilities/auth/events";
import storage from "@hotel-engine/storage";
import { tokenKey } from "@hotel-engine/constants";
import type { AccessTokenStored } from "@hotel-engine/utilities/auth/helpers";
import type { AppDispatch } from "store";
import { AuthActions } from "store/Auth/AuthRedux";
import { queryClient } from "@hotel-engine/contexts";
import { captureException, captureMessage } from "@hotel-engine/utilities/logger";
import type { User as Auth0User } from "@auth0/auth0-react";

export const handleAuth0Callback = async (
  dispatch: AppDispatch,
  auth0User: Auth0User | undefined
) => {
  try {
    const tokens = storage.getSecureItem(tokenKey);
    const parsedTokens = JSON.parse(tokens as string) as AccessTokenStored;
    const updatedParsedTokens = {
      ...parsedTokens,
      unknown: "getFromAuth0",
    };

    storage.setSecureItem(tokenKey, JSON.stringify(updatedParsedTokens));
    cleanAccessToken("unknown"); // clean token except for unknown

    if (!auth0User) {
      captureMessage(`Auth0 user undefined in handleAuth0Callback`, {});
    }
    // log user email_verified status
    else if (!auth0User.email_verified && !auth0User.sub?.startsWith("saml")) {
      captureMessage(`User signed in with unverified email`, {
        email: auth0User.email,
        email_verified: auth0User.email_verified,
        sub: auth0User.sub,
      });
    }

    const newUser = await User.getSelf();
    if (newUser) {
      dispatch(AuthActions.setUser(newUser));
      queryClient.clear();
      setAuthStatus(true);
      Auth.redirect();
    }
  } catch (e) {
    captureException(e);
    await Auth.signOut("Auth0 Callback Error");
  }
};
