import { useEffect, useState } from "react";
import { useLocation } from "@hotel-engine/lib/react-router-dom";
import { useAppSelector } from "store/hooks";

import DownloadAppBanner from "@hotel-engine/app/DownloadAppBanner";
import { useCustomFieldsQuery } from "@hotel-engine/react-query/customFields/useCustomFieldsQuery";
import { captureMessage } from "@hotel-engine/utilities/logger";
import { Box } from "@hotelengine/atlas-web";
import NuclearErrorModal from "@hotel-engine/app/modals/NuclearErrorModal";

import type { ILocationState } from "pages/Itinerary";

import MarketingBanners from "../MarketingBanners";
import AdditionalInfo from "../AdditionalInfo";
import CompanyDetails from "../CompanyDetails";
import ItineraryRefundPolicy from "../ItineraryRefundPolicy";
import RefundDetails from "../RefundDetails";
import { RoomInfo } from "../RoomInfo";
import SummaryOfCharges from "../SummaryOfCharges";
import TripInfo from "../TripInfo";
import Location from "../Location";

import { useModificationsContext } from "../../../ModificationsContext";
import ModificationViews from "../../../shared/ModficationViews";
import ModificationStatusModal from "../../../shared/ModficationViews/components/ModificationStatusModal";
import { useNavigationBlocker } from "../../../shared/ModficationViews/components/NavigationModal/useNavigationBlocker";
import Travelers from "../Travelers";
import ReviewProperty from "../ItineraryActions/components/ReviewProperty";
import ModificationStatuses from "../ItineraryActions/components/ModificationStatuses";
import * as Styled from "./styles";

const ItineraryContentBody = () => {
  const user = useAppSelector((state) => state.Auth?.user);
  const {
    state: { modificationView, showStatusModal },
    reservation,
  } = useModificationsContext();
  const [currentReservation, setCurrentReservation] = useState(reservation);
  const { state: locationState } = useLocation<ILocationState>();

  useNavigationBlocker();

  useEffect(() => {
    if (reservation.contractNumber !== currentReservation.contractNumber) {
      setCurrentReservation(reservation);
    }
  }, [reservation, currentReservation]);

  const showModificationStatuses = reservation?.modifications?.all
    ? !!Object.keys(reservation.modifications.all).length
    : false;

  const isViewOnlyTraveler = user?.role === "view_only_traveler";

  const { data: customFields, isError: customFieldsError } = useCustomFieldsQuery(
    { departmentId: user?.department.id, role: user?.role },
    {
      onError(error) {
        captureMessage("Itinerary: Error loading all custom fields", {
          error,
          requestValue: "custom fields",
        });
      },
    }
  );

  /** This component makes up the body of the ItineraryContent that we will replace with "internal" modification flows.
   * When a dispatch is made from the ModificationsContext to update ModificationViews (defaults to undefined) we will
   * render the appropriate modification flow instead of the body content.
   */
  return (
    <>
      {!!showStatusModal && <ModificationStatusModal />}

      {!!modificationView ? (
        <Styled.ModificationViewContainer>
          <ModificationViews />
        </Styled.ModificationViewContainer>
      ) : (
        <>
          <Box marginTop={24}>
            <ReviewProperty />
          </Box>
          {!!showModificationStatuses && (
            <Box marginTop={24}>
              <ModificationStatuses
                modifications={reservation?.modifications}
                propertyName={reservation?.propertyName}
              />
            </Box>
          )}
          {!!reservation?.occupants?.length && (
            <Travelers
              travelers={reservation.occupants.flat()}
              childCount={reservation.childCount}
            />
          )}
          <TripInfo />
          <RoomInfo />
          {!isViewOnlyTraveler && (
            <CompanyDetails
              allCustomFields={customFields}
              reservation={currentReservation}
              setReservation={setCurrentReservation}
              openEditCustomFields={!!locationState?.editCustomFields}
              verificationRequired={!!user && user.business.directBillVerificationRequired}
            />
          )}
          <ItineraryRefundPolicy />
          <Location />
          <AdditionalInfo supportPhone={(!!user && user.business.supportPhone) || null} />
          <SummaryOfCharges user={user} />
          {!!reservation.refundBreakdowns?.length && <RefundDetails />}
          <DownloadAppBanner />
          <MarketingBanners />
          <NuclearErrorModal hasPageError={customFieldsError} />
        </>
      )}
    </>
  );
};

export default ItineraryContentBody;
