import type { IBillingInvoiceType } from "@hotel-engine/types/billing/invoices";
import type { InvoiceType } from "@hotel-engine/types/invoiceItem";

type BookingType = "car" | "flight" | "hotel" | "car-insurance";
type IconType = "car-side" | "plane" | "hotel" | "he-flex" | "file-invoice-dollar" | "user-shield";

export function getBookingType(bookingId: string): BookingType {
  if (/^F-/.test(bookingId)) {
    return "flight";
  }
  if (/^C-/.test(bookingId)) {
    return "car";
  }
  if (/^IC-/.test(bookingId)) {
    return "car-insurance";
  }

  return "hotel";
}

export function getBookingIconFromBookingId(bookingId: string): IconType {
  if (getBookingType(bookingId) == "flight") {
    return "plane";
  }
  if (getBookingType(bookingId) == "car") {
    return "car-side";
  }
  if (getBookingType(bookingId) == "car-insurance") {
    return "user-shield";
  }

  return "hotel";
}

export function getIconNameFromInvoiceType(
  invoiceType: InvoiceType | IBillingInvoiceType
): IconType {
  if (invoiceType === "Flight") {
    return "plane";
  }
  if (invoiceType === "Car") {
    return "car-side";
  }
  if (invoiceType === "FlexPro") {
    return "he-flex";
  }
  if (invoiceType === "Incidental") {
    return "file-invoice-dollar";
  }

  if (invoiceType === "Car Rental Protection") {
    return "user-shield";
  }

  return "hotel";
}
