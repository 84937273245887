import { ampli } from "ampli";
import config from "config";
import { useState } from "react";

import { useModificationsContext } from "@hotel-engine/app/ItineraryContent/ModificationsContext";
import { routes } from "@hotel-engine/constants";
import { useFormatDate } from "@hotel-engine/hooks";
import { useLocation, useNavigate } from "@hotel-engine/lib/react-router-dom";
import { Reservation } from "@hotel-engine/services";
import type { IUser } from "@hotel-engine/types/user";
import {
  formatCurrency,
  isStateOfTexasUser,
  isUserEligibleToViewAndUseRewards,
} from "@hotel-engine/utilities";
import { toast, Typography } from "@hotelengine/atlas-web";

import SummaryOfChargesItem from "@hotel-engine/app/ItineraryContent/shared/SummaryOfChargesItem";
import { COMPANY_NAME } from "@hotel-engine/constants/companyNames";
import LoyaltyReminder from "../LoyaltyReminder";
import FeeDetails from "./components/FeeDetails";
import FlexLine from "./components/FlexLine";
import NightlyRates from "./components/NightlyRate";
import * as Styled from "./styles";
import IncidentalBanner from "./components/IncidentalBanner";

export interface ITripExpensesProps {
  /** Current user */
  user: IUser | null;
}

const SummaryOfCharges = ({ user }: ITripExpensesProps) => {
  const { reservation } = useModificationsContext();
  const navigate = useNavigate();
  const rrLocation = useLocation();

  const isViewOnlyTraveler = user?.role === "view_only_traveler";
  const userIsBooker = user?.id === reservation.rewardsUserId;
  const currencyCode = user?.business?.currencyCode || "USD";

  const [requestReceiptStatus, setRequestReceiptStatus] = useState<
    "success" | "loading" | "error" | ""
  >("");

  const hideNonTotalLineCharges =
    reservation?.supplier === "Manual" && reservation.status === "cancelled";
  const showMemberSavings = Number(reservation?.charges?.memberSavingsTotal) > 0;
  const paidOnDate = useFormatDate(reservation.createdAt, "MMM DD YYYY") ?? "";
  const flexCost = (reservation.flexEnabled && parseFloat(reservation?.charges?.flexCost)) || 0;

  const showFlexLine = !!reservation.flexEnabled && reservation.flexType !== "flex_pro";

  const isBookingActiveOrUpcoming = /^(?!.*(completed|cancelled)).*$/.test(reservation.status);

  const loyaltyEntered =
    reservation?.occupants?.some((room) =>
      room?.some((occupant) => occupant.loyaltyRewardProgramNumber)
    ) || false;

  const isLoyaltyFeatureVisible =
    config.loyaltyEligibleEnabled === "true" &&
    !!user?.business.loyaltyRewardsEnabled &&
    !!loyaltyEntered &&
    !!isBookingActiveOrUpcoming;

  const requestReceipt = async () => {
    if (["loading", "success"].includes(requestReceiptStatus)) {
      return;
    }

    const { id, checkIn } = reservation;

    setRequestReceiptStatus("loading");

    try {
      await Reservation.getReceipt(id, checkIn);
      toast({
        title: "Receipt requested received",
        description:
          "Expect a receipt to be sent to the email address associated with this account within 24 hours.",
        icon: "circle-check",
        sentiment: "positive",
      });
      setRequestReceiptStatus("success");
    } catch {
      setRequestReceiptStatus("error");
      toast({
        title: "There was an issue requesting a receipt",
        description: "Please try again.",
        icon: "circle-exclamation",
        sentiment: "critical",
      });
    }
  };

  const handleReportBillingIssue = () => {
    navigate(`${routes.billingIssue}/${reservation.id}`, {
      state: {
        reservation,
        prevPath: rrLocation.pathname + rrLocation.search,
      },
    });
    ampli.clickReportBillingIssue({ bookingId: reservation?.contractNumber });
  };

  return !!user && !!reservation.charges ? (
    <Styled.GridContainer data-testid="summary-of-charges">
      <Typography variant="uppercase/xs-strong" marginBottom={8} color="foregroundSecondary">
        Payment info
      </Typography>
      <SummaryOfChargesItem
        label="Billing name"
        value={reservation.bookedBy}
        typography="body/md"
      />

      {!hideNonTotalLineCharges && (
        <SummaryOfChargesItem
          label="Payment method"
          value={reservation?.charges?.paymentType}
          typography="body/md"
        />
      )}

      <SummaryOfChargesItem label="Paid on" value={paidOnDate} typography="body/md" />
      <Styled.Divider dotted />

      {!hideNonTotalLineCharges && !!showMemberSavings && (
        <>
          <NightlyRates
            reservation={reservation}
            currencyCode={currencyCode}
            showBreakdown={isStateOfTexasUser(user) && reservation.nightlyRates?.length > 0}
            typography="body/md"
          />
          <SummaryOfChargesItem
            label={`${COMPANY_NAME} member savings`}
            value={`-${formatCurrency(
              Number(reservation.charges?.memberSavingsTotal),
              true,
              currencyCode
            )}`}
            labelColor="sentimentPositiveDefault"
            valueColor="sentimentPositiveDefault"
            typography="body/md"
          />
          <Styled.Divider dotted />
          <SummaryOfChargesItem
            label="Room subtotal"
            value={formatCurrency(Number(reservation.charges?.subtotal), true, currencyCode)}
            typography="body/md"
          />
        </>
      )}

      {!hideNonTotalLineCharges && !showMemberSavings && (
        <NightlyRates
          reservation={reservation}
          currencyCode={currencyCode}
          showBreakdown={isStateOfTexasUser(user) && reservation.nightlyRates?.length > 0}
          typography="body/md"
        />
      )}

      {!!showFlexLine && (
        <FlexLine reservation={reservation} currencyCode={currencyCode} flexCost={flexCost} />
      )}

      {!!reservation.allowIncidentals && Number(reservation?.charges?.incidentalsFee) > 0 && (
        <SummaryOfChargesItem
          label="Incidentals coverage"
          value={formatCurrency(Number(reservation?.charges?.incidentalsFee), true, currencyCode)}
          typography="body/md"
        />
      )}

      {Number(reservation?.charges?.bookingFee) > 0 && (
        <SummaryOfChargesItem
          label="Booking fee"
          value={formatCurrency(Number(reservation?.charges?.bookingFee), true, currencyCode)}
          typography="body/md"
        />
      )}

      {Number(reservation?.charges?.convenienceFee) > 0 && (
        <SummaryOfChargesItem
          label="Convenience fee"
          value={formatCurrency(reservation?.charges?.convenienceFee, true, currencyCode)}
          typography="body/md"
        />
      )}

      {!!Number(reservation?.charges?.prepaidFees) && (
        <SummaryOfChargesItem
          label="Property charge"
          value={formatCurrency(Number(reservation?.charges?.prepaidFees), true, currencyCode)}
          popoverContent="These are fees charged by the hotel at the time of booking."
          typography="body/md"
        />
      )}

      {!hideNonTotalLineCharges && (
        <SummaryOfChargesItem
          label="Taxes &amp; fees"
          value={formatCurrency(Number(reservation?.charges?.taxes), true, currencyCode)}
          popoverContent={`Fees are inclusive of ${COMPANY_NAME} overhead costs and incurred hotel and supplier fees. Taxes are charged by the hotel for tax obligations.`}
          typography="body/md"
        />
      )}

      {Number(reservation?.charges?.redeemedRewardPointsDollarTotal) > 0 && (
        <SummaryOfChargesItem
          label="Redeemed points"
          value={formatCurrency(
            -Math.abs(Number(reservation?.charges?.redeemedRewardPointsDollarTotal)),
            true,
            currencyCode
          )}
          labelColor="sentimentPositiveDefault"
          valueColor="sentimentPositiveDefault"
          typography="body/md"
        />
      )}

      {Number(reservation?.charges?.redeemedTravelCreditsTotal) > 0 && (
        <SummaryOfChargesItem
          label="Redeemed travel credits"
          value={formatCurrency(
            -Math.abs(Number(reservation?.charges?.redeemedTravelCreditsTotal)),
            true,
            currencyCode
          )}
          labelColor="sentimentPositiveDefault"
          valueColor="sentimentPositiveDefault"
          typography="body/md"
        />
      )}

      <FeeDetails charges={reservation} currencyCode={currencyCode} />

      <Styled.Divider />

      <SummaryOfChargesItem
        label={user?.business?.hotelCollect ? "Room total" : "Total charges"}
        value={formatCurrency(
          Number(reservation?.charges?.totalCustomerCharge),
          true,
          currencyCode
        )}
        labelColor="foregroundPrimary"
        isBold
        typography="body/md"
      />

      {reservation.rewardPoints > 0 &&
        !!userIsBooker &&
        isUserEligibleToViewAndUseRewards(user) && (
          <Styled.Rewards>
            <img src={`${config.cdnHost}/assets/rewards/Reward_Icon_12.svg`} alt="Rewards icon" />
            <Typography variant="body/sm" color="foregroundPrimary">
              You will earn{" "}
              <Typography variant="heading/sm" color="foregroundPrimary">
                {reservation.rewardPoints.toLocaleString()} {COMPANY_NAME} rewards points
              </Typography>
            </Typography>
          </Styled.Rewards>
        )}

      {!!reservation.hotelFeeAmount && (
        <SummaryOfChargesItem
          label="Due at check-in"
          value={formatCurrency(reservation.hotelFeeAmount, true, currencyCode)}
          popoverContent="These are fees due to the property at the time of check-in and will be charged by the front desk when you or your guest arrive."
          typography="body/md"
        />
      )}

      {reservation.travelCompAmount > 0 && (
        <SummaryOfChargesItem
          label="Travel credit comp"
          value={formatCurrency(reservation.travelCompAmount, true, currencyCode)}
          typography="body/md"
        />
      )}

      {!!((reservation.travelCompAmount ?? 0 > 0) && reservation.travelCompDescription) && (
        <Styled.Alert
          sentiment="helpful"
          title="A travel credit comp has been issued for this booking."
          testID="travel-comp-description"
        >
          {reservation?.travelCompDescription}
        </Styled.Alert>
      )}

      {reservation.status === "cancelled" &&
        parseInt(reservation.earnedTravelCreditsAmount) > 0 && (
          <SummaryOfChargesItem
            label="Travel credits earned"
            value={formatCurrency(reservation.earnedTravelCreditsAmount, true, currencyCode)}
            typography="body/md"
          />
        )}

      {!!isLoyaltyFeatureVisible && <LoyaltyReminder />}

      {!reservation.hotelCollect && reservation.allowIncidentals ? (
        <>
          <Typography variant="body/xs" color="foregroundTertiary" style={{ marginTop: "12px" }}>
            Payment has been made for the full amount of this reservation.
          </Typography>
          {!isViewOnlyTraveler && <IncidentalBanner />}
        </>
      ) : !reservation.hotelCollect ? (
        <Typography variant="body/xs" color="foregroundTertiary" style={{ marginTop: "12px" }}>
          Payment has been made for the full amount of the reservation; however, the guest must
          provide a valid credit card upon check in for any incidentals. Please be advised the hotel
          may place a pre-authorization on this card that will be released upon checkout.
        </Typography>
      ) : null}

      <Styled.FieldGridLine>
        {isStateOfTexasUser(user) && (
          <Styled.Link variant="link/sm" onClick={requestReceipt}>
            Request receipt
          </Styled.Link>
        )}
        {!isViewOnlyTraveler && (
          <Styled.Link variant="link/sm" onClick={handleReportBillingIssue}>
            Report billing issue
          </Styled.Link>
        )}
      </Styled.FieldGridLine>
    </Styled.GridContainer>
  ) : null;
};

export default SummaryOfCharges;
