import { createSlice } from "@reduxjs/toolkit";

import type { ISearchResultsState } from "store/Search/SearchResults/types";

import {
  setStreamLoading,
  setSpecificSearchLoading,
  setResultsExpired,
  setRateRefreshFailed,
  setRateRefreshLoading,
  setRateRefreshSuccess,
  removeExpiredResults,
  setAvailableAmenities,
  setStreamResults,
  setSpecificSearchResult,
  filterResults,
  sortResults,
  selectDecoratedSpotlightResult,
  setStreamError,
  setSpecificSearchError,
  updatePropertyFavoriteId,
  toggleIsMapSearch,
  setSessionId,
  clearRefreshContinuationTokens,
} from "./reducers";

export const initialState: ISearchResultsState = {
  checkIn: null,
  checkOut: null,
  decoratedSpotlightPropertyId: null,
  effectiveSearchRadius: null,
  effectiveSearchRadiusUnit: null,
  guestCount: null,
  id: null,
  isMapSearch: false,
  latitude: null,
  location: null,
  longitude: null,
  loyaltyEligible: false,
  outOfPolicyCount: null,
  outOfPolicyReasons: [],
  propertyIdsHidden: [],
  propertyIdsPreferred: [],
  propertyIdsSpotlight: [],
  radius: null,
  rateRefreshFailed: null,
  rateRefreshLoading: false,
  rateRefreshSuccess: false,
  refreshContinuationTokens: [],
  results: {},
  roomCount: null,
  sessionId: null,
  specificResult: undefined,
  specificSearchError: false,
  specificSearchId: undefined,
  specificSearchLoading: false,
  streamError: false,
  streamLoading: false,
  suppliers: [],
  visibleHiddenResultIds: [],
  visibleResultIds: [],
};

export const searchResultsSlice = createSlice({
  name: "SearchResults",
  initialState,
  reducers: {
    clearRefreshContinuationTokens,
    setStreamLoading,
    setSpecificSearchLoading,
    setResultsExpired,
    setRateRefreshFailed,
    setRateRefreshLoading,
    setRateRefreshSuccess,
    removeExpiredResults,
    setAvailableAmenities,
    setStreamResults,
    setSpecificSearchResult,
    filterResults,
    sortResults,
    selectDecoratedSpotlightResult,
    setStreamError,
    setSpecificSearchError,
    updatePropertyFavoriteId,
    toggleIsMapSearch,
    setSessionId,
    resetState: () => initialState,
  },
});

export const searchResultsActions = searchResultsSlice.actions;

export default searchResultsSlice.reducer;
