import type { PayloadAction } from "@reduxjs/toolkit";
import type { IFlightResult, KnownCabinClass } from "@hotelengine/core-booking-web";
import {
  type FlightsSortOptions,
  type ShelfBlockKey,
  StopFilterOptions,
  initialFlightsFilterState,
  AllAirlinesFilter,
  defaultCabinClassFilter,
} from "store/Flights/FlightsFilters/flights.filters.constants";
import {
  getUnselectedAirlineFilters,
  getAvailableFiltersFromResults,
} from "store/Flights/FlightsFilters/flights.filters.helpers";
import type { IFlightsSearchFilters } from "store/Flights/FlightsFilters/flights.filters.types";

export const resetFilters = () => initialFlightsFilterState;

export const initFlightsFilters = (
  state: IFlightsSearchFilters,
  action: PayloadAction<{
    results: IFlightResult[];
    departureDate: string;
    passengersCount: number;
    stops: StopFilterOptions;
    cabinClass: KnownCabinClass | null;
  }>
) => {
  const { cabinClass, results, departureDate, passengersCount, stops } = action.payload;

  return getAvailableFiltersFromResults({
    cabinClass,
    departureDate,
    passengersCount,
    results,
    state,
    stops,
  });
};

export const clearSelectedFilters = (state: IFlightsSearchFilters) => {
  // clear stops filter
  state.stops = StopFilterOptions.any;
  // clear airlines filter
  state.airlines = getUnselectedAirlineFilters(state.airlines);
  state.cabinClass = defaultCabinClassFilter;
  // clear price range
  state.priceRange.userMin = state.priceRange.floor;
  state.priceRange.userMax = state.priceRange.ceil;
  // clear takeoff time
  state.takeoffTime.userMin = state.takeoffTime.floor;
  state.takeoffTime.userMax = state.takeoffTime.ceil;
  // clear landing time
  state.landingTime.userMin = state.landingTime.floor;
  state.landingTime.userMax = state.landingTime.ceil;
  // clear duration
  state.duration.userMin = state.duration.floor;
  state.duration.userMax = state.duration.ceil;
  // clear in policy
  state.inPolicy = true;
};

export const setFlightsFilterStopOption = (
  state: IFlightsSearchFilters,
  action: PayloadAction<{ option: StopFilterOptions }>
) => {
  state.stops = action.payload.option;
};

export const setUserPriceFilter = (
  state: IFlightsSearchFilters,
  action: PayloadAction<{ userMin: number; userMax: number }>
) => {
  return {
    ...state,
    priceRange: {
      ...state.priceRange,
      userMin: action.payload.userMin,
      userMax: action.payload.userMax,
    },
  };
};

export const setDurationFilter = (
  state: IFlightsSearchFilters,
  action: PayloadAction<{ userMin: number; userMax: number }>
) => {
  return {
    ...state,
    duration: {
      ...state.duration,
      userMin: action.payload.userMin,
      userMax: action.payload.userMax,
    },
  };
};

export const setSortByOption = (
  state: IFlightsSearchFilters,
  action: PayloadAction<{ option: FlightsSortOptions }>
) => {
  state.selectedSort = action.payload.option;
};

export const setTakeOffTimesFilter = (
  state: IFlightsSearchFilters,
  action: PayloadAction<{ userMin: number; userMax: number }>
) => {
  state.takeoffTime.userMin = action.payload.userMin;
  state.takeoffTime.userMax = action.payload.userMax;
};

export const setLandingTimesFilter = (
  state: IFlightsSearchFilters,
  action: PayloadAction<{ userMin: number; userMax: number }>
) => {
  state.landingTime.userMin = action.payload.userMin;
  state.landingTime.userMax = action.payload.userMax;
};

export const setSelectedShelfBlock = (
  state: IFlightsSearchFilters,
  action: PayloadAction<ShelfBlockKey | "">
) => {
  Object.keys(state.shelf).forEach((key) => {
    state.shelf[key].selected = action.payload === state.shelf[key].value;
  });
};

export const toggleAirlineFilter = (
  state: IFlightsSearchFilters,
  action: PayloadAction<{ airlineIataCode: string }>
) => {
  const { airlineIataCode } = action.payload;
  if (airlineIataCode === AllAirlinesFilter) {
    const selectedAll = !state.airlines[AllAirlinesFilter];
    Object.keys(state.airlines).forEach((key) => {
      state.airlines[key] = selectedAll;
    });
  } else {
    state.airlines[airlineIataCode] = !state.airlines[airlineIataCode];
    state.airlines[AllAirlinesFilter] = Object.keys(state.airlines).every(
      (key) => key === AllAirlinesFilter || state.airlines[key]
    );
  }
};

export const toggleCabinClassFilter = (
  state: IFlightsSearchFilters,
  action: PayloadAction<{ cabinClass: KnownCabinClass }>
) => {
  const { cabinClass } = action.payload;
  state.cabinClass[cabinClass] = !state.cabinClass[cabinClass];
};

export const setInPolicyFilter = (state: IFlightsSearchFilters, action: PayloadAction<boolean>) => {
  state.inPolicy = action.payload;
};

export const setSearchFilters = (
  state: IFlightsSearchFilters,
  action: PayloadAction<IFlightsSearchFilters>
) => {
  const {
    airlines,
    cabinClass,
    duration,
    inPolicy,
    landingTime,
    priceRange,
    selectedSort,
    stops,
    takeoffTime,
  } = action.payload;

  state.airlines = airlines;
  state.cabinClass = cabinClass;
  state.duration = duration;
  state.inPolicy = inPolicy;
  state.landingTime = landingTime;
  state.priceRange = priceRange;
  state.selectedSort = selectedSort;
  state.stops = stops;
  state.takeoffTime = takeoffTime;
};
