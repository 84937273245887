import { Typography } from "@hotelengine/atlas-web";
import type { IBusiness } from "@hotel-engine/types/business";
import { formatCurrency, formatDate } from "@hotel-engine/utilities";

import * as Styled from "./styles";
import { useTypographyScaling } from "@hotel-engine/app/ItineraryContent/hooks/useTypographyScaling";
import type { IRefundBreakdown } from "@hotel-engine/types/reservation";

interface IRefundDetailsItem {
  business: IBusiness | undefined;
  refundBreakdown: IRefundBreakdown;
}

const RefundDetailsItem = ({ business, refundBreakdown }: IRefundDetailsItem) => {
  const { typographyVariants } = useTypographyScaling();

  return (
    <Styled.RefundDetailsItem>
      <Styled.FieldLine>
        <Typography variant={typographyVariants.body} color="foregroundSecondary">
          Refund method
        </Typography>
        <Typography variant={typographyVariants.body} color="foregroundPrimary">
          {refundBreakdown.originalFormOfPayment ? "Original form of payment" : "Travel credit"}
        </Typography>
      </Styled.FieldLine>

      <Styled.FieldLine>
        <Typography variant={typographyVariants.body} color="foregroundSecondary">
          Paid on
        </Typography>
        <Typography variant={typographyVariants.body} color="foregroundPrimary">
          {formatDate(
            refundBreakdown.createdAt,
            "MMM D YYYY",
            business?.preferredDateFormat || "mdy"
          )}
        </Typography>
      </Styled.FieldLine>

      <Styled.FieldLine>
        <Typography variant={typographyVariants.body} color="foregroundSecondary">
          Refund amount
        </Typography>
        <Typography variant={typographyVariants.body} color="foregroundPrimary">
          {formatCurrency(refundBreakdown.totalRefund || "", true, business?.currencyCode)}
        </Typography>
      </Styled.FieldLine>
    </Styled.RefundDetailsItem>
  );
};

export default RefundDetailsItem;
