import type { IBooking } from "@hotel-engine/types/trips";

import StatusChip from "./components/StatusChip";
import FlightDetailCell from "./components/FlightDetailCell";
import CarDetailCell from "./components/CarDetailCell";
import LodgingDetailCell from "./components/LodgingDetailCell";

export interface ITripDetailCellProps {
  data: IBooking;
  searchValue: string;
  isFullyCancelled: boolean;
}

const TripDetailCell = ({ data, searchValue, isFullyCancelled }: ITripDetailCellProps) => {
  const bookingType = data.bookingType;
  const details = data.details;

  const statusChip = (
    <StatusChip
      pendingModifications={details.bookingStatuses?.pendingModifications}
      isFullyCancelled={isFullyCancelled}
      cancelledRoomCount={data.cancelledRoomCount}
    />
  );

  if (bookingType === "flight") {
    return <FlightDetailCell details={details} statusChip={statusChip} />;
  }

  if (bookingType === "car") {
    return <CarDetailCell details={details} statusChip={statusChip} />;
  }

  if (bookingType === "lodging") {
    return <LodgingDetailCell data={data} searchValue={searchValue} statusChip={statusChip} />;
  }

  return null;
};

export default TripDetailCell;
