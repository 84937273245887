import * as Styled from "../TripsListItem/styles";

import { DateTime } from "luxon";

import { Typography, Icon as AtlasIcon, Box } from "@hotelengine/atlas-web";
import type { IconName } from "@hotelengine/atlas-core";
import { TravelersFooter } from "../TravelersFooter";
import StatusChip from "../../../views/TripsList/Columns/TripDetailCell/components/StatusChip";
import type {
  LodgingBooking,
  LodgingTraveler,
  LodgingBookingDetails,
} from "pages/Trips/data/schemas/lodging";

export interface ILodgingCardProps {
  booking: LodgingBooking;
  travelers: LodgingTraveler[];
  isFullyCancelled: boolean;
}

export const LodgingCard = ({ booking, travelers, isFullyCancelled }: ILodgingCardProps) => {
  return (
    <>
      <Styled.LodgingCard>
        <LodgingCardImage
          imageUrl={booking?.details?.property?.imageUrl || undefined}
          pendingModifications={booking?.details?.bookingStatuses?.pendingModifications}
          isFullyCancelled={isFullyCancelled}
          cancelledRoomCount={booking?.cancelledRoomCount || 0}
        />
        <LodgingCardInfo
          details={booking.details}
          roomCount={booking.roomCount || 0}
          cancelledRoomCount={booking?.cancelledRoomCount || 0}
        />
        <Styled.TravelersFooter>
          <AtlasIcon name={"user" as IconName} size="md" color="backgroundPrimary" />
          <TravelersFooter travelers={travelers} />
        </Styled.TravelersFooter>
      </Styled.LodgingCard>
    </>
  );
};

const LodgingCardImage = ({
  imageUrl,
  pendingModifications,
  isFullyCancelled,
  cancelledRoomCount,
}: {
  imageUrl?: string;
  pendingModifications?: boolean;
  isFullyCancelled?: boolean;
  cancelledRoomCount?: number;
}) => {
  return (
    <Styled.LodgingCardImage>
      <Styled.StatusChipContainer>
        <StatusChip
          pendingModifications={pendingModifications}
          isFullyCancelled={isFullyCancelled}
          cancelledRoomCount={cancelledRoomCount}
        />
      </Styled.StatusChipContainer>
      {!!imageUrl && <Styled.Image image={imageUrl}></Styled.Image>}
    </Styled.LodgingCardImage>
  );
};

const LodgingCardInfo = ({
  details,
  roomCount,
  cancelledRoomCount,
}: { details: LodgingBookingDetails; roomCount: number; cancelledRoomCount: number }) => {
  const roomCountCopy = `${roomCount > 1 ? roomCount : cancelledRoomCount} rooms`;
  const locationCopy = [
    details?.property?.city,
    details?.property?.state,
    details?.property?.country,
  ]
    .filter(Boolean)
    .join(", ");

  const sameMonth = DateTime.fromISO(details.checkIn).hasSame(
    DateTime.fromISO(details.checkOut),
    "month"
  );
  const datesCopy = `${DateTime.fromISO(details.checkIn).toFormat("MMM d")} - ${DateTime.fromISO(
    details.checkOut
  ).toFormat(sameMonth ? "d" : "MMM d")}`;

  const showRoomCountCopy = roomCount > 1 || (roomCount === 0 && cancelledRoomCount > 1);

  return (
    <Styled.LodgingCardInfo>
      <Typography variant="heading/lg" color="foregroundPrimary" padding={16}>
        {details?.property?.name}
      </Typography>
      <Styled.CardDivider />
      <Box padding={16} display="flex" flexDirection="row" justifyContent="space-between">
        <Styled.LodgingCardRow>
          <Typography variant="uppercase/2xs-strong" color="foregroundSecondary">
            {datesCopy}
          </Typography>
          <AtlasIcon name={"circle-small" as IconName} size="sm" color="neutralN200" />
          <Typography variant="uppercase/2xs-strong" color="foregroundSecondary">
            {locationCopy}
          </Typography>
        </Styled.LodgingCardRow>
        <Typography variant="uppercase/2xs-strong" color="foregroundSecondary">
          {showRoomCountCopy ? roomCountCopy : details.externalConfirmationNumber}
        </Typography>
      </Box>
    </Styled.LodgingCardInfo>
  );
};
