export enum DirectBillStatus {
  suspended = "suspended",
  enabled = "enabled",
  disabled = "disabled",
}

export enum LandingView {
  transactions = "transactions",
  directBill = "directBill",
}

export interface ICreditData {
  availableCredit: number | null;
  balance: number | null;
  creditLimit: number | null;
  directBillAchEnabled: boolean;
  hasInvoices: boolean;
  directBillCreditCardEnabled: boolean;
  directBillStatus: DirectBillStatus | null;
  directBillEnabledAt: string | null;
  directBillPromotionEnabled: boolean;
  directBillUserDefault: boolean;
  directBillVerificationRequired: boolean;
}
