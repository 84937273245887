import { AtlasRoot, type Theme } from "@hotelengine/atlas-web";
import { tokens } from "@hotelengine/atlas-web/dist/theme/tokens";
import { createContext, useContext, useMemo, useState } from "react";
import { ThemeProvider } from "styled-components";
import { globalTheme } from "../styles/themes/global.theme";

const themeMapping: Record<Theme, typeof globalTheme> = {
  default: {
    ...globalTheme,
  },
  dark: {
    ...globalTheme,
    ...tokens.dark,
  },
  defaultRefresh: {
    ...globalTheme,
    ...tokens.defaultRefresh,
  },
  darkRefresh: {
    ...globalTheme,
    ...tokens.dark,
  },
  defaultRebrand: {
    ...globalTheme,
    ...tokens.defaultRebrand,
  },
  darkRebrand: {
    ...globalTheme,
    ...tokens.darkRebrand,
  },
};

export const GlobalThemeContext = createContext({
  theme: "defaultRebrand",
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  setTheme: (t: Theme) => {},
  tokens: themeMapping.default,
  isDark: false,
});

export const GlobalThemeProvider = ({
  theme: propsTheme = "defaultRebrand",
  children,
}: {
  theme?: Theme;
  children?: React.ReactNode;
}) => {
  const [theme, setTheme] = useState<Theme>(propsTheme);

  const contextVal = useMemo(
    () => ({
      theme,
      setTheme,
      tokens: themeMapping[theme],
      isDark: theme.includes("dark"),
    }),
    [theme, setTheme]
  );

  return (
    <GlobalThemeContext.Provider value={contextVal}>
      <AtlasRoot theme={theme}>
        <ThemeProvider theme={contextVal.tokens}>{children}</ThemeProvider>
      </AtlasRoot>
    </GlobalThemeContext.Provider>
  );
};

export const useGlobalTheme = () => useContext(GlobalThemeContext);
