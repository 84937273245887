import { useEffect, useMemo, useState } from "react";
import { useBreakpoint } from "@hotel-engine/hooks";
import useTripsResult from "pages/Trips/hooks/useTripsResult";
import { useParams } from "@hotel-engine/lib/react-router-dom";
import useTripsControl from "pages/Trips/hooks/useTripsControl";
import { TRIPS_DEFAULT_LIMIT, TRIPS_DEFAULT_OFFSET } from "pages/Trips/data/querySchema";
import type { TripData } from "pages/Trips/data/schemas/trips";

export const usePaginationControls = () => {
  const [params] = useTripsControl();
  const result = useTripsResult();
  const { status } = useParams();
  const [cachedTrips, setCachedTrips] = useState<TripData[]>([]);
  const isMobileScreen = useBreakpoint("xxl", "max");

  const showInMemoryPagination = useMemo(
    () => ["all", "past"].includes(status as string),
    [status]
  );

  const page = useMemo(() => {
    const offset = Number(params.offset) || TRIPS_DEFAULT_OFFSET;
    const limit = Number(TRIPS_DEFAULT_LIMIT) || TRIPS_DEFAULT_LIMIT;

    return Math.floor(offset / limit);
  }, [params.offset]);

  const isNextDisabled = useMemo(() => {
    if (!result.data?.trips?.length) return true;

    return result.data.trips.length === 0 || result.data.trips.length < Number(result.data.limit);
  }, [result]);

  useEffect(() => {
    if (isMobileScreen) return;
    if (showInMemoryPagination && result.data?.trips) {
      setCachedTrips((prevTrips) => [...prevTrips, ...(result.data?.trips || [])]);
    }
  }, [isMobileScreen, result.data, showInMemoryPagination]);

  useEffect(() => {
    if (isMobileScreen) return;

    setCachedTrips([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileScreen, result]);

  return { isNextDisabled, showInMemoryPagination, cachedTrips, page };
};
